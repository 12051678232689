export const PASSWORD_INVALID_MESSAGE =
  /* eslint-disable-next-line */
  'Your password must be at least 12 characters long and must contain at least one lowercase letter, one uppercase letter, one digit, no spaces, and one special character (allowed: _-.: @!#$%^&+=).';

const validatePassword = (password, newUser) => {
  if (newUser && !password) {
    return 'Password is required';
  }

  if (password) {
    /* eslint-disable-next-line */
    const pattern = /^(?!.*\s)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_\-.:@!#$%^&+=]).{12,}/;
    if (!pattern.test(password)) return PASSWORD_INVALID_MESSAGE;
  }

  return undefined;
};

export default validatePassword;
