import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Title } from 'react-admin';
import SaveAndRecomputeButton from './saveWithOptionalRecomputeButton';
import CheckpointDeleteButton from './checkpointDeleteButton.presenter';

const styles = {
  button: {
    margin: 5,
  },
  toolbar: {
    position: 'fixed',
    top: '30px',
    zIndex: 10,
    left: '66px',
    right: 0,
    justifyContent: 'space-between',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
};

/**
 * There's an issue with the 'CustomToolbar' component used elsewhere, when you try to add multiple children
 * along the DeleteButton. Just re-implementing it here.
 */
const CheckpointToolbar = ({
  title,
  record = { id: undefined },
  campaignId,
  handleSubmitWithRedirect,
  ...other
}) => (
  <Toolbar {...other} style={styles.toolbar}>
    <div style={styles.inline}>
      {/* <GoBack testid="back" /> */}
      {title ? <Title record={record} testid="editCheckpointTitle" /> : null}
    </div>
    <div>
      {record && record.id && (
        <CheckpointDeleteButton record={record} {...other} testid="deleteCheckpointButtonId" />
      )}
      <SaveAndRecomputeButton
        record={record}
        campaignId={campaignId}
        {...other}
        testId="saveAndRecomputeButtonId"
      />
    </div>
  </Toolbar>
);

CheckpointToolbar.propTypes = {
  record: PropTypes.shape.isRequired,
  campaignId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

CheckpointToolbar.defaultProps = {
  title: undefined,
};

export default CheckpointToolbar;
