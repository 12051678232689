import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { Button } from 'react-admin';

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

export const DeleteSegmentButtonPresenter = ({
  classes = {},
  className,
  icon,
  label = 'ra.action.delete',
  onClick,
}) => (
  <Button
    onClick={onClick}
    label={label}
    className={classnames('ra-delete-button', classes.deleteButton, className)}
    key="button"
  >
    {icon}
  </Button>
);

DeleteSegmentButtonPresenter.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

DeleteSegmentButtonPresenter.defaultProps = {
  classes: {},
  className: null,
  label: 'ra.action.delete',
  icon: <ActionDelete />,
};

export default withStyles(styles)(DeleteSegmentButtonPresenter);
