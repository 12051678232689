import React from 'react';
import {
  List,
  TextField,
  Datagrid,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ChipField,
  DateField,
  TextInput,
  FormDataConsumer,
  ReferenceField,
  SelectInput,
} from 'react-admin';
import PropTypes from 'prop-types';

import { renderUser } from '../users/services/render-helper';

const renderMission = mission => mission.location.name;
const renderSubmission = submission => `${submission.id} - ${submission.status}`;

export const EventFilters = props => (
  <Filter {...props}>
    <ReferenceInput label="Name" source="eventName" reference="events/names" alwaysOn>
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <ReferenceArrayInput
      sort={{ field: 'updatedAt', order: 'DESC' }}
      label="User"
      source="userId"
      reference="users"
      alwaysOn
    >
      <AutocompleteArrayInput optionText={renderUser} />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Campaigns"
      sort={{ field: 'updatedAt', order: 'DESC' }}
      source="campaignId"
      reference="campaigns"
      fullWidth
      alwaysOn
    >
      <AutocompleteArrayInput label="Campaigns" fullWidth />
    </ReferenceArrayInput>

    <FormDataConsumer form="filterForm" alwaysOn source="missionId">
      {({ formData }) =>
        formData.campaignId && (
          <ReferenceInput
            label="Mission"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="missionId"
            filter={{ campaignId: formData.campaignId }}
            reference="missions"
            fullWidth
            allowEmpty
          >
            <AutocompleteInput label="Mission" fullWidth optionText={renderMission} />
          </ReferenceInput>
        )
      }
    </FormDataConsumer>
    <FormDataConsumer form="filterForm" alwaysOn source="submissionId">
      {({ formData }) => {
        if (!formData.campaignId || !formData.missionId || !formData.userId) {
          return <TextInput label="Submission Id" source="submissionId" />;
        }
        if (formData.userId.length !== 1) {
          return <p>You can only select one user to link a submission</p>;
        }
        return (
          <ReferenceInput
            label="Submission"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="submissionId"
            filter={{
              campaignId: formData.campaignId,
              missionId: formData.missionId,
              userId: formData.userIds,
            }}
            reference="submissionsNew"
            fullWidth
            allowEmpty
          >
            <SelectInput label="Submission" fullWidth optionText={renderSubmission} />
          </ReferenceInput>
        );
      }}
    </FormDataConsumer>
    <ReferenceInput
      sort={{ field: 'updatedAt', order: 'DESC' }}
      label="Event triggered by"
      source="eventTriggeredBy"
      reference="users"
    >
      <AutocompleteInput optionText={renderUser} />
    </ReferenceInput>
  </Filter>
);

const Message = ({ record = {} }) => {
  const html = (record.message || '').replace(/\r\n|\r|\n/g, '<br>');
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
Message.propTypes = {
  record: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

const EventList = ({ classes, ...props }) => (
  <List
    {...props}
    filters={<EventFilters />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    perPage={100}
    pagination={null}
  >
    <Datagrid expand={<Message />}>
      <ChipField source="name" sortable={false} />
      <DateField label="Created at" source="timestamp" sortable={false} showTime />
      <ReferenceField
        source="eventTriggeredBy"
        reference="users"
        basePath="/users"
        linkType="show"
        sortable={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="User"
        source="messageAttributes.userId"
        reference="users"
        basePath="/users"
        linkType="show"
        sortable={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="Campaign"
        source="messageAttributes.campaignId"
        reference="campaigns"
        basePath="/campaigns"
        linkType="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Mission"
        source="messageAttributes.missionId"
        reference="missions"
        basePath="/missions"
        linkType="show"
        sortable={false}
      >
        <TextField source="location.name" />
      </ReferenceField>
      <TextField label="Submission Id" source="messageAttributes.submissionId" />
    </Datagrid>
  </List>
);

export default EventList;
