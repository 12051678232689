import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withDataProvider, showNotification } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import {
  importMissions,
  extractMissionsFromExcel,
} from '../../../../missions/services/import-helper';
import { PGADMIN_URL, WISER_PGADMIN_VIEW_WIKI_URL } from '../../../../../setup/config';

// eslint-disable-next-line import/no-named-as-default
import Warning from '../../../../components/alert/warning';
import { warningStyles } from '../../../../layout/globalStyle/alertStyle';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    textAlign: 'center',
    color: '#999',
    marginTop: '8px',
    minHeight: '32px',
  },
  label: {
    marginBottom: '5px',
    marginTop: '5px',
  },

  root: { width: '30%' },
};

const WarningText = () => (
  <p style={warningStyles.p}>
    You are making changes in location file. You should update all PgAdmin Views using this
    campaign. Go to{' '}
    <a href={PGADMIN_URL} target="_blank" rel="noopener noreferrer">
      PgAdmin
    </a>{' '}
    to see all existing Views or learn more{' '}
    <a href={WISER_PGADMIN_VIEW_WIKI_URL} target="_blank" rel="noopener noreferrer">
      here
    </a>
    .
  </p>
);

export class ImportMissionButtonPresenter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showWarningMessage: false,
    };
  }

  onDrop = acceptedFiles => {
    const { dispatch, campaignId } = this.props;

    return new Promise((resolves, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        e.stopPropagation();
        e.preventDefault();
        try {
          const missions = extractMissionsFromExcel(reader.result);
          if (!missions) {
            reject(
              Error('Mission creation failed: check if your header (first row) has correct names'),
            );
          } else if (missions.length === 0) {
            reject(Error('No Mission is provided'));
          } else {
            this.setState({ showWarningMessage: true });
            dispatch(
              showNotification('Upload started - check Slack channel for update on status', 'info'),
            );
            importMissions(missions, campaignId)
              .then(resolves)
              .catch(err => reject(err));
          }
        } catch (ex) {
          reject(Error(`Mission creation failed: ${ex.message}`));
        }
      };

      reader.onerror = ex => {
        reject(Error(`Mission creation failed: ${ex.message}`));
      };

      reader.readAsBinaryString(acceptedFiles[0]);
    }).catch(error => dispatch(showNotification(error.message, 'warning')));
  };

  render() {
    const { classes } = this.props;
    const { showWarningMessage } = this.state;
    return (
      <div>
        {showWarningMessage ? (
          <Warning
            propsStyle={{
              warningContainer: {
                width: '93%',
                marginBottom: 10,
              },
            }}
            onHandleClose={() => this.setState({ showWarningMessage: false })}
            testId="pGAdminViewChangeWarningMessage"
            content={<WarningText />}
          />
        ) : null}
        <div className={classes.root}>
          <Dropzone
            onDrop={this.onDrop}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            disableClick={false}
            multiple={false}
            className={classes.dropZone}
          >
            <p className={classes.label}>Import missions</p>
          </Dropzone>
        </div>
      </div>
    );
  }
}

ImportMissionButtonPresenter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  campaignId: PropTypes.string.isRequired,
};

export default withStyles(styles)(withDataProvider(ImportMissionButtonPresenter));
