import React from 'react';
import { Field } from 'redux-form';
import IconButton from '@material-ui/core/IconButton';
import ActionHide from '@material-ui/icons/HighlightOff';
import classnames from 'classnames';

const emptyRecord = {};

const sanitizeRestProps = ({ alwaysOn, ...props }) => props;

export const FilterFormInputPresenter = ({ filterElement, handleHide, classes, resource }) => (
  <div
    data-source={filterElement.props.source}
    className={classnames('filter-field', classes.body)}
  >
    {!filterElement.props.alwaysOn && (
      <IconButton
        className="hide-filter"
        onClick={handleHide}
        data-key={filterElement.props.source}
      >
        <ActionHide />
      </IconButton>
    )}
    <Field
      testid="field"
      allowEmpty
      {...sanitizeRestProps(filterElement.props)}
      name={filterElement.props.source}
      component={filterElement.type}
      resource={resource}
      record={emptyRecord}
    />
    <div className={classes.spacer}>&nbsp;</div>
  </div>
);

export default FilterFormInputPresenter;
