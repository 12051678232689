import { UPDATE, CREATE } from 'react-admin';

export const UPDATE_CHECKPOINT = 'UPDATE';
export const CREATE_CHECKPOINT = 'CREATE';

export const updateCheckpoint = (id, data, recomputeDataIntegrityIssues) => ({
  type: UPDATE_CHECKPOINT,
  payload: { id, data: { ...data }, recomputeDataIntegrityIssues },

  meta: {
    resource: 'checkpoints',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'Successfully updated checkpoint',
        level: 'info',
      },
      redirectTo: `/campaigns/${data.campaignId}/show/checkpoints`,
    },
    onFailure: {
      notification: {
        body: 'Failed to update checkpoint',
        level: 'error',
      },
    },
  },
});

export const createCheckpoint = (data, recomputeDataIntegrityIssues) => ({
  type: CREATE_CHECKPOINT,
  payload: {
    data,
    recomputeDataIntegrityIssues: recomputeDataIntegrityIssues && data.answerRequired !== false,
  },

  meta: {
    resource: 'checkpoints',
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: 'Successfully created checkpoint',
        level: 'info',
      },
      redirectTo: `/campaigns/${data.campaignId}/show/checkpoints`,
    },
    onFailure: {
      notification: {
        body: 'Failed to create checkpoint',
        level: 'error',
      },
    },
  },
});

export default undefined;
