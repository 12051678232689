import React from 'react';
import { ArrayField, Datagrid, TextField, DateField } from 'react-admin';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Field from '../../../../layout/components/fields/textField.presenter';
import Title from '../../../../layout/components/fields/title.presenter';
import ListBooleanField from '../../../../components/fields/booleanField';
import styles from '../../../../layout/globalStyle/cardStyle';

const CampaignSchedule = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Campaign Schedule" />
    <CardContent {...props}>
      <Field title="Start Date (Sydney time)" record={record.startDate} />
      <Field title="End Date (Sydney time)" record={record.endDate} />
      <Field title="Debriefing date" record={record.clientDebriefingDate} />
      <Field title="Cycle's type" record={record.cycleType || 'None'} />
      <Title title="Cycles" />
      <ArrayField source="cycles" record={record} {...props}>
        <Datagrid>
          <TextField source="name" sortable={false} />
          <DateField
            label="Start Date (local)"
            source="startDate"
            locales="en-AU"
            showTime
            sortable={false}
          />
          <DateField
            label="End Date (local)"
            source="endDate"
            locales="en-AU"
            showTime
            sortable={false}
          />
          <ListBooleanField source="current" sortable={false} />
        </Datagrid>
      </ArrayField>
    </CardContent>
  </Card>
));

export default CampaignSchedule;
