/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Snackbar } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ContentCopy } from 'styled-icons/material';

/**
 * A component to render a text field with optional copy-to-clipboard functionality.
 * @param {Object} props
 * @param {string} props.source - The key of the record data to display.
 * @param {Object} [props.record={}] - The record data object.
 * @param {boolean} [props.showCopyButton=false] - Whether to show the copy button.
 * @param {string} [props.label] - An optional label for accessibility.
 * @returns {React.Element}
 */
export const TextFieldWithCopyButton = ({ text, showCopyButton, label, copyNotificationText }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        message={copyNotificationText}
        onClose={() => setOpen(false)}
      />
      <Typography
        label={label}
        noWrap
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {text}
      </Typography>
      {showCopyButton && (
        <CopyToClipboard text={text} onCopy={() => setOpen(true)}>
          <Button size="small" testId="copyButton">
            <ContentCopy size="24" />
          </Button>
        </CopyToClipboard>
      )}
    </div>
  );
};

TextFieldWithCopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  showCopyButton: PropTypes.bool,
  label: PropTypes.string,
  copyNotificationText: PropTypes.string,
};

TextFieldWithCopyButton.defaultProps = {
  showCopyButton: false,
  label: '',
  copyNotificationText: '',
};

export default TextFieldWithCopyButton;
