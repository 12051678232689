import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ReferenceArrayField, SingleFieldList, ChipField, ReferenceField } from 'react-admin';
import Field from '../../../../layout/components/fields/textField.presenter';
import Title from '../../../../layout/components/fields/title.presenter';
import BooleanField from '../../../../layout/components/fields/booleanField.presenter';
import TeamChipField from '../../../../companies/components/teamChipField';
import styles from '../../../../layout/globalStyle/cardStyle';
import TooltipWrapper from '../../../../components/tooltip/tooltip';
import { campaignType } from '../../../../components/tooltip/tooltips.constant';
import LinkedCampaignsField from './linkedCampaignsField';

const ClientSettings = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Main Client Settings" />
    <CardContent {...props}>
      <Title title="Owner" />
      <ReferenceField
        label="Owner"
        source="ownerId"
        reference="users"
        linkType="show"
        record={record}
        {...props}
      >
        <ChipField source="email" />
      </ReferenceField>
      <Title title="Clients" />
      <ReferenceArrayField
        label="Client"
        source="clientIds"
        reference="users"
        linkType="show"
        record={record}
        {...props}
      >
        <SingleFieldList linkType="show">
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>

      <Title title="Teams" />
      <ReferenceArrayField
        label="Teams"
        source="teamIds"
        reference="teams"
        record={record}
        {...props}
      >
        <SingleFieldList linkType={false}>
          <TeamChipField />
        </SingleFieldList>
      </ReferenceArrayField>
      <Field title="Stage" record={record.stage} />
      <TooltipWrapper title={campaignType}>
        <Field title="Campaign Type" record={record.campaignType} />
      </TooltipWrapper>
      <Field title="Campaign Name" record={record.name} />
      <BooleanField title="Campaign Active" record={record.active} />
      <BooleanField title="Available To client" record={record.availableToClients} />
      <BooleanField
        title="Allow Excel Export"
        record={record.excelExportAvailableToClients}
        testId="excelExportAvailableToClients"
      />
      <BooleanField
        title="Allow Photo Download"
        record={record.photoDownloadAvailableToClients}
        testId="photoDownloadAvailableToClients"
      />
      <Field title="Country" record={record.country} />
      <Field title="Metabase Id" record={record.metabaseId} />
      <Field title="Metabase Dashboard URL" record={record.metabaseDashboardUrl} />
      <Title title="Linked Campaigns" />
      <LinkedCampaignsField record={record} />
      {/* We should use ReferenceArrayField here, but it cannot work as expected, the mother 
          component not always pass the correct record to this component, sometimes it has 
          linkedCampaigns, sometime doesn't, I leave the code here for further investigation */}
      {/* <ReferenceArrayField
          label="Linked Campaigns"
          source="linkedCampaigns"
          reference="campaigns"
          record={record}
          {...props}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField> */}
    </CardContent>
  </Card>
));

export default ClientSettings;
