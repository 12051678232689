import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Field from '../../../../layout/components/fields/textField.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';

const TraxPresenter = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Trax" />
    <CardContent {...props}>
      <Field title="ProjectId" record={record.traxProjectId} />
      <Field title="ApiKey" record={record.traxApiKey} />
      <Field title="UserID" record={record.traxUserId} />
      <Field title="Project stage" record={record.traxTarget} />
    </CardContent>
  </Card>
));

export default TraxPresenter;
