import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import BooleanField from '../../../../layout/components/fields/booleanField.presenter';
import Title from '../../../../layout/components/fields/title.presenter';
import ImportMissionButton from './importMissionsButton.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';

const Location = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Location" />
    <CardContent {...props}>
      <BooleanField title="Anywhere missions" record={record.anywhere} />
      <Title title="Locations file" />
      <ImportMissionButton campaignId={record.id} />
    </CardContent>
  </Card>
));

export default Location;
