import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Title from './title.presenter';
import styles from './fieldSharedStyle';

const ArrayField = ({ title, record, defaultValue }) => (
  <React.Fragment>
    <Title title={title} />
    <Typography style={styles.record} testid="record">
      {record && record.length !== 0 ? record.join(', ') : defaultValue}
    </Typography>
  </React.Fragment>
);

ArrayField.propTypes = {
  title: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

ArrayField.defaultProps = {
  defaultValue: '-',
};

export default ArrayField;
