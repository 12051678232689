import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { UPDATE, showNotification, withDataProvider } from 'react-admin';
import { Typography, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  root: {
    width: '400px',
  },
  inputs: {
    padding: '10px',
    textAlign: 'center',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  textField: {
    width: '100%',
  },
  dateField: {
    width: '100%',
    marginTop: '15px',
    minHeight: '90px',
  },
  header: {
    backgroundColor: '#00C7FC',
    height: '48px',
  },
  title: {
    padding: '9px 15px',
    color: 'white',
  },
  button: {
    marginTop: '25px',
  },
  batchOperation: {
    border: '1px solid grey',
    borderRadius: '6px',
    padding: '10px',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  colorPicker: { width: '100%' },
  helperText: { color: 'red' },
};

export class AddNoteDrawerPresenter extends PureComponent {
  state = {
    error: false,
    content: '',
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: false });
  };

  validateInput = () => {
    const { content } = this.state;
    if (content === '') {
      this.setState({ error: true });
    } else {
      this.addNote();
    }
  };

  addNote = async () => {
    const { dataProvider, dispatch, campaignId, close, record } = this.props;
    const { state } = this;
    const data = {
      userId: localStorage.getItem('clientId'),
      content: state.content,
    };
    close();

    const campaignWithNotes = {
      ...record,
      notes: [...(record.notes || []), data],
    };
    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { data: campaignWithNotes, id: campaignId },
        {
          onSuccess: {},
          onFailure: {
            notification: {
              body: `Add note failed on the backend side`,
              level: 'error',
            },
          },
          refresh: true,
        },
      );
    } catch (ex) {
      dispatch(showNotification(`Add note failed: ${ex.message}`, 'error'));
    }
  };

  render() {
    const { classes } = this.props;
    const { error, content } = this.state;
    return (
      <Grid className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" component="h2" className={classes.title}>
            Add note
          </Typography>
        </div>
        <div className={classes.inputs}>
          <TextField
            error={error && content === ''}
            label="Content"
            type="text"
            name="content"
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
            fullWidth
            multiline
            variant="outlined"
          />
          <Button
            testid="addButton"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.validateInput}
            id="createNoteButton"
          >
            Add
          </Button>
          {error ? (
            <FormHelperText testid="helperText" id="component-error-text" style={styles.helperText}>
              Please fill everything in
            </FormHelperText>
          ) : null}
        </div>
      </Grid>
    );
  }
}

AddNoteDrawerPresenter.propTypes = {
  classes: PropTypes.shape().isRequired,
  campaignId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
export default withDataProvider(withStyles(styles)(AddNoteDrawerPresenter));
