import fan from '../../services/fan';

const PUT = 'PUT';
const POST = 'POST';
const DELETE = 'DELETE';
const PATCH = 'PATCH';

const returnStructuredResponse = response => {
  const { status, data, headers } = response;

  return { status, headers: new Headers(headers), body: data, json: data };
};

export default (url, axiosOptions = {}) => {
  switch (axiosOptions.method) {
    case PUT: {
      return fan
        .put(url, axiosOptions.body, axiosOptions)
        .then(response => returnStructuredResponse(response));
    }
    case POST: {
      return fan
        .post(url, axiosOptions.body, axiosOptions)
        .then(response => returnStructuredResponse(response));
    }
    case DELETE: {
      return fan.delete(url, axiosOptions).then(response => returnStructuredResponse(response));
    }
    case PATCH: {
      return fan
        .patch(url, axiosOptions.body, axiosOptions)
        .then(response => returnStructuredResponse(response));
    }
    default:
      return fan.get(url, axiosOptions).then(response => returnStructuredResponse(response));
  }
};
