import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Title from './title.presenter';
import styles from './fieldSharedStyle';

const TextField = ({ title, record, defaultValue }) => (
  <React.Fragment>
    <Title title={title} />
    <Typography style={styles.record} testid="record">
      {record || defaultValue}
    </Typography>
  </React.Fragment>
);

TextField.propTypes = {
  title: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

TextField.defaultProps = {
  defaultValue: '-',
};

export default TextField;
