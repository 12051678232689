import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {
  UPDATE,
  withDataProvider,
  ArrayField,
  Datagrid,
  NumberField,
  DateField,
  TextField as ReactAdminTextField,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';

import AddCashBoostButton from './components/addCashBoostButton';
import SaveNewCashBoostButton from './components/saveNewCashBoostButton';
import DeleteButton from './components/deleteCashBoostButton';

const styles = theme => ({
  createForm: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    margin: 10,
    padding: 10,
  },
  createFormTitle: {
    color: theme.palette.secondary.main,
  },
});

export class CashboostsRulesPresenter extends PureComponent {
  state = {
    createForm: {},
    isLoading: false,
    isCreateFormOpen: false,
  };

  onToggleCreateFormVisibility = () => {
    this.setState(prevState => ({
      isCreateFormOpen: !prevState.isCreateFormOpen,
    }));
  };

  onCreateChange = fieldName => ({ target }) => {
    this.setState(prevState => ({
      createForm: {
        ...prevState.createForm,
        [fieldName]: target.value,
      },
      [`${fieldName}Error`]: null,
    }));
  };

  onAddNewCashBoost = async () => {
    const { createForm } = this.state;
    const { dataProvider, record } = this.props;
    const { startDate, endDate, submissionCountTarget, reward } = createForm;
    let hasError = false;
    if (!startDate) {
      hasError = true;
      this.setState({ startDateError: 'The field is mandatory' });
    }
    if (!endDate) {
      hasError = true;
      this.setState({ endDateError: 'The field is mandatory' });
    }
    if (!submissionCountTarget) {
      hasError = true;
      this.setState({ submissionCountTargetError: 'The field is mandatory' });
    }
    if (!reward) {
      hasError = true;
      this.setState({ rewardError: 'The field is mandatory' });
    }
    if (hasError) {
      return;
    }

    this.setState({ isLoading: true });

    const campaignWithNewCashBoost = {
      ...record,
      cashBoosts: [
        ...(record.cashBoosts || []),
        { startDate, endDate, submissionCountTarget, reward },
      ],
    };
    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { id: record.id, data: campaignWithNewCashBoost },
        {
          onSuccess: {
            notification: { body: 'Cashboost Added', level: 'info' },
            refresh: true,
          },
          onFailure: {
            notification: { body: 'Error: could not add the cashboost', level: 'warning' },
          },
        },
      );
      this.setState({ isLoading: false, isCreateFormOpen: false });
    } catch (ex) {
      this.setState({ isLoading: false });
    }
  };

  onDeleteRule = index => async () => {
    const { dataProvider, record } = this.props;
    this.setState({ isLoading: true });

    const campaignWithoutCashBoost = {
      ...record,
      cashBoosts: (record.cashBoosts || []).filter((_, cashBoostindex) => cashBoostindex !== index),
    };
    // throw new Error('TOTO');
    await dataProvider(
      UPDATE,
      'campaigns',
      { id: record.id, data: campaignWithoutCashBoost },
      {
        onSuccess: {
          notification: { body: 'Cash boost Removed', level: 'info' },
          refresh: true,
        },
        onFailure: {
          notification: { body: 'Error: could not remove the Cash boost', level: 'warning' },
        },
      },
    );
    this.setState({ isLoading: false });
  };

  renderCreateCashBoostForm = () => {
    const {
      createForm,
      isLoading,
      startDateError,
      endDateError,
      submissionCountTargetError,
      rewardError,
    } = this.state;
    const {
      record: { rewardCurrency },
    } = this.props;
    const { classes } = this.props;
    return (
      <form className={classes.createForm}>
        <Typography variant="h5" component="h2" className={classes.createFormTitle}>
          Add cash boost
        </Typography>
        <TextField
          id="submissionCountTarget"
          error={submissionCountTargetError}
          value={createForm.submissionCountTarget}
          label="Submission's target"
          onChange={this.onCreateChange('submissionCountTarget')}
          type="number"
          margin="normal"
        />

        <TextField
          id="rewardCurrency"
          label="Reward Currency"
          defaultValue={rewardCurrency}
          margin="normal"
          disabled
        />

        <TextField
          id="reward"
          error={rewardError}
          value={createForm.reward}
          label="Reward"
          onChange={this.onCreateChange('reward')}
          type="number"
          margin="normal"
        />

        <TextField
          id="startDate"
          error={startDateError}
          value={createForm.startDate}
          label="Start date"
          onChange={this.onCreateChange('startDate')}
          type="date"
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          id="endDate"
          error={endDateError}
          value={createForm.endDate}
          label="End date"
          onChange={this.onCreateChange('endDate')}
          type="date"
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {isLoading ? (
          <CircularProgress />
        ) : (
          <SaveNewCashBoostButton onClick={this.onAddNewCashBoost} />
        )}
      </form>
    );
  };

  render() {
    const { isCreateFormOpen } = this.state;
    const { record } = this.props;
    const data = {
      ...record,
      cashBoosts: (record.cashBoosts || []).map((cb, index) => ({
        ...cb,
        index,
        rewardCurrency: record.rewardCurrency,
      })),
    };

    return (
      <div>
        <AddCashBoostButton onClick={this.onToggleCreateFormVisibility} />
        <ArrayField record={data} resource="campaigns" source="cashBoosts">
          <Datagrid>
            <NumberField label="Submissions's target" source="submissionCountTarget" />
            <ReactAdminTextField label="Reward Currency" source="rewardCurrency" />
            <NumberField source="reward" />
            <DateField source="startDate" />
            <DateField source="endDate" />
            <DeleteButton onClick={this.onDeleteRule} />
          </Datagrid>
        </ArrayField>
        <Drawer anchor="right" open={isCreateFormOpen} onClose={this.onToggleCreateFormVisibility}>
          {this.renderCreateCashBoostForm()}
        </Drawer>
      </div>
    );
  }
}

CashboostsRulesPresenter.propTypes = {
  record: PropTypes.shape({
    cashBoosts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dataProvider: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

CashboostsRulesPresenter.defaultProps = {
  record: null,
};

export default withStyles(styles)(withDataProvider(CashboostsRulesPresenter));
