export const isEmailDomainValid = values => {
  if (!values || values.length === 0) {
    return "email's domain is required";
  }
  return values.reduce((errorMessage, emailDomain) => {
    if (!emailDomain.startsWith('@')) {
      return "Every email's domain must start with @";
    }
    if (!emailDomain.includes('.')) {
      return "Every email's domain must contains at least one dot";
    }
    if ((emailDomain.match(/@/g) || []).length !== 1) {
      return "Use commas to seperate your the email's domain name";
    }
    return errorMessage;
  }, undefined);
};

export const parseEmailDomains = v => (v || '').split(',').map(e => e.trim());

export const formatEmailDomains = v => (v || []).join(', ');
