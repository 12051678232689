import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const Pagination = ({ page, perPage, total, setPage }) => {
  const nbPages = Math.ceil(total / perPage) || 1;

  return (
    <div>
      <Button
        variant="outlined"
        testid="firstButton"
        disabled={page === 1}
        color="primary"
        key="first"
        icon={<ChevronLeft />}
        onClick={() => setPage(1)}
        labelPosition="before"
      >
        {' << '}
      </Button>
      <Button
        variant="outlined"
        testid="previousButton"
        disabled={page < 2}
        color="primary"
        key="prev"
        icon={<ChevronLeft />}
        onClick={() => setPage(page - 1)}
      >
        {' < '}
      </Button>
      <Button
        variant="outlined"
        testid="nextButton"
        disabled={page >= nbPages}
        color="primary"
        key="next"
        icon={<ChevronRight />}
        onClick={() => setPage(page + 1)}
        labelPosition="before"
      >
        {' > '}
      </Button>
      <Button
        variant="outlined"
        testid="lastButton"
        disabled={page >= nbPages}
        color="primary"
        key="last"
        icon={<ChevronRight />}
        onClick={() => setPage(nbPages)}
        labelPosition="before"
      >
        {' >> '}
      </Button>
      {` ${page}/${nbPages} `}
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Pagination;
