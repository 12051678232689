import React from 'react';
import {
  ArrayField,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
} from 'react-admin';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';

const PersonalInfo = props => (
  <ArrayField {...props} source="phoneInfo.installations">
    <Datagrid>
      <TextField source="deviceName" sortable={false} />
      <TextField source="platform" sortable={false} />
      <TextField source="appVersion" sortable={false} />
      <BooleanField source="hasEnabledNotification" sortable={false} />
      <TextField source="deviceYearClass" sortable={false} />
      <DateField source="updatedAt" sortable={false} />
      <TextField source="platformVersion" sortable={false} />
      <TextField source="expoVersion" sortable={false} />
      <FunctionField
        label="Has Fcm token"
        sortable={false}
        render={installation => (installation.fcmToken ? <TrueIcon /> : <FalseIcon />)}
      />
      <FunctionField
        label="Has Apn token"
        sortable={false}
        render={installation => (installation.apnToken ? <TrueIcon /> : <FalseIcon />)}
      />
    </Datagrid>
  </ArrayField>
);

export default PersonalInfo;
