import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  Edit,
  NumberInput,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  DeleteButton,
  SelectInput,
} from 'react-admin';
import { parse } from 'query-string';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ColorInput } from 'react-admin-color-input';
import CustomToolbar from '../components/toolbars/toolbars';
import BooleanField from '../components/fields/booleanField';
import sanitizeRedundantWhitespace from '../components/utils/sanitizeWhitespace/sanitizeRedundantWhitespace';
import { STATES, rewardCurrencies } from '../campaigns/config/campaignConfig';
import SelectAndSearchPresenter from '../components/select/selectAndSearch';
import sanitizeAndMatchOptionFilter from '../components/select/selectAndSearchFilters';
import validateReward from '../../util/rewardUtil';

const styles = {
  formContent: {
    marginTop: 60,
  },
};

const MissionTitle = ({ record = { location: {} } }) => <span> {record.location.name} </span>;

MissionTitle.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

class MissionEdit extends PureComponent {
  render() {
    const { location, classes, acceptedByUseId } = this.props;
    const { campaignId } = parse(location.search) || { campaignId: '' };
    const redirectUrl = campaignId ? `/campaigns/${campaignId}/show/missions` : '/campaigns';
    return (
      <Edit {...this.props} undoable={false} title={<MissionTitle />}>
        <SimpleForm
          defaultValue={{ campaignId }}
          redirect={redirectUrl}
          toolbar={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CustomToolbar Title={MissionTitle}>
              <DeleteButton />
            </CustomToolbar>
          }
          style={styles.formContent}
          validate={validateReward}
        >
          <TextInput
            label="Location name"
            source="location.name"
            validate={required()}
            parse={sanitizeRedundantWhitespace}
          />
          <NumberInput label="Latitude" source="location.geoPoint.latitude" validate={required()} />
          <NumberInput
            label="Longitude"
            source="location.geoPoint.longitude"
            validate={required()}
          />
          <TextInput label="Address" source="location.address" validate={required()} />
          <TextInput label="City" source="location.suburb" />
          <TextInput label="Post Code" source="location.postCode" />
          <SelectAndSearchPresenter
            name="state"
            source="state"
            choices={STATES}
            isMulti
            placeholder="State"
            handleFilterOption={sanitizeAndMatchOptionFilter}
            alwaysOn
          />
          <DateTimeInput
            source="startDate"
            formClassName={classes.inlineBlock}
            parse={date => date}
            validate={required()}
          />
          <DateTimeInput
            source="endDate"
            formClassName={classes.inlineBlock}
            parse={date => date}
            validate={required()}
          />
          <NumberInput source="availableSubmitNumber" disabled />
          <SelectInput label="Reward Currency" source="rewardCurrency" choices={rewardCurrencies} />
          <NumberInput source="reward" />
          <NumberInput source="experienceReward" />
          <ArrayInput source="segments">
            <SimpleFormIterator>
              <TextInput source="name" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput source="maxSubmissionsPerLocation" validate={required()} />
          <NumberInput source="maxSubmissionsPerUserPerLocation" />
          <BooleanInput source="disabled" validate={required()} />
          <BooleanInput source="storeClosed" validate={required()} />
          <BooleanField addLabel source="active" />
          <BooleanField addLabel source="disabledBySegment" validate={required()} />
          <ColorInput source="color" />
          {acceptedByUseId && acceptedByUseId.lenght > 0 ? (
            <ReferenceArrayInput label="Accepted by" reference="acceptedByUserId">
              <SelectArrayInput optionText="email" />
            </ReferenceArrayInput>
          ) : null}
          <TextInput source="traxStoreId" />
        </SimpleForm>
      </Edit>
    );
  }
}

MissionEdit.propTypes = {
  locaton: PropTypes.shape({
    search: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({}),
};

MissionEdit.defaultProps = {
  locaton: {},
  classes: {},
};

const style = {
  richTextInput: {
    '& .ql-editor': {
      minHeight: '100px',
    },
  },
};

export default withStyles(style)(MissionEdit);
