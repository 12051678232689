import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ChipField,
  EditButton,
  DeleteButton,
  Button,
  ImageField,
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import VisibleIcon from '@material-ui/icons/Visibility';
import { tableStyle, postRowStyle } from '../layout/globalStyle/tableStyle';

export const Expand = ({ record }) => <div>{record.shortDescription}</div>;

Expand.propTypes = {
  record: PropTypes.shape({
    shortDescription: PropTypes.string.isRequired,
  }).isRequired,
};

export const OpenReportButton = ({ record = {}, open = window.open, ...rest }) => {
  const { link } = record;
  return (
    <Button
      {...rest}
      to="route"
      label="Show"
      target="_blank"
      onClick={event => {
        event.preventDefault();
        open(link);
      }}
    >
      <VisibleIcon />
    </Button>
  );
};

const UseCaseList = ({ classes, ...props }) => (
  <List
    {...props}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    perPage={50}
  >
    <Datagrid
      expand={<Expand />}
      rowStyle={postRowStyle}
      classes={{
        thead: classes.thead,
        tbody: classes.tbody,
        row: classes.row,
        table: classes.table,
      }}
    >
      <ImageField
        classes={{ image: classes.image }}
        source="imageUrl"
        label=""
        title="title"
        sortable={false}
      />
      <TextField source="name" cellClassName={classes.w25} headerClassName={classes.w25} />
      <ChipField source="type" />
      <ChipField source="topic" />
      <ChipField source="industry" />
      <OpenReportButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default withStyles(tableStyle)(UseCaseList);
