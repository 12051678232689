import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Title from './title.presenter';
import styles from './fieldSharedStyle';

const customStyle = {
  data: {
    ...styles.record,
    '& span': styles.record,
  },
};

const Field = ({ title, children, defaultValue, classes }) => (
  <React.Fragment>
    <Title title={title} />
    <Typography className={classes.data} testid="record">
      {children || defaultValue}
    </Typography>
  </React.Fragment>
);

Field.propTypes = {
  classes: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  defaultValue: PropTypes.string,
};

Field.defaultProps = {
  defaultValue: '-',
};

export default withStyles(customStyle)(Field);
