import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  required,
  Edit,
  SimpleForm,
  SelectInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import PropTypes from 'prop-types';

import { renderClient } from '../../services/render-helper';
import { sanitiseDate, validateSubscriptionDates } from './subscriptionFormValidation';

const SubscriptionsEdit = props => {
  const { styles } = props;

  return (
    <Edit {...props} styles={styles}>
      <SimpleForm submitOnEnter={false} undoable redirect="list">
        <SelectInput
          source="type"
          testid="typeInput"
          choices={[{ id: 'DASHBOARD_TRIAL', name: 'Dashboard Trial' }]}
          validate={required()}
        />
        <ReferenceInput
          testid="customerInput"
          label="Customer"
          sort={{ field: 'username', order: 'ASC' }}
          source="customerId"
          reference="users"
          linkType="show"
          filter={{ role: 'CLIENT' }}
          validate={required()}
          fullWidth
        >
          <AutocompleteInput label="Owner" optionText={renderClient} fullWidth />
        </ReferenceInput>
        <DateTimeInput
          label="Start Date (Sydney time)"
          testid="startDateInput"
          source="startDate"
          parse={sanitiseDate} /* Important! Do not remove. This ensures the format is correct! */
          validate={validateSubscriptionDates}
        />
        <DateTimeInput
          label="End Date (Sydney time)"
          testid="endDateInput"
          source="endDate"
          parse={sanitiseDate} /* Important! Do not remove. This ensures the format is correct! */
          validate={validateSubscriptionDates}
        />
      </SimpleForm>
    </Edit>
  );
};

SubscriptionsEdit.propTypes = {
  styles: PropTypes.shape({}),
};

SubscriptionsEdit.defaultProps = {
  styles: {},
};

/* Modify styles here if desired. */
const styles = {};

export default withStyles(styles)(SubscriptionsEdit);
