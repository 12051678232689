import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { cloneCampaign } from '../actions/campaignActions';

class CampaignCloneDialog extends PureComponent {
  state = {
    dialogOpen: false,
    keepCheckpoints: true,
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  toggleKeepCheckpoints = event => {
    this.setState({
      keepCheckpoints: event.target.checked,
    });
  };

  handleClickSave = () => {
    const { handleSubmit, cloneAction, basePath, redirect } = this.props;
    const { keepCheckpoints } = this.state;
    return handleSubmit(values => {
      cloneAction(values, keepCheckpoints, basePath, redirect);
    });
  };

  render() {
    const { dialogOpen, keepCheckpoints } = this.state;
    const { handleSubmitWithRedirect, handleSubmit, ...props } = this.props;
    return (
      <div>
        <Button color="primary" onClick={this.handleDialogOpen} testId="openDialogButton">
          <AddToPhotosIcon /> CLONE
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
          testId="dialog"
        >
          <DialogTitle id="form-dialog-title">Clone Campaign</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={<Checkbox checked={keepCheckpoints} onChange={this.toggleKeepCheckpoints} />}
              label="Keep checkpoints when cloning campaign"
              testId="keepCheckPointCheckBox"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary" testId="cancelButton">
              CANCEL
            </Button>
            <SaveButton
              handleSubmitWithRedirect={this.handleClickSave}
              {...props}
              testId="saveButtonTest"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  undefined,
  { cloneAction: cloneCampaign },
)(CampaignCloneDialog);
