import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Create,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';

import PropTypes from 'prop-types';

import { renderClient } from '../users/services/render-helper';
import { isEmailDomainValid, parseEmailDomains, formatEmailDomains } from './services/emailDomain';

class CompanyCreate extends PureComponent {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm>
          <TextInput source="name" />
          <TextInput
            source="emailDomains"
            parse={parseEmailDomains}
            format={formatEmailDomains}
            validate={isEmailDomainValid}
          />
          <ReferenceArrayInput
            label="Clients"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="userIds"
            reference="users"
            filter={{ role: 'CLIENT' }}
            linkType="show"
            fullWidth
            required={required()}
          >
            <AutocompleteArrayInput label="User" optionText={renderClient} fullWidth />
          </ReferenceArrayInput>
          <SelectInput
            source="membership"
            allowEmpty
            choices={[
              { id: 'No plan', name: 'No plan' },
              { id: 'Silver', name: 'Silver' },
              { id: 'Gold', name: 'Gold' },
              { id: 'Platinum', name: 'Platinum' },
            ]}
            required={required()}
          />
        </SimpleForm>
      </Create>
    );
  }
}

CompanyCreate.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};
CompanyCreate.defaultProps = {};

export default CompanyCreate;
