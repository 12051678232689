import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import classnames from 'classnames';
import { Button, withDataProvider, UPDATE } from 'react-admin';

export const sanitizeRestProps = ({
  basePath,
  classes,
  dispatchCrudDelete,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  undoable,
  redirect,
  submitOnEnter,
  ...rest
}) => rest;

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });
// heavily inspired (stolen) from delete button of RA https://raw.githubusercontent.com/marmelab/react-admin/master/packages/ra-ui-materialui/src/button/DeleteWithUndoButton.js
export class BanButtonPresenter extends Component {
  handleBan = event => {
    event.stopPropagation();
    const { record, dataProvider } = this.props;

    dataProvider(
      UPDATE,
      'userStatus',
      { id: record.id, data: 'Banned' },
      {
        refresh: true,
        onSuccess: {
          notification: { body: 'User banned', level: 'info' },
        },
        onError: {
          notification: { body: 'Error: user not banned', level: 'warning' },
        },
      },
    );
  };

  render() {
    const { classes = {}, className, icon, record, ...rest } = this.props;
    if (!record || record.status === 'Banned') {
      return null;
    }
    const label = record && record.role === 'CLIENT' ? 'Reject' : 'Ban';
    return (
      <Button
        onClick={this.handleBan}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
        {...sanitizeRestProps(rest)}
      >
        {icon}
      </Button>
    );
  }
}

BanButtonPresenter.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  record: PropTypes.shape({}),
  resource: PropTypes.string.isRequired,
  icon: PropTypes.element,
  dataProvider: PropTypes.func.isRequired,
};

BanButtonPresenter.defaultProps = {
  basePath: null,
  classes: {},
  className: null,
  record: null,
  icon: <RemoveCircle />,
};

export default compose(
  withDataProvider,
  withStyles(styles),
)(BanButtonPresenter);
