export const renderUser = user => {
  if (user.firstName && user.lastName && user.email) {
    return `${user.firstName} ${user.lastName} - ${user.email}`;
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.email) {
    return user.email;
  }

  return user.id;
};

export const renderClient = client => `${client.userName} - ${client.email}`;
