import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Add';
import classnames from 'classnames';

import { Button } from 'react-admin';

const styles = theme =>
  createStyles({
    button: {
      float: 'right',
    },
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 60,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000,
    },
    floatingLink: {
      color: 'inherit',
    },
  });

export const AddNewSegmentButtonPresenter = ({
  className,
  classes = {},
  label = 'ra.action.create',
  icon = <ContentAdd />,
  onClick,
}) => (
  <Button
    onClick={onClick}
    label={label}
    className={classnames(classes.button, className)}
    key="button"
  >
    {icon}
  </Button>
);

AddNewSegmentButtonPresenter.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

AddNewSegmentButtonPresenter.defaultProps = {
  classes: {},
  className: null,
  label: 'ra.action.create',
  icon: <ContentAdd />,
};

export default withStyles(styles)(AddNewSegmentButtonPresenter);
