import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';

const styles = {
  showButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
};

const GoBack = connect(
  null,
  {
    goBack,
  },
)(({ record, data, ...props }) => (
  <div style={styles.showButtons}>
    <div style={styles.inline}>
      <IconButton onClick={props.goBack}>
        <ArrowBackIos testid="back" style={styles.arrowColor} />
      </IconButton>
    </div>
  </div>
));

export default GoBack;
