/**
 * Moved the hard-coded message topics from the components (messageList.presenter, missionEdit, etc)
 * here to avoid code duplication and making things less error prone when editing the values.
 *
 * - This is still absolutely insufficient and will not scale globally.
 * - At least we can now manage this in one place.
 *
 * FIXME This needs cleanup. See epic SNOOP-1704.
 */
export const MESSAGE_TOPICS = [
  /* Australian states. */
  { id: 'ACT', name: 'ACT' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' },
  /* Countries */
  { id: 'AUS', name: 'AUS' },
  { id: 'NZ', name: 'NZ' },
  { id: 'SG', name: 'SG' },
  { id: 'MX', name: 'MX' },
  /* Misc */
  { id: 'DEV', name: 'DEV' },
  { id: 'SNOOPER', name: 'SNOOPER' },
  { id: 'MY', name: 'Malaysia (MY)' },
  { id: 'MY_JHR', name: 'Johor (JHR)' },
  { id: 'MY_KDH', name: 'Kedah (KDH)' },
  { id: 'MY_KTN', name: 'Kelantan (KTN)' },
  { id: 'MY_MLK', name: 'Malacca (MLK)' },
  { id: 'MY_NSN', name: 'Negeri Sembilan (NSN)' },
  { id: 'MY_PHG', name: 'Pahang (PHG)' },
  { id: 'MY_PNG', name: 'Penang (PNG)' },
  { id: 'MY_PRK', name: 'Perak (PRK)' },
  { id: 'MY_PLS', name: 'Perlis (PLS)' },
  { id: 'MY_SBH', name: 'Sabah (SBH)' },
  { id: 'MY_SWK', name: 'Sarawak (SWK)' },
  { id: 'MY_SGR', name: 'Selangor (SGR)' },
  { id: 'MY_TRG', name: 'Terengganu (TRG)' },
  { id: 'MY_KUL', name: 'Kuala Lumpur (KUL)' },
  { id: 'MY_LBN', name: 'Labuan (LBN)' },
  { id: 'MY_PJY', name: 'Putrajaya (PJY)' } /* Mexico */,
  { id: 'MX-CMX', name: 'Ciudad de México' },
  { id: 'MX-AGU', name: 'Aguascalientes' },
  { id: 'MX-BCN', name: 'Baja California' },
  { id: 'MX-BCS', name: 'Baja California Sur' },
  { id: 'MX-CAM', name: 'Campeche' },
  { id: 'MX-COA', name: 'Coahuila de Zaragoza' },
  { id: 'MX-COL', name: 'Colima' },
  { id: 'MX-CHP', name: 'Chiapas' },
  { id: 'MX-CHH', name: 'Chihuahua' },
  { id: 'MX-DUR', name: 'Durango' },
  { id: 'MX-GUA', name: 'Guanajuato' },
  { id: 'MX-GRO', name: 'Guerrero' },
  { id: 'MX-HID', name: 'Hidalgo' },
  { id: 'MX-JAL', name: 'Jalisco' },
  { id: 'MX-MEX', name: 'México' },
  { id: 'MX-MIC', name: 'Michoacán de Ocampo' },
  { id: 'MX-MOR', name: 'Morelos' },
  { id: 'MX-NAY', name: 'Nayarit' },
  { id: 'MX-NLE', name: 'Nuevo León' },
  { id: 'MX-OAX', name: 'Oaxaca' },
  { id: 'MX-PUE', name: 'Puebla' },
  { id: 'MX-QUE', name: 'Querétaro' },
  { id: 'MX-ROO', name: 'Quintana Roo' },
  { id: 'MX-SLP', name: 'San Luis Potosí' },
  { id: 'MX-SIN', name: 'Sinaloa' },
  { id: 'MX-SON', name: 'Sonora' },
  { id: 'MX-TAB', name: 'Tabasco' },
  { id: 'MX-TAM', name: 'Tamaulipas' },
  { id: 'MX-TLA', name: 'Tlaxcala' },
  { id: 'MX-VER', name: 'Veracruz de Ignacio de la Llave' },
  { id: 'MX-YUC', name: 'Yucatán' },
  { id: 'MX-ZAC', name: 'Zacatecas' },
  /* Japan */
  { id: 'JP', name: 'Japan' },
  { id: 'JP-01', name: 'Hokkaido' },
  { id: 'JP-02', name: 'Aomori' },
  { id: 'JP-03', name: 'Iwate' },
  { id: 'JP-04', name: 'Miyagi' },
  { id: 'JP-05', name: 'Akita' },
  { id: 'JP-06', name: 'Yamagata' },
  { id: 'JP-07', name: 'Fukushima' },
  { id: 'JP-08', name: 'Ibaraki' },
  { id: 'JP-09', name: 'Tochigi' },
  { id: 'JP-10', name: 'Gunma' },
  { id: 'JP-11', name: 'Saitama' },
  { id: 'JP-12', name: 'Chiba' },
  { id: 'JP-13', name: 'Tokyo' },
  { id: 'JP-14', name: 'Kanagawa' },
  { id: 'JP-15', name: 'Niigata' },
  { id: 'JP-16', name: 'Toyama' },
  { id: 'JP-17', name: 'Ishikawa' },
  { id: 'JP-18', name: 'Fukui' },
  { id: 'JP-19', name: 'Yamanashi' },
  { id: 'JP-20', name: 'Nagano' },
  { id: 'JP-21', name: 'Gifu' },
  { id: 'JP-22', name: 'Shizuoka' },
  { id: 'JP-23', name: 'Aichi' },
  { id: 'JP-24', name: 'Mie' },
  { id: 'JP-25', name: 'Shiga' },
  { id: 'JP-26', name: 'Kyoto' },
  { id: 'JP-27', name: 'Osaka' },
  { id: 'JP-28', name: 'Hyogo' },
  { id: 'JP-29', name: 'Nara' },
  { id: 'JP-30', name: 'Wakayama' },
  { id: 'JP-31', name: 'Tottori' },
  { id: 'JP-32', name: 'Shimane' },
  { id: 'JP-33', name: 'Okayama' },
  { id: 'JP-34', name: 'Hiroshima' },
  { id: 'JP-35', name: 'Yamaguchi' },
  { id: 'JP-36', name: 'Tokushima' },
  { id: 'JP-37', name: 'Kagawa' },
  { id: 'JP-38', name: 'Ehime' },
  { id: 'JP-39', name: 'Kochi' },
  { id: 'JP-40', name: 'Fukuoka' },
  { id: 'JP-41', name: 'Saga' },
  { id: 'JP-42', name: 'Nagasaki' },
  { id: 'JP-43', name: 'Kumamoto' },
  { id: 'JP-44', name: 'Oita' },
  { id: 'JP-45', name: 'Miyazaki' },
  { id: 'JP-46', name: 'Kagoshima' },
  { id: 'JP-47', name: 'Okinawa' },
  /* South Korea */
  { id: 'KR', name: 'South Korea' },
  { id: 'KR-11', name: 'Seoul' },
  { id: 'KR-26', name: 'Busan' },
  { id: 'KR-27', name: 'Daegu' },
  { id: 'KR-28', name: 'Incheon' },
  { id: 'KR-29', name: 'Gwangju' },
  { id: 'KR-30', name: 'Daejeon' },
  { id: 'KR-31', name: 'Ulsan' },
  { id: 'KR-41', name: 'Gyeonggi' },
  { id: 'KR-42', name: 'Gangwon' },
  { id: 'KR-43', name: 'North Chungcheong' },
  { id: 'KR-44', name: 'South Chungcheong' },
  { id: 'KR-45', name: 'North Jeolla' },
  { id: 'KR-46', name: 'South Jeolla' },
  { id: 'KR-47', name: 'North Gyeongsang' },
  { id: 'KR-48', name: 'South Gyeongsang' },
  { id: 'KR-49', name: 'Jeju' },
  { id: 'KR-50', name: 'Sejong' },
  /* Canada */
  { id: 'CA-AB', name: 'Alberta' },
  { id: 'CA-BC', name: 'British Columbia' },
  { id: 'CA-MB', name: 'Manitoba' },
  { id: 'CA-NB', name: 'New Brunswick' },
  { id: 'CA-NL', name: 'Newfoundland and Labrador' },
  { id: 'CA-NS', name: 'Nova Scotia' },
  { id: 'CA-ON', name: 'Ontario' },
  { id: 'CA-PE', name: 'Prince Edward Island' },
  { id: 'CA-QC', name: 'Quebec' },
  { id: 'CA-SK', name: 'Saskatchewan' },
  { id: 'CA-NT', name: 'Northwest Territories' },
  { id: 'CA-NU', name: 'Nunavut' },
  { id: 'CA-YT', name: 'Yukon' },
  /* United States */
  { id: 'US-AL', name: 'Alabama' },
  { id: 'US-AK', name: 'Alaska' },
  { id: 'US-AZ', name: 'Arizona' },
  { id: 'US-AR', name: 'Arkansas' },
  { id: 'US-CA', name: 'California' },
  { id: 'US-CO', name: 'Colorado' },
  { id: 'US-CT', name: 'Connecticut' },
  { id: 'US-DE', name: 'Delaware' },
  { id: 'US-FL', name: 'Florida' },
  { id: 'US-GA', name: 'Georgia' },
  { id: 'US-HI', name: 'Hawaii' },
  { id: 'US-ID', name: 'Idaho' },
  { id: 'US-IL', name: 'Illinois' },
  { id: 'US-IN', name: 'Indiana' },
  { id: 'US-IA', name: 'Iowa' },
  { id: 'US-KS', name: 'Kansas' },
  { id: 'US-KY', name: 'Kentucky' },
  { id: 'US-LA', name: 'Louisiana' },
  { id: 'US-ME', name: 'Maine' },
  { id: 'US-MD', name: 'Maryland' },
  { id: 'US-MA', name: 'Massachusetts' },
  { id: 'US-MI', name: 'Michigan' },
  { id: 'US-MN', name: 'Minnesota' },
  { id: 'US-MS', name: 'Mississippi' },
  { id: 'US-MO', name: 'Missouri' },
  { id: 'US-MT', name: 'Montana' },
  { id: 'US-NE', name: 'Nebraska' },
  { id: 'US-NV', name: 'Nevada' },
  { id: 'US-NH', name: 'New Hampshire' },
  { id: 'US-NJ', name: 'New Jersey' },
  { id: 'US-NM', name: 'New Mexico' },
  { id: 'US-NY', name: 'New York' },
  { id: 'US-NC', name: 'North Carolina' },
  { id: 'US-ND', name: 'North Dakota' },
  { id: 'US-OH', name: 'Ohio' },
  { id: 'US-OK', name: 'Oklahoma' },
  { id: 'US-OR', name: 'Oregon' },
  { id: 'US-PA', name: 'Pennsylvania' },
  { id: 'US-RI', name: 'Rhode Island' },
  { id: 'US-SC', name: 'South Carolina' },
  { id: 'US-SD', name: 'South Dakota' },
  { id: 'US-TN', name: 'Tennessee' },
  { id: 'US-TX', name: 'Texas' },
  { id: 'US-UT', name: 'Utah' },
  { id: 'US-VT', name: 'Vermont' },
  { id: 'US-VA', name: 'Virginia' },
  { id: 'US-WA', name: 'Washington' },
  { id: 'US-WV', name: 'West Virginia' },
  { id: 'US-WI', name: 'Wisconsin' },
  { id: 'US-WY', name: 'Wyoming' },
  { id: 'US-DC', name: 'District of Columbia' },
  { id: 'US-AS', name: 'American Samoa' },
  { id: 'US-GU', name: 'Guam' },
  { id: 'US-MP', name: 'Northern Mariana Islands' },
  { id: 'US-PR', name: 'Puerto Rico' },
  { id: 'US-UM', name: 'United States Minor Outlying Islands' },
  { id: 'US-VI', name: 'Virgin Islands' },
  /* Thailand */
  { id: 'TH', name: 'Thailand' },
  { id: 'TH-10', name: 'Krung Thep Maha Nakhon' },
  { id: 'TH-11', name: 'Samut Prakan' },
  { id: 'TH-12', name: 'Nonthaburi' },
  { id: 'TH-13', name: 'Pathum Thani' },
  { id: 'TH-14', name: 'Phra Nakhon Si Ayutthaya' },
  { id: 'TH-15', name: 'Ang Thong' },
  { id: 'TH-16', name: 'Lopburi' },
  { id: 'TH-17', name: 'Sing Buri' },
  { id: 'TH-18', name: 'Chai Nat' },
  { id: 'TH-19', name: 'Saraburi' },
  { id: 'TH-20', name: 'Chonburi' },
  { id: 'TH-21', name: 'Rayong' },
  { id: 'TH-22', name: 'Chanthaburi' },
  { id: 'TH-23', name: 'Trat' },
  { id: 'TH-24', name: 'Chachoengsao' },
  { id: 'TH-25', name: 'Prachin Buri' },
  { id: 'TH-26', name: 'Nakhon Nayok' },
  { id: 'TH-27', name: 'Sa Kaeo' },
  { id: 'TH-30', name: 'Nakhon Ratchasima' },
  { id: 'TH-31', name: 'Buri Ram' },
  { id: 'TH-32', name: 'Surin' },
  { id: 'TH-33', name: 'Si Sa Ket' },
  { id: 'TH-34', name: 'Ubon Ratchathani' },
  { id: 'TH-35', name: 'Yasothon' },
  { id: 'TH-36', name: 'Chaiyaphum' },
  { id: 'TH-37', name: 'Amnat Charoen' },
  { id: 'TH-38', name: 'Bueng Kan' },
  { id: 'TH-39', name: 'Nong Bua Lam Phu' },
  { id: 'TH-40', name: 'Khon Kaen' },
  { id: 'TH-41', name: 'Udon Thani' },
  { id: 'TH-42', name: 'Loei' },
  { id: 'TH-43', name: 'Nong Khai' },
  { id: 'TH-44', name: 'Maha Sarakham' },
  { id: 'TH-45', name: 'Roi Et' },
  { id: 'TH-46', name: 'Kalasin' },
  { id: 'TH-47', name: 'Sakon Nakhon' },
  { id: 'TH-48', name: 'Nakhon Phanom' },
  { id: 'TH-49', name: 'Mukdahan' },
  { id: 'TH-50', name: 'Chiang Mai' },
  { id: 'TH-51', name: 'Lamphun' },
  { id: 'TH-52', name: 'Lampang' },
  { id: 'TH-53', name: 'Uttaradit' },
  { id: 'TH-54', name: 'Phrae' },
  { id: 'TH-55', name: 'Nan' },
  { id: 'TH-56', name: 'Phayao' },
  { id: 'TH-57', name: 'Chiang Rai' },
  { id: 'TH-58', name: 'Mae Hong Son' },
  { id: 'TH-60', name: 'Nakhon Sawan' },
  { id: 'TH-61', name: 'Uthai Thani' },
  { id: 'TH-62', name: 'Kamphaeng Phet' },
  { id: 'TH-63', name: 'Tak' },
  { id: 'TH-64', name: 'Sukhothai' },
  { id: 'TH-65', name: 'Phitsanulok' },
  { id: 'TH-66', name: 'Phichit' },
  { id: 'TH-67', name: 'Phetchabun' },
  { id: 'TH-70', name: 'Ratchaburi' },
  { id: 'TH-71', name: 'Kanchanaburi' },
  { id: 'TH-72', name: 'Suphan Buri' },
  { id: 'TH-73', name: 'Nakhon Pathom' },
  { id: 'TH-74', name: 'Samut Sakhon' },
  { id: 'TH-75', name: 'Samut Songkhram' },
  { id: 'TH-76', name: 'Phetchaburi' },
  { id: 'TH-77', name: 'Prachuap Khiri Khan' },
  { id: 'TH-80', name: 'Nakhon Si Thammarat' },
  { id: 'TH-81', name: 'Krabi' },
  { id: 'TH-82', name: 'Phangnga' },
  { id: 'TH-83', name: 'Phuket' },
  { id: 'TH-84', name: 'Surat Thani' },
  { id: 'TH-85', name: 'Ranong' },
  { id: 'TH-86', name: 'Chumphon' },
  { id: 'TH-90', name: 'Songkhla' },
  { id: 'TH-91', name: 'Satun' },
  { id: 'TH-92', name: 'Trang' },
  { id: 'TH-93', name: 'Phatthalung' },
  { id: 'TH-94', name: 'Pattani' },
  { id: 'TH-95', name: 'Yala' },
  { id: 'TH-96', name: 'Narathiwat' },
  { id: 'TH-S', name: 'Pattaya' },
  /* Taiwan */
  { id: 'TW', name: 'Taiwan' },
  { id: 'TW-KHH', name: 'Kaohsiung' },
  { id: 'TW-TPE', name: 'Taipei' },
  { id: 'TW-TXG', name: 'Taichung' },
  { id: 'TW-TNN', name: 'Tainan' },
  { id: 'TW-KIN', name: 'Kinmen' },
  { id: 'TW-LIE', name: 'Lienchiang' },
  { id: 'TW-CHA', name: 'Changhua' },
  { id: 'TW-HUA', name: 'Hualien' },
  { id: 'TW-ILA', name: 'Yilan' },
  { id: 'TW-MIA', name: 'Miaoli' },
  { id: 'TW-NAN', name: 'Nantou' },
  { id: 'TW-PEN', name: 'Penghu' },
  { id: 'TW-PIF', name: 'Pingtung' },
  { id: 'TW-TTT', name: 'Taitung' },
  { id: 'TW-NWT', name: 'New Taipei' },
  { id: 'TW-YUN', name: 'Yunlin' },
  { id: 'TW-CYI', name: 'Chiayi City' },
  { id: 'TW-HSZ', name: 'Hsinchu City' },
  { id: 'TW-KEE', name: 'Keelung' },
  { id: 'TW-TAO', name: 'Taoyuan' },
  { id: 'TW-CYQ', name: 'Chiayi County' },
  { id: 'TW-HSQ', name: 'Hsinchu County' },
  /* Hong Kong */
  { id: 'HK', name: 'Hong Kong' },
];

export const MESSAGE_TYPE_PUSH_NOTIFICATION = Object.freeze({
  id: 'PushNotification',
  name: 'Push Notification',
  shortName: 'Push',
});
export const MESSAGE_TYPE_IN_APP_MESSAGE = Object.freeze({
  id: 'InAppMessage',
  name: 'In-App Message',
  shortName: 'In-App',
});

export const MESSAGE_TYPES = Object.freeze([
  MESSAGE_TYPE_PUSH_NOTIFICATION,
  MESSAGE_TYPE_IN_APP_MESSAGE,
]);

export const MESSAGE_TYPE_SHORT_NAMES = Object.freeze(
  /* eslint-disable no-param-reassign */
  MESSAGE_TYPES.reduce((obj, item) => {
    obj[item.id] = item.shortName;
    return obj;
  }, {}),
  /* eslint-enable no-param-reassign */
);

export default undefined;
