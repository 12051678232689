import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreateButton from '@material-ui/icons/Create';

const EditMissionButton = ({ campaignId, record }) => (
  <Button
    component={Link}
    to={{
      pathname: `/missions/${record.id}`,
      search: `?campaignId=${campaignId}`,
    }}
    label="Edit"
  >
    <CreateButton />
  </Button>
);
EditMissionButton.propTypes = {
  campaignId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

EditMissionButton.defaultProps = {
  record: null,
};

export default EditMissionButton;
