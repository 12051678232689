/* eslint-disable no-console */
import { HttpError } from 'react-admin';
import fan from '../../../services/fan';

/**
 * Used for accept mission on behalf of user
 * @param {string} missionId
 * @param {string} userId
 * @returns Promise
 */
export const acceptMissionOnBehalfOfUser = async (missionId, userId, acceptTimes) => {
  if (!(missionId && userId && acceptTimes)) {
    throw new Error('Mission id and User id must be provided but is undefined');
  }
  const url = `/admin/missions/${missionId}/submissions?forUser=${userId}&acceptTimes=${acceptTimes}`;

  try {
    const result = await fan.post(url);
    return result;
  } catch (error) {
    const { status, statusText, body } = error;
    return Promise.reject(new HttpError((body && body.message) || statusText, status, body));
  }
};

/**
 * Used for search users by string match
 * @param {string} str
 * @returns Promise
 */
export const searchUserbyString = async (str = '') => {
  const url = `/users/?q=${str}`;
  try {
    const result = await fan.get(url);
    return result;
  } catch (error) {
    const { status, statusText, body } = error;
    return Promise.reject(new HttpError((body && body.message) || statusText, status, body));
  }
};
