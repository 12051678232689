import React, { Fragment } from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  LongTextInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { refreshView as refreshViewAction } from 'ra-core';
import { currencies } from '../config/usersConfig';
import { validateMaxAmountByCurrency } from '../../../util/currencyUtil';
import { transactionLimits } from '../../config/currencyConfig';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginBottom: theme.spacing.unit * 3,
    zIndex: 1300,
  },
  suggestion: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

export const validateBankBalance = values => {
  const errors = {};
  const error = validateMaxAmountByCurrency(
    values.currency,
    values.transaction,
    transactionLimits,
    false,
  );
  if (error) {
    errors.transaction = error;
  }
  return errors;
};

export const BankActivityCreatePresenter = props => {
  const { userId, onSubmit, refreshView, classes } = props;
  const redirect = () => {
    onSubmit();
    refreshView();
    return false;
  };

  return (
    <Create
      {...props}
      resource="bank-account/transactions"
      basePath="/bank-account/transactions"
      title={<Fragment />}
    >
      <SimpleForm
        defaultValue={{ userId }}
        redirect={redirect}
        undoable={false}
        validate={validateBankBalance}
      >
        <Typography variant="h5" component="h2">
          Edit account balance
        </Typography>

        <SelectInput
          source="type"
          choices={[
            { id: 'Cash Boost', name: 'Cash Boost' },
            { id: 'Contest', name: 'Contest' },
            { id: 'Extra reward', name: 'Extra reward' },
            { id: 'Compensation', name: 'Compensation' },
            { id: 'Failed Cash out', name: 'Failed Cash out' },
            { id: 'AirTasker', name: 'AirTasker' },
            { id: 'Other', name: 'Other' },
          ]}
          validate={required()}
          fullWidth
        />
        <ReferenceInput
          sort={{ field: 'updatedAt', order: 'DESC' }}
          source="campaignId"
          reference="campaigns"
          fullWidth
        >
          <AutocompleteInput
            fullWidth
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
          />
        </ReferenceInput>

        <LongTextInput source="comments" fullWidth inputProps={{ rows: '3' }} />
        <NumberInput source="transaction" validate={required()} fullWidth />
        <SelectInput
          source="currency"
          defaultValue=""
          choices={currencies}
          validate={required()}
          fullWidth
        />
        <TextInput label="Notification's title" source="notification.title" fullWidth />
        <LongTextInput
          label="Notification's body"
          source="notification.body"
          fullWidth
          inputProps={{ rows: '3' }}
        />
      </SimpleForm>
    </Create>
  );
};

export default withStyles(styles)(
  connect(
    null,
    { refreshView: refreshViewAction },
  )(BankActivityCreatePresenter),
);
