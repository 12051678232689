import React from 'react';
import {
  NumberInput,
  required,
  ArrayInput,
  ImageInput,
  ImageField,
  LongTextInput,
  TextInput,
  SelectArrayInput,
  SimpleFormIterator,
  FileField,
  FileInput,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import RichTextInput from '../../layout/components/richTextInput/richTextInput.presenter';
import { types, industries, topics } from '../config/campaignConfig';
import styles from '../../layout/globalStyle/cardStyle';

export const parseList = v => (v || '').split(',').map(e => e.trim());
export const formatList = v => (v || []).join(', ');

// eslint-disable-next-line react/prop-types
export const getIndustryReportInput = ({ formData }) => {
  if (!formData || formData.campaignType !== 'Industry Report') {
    return null;
  }
  return (
    <Grid item md={12}>
      <Card style={styles.content}>
        <CardHeader testid="industryReport" title="Industry Report" />
        <CardContent>
          <ImageInput
            source="industryReport.imageUrl"
            label="Industry report image"
            accept="image/*"
            validate={required()}
          >
            <ImageField source="industryReport.imageUrl" title="Industry report image" />
          </ImageInput>
          <ImageInput
            source="industryReport.imageExamples"
            label="Example images"
            accept="image/*"
            multiple
          >
            <ImageField source="industryReport.imageExamples" title="Image examples" />
          </ImageInput>
          <FileInput
            source="industryReport.sneakPeekPdfUrl"
            label="Sneak peak pdf"
            accept="application/pdf"
          >
            <FileField source="industryReport.sneakPeekPdfUrl" title="Sneak peak pdf" />
          </FileInput>
          <LongTextInput
            label="Short description"
            source="industryReport.shortDescription"
            fullWidth
            validate={required()}
          />
          <NumberInput label="Price" source="industryReport.price" fullWidth />
          <SelectArrayInput
            label="Industries"
            fullWidth
            source="industryReport.industries"
            choices={industries.map(id => ({ id, name: id }))}
          />
          <SelectArrayInput
            label="Types"
            fullWidth
            source="industryReport.types"
            choices={types.map(id => ({ id, name: id }))}
          />

          <SelectArrayInput
            label="Topics"
            fullWidth
            source="industryReport.topics"
            choices={topics.map(id => ({ id, name: id }))}
          />
          <ArrayInput source="industryReport.tags" label="Tags">
            <SimpleFormIterator>
              <TextInput source="name" fullWidth label="name" />
              <TextInput source="value" fullWidth label="value" />
            </SimpleFormIterator>
          </ArrayInput>
          <RichTextInput
            label="Description"
            source="industryReport.description"
            validate={required(`The Description is mandatory`)}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const parseAvailableToUsers = v => (v || '').split(/\r?\n/).filter(id => id.length > 0);

export const formatAvailableToUsers = v => (v || []).join('\n');

export const extractTags = tags => tags.map(tag => tag.split(':')[1]).join(', ');
