import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  Edit,
  LongTextInput,
  SelectInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
} from 'react-admin';

import { types, industries, topics } from '../config/config';

class MissionEdit extends PureComponent {
  render() {
    return (
      <Edit {...this.props} undoable={false}>
        <SimpleForm redirect="edit">
          <ImageInput
            testid="imageInput"
            source="imageUrl"
            label="Image"
            accept="image/*"
            validate={required()}
          >
            <ImageField source="imageUrl" title="Image" />
          </ImageInput>
          <TextInput source="name" validate={required()} />
          <LongTextInput source="shortDescription" validate={required()} />
          <FileInput
            testid="fileInput"
            source="link"
            label="PDF"
            accept="application/pdf"
            validate={required()}
          >
            <FileField source="link" title="PDF" />
          </FileInput>
          <SelectInput
            source="type"
            choices={types.map(id => ({ id, name: id }))}
            validate={required()}
          />
          <SelectInput
            source="industry"
            choices={industries.map(id => ({ id, name: id }))}
            validate={required()}
          />
          <SelectInput
            source="topic"
            choices={topics.map(id => ({ id, name: id }))}
            validate={required()}
          />
        </SimpleForm>
      </Edit>
    );
  }
}

export default MissionEdit;
