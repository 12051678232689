/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import config from '../setup/config';
import fan from '../services/fan';

/**
 * Custom hook to fetch data from an API.
 *
 * @param {string} path - The endpoint path to fetch data from.
 * @returns {Object} - An object containing data, loading, and error states.
 */
const useFetch = path => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const baseUrl = config.FAN_BACKEND_BASE_URL;
          const url = baseUrl + path;

          setLoading(true);
          const response = await fan.get(url);
          return setData(response.data);
        } catch (err) {
          setError(err);
          throw new Error(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    },
    [path],
  );

  return { data, loading, error };
};

export default useFetch;

useFetch.propTypes = {
  path: PropTypes.string.isRequired,
};
