import React from 'react';
import { Login } from 'react-admin';

const CustomLoginPresenter = props => (
  <Login
    {...props}
    backgroundImage="https://www.snooper-app.com/wp-content/uploads/2018/11/Landing_page_clients_1.jpg"
  />
);

export default CustomLoginPresenter;
