import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const LengthOfArrayField = ({ source, record = {} }) => (
  <Fragment>{(record[source] || []).length}</Fragment>
);

LengthOfArrayField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}),
};

LengthOfArrayField.defaultProps = {
  record: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
};
export default LengthOfArrayField;
