const sanitizeAndMatchOptionFilter = (option, inputValue) => {
  if (!option || inputValue === undefined || inputValue === null) return undefined;

  const inputValueWithOutSpecialChar = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const sanitizeInput = new RegExp(inputValueWithOutSpecialChar.replace(/[-_]/g, ''), 'i');

  if (!option.value) return true;

  if (option.value.replace(/[-_]/g, '').match(sanitizeInput) || option.label.match(sanitizeInput)) {
    return true;
  }
  return false;
};

export default sanitizeAndMatchOptionFilter;
