import { CREATE } from 'react-admin';

export const CLONE_CAMPAIGN = 'CREATE';

export const cloneCampaign = (data, keepCheckpoints, basePath, redirect) => ({
  type: CLONE_CAMPAIGN,
  payload: {
    data,
    keepCheckpoints,
  },

  meta: {
    resource: 'campaigns',
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: 'Successfully cloned campaign',
        level: 'info',
      },
      redirectTo: redirect,
      basePath,
    },
    onFailure: {
      notification: {
        body: 'Failed to clone campaign',
        level: 'error',
      },
    },
  },
});

export default undefined;
