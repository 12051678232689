import XLSX from 'xlsx';
import { CREATE } from 'react-admin';
import uuid from 'uuid/v4';

export const extractUsersFromExcel = (
  data,
  options = {
    type: 'binary',
  },
) => {
  const workbook = XLSX.read(data, options);

  const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
  const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  return rawData
    .splice(1) // remove excel headers
    .filter(rawMission => rawMission[1]) // remove blank lines
    .map(rawMission => ({
      email: rawMission[0],
      firstName: rawMission[1],
      lastName: rawMission[2],
      password: rawMission[3] || uuid(),
      company: rawMission[4],
      role: rawMission[5] || 'USER',
    }));
};

export const importUsers = async (users, dataProvider) => {
  const missionsInserted = [];
  const actions = users.map(user => ({ type: CREATE, data: { data: user } }));

  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    // eslint-disable-next-line no-await-in-loop
    const missionInserted = await dataProvider(action.type, 'users', action.data, {
      onSuccess: {
        refresh: i !== 0 && (i % 10 === 0 || i + 1 === actions.length),
      },
      onFailure: {
        notification: {
          body: `User creation failed`,
          level: 'warning',
        },
        refresh: true,
      },
    });
    missionsInserted.push(missionInserted);
  }
  return missionsInserted;
};
