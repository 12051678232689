import React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { renderUser } from '../../users/services/render-helper';

export default class ReviewersSelect extends React.Component {
  render() {
    return (
      <ReferenceArrayInput
        label="Reviewers"
        sort={{ field: 'username', order: 'ASC' }}
        source="reviewers"
        reference="users"
        linkType="show"
        fullWidth
        testId="reviewers"
        filter={{ role: ['REVIEWER', 'SENIOR_REVIEWER'] }}
      >
        <AutocompleteArrayInput testId="reviewers-input" optionText={renderUser} fullWidth />
      </ReferenceArrayInput>
    );
  }
}
