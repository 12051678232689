import React, { PureComponent, Fragment } from 'react';
import {
  DateField,
  NumberField,
  Datagrid,
  Pagination,
  FunctionField,
  DeleteButton,
  List,
  CardActions,
  Filter,
  TextInput,
  SelectArrayInput,
  NumberInput,
  SelectInput,
  TextField,
} from 'react-admin';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import { renderSegments } from '../../../../missions/services/render-helper';
import EditMissionButton from './buttons/edit';
import ApplySegmentsRulesButton from './buttons/applySegmentsRulesButton';
import OpenDrawerButton from './buttons/open-bulk-edit-drawer';
import BulkMissionEdit from './bulk-mission-edit.container';
import { tableStyle, postRowStyle } from '../../../../layout/globalStyle/tableStyle';
import LocationField from '../../../../components/fields/locationField';
import BooleanField, {
  OnOffField,
  OpenCloseField,
} from '../../../../components/fields/booleanField';
import ExportMissionButton from './exportXlsx/exportMissionButtonPresenter';
import AcceptMissionOnBehalfOfUserDialog from './AcceptMissionOnBehalfOfUserDialog';

import { STATES } from '../../../config/campaignConfig';
import SelectAndSearchPresenter from '../../../../components/select/selectAndSearch';
import sanitizeAndMatchOptionFilter from '../../../../components/select/selectAndSearchFilters';

const styles = {
  filterWidth: {
    minWidth: 170,
  },
};

const isMissionActive = ({ availableSubmitNumber, endDate, startDate }) => {
  const now = new Date().getTime();
  return (
    availableSubmitNumber > 0 &&
    new Date(startDate).getTime() < now &&
    new Date(endDate).getTime() > now
  );
};

export const MissionAction = ({ campaignId, toggleDrawerVisibility, ...props }) => (
  <CardActions>
    <ApplySegmentsRulesButton campaignId={campaignId} />
    <OpenDrawerButton toggleDrawerVisibility={toggleDrawerVisibility} />
    <ExportMissionButton {...props} />
  </CardActions>
);

MissionAction.propTypes = {
  toggleDrawerVisibility: PropTypes.func.isRequired,
};

export const MissionFilters = withStyles(styles)(({ classes, ...props }) => (
  <Filter {...props} style={tableStyle.filter}>
    <TextInput
      label="Location"
      source="location"
      variant="outlined"
      options={{ variant: 'outlined' }}
      alwaysOn
    />

    <TextInput label="Segments" source="segment" alwaysOn />
    <SelectAndSearchPresenter
      name="state"
      source="state"
      handleFilterOption={sanitizeAndMatchOptionFilter}
      choices={STATES}
      isMulti
      placeholder="State"
      alwaysOn
    />
    <SelectArrayInput
      label="Banner"
      source="banner"
      choices={[
        { id: 'Coles', name: 'Coles' },
        { id: 'Woolworths', name: 'Woolworths' },
        { id: 'IGA', name: 'IGA' },
        { id: 'Ritchies', name: 'Ritchies' },
        { id: 'BWS', name: 'BWS' },
        { id: 'Liquorland', name: 'Liquorland' },
        { id: 'Dan Murphys', name: 'Dan Murphys' },
        { id: 'Caltex', name: 'Caltex' },
        { id: '7 Eleven', name: '7 Eleven' },
        { id: 'Coles Express', name: 'Coles Express' },
        { id: 'Thirsty Camel', name: 'Thirsty Camel' },
        { id: 'BP', name: 'BP' },
        { id: 'Telstra', name: 'Telstra' },
        { id: 'Chemist Warehouse', name: 'Chemist Warehouse' },
        { id: 'Priceline', name: 'Priceline' },
        { id: 'Terry White', name: 'Terry White' },
        { id: 'Aldi', name: 'Aldi' },
        { id: 'Countdown', name: 'Countdown' },
      ]}
      style={styles.filterWidth}
      alwaysOn
    />
    <SelectArrayInput
      label="Area"
      source="area"
      choices={[{ id: 'Metro', name: 'Metro' }, { id: 'Regional', name: 'Regional' }]}
      style={styles.filterWidth}
      alwaysOn
    />
    <SelectInput
      source="isActive"
      choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
      alwaysOn
    />
    <NumberInput source="reward" alwaysOn />
    <NumberInput source="experienceReward" alwaysOn />
    <NumberInput source="maxSubmissionsPerLocation" style={styles.filterWidth} alwaysOn />
    <NumberInput source="availableSubmitNumber" style={styles.filterWidth} alwaysOn />
    <SelectInput
      label="Enabled"
      source="disabled"
      choices={[{ id: false, name: 'On' }, { id: true, name: 'Off' }]}
      alwaysOn
    />
    <SelectInput
      label="Store Status"
      source="storeClosed"
      choices={[{ id: false, name: 'Open' }, { id: true, name: 'Closed' }]}
      alwaysOn
    />
  </Filter>
));

class MissionList extends PureComponent {
  state = {
    isDrawerOpen: false,
  };

  toggleDrawerVisibility = () => {
    this.setState(prevState => ({ isDrawerOpen: !prevState.isDrawerOpen }));
  };

  render() {
    const { campaignId, classes, record } = this.props;
    const { isDrawerOpen } = this.state;
    const action = (
      <MissionAction
        campaignId={campaignId}
        toggleDrawerVisibility={this.toggleDrawerVisibility}
        {...this.props}
      />
    );
    return (
      <Fragment>
        <Drawer anchor="right" open={isDrawerOpen} onClose={this.toggleDrawerVisibility}>
          <BulkMissionEdit campaignId={campaignId} close={this.toggleDrawerVisibility} />
        </Drawer>
        <List
          {...this.props}
          pagination={<Pagination />}
          filter={{ campaignId }}
          sort={{ field: 'createdAt', order: 'DESC' }}
          resource="missions"
          basePath="missions"
          bulkActionButtons={false}
          filters={<MissionFilters />}
          actions={action}
          title=" "
        >
          <Datagrid
            rowStyle={postRowStyle}
            classes={{
              thead: classes.thead,
              tbody: classes.tbodyMissions,
              row: classes.row,
              table: classes.table,
            }}
          >
            <LocationField
              label="Location"
              cellClassName={classes.w20}
              headerClassName={classes.w20}
            />
            <DateField source="startDate" cellClassName={classes.w6} headerClassName={classes.w6} />
            <DateField source="endDate" cellClassName={classes.w6} headerClassName={classes.w6} />
            <FunctionField
              label="Segments"
              render={renderSegments(3)}
              cellClassName={classes.w13}
              headerClassName={classes.w13}
            />
            <NumberField
              source="maxSubmissionsPerLocation"
              style={tableStyle.alignCenter}
              cellClassName={classes.w9}
              headerClassName={classes.longField}
            />
            <NumberField
              source="availableSubmitNumber"
              style={tableStyle.alignCenter}
              cellClassName={classes.w9}
              headerClassName={classes.longField}
            />
            <FunctionField
              label="# Submissions"
              style={tableStyle.alignCenter}
              sortable={false}
              render={item => (item.acceptedByUserId ? item.acceptedByUserId.length : 0)}
              cellClassName={classes.w6}
              headerClassName={classes.w6}
            />
            <TextField
              source="rewardCurrency"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
            />
            <NumberField
              source="reward"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
            />
            <NumberField
              source="experienceReward"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
            />
            <BooleanField
              label="Available"
              style={tableStyle.alignCenter}
              render={isMissionActive}
              cellClassName={classes.w5}
              headerClassName={classes.w5}
            />
            <OnOffField
              label="Enabled"
              source="disabled"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
              inversed
            />
            <OpenCloseField
              label="Store Status"
              source="storeClosed"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
              inversed
            />
            <OpenCloseField
              label="Segment"
              source="disabledBySegment"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
              inversed
            />
            <OnOffField
              label="Cycle"
              source="betweenCycle"
              style={tableStyle.alignCenter}
              cellClassName={classes.w4}
              headerClassName={classes.w4}
              inversed
            />
            <EditMissionButton
              campaignId={campaignId}
              cellClassName={classes.w7}
              headerClassName={classes.w7}
            />
            <DeleteButton
              redirect={false}
              cellClassName={classes.w7}
              headerClassName={classes.w7}
              undoable={false}
            />
            <AcceptMissionOnBehalfOfUserDialog record={record} showDialog={false} />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
}

MissionList.propTypes = {
  campaignId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  record: PropTypes.shape({}).isRequired,
};

export default withStyles(tableStyle)(MissionList);
