import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import GoBack from '../../components/backButton/backButton';
import CampaignCloneDialog from '../components/campaignCloneDialog.presenter';

const styles = {
  toolbar: {
    position: 'fixed',
    top: '30px',
    zIndex: 10,
    left: '66px',
    right: 0,
    justifyContent: 'space-between',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
};

const CampaignCreateCustomToolbar = ({ Title, show, record, ...props }) => (
  <Toolbar {...props} style={styles.toolbar}>
    <div style={styles.inline}>
      <GoBack testid="back" />
      {Title ? <Title record={record} testid="titleTest" /> : null}
    </div>
    <div>{record.id ? <CampaignCloneDialog {...props} /> : <SaveButton {...props} />}</div>
  </Toolbar>
);

export default CampaignCreateCustomToolbar;
