import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  Typography,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircle } from 'styled-icons/fa-solid';
import styles from '../../../layout/globalStyle/cardStyle';
import RemoveRestrictionsButton from '../buttons/removeRestrictionsButton';

const defaultStyle = {
  label: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  value: {
    color: 'grey',
    textAlign: 'justify',
    marginLeft: 10,
  },
  commonFlexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    color: '#E9D502',
    marginLeft: 20,
  },
};

export const showRestrictions = restrictions => {
  if (!restrictions) return false;

  const currentDate = new Date();

  for (let i = 0; i < restrictions.length; ++i) {
    const expireDate = new Date(restrictions[i].expireAt);

    if (expireDate >= currentDate) {
      return true;
    }
  }

  return false;
};

export const formatDate = date => {
  if (!date) return undefined;

  const originalDate = new Date(date);
  originalDate.setUTCMinutes(originalDate.getUTCMinutes() + 1);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  };

  let formattedDate = null;
  try {
    const formatter = new Intl.DateTimeFormat('en-AU', options);
    formattedDate = formatter.format(originalDate);
  } catch (error) {
    if (error.message === 'Invalid time value' || error.name === 'RangeError') {
      return `Invalid Date: ${date}`;
    }
  }
  return formattedDate.concat(' (UTC)');
};

const restrictionInfoText =
  "The user is subject to the following restrictions, which may have been imposed due to recent changes in their password, bank details, or other critical information. These restrictions are enforced as a security measure to safeguard the user's account. You should only remove it upon a user's request, and you must verify their details before doing so.";

const RestrictionsInfo = ({ record }) =>
  showRestrictions(record.restrictions) ? (
    <Card style={styles.cardContent} testId="restrictionsInfo">
      <span
        style={{
          ...defaultStyle.commonFlexBox,
          marginRight: 20,
        }}
      >
        <CardHeader testId="showRestrictionsInfoHeader" title="Restrictions" />
        <RemoveRestrictionsButton record={record} testId="removeRestrictionsButton" />
      </span>
      <span style={{ ...defaultStyle.commonFlexBox, maxWidth: 500 }}>
        <InfoCircle size={90} style={defaultStyle.icon} testId="infoIcon" />
        <Typography
          style={{ ...defaultStyle.value, ...styles.value }}
          testId="showRestrictionsInfoText"
        >
          {restrictionInfoText}
        </Typography>
      </span>
      <Paper style={{ margin: 20 }} testId="showRestrictionsInfoTable">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Expire At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.restrictions.map(restriction => (
              <TableRow>
                <TableCell>{restriction.type}</TableCell>
                <TableCell>{formatDate(restriction.expireAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  ) : null;

export default RestrictionsInfo;

RestrictionsInfo.propTypes = {
  record: PropTypes.shape({}),
};

RestrictionsInfo.defaultProps = {
  record: null,
};
