import React from 'react';
import PropTypes from 'prop-types';
import { Edit, SimpleForm } from 'react-admin';
import { parse } from 'query-string';
import CheckpointForm from './components/checkpointForm/checkpointForm';
import CheckpointToolbar from './components/checkpointToolbar';

const CheckpointTitle = ({ record = {} }) => <span> {record.shortDescription} </span>;

CheckpointTitle.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

function CheckpointEdit(props) {
  const getCampaignId = () => {
    const { location } = props;
    const { campaignId } = parse(location.search) || { campaignId: '' };
    return campaignId;
  };

  const campaignId = getCampaignId();

  const redirectUrl = campaignId ? `/campaigns/${campaignId}/show/checkpoints` : '/campaigns';

  return (
    <Edit {...props} undoable={false} title={<CheckpointTitle />}>
      <SimpleForm
        defaultValue={{ campaignId }}
        redirect={redirectUrl}
        toolbar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <CheckpointToolbar Title={CheckpointTitle} campaignId={campaignId} />
        }
      >
        <CheckpointForm {...props} />
      </SimpleForm>
    </Edit>
  );
}

export default CheckpointEdit;
