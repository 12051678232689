const EN = {
  campaigns: {
    availableToBadges: {
      tooltip: 'Users can only see or accept missions if they have any one of these badges',
      label: 'Only visible to/acceptable by badges',
    },
    notAvailableToBadges: {
      tooltip: 'Users can NOT see or accept missions if they have any one of these badges.',
      label: 'Hidden from/not acceptable by badges',
    },
    availableToExperienceLevels: {
      tooltip:
        'Users can only accept missions if they have any of these experience levels. Users with other experience levels can still see the missions, but they will not be allowed to accept them.',
      label: 'Available to experience levels',
    },
    notAvailableToExperienceLevels: {
      tooltip:
        'Users can neither see, nor accept missions if they have any of these experience levels.',
      label: 'Hidden from/not acceptable by experience levels',
    },
  },
};

const dictionary = {
  EN,
};

export function getNested(targetObject, ...args) {
  if (!targetObject || args.length < 1) {
    return undefined;
  }

  return args.reduce((obj, level) => obj && obj[level], targetObject);
}

/* Note that this is not the final version but a very, very, primitive one.
   We'll introduce a library that supports more features in the future, this
   is just to centralise labels for the moment. */
const currentLanguage = 'EN';
const getDictionaryValue = (...args) => {
  const val = getNested(dictionary[currentLanguage], ...args);
  return val && typeof val === 'string' ? val : args.join('.');
};

export default getDictionaryValue;
