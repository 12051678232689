import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const defaultStyle = {
  label: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  value: {
    marginBottom: 10,
    color: 'grey',
  },
};

const ListInfo = ({ title, record, children, defaultValue, styles }) => (
  <React.Fragment>
    <Typography style={{ ...defaultStyle.label, ...styles.label }} testid="title">
      {title}:
    </Typography>
    <Typography style={{ ...defaultStyle.value, ...styles.value }} testid="record">
      {children || record || defaultValue}
    </Typography>
  </React.Fragment>
);

ListInfo.propTypes = {
  styles: PropTypes.shape({
    label: PropTypes.shape({}),
    value: PropTypes.shape({}),
  }),
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

ListInfo.defaultProps = {
  children: null,
  defaultValue: '/',
  styles: {},
};

export default ListInfo;
