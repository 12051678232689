import React, { PureComponent } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { UPDATE, withDataProvider } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

import DeleteSegmentButton from './components/deleteSegmentButton';
import EditSegmentButton from './components/editSegmentButton';
import AddNewSegmentButton from './components/addNewSegmentButton';
import SaveNewSegmentButton from './components/saveNewSegmentButton';
import BooleanField from '../../../../components/fields/booleanField';
import CopySegmentRulesDialog from './components/copySegmentRulesDialog.presenter';

const styles = theme => ({
  createForm: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    margin: 10,
    padding: 10,
  },
  createFormTitle: {
    color: theme.palette.secondary.main,
  },
});

export const calculateMaxRulePercentage = segmentRule => {
  if (!segmentRule || typeof segmentRule !== 'object') {
    return 'Error';
  }

  const {
    completed,
    abortedCompleted,
    preValidation,
    waitingForValidation,
    maxSubmissions,
  } = segmentRule;

  const isValidNumber = value => typeof value === 'number' && !Number.isNaN(value);

  if (
    !isValidNumber(completed) ||
    !isValidNumber(abortedCompleted) ||
    !isValidNumber(preValidation) ||
    !isValidNumber(waitingForValidation) ||
    !isValidNumber(maxSubmissions) ||
    maxSubmissions === 0
  ) {
    return 'Error';
  }

  const total = completed + preValidation + abortedCompleted + waitingForValidation;
  const percentage = (total / maxSubmissions) * 100;

  return Math.round(percentage * 100) / 100;
};

export const calculateCompletionPercentage = segmentRule => {
  if (!segmentRule || typeof segmentRule !== 'object') {
    return 'Error';
  }

  const { completed, abortedCompleted, preValidation, maxSubmissions } = segmentRule;

  const isValidNumber = value => typeof value === 'number' && !Number.isNaN(value);

  if (
    !isValidNumber(completed) ||
    !isValidNumber(abortedCompleted) ||
    !isValidNumber(preValidation) ||
    !isValidNumber(maxSubmissions) ||
    maxSubmissions === 0
  ) {
    return 'Error';
  }

  const total = completed + preValidation + abortedCompleted;
  const percentage = (total / maxSubmissions) * 100;

  return Math.round(percentage * 100) / 100;
};

export class SegmentsRulesPresenter extends PureComponent {
  state = {
    createForm: {
      target: 'Campaign',
    },
    editForm: {},
    editIndex: false,
    isLoading: false,
    isCreateFormOpen: false,
    isEditFormOpen: false,
  };

  onToggleCreateFormVisibility = () => {
    this.setState(prevState => ({
      isCreateFormOpen: !prevState.isCreateFormOpen,
    }));
  };

  onToggleEditFormVisibility = (segmentsRule, index) => {
    const { isEditFormOpen } = this.state;
    if (isEditFormOpen) {
      this.setState({
        isEditFormOpen: false,
        editForm: {},
        editIndex: false,
      });
      return;
    }

    const ruleUnderEdit = {
      maxSubmissions: segmentsRule.maxSubmissions.toString(),
      maxSubmissionsError: false,
      segmentError: false,
      target: segmentsRule.target,
    };
    const rules = segmentsRule.segments || [];
    rules.forEach(rule => {
      ruleUnderEdit[rule.name] = rule.value;
    });

    this.setState(
      {
        editForm: ruleUnderEdit,
        editIndex: index,
      },
      () => {
        this.setState(prevState => ({
          isEditFormOpen: !prevState.isEditFormOpen,
        }));
      },
    );
  };

  onCreateChange = fieldName => ({ target }) => {
    this.setState(prevState => {
      let { maxSubmissionsError, segmentError } = prevState.createForm;
      if (fieldName === 'maxSubmissions' && target.value) {
        maxSubmissionsError = false;
      }
      if (fieldName !== 'maxSubmissions' && target.value) {
        segmentError = false;
      }
      return {
        createForm: {
          ...prevState.createForm,
          [fieldName]: target.value,
          maxSubmissionsError,
          segmentError,
        },
      };
    });
  };

  onEditChange = fieldName => ({ target }) => {
    this.setState(prevState => {
      let { maxSubmissionsError, segmentError } = prevState.editForm;
      if (fieldName === 'maxSubmissions' && target.value) {
        maxSubmissionsError = false;
      }
      if (fieldName !== 'maxSubmissions' && target.value) {
        segmentError = false;
      }
      return {
        editForm: {
          ...prevState.editForm,
          [fieldName]: target.value,
          maxSubmissionsError,
          segmentError,
        },
      };
    });
  };

  onAddNewRule = async () => {
    const { createForm } = this.state;
    const {
      dataProvider,
      record,
      record: { segments },
    } = this.props;
    const unsortedSegments = Object.keys(createForm)
      .filter(k => k !== 'maxSubmissions' && k !== 'target' && !k.endsWith('Error'))
      .map(name => ({ name, value: createForm[name] }))
      .filter(s => s.value);

    const newSegments = this.sortRuleSegmentsBasedOnSegments(unsortedSegments, segments);

    if (newSegments.length === 0) {
      this.setState(prevState => ({
        createForm: { ...prevState.createForm, segmentError: 'Must select at least one segment' },
      }));
      return;
    }
    if (!createForm.maxSubmissions || createForm.maxSubmissions < 1) {
      this.setState(prevState => ({
        createForm: { ...prevState.createForm, maxSubmissionsError: true },
      }));
      return;
    }
    this.setState({ isLoading: true });

    const campaignWithNewSegmentRule = {
      ...record,
      segmentsRules: [
        ...(record.segmentsRules || []),
        {
          segments: newSegments,
          maxSubmissions: createForm.maxSubmissions,
          target: createForm.target,
        },
      ],
    };
    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { id: record.id, data: campaignWithNewSegmentRule },
        {
          onSuccess: {
            notification: { body: 'Rule Added', level: 'info' },
            refresh: true,
          },
          onFailure: {
            notification: { body: 'Error: could not add the rule', level: 'warning' },
          },
        },
      );
      this.setState({ isLoading: false, isCreateFormOpen: false });
    } catch (ex) {
      this.setState({ isLoading: false });
    }
  };

  onDeleteRule = index => async () => {
    const { dataProvider, record } = this.props;

    this.setState({ isLoading: true });

    const campaignWithoutSegmentRule = {
      ...record,
      segmentsRules: (record.segmentsRules || []).filter(
        (_, segmentRulesindex) => segmentRulesindex !== index,
      ),
    };
    await dataProvider(
      UPDATE,
      'campaigns',
      { id: record.id, data: campaignWithoutSegmentRule },
      {
        onSuccess: {
          notification: { body: 'Rule Removed', level: 'info' },
          refresh: true,
        },
        onFailure: {
          notification: { body: 'Error: could not remove the rule', level: 'warning' },
        },
      },
    );
    this.setState({ isLoading: false });
  };

  onEditRule = async () => {
    const { editForm, editIndex } = this.state;
    const {
      dataProvider,
      record,
      record: { segments },
    } = this.props;
    const unsortedSegments = Object.keys(editForm)
      .filter(k => k !== 'maxSubmissions' && k !== 'target' && !k.endsWith('Error'))
      .map(name => ({ name, value: editForm[name] }))
      .filter(s => s.value);

    const newSegments = this.sortRuleSegmentsBasedOnSegments(unsortedSegments, segments);

    if (newSegments.length === 0) {
      this.setState(prevState => ({
        editForm: { ...prevState.editForm, segmentError: 'Must select at least one segment' },
      }));
      return;
    }
    if (!editForm.maxSubmissions || editForm.maxSubmissions < 1) {
      this.setState(prevState => ({
        editForm: { ...prevState.editForm, maxSubmissionsError: true },
      }));
      return;
    }
    this.setState({ isLoading: true });

    const segmentsRules = [...record.segmentsRules];
    segmentsRules[editIndex] = {
      segments: newSegments,
      maxSubmissions: editForm.maxSubmissions,
      target: editForm.target,
    };

    const campaignWithNewSegmentRule = {
      ...record,
      segmentsRules,
    };

    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { id: record.id, data: campaignWithNewSegmentRule },
        {
          onSuccess: {
            notification: { body: 'Rule Updated', level: 'info' },
            refresh: true,
          },
          onFailure: {
            notification: { body: 'Error: could not update the rule', level: 'warning' },
          },
        },
      );
      this.setState({ isLoading: false, isEditFormOpen: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  /** To make sure the new rule has same order as the segments, for example
   * record: { ..., segments: [{name: "State", values: ["NSW", "VIC"]}, {name: "Banner", values: ["Coles", "Woolis"]}] }
   * Here, the order is: State -> Banner (State come before Banner)
   * So when we create new rule, it should be like
   * {..., segments:[{name: "State", value: "NSW"}, {name: "Banner", value: "Coles"}]},
   * But not
   * {..., segments:[{name: "Banner", value: "Coles"}, {name: "State", value: "NSW"}]},
   * Because "State" should come before "Banner"
   */
  sortRuleSegmentsBasedOnSegments = (unsortedSegments, segments) => {
    let newSegments = [];
    if (segments && segments.length > 0) {
      segments.forEach(segment => {
        const rule = unsortedSegments.find(s => s.name === segment.name);
        if (rule) {
          newSegments.push(rule);
        }
      });
    } else {
      newSegments = [...unsortedSegments];
    }
    return newSegments;
  };

  getSegmentsForHeader = (segments, segmentsRules) => {
    const showedSegments = segments.length > 0 ? segments : segmentsRules[0].segments;
    return showedSegments.map(segment => <TableCell key={segment.name}>{segment.name}</TableCell>);
  };

  renderTableBody = () => {
    const { record } = this.props;
    return <TableBody>{(record.segmentsRules || []).map(this.renderRuleLine)}</TableBody>;
  };

  renderCreateRuleForm = () => {
    const { createForm, isLoading } = this.state;
    const { record, classes } = this.props;
    return (
      <form className={classes.createForm}>
        <Typography variant="h5" component="h2" className={classes.createFormTitle}>
          Add new segment rule
        </Typography>
        {(record.segments || []).map(segment => (
          <FormControl key={segment.name} error={createForm.segmentError}>
            <InputLabel htmlFor={segment.name.replace(' ', '-')}>{segment.name}</InputLabel>
            <Select
              displayEmpty
              testID={`create-select-${segment.name}`}
              value={createForm[segment.name] || ''}
              onChange={this.onCreateChange(segment.name)}
              inputProps={{
                name: segment.name,
                id: segment.name.replace(' ', '-'),
              }}
            >
              <MenuItem value="" />
              {segment.values.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
            {createForm.segmentError && <FormHelperText>{createForm.segmentError}</FormHelperText>}
          </FormControl>
        ))}
        <TextField
          id="maxSubmissions"
          testID="create-maxSubmissions"
          error={createForm.maxSubmissionsError}
          value={createForm.maxSubmissions}
          label="Max Submissions"
          onChange={this.onCreateChange('maxSubmissions')}
          type="number"
          margin="normal"
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Apply on</InputLabel>
          <Select
            testID="create-select-target"
            value={createForm.target}
            onChange={this.onCreateChange('target')}
            inputProps={{
              name: 'target',
              id: 'target-simple',
            }}
          >
            <MenuItem value="Campaign">Campaign</MenuItem>
            <MenuItem value="Cycle" disabled={record.cycleType == null}>
              Cycle
            </MenuItem>
          </Select>
        </FormControl>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <SaveNewSegmentButton onClick={this.onAddNewRule} testID="addNewRuleButton" />
        )}
      </form>
    );
  };

  renderEditRuleForm = () => {
    const { editForm, isLoading } = this.state;
    const { record, classes } = this.props;
    return (
      <form className={classes.createForm}>
        <Typography variant="h5" component="h2" className={classes.createFormTitle}>
          Edit segment rule
        </Typography>
        {(record.segments || []).map(segment => (
          <FormControl key={segment.name} error={editForm.segmentError}>
            <InputLabel htmlFor={segment.name.replace(' ', '-')}>{segment.name}</InputLabel>
            <Select
              displayEmpty
              testID={`edit-select-${segment.name}`}
              value={editForm[segment.name] || ''}
              onChange={this.onEditChange(segment.name)}
              inputProps={{
                name: segment.name,
                id: segment.name.replace(' ', '-'),
              }}
            >
              <MenuItem value="" />
              {segment.values.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
            {editForm.segmentError && <FormHelperText>{editForm.segmentError}</FormHelperText>}
          </FormControl>
        ))}
        <TextField
          id="maxSubmissions"
          testID="edit-maxSubmissions"
          error={editForm.maxSubmissionsError}
          value={editForm.maxSubmissions}
          label="Max Submissions"
          onChange={this.onEditChange('maxSubmissions')}
          type="number"
          margin="normal"
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Apply on</InputLabel>
          <Select
            testID="edit-select-target"
            value={editForm.target}
            onChange={this.onEditChange('target')}
            inputProps={{
              name: 'target',
              id: 'target-simple',
            }}
          >
            <MenuItem value="Campaign">Campaign</MenuItem>
            <MenuItem value="Cycle" disabled={record.cycleType == null}>
              Cycle
            </MenuItem>
          </Select>
        </FormControl>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <SaveNewSegmentButton onClick={this.onEditRule} testID="editRuleButton" />
        )}
      </form>
    );
  };

  renderRuleLine = (segmentsRule, index) => {
    const { record } = this.props;
    const { isLoading } = this.state;

    return (
      <TableRow key={segmentsRule.segments.map(s => s.name).join('-')}>
        {(record.segments || segmentsRule.segments).map(segment => {
          const segmentFromSegmentRule = segmentsRule.segments.find(s => s.name === segment.name);
          return (
            <TableCell key={segment.name}>
              {segmentFromSegmentRule && segmentFromSegmentRule.value}
            </TableCell>
          );
        })}
        <TableCell>
          <BooleanField label="Active" record={segmentsRule} source="ruleActive" />
        </TableCell>
        <TableCell>{calculateCompletionPercentage(segmentsRule)}</TableCell>
        <TableCell>{calculateMaxRulePercentage(segmentsRule)}</TableCell>
        <TableCell>{segmentsRule.active}</TableCell>
        <TableCell>{segmentsRule.abortedWaitingForValidation}</TableCell>
        <TableCell>{segmentsRule.waitingForValidation}</TableCell>
        <TableCell>{segmentsRule.preValidation}</TableCell>
        <TableCell>{segmentsRule.completed}</TableCell>
        <TableCell>{segmentsRule.abortedCompleted}</TableCell>
        <TableCell>{segmentsRule.maxSubmissions}</TableCell>
        <TableCell>
          <Chip label={segmentsRule.target} />
        </TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <EditSegmentButton
              onClick={() => this.onToggleEditFormVisibility(segmentsRule, index)}
            />
          )}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DeleteSegmentButton onClick={this.onDeleteRule(index)} />
          )}
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { isCreateFormOpen, isEditFormOpen } = this.state;
    const {
      record,
      record: { id },
    } = this.props;
    const segmentsRules = record.segmentsRules || [];
    const segments = record.segments || [];
    if (segments.length === 0 && segmentsRules.length === 0) {
      return <Typography>No segments in your missions</Typography>;
    }
    return (
      <div>
        <AddNewSegmentButton onClick={this.onToggleCreateFormVisibility} />
        <CopySegmentRulesDialog campaignId={id} />
        <Table>
          <TableHead>
            <TableRow>
              {this.getSegmentsForHeader(segments, segmentsRules)}
              <TableCell>Active</TableCell>
              <Tooltip
                testid="completionColumnTooltip"
                title="The ‘Completion %’ defines how we’re tracking towards the segment target by only including successful submissions. Includes: Pre-validated, Completed successfully, and Aborted - Completed."
              >
                <TableCell>Completion %</TableCell>
              </Tooltip>
              <Tooltip
                testid="maxRuleColumnTooltip"
                title="The ‘Max rule %’ defines when a segment closes.. Includes: Waiting for validation, Pre-validated, Completed successfully, and Aborted - Completed."
              >
                <TableCell>Max rule %</TableCell>
              </Tooltip>
              <TableCell>Actives</TableCell>
              <TableCell>Aborted - Waiting for Validation</TableCell>
              <TableCell>Waiting for validation</TableCell>
              <TableCell>Pre-validated</TableCell>
              <TableCell>Completed successfully</TableCell>
              <TableCell>Aborted Completed</TableCell>
              <TableCell>Max Submissions</TableCell>
              <TableCell>Target</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {this.renderTableBody()}
        </Table>
        <Drawer
          anchor="right"
          open={isCreateFormOpen}
          onClose={this.onToggleCreateFormVisibility}
          testId="creatFromDrawer"
        >
          {this.renderCreateRuleForm()}
        </Drawer>
        <Drawer
          anchor="right"
          open={isEditFormOpen}
          onClose={() => this.onToggleEditFormVisibility({})}
          testId="editFromDrawer"
        >
          {this.renderEditRuleForm()}
        </Drawer>
      </div>
    );
  }
}

SegmentsRulesPresenter.propTypes = {
  record: PropTypes.shape({
    cycleType: PropTypes.string,
    segmentsRules: PropTypes.arrayOf(PropTypes.shape({})),
    segments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dataProvider: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

SegmentsRulesPresenter.defaultProps = {
  record: null,
};

export default withStyles(styles)(withDataProvider(SegmentsRulesPresenter));
