const HONG_KONG_BANK_NAMES = Object.freeze([
  Object.freeze({ id: '552', name: 'AAREAL BANK AG, WIESBADEN, GERMANY [552]' }),
  Object.freeze({ id: '307', name: 'ABN AMRO BANK N.V. [307]' }),
  Object.freeze({ id: 'A10', name: 'AGRICULTURAL BANK OF CHINA LIMITED FRANKFURT BRANCH [A10]' }),
  Object.freeze({ id: '222', name: 'AGRICULTURAL BANK OF CHINA LIMITED [222]' }),
  Object.freeze({ id: '340', name: 'AGRICULTURAL BANK OF CHINA LIMITED, SINGAPORE BRANCH [340]' }),
  Object.freeze({ id: '400', name: 'AGRICULTURAL BANK OF CHINA, BEIJING [400]' }),
  Object.freeze({ id: '395', name: 'AIRSTAR BANK LIMITED [395]' }),
  Object.freeze({ id: '256', name: 'ALLAHABAD BANK [256]' }),
  Object.freeze({ id: '402', name: 'ALLIED BANKING CORPORATION (HK) LTD. [402]' }),
  Object.freeze({ id: '393', name: 'ANT BANK (HONG KONG) LIMITED [393]' }),
  Object.freeze({ id: '152', name: 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD [152]' }),
  Object.freeze({ id: '261', name: 'AXIS BANK LIMITED [261]' }),
  Object.freeze({
    id: '234',
    name: 'BANCA MONTE DEI PASCHI DI SIENA S.P.A. - HONG KONG BRANCH [234]',
  }),
  Object.freeze({ id: '147', name: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A. [147]' }),
  Object.freeze({ id: '356', name: 'BANCO DO BRASIL S. A., TOKYO BRANCH [356]' }),
  Object.freeze({ id: '267', name: 'BANCO SANTANDER, S.A. [267]' }),
  Object.freeze({ id: '049', name: 'BANGKOK BANK PUBLIC COMPANY LTD [049]' }),
  Object.freeze({ id: '553', name: 'BANK FOR FOREIGN TRADE OF VIETNAM [553]' }),
  Object.freeze({ id: '278', name: 'BANK J. SAFRA SARASIN LTD [278]' }),
  Object.freeze({ id: '320', name: 'BANK JULIUS BAER & CO. LTD. [320]' }),
  Object.freeze({ id: '055', name: 'BANK OF AMERICA, NATIONAL ASSOCIATION [055]' }),
  Object.freeze({ id: '259', name: 'BANK OF BARODA [259]' }),
  Object.freeze({ id: '283', name: 'BANK OF CHINA (ELUOSI) [283]' }),
  Object.freeze({ id: '012', name: 'BANK OF CHINA (HONG KONG) LIMITED [012]' }),
  Object.freeze({ id: '014', name: 'BANK OF CHINA (HONG KONG) LIMITED [014]' }),
  Object.freeze({ id: '019', name: 'BANK OF CHINA (HONG KONG) LIMITED [019]' }),
  Object.freeze({ id: '026', name: 'BANK OF CHINA (HONG KONG) LIMITED [026]' }),
  Object.freeze({ id: '030', name: 'BANK OF CHINA (HONG KONG) LIMITED [030]' }),
  Object.freeze({ id: '031', name: 'BANK OF CHINA (HONG KONG) LIMITED [031]' }),
  Object.freeze({ id: '033', name: 'BANK OF CHINA (HONG KONG) LIMITED [033]' }),
  Object.freeze({ id: '036', name: 'BANK OF CHINA (HONG KONG) LIMITED [036]' }),
  Object.freeze({ id: '064', name: 'BANK OF CHINA (HONG KONG) LIMITED [064]' }),
  Object.freeze({ id: '070', name: 'BANK OF CHINA (HONG KONG) LIMITED [070]' }),
  Object.freeze({ id: '290', name: 'BANK OF CHINA (HUNGARY) CLOSE LIMITED [290]' }),
  Object.freeze({ id: '282', name: 'BANK OF CHINA (MALAYSIA) BERHAD [282]' }),
  Object.freeze({ id: '284', name: 'BANK OF CHINA LIMITED JAKARTA BRANCH [284]' }),
  Object.freeze({ id: '298', name: 'BANK OF CHINA LIMITED JOHANNESBURG BRANCH [298]' }),
  Object.freeze({ id: '301', name: 'BANK OF CHINA LIMITED LUXEMBOURG BRANCH [301]' }),
  Object.freeze({ id: '279', name: 'BANK OF CHINA LIMITED MANILA BRANCH [279]' }),
  Object.freeze({ id: '291', name: 'BANK OF CHINA LIMITED MILAN BRANCH [291]' }),
  Object.freeze({ id: '304', name: 'BANK OF CHINA LIMITED NEW YORK BRANCH [304]' }),
  Object.freeze({ id: '303', name: 'BANK OF CHINA LIMITED PARIS BRANCH [303]' }),
  Object.freeze({ id: '327', name: 'BANK OF CHINA LIMITED PHNOM PENH BRANCH [327]' }),
  Object.freeze({ id: '285', name: 'BANK OF CHINA LIMITED SEOUL BRANCH [285]' }),
  Object.freeze({ id: '286', name: 'BANK OF CHINA LIMITED SINGAPORE BRANCH [286]' }),
  Object.freeze({ id: '288', name: 'BANK OF CHINA LIMITED SYDNEY BRANCH [288]' }),
  Object.freeze({ id: 'A00', name: 'BANK OF CHINA LIMITED TAIPEI BRANCH [A00]' }),
  Object.freeze({ id: '280', name: 'BANK OF CHINA LIMITED TOKYO BRANCH [280]' }),
  Object.freeze({ id: '338', name: 'BANK OF CHINA LIMITED, HONG KONG BRANCH [338]' }),
  Object.freeze({ id: '311', name: 'BANK OF CHINA LIMITED, PANAMA BRANCH [311]' }),
  Object.freeze({ id: '292', name: 'BANK OF CHINA LONDON BRANCH [292]' }),
  Object.freeze({ id: 'A12', name: 'BANK OF CHINA LTD. - ABU DHABI [A12]' }),
  Object.freeze({ id: '300', name: 'BANK OF CHINA, LUXEMBOURG S.A. [300]' }),
  Object.freeze({
    id: '428',
    name: 'BANK OF COMMUNICATIONS CO., LTD. HEAD OFFICE, SHANGHAI [428]',
  }),
  Object.freeze({ id: '429', name: 'BANK OF COMMUNICATIONS CO., LTD. SHENZHEN BRANCH [429]' }),
  Object.freeze({ id: '027', name: 'BANK OF COMMUNICATIONS CO., LTD. [027]' }),
  Object.freeze({ id: '382', name: 'BANK OF COMMUNICATIONS HONG KONG LIMITED [382]' }),
  Object.freeze({ id: '349', name: 'BANK OF COMMUNICATIONS SEOUL BRANCH [349]' }),
  Object.freeze({ id: '555', name: 'BANK OF HANGZHOU, HANGZHOU [555]' }),
  Object.freeze({ id: '058', name: 'BANK OF INDIA [058]' }),
  Object.freeze({ id: '506', name: 'BANK OF JIANGSU CO. LTD., NANJING [506]' }),
  Object.freeze({ id: '086', name: 'BANK OF MONTREAL [086]' }),
  Object.freeze({ id: '542', name: 'BANK OF MONTREAL, GUANGZHOU BRANCH [542]' }),
  Object.freeze({ id: '478', name: 'BANK OF NINGBO CO. LTD., ZHEJIANG [478]' }),
  Object.freeze({ id: '372', name: 'BANK OF SHANGHAI (HONG KONG) LIMITED [372]' }),
  Object.freeze({ id: '272', name: 'BANK OF SINGAPORE LIMITED [272]' }),
  Object.freeze({ id: '201', name: 'BANK OF TAIWAN [201]' }),
  Object.freeze({ id: '241', name: 'BANK SINOPAC [241]' }),
  Object.freeze({ id: '244', name: 'BANK SINOPAC [244]' }),
  Object.freeze({ id: '355', name: 'BANK SINOPAC, LOS ANGELES BRANCH [355]' }),
  Object.freeze({ id: '431', name: 'BANK SINOPAC, TAIPEI [431]' }),
  Object.freeze({ id: '074', name: 'BARCLAYS BANK PLC [074]' }),
  Object.freeze({ id: '067', name: 'BDO UNIBANK, INC. [067]' }),
  Object.freeze({ id: '275', name: 'BNP PARIBAS SECURITIES SERVICES [275]' }),
  Object.freeze({ id: '240', name: 'BNP PARIBAS WEALTH MANAGEMENT [240]' }),
  Object.freeze({ id: '056', name: 'BNP PARIBAS [056]' }),
  Object.freeze({ id: '252', name: 'BOC CREDIT CARD (INTERNATIONAL) LIMITED [252]' }),
  Object.freeze({ id: '350', name: 'BSI LTD [350]' }),
  Object.freeze({ id: '351', name: 'CAMBODIA MEKONG BANK PUBLIC LIMITED [351]' }),
  Object.freeze({ id: '092', name: 'CANADIAN IMPERIAL BANK OF COMMERCE [092]' }),
  Object.freeze({ id: '366', name: 'CANADIAN IMPERIAL BANK OF COMMERCE, CANADA [366]' }),
  Object.freeze({ id: '262', name: 'CANARA BANK [262]' }),
  Object.freeze({ id: '263', name: 'CATHAY BANK [263]' }),
  Object.freeze({ id: '236', name: 'CATHAY UNITED BANK COMPANY, LIMITED [236]' }),
  Object.freeze({ id: '206', name: 'CHANG HWA COMMERCIAL BANK LTD [206]' }),
  Object.freeze({ id: '529', name: 'CHANGSHA CITY COMMERCIAL BANK [529]' }),
  Object.freeze({ id: '018', name: 'CHINA CITIC BANK INTERNATIONAL LIMITED [018]' }),
  Object.freeze({ id: '051', name: 'CHINA CITIC BANK INTERNATIONAL LIMITED [051]' }),
  Object.freeze({ id: '437', name: 'CHINA CITIC BANK, BEIJING [437]' }),
  Object.freeze({ id: '009', name: 'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [009]' }),
  Object.freeze({ id: '221', name: 'CHINA CONSTRUCTION BANK CORPORATION [221]' }),
  Object.freeze({ id: '358', name: 'CHINA CONSTRUCTION BANK SEOUL BRANCH [358]' }),
  Object.freeze({ id: '276', name: 'CHINA DEVELOPMENT BANK CORPORATION [276]' }),
  Object.freeze({ id: '368', name: 'CHINA EVERBRIGHT BANK CO., LTD [368]' }),
  Object.freeze({ id: '434', name: 'CHINA EVERBRIGHT BANK, BEIJING [434]' }),
  Object.freeze({ id: '447', name: 'CHINA GUANGFA BANK CO. LTD, BEIJING [447]' }),
  Object.freeze({ id: '549', name: 'CHINA GUANGFA BANK CO., LTD. GUANGZHOU [549]' }),
  Object.freeze({ id: 'A09', name: 'CHINA MERCHANTS BANK CO., LTD, SINGAPORE BRANCH [A09]' }),
  Object.freeze({ id: '238', name: 'CHINA MERCHANTS BANK CO., LTD. [238]' }),
  Object.freeze({ id: '435', name: 'CHINA MERCHANTS BANK, HEAD OFFICE, SHENZHEN [435]' }),
  Object.freeze({ id: '353', name: 'CHINA MINSHENG BANKING CORP., LTD. [353]' }),
  Object.freeze({ id: '436', name: 'CHINA MINSHENG BANKING CORPORATION LTD., BEIJING [436]' }),
  Object.freeze({ id: '507', name: 'CHINA RESOURCES BANK OF ZHUHAI CO LTD., ZHUHAI [507]' }),
  Object.freeze({ id: '039', name: 'CHIYU BANKING CORPORATION LTD [039]' }),
  Object.freeze({ id: '041', name: 'CHONG HING BANK LTD [041]' }),
  Object.freeze({ id: '374', name: 'CIMB BANK BERHAD [374]' }),
  Object.freeze({ id: '343', name: 'CIMB THAI BANK PUBLIC COMPANY LIMITED [343]' }),
  Object.freeze({ id: '531', name: 'CITIBANK (CHINA) CO LTD, SHENZHEN BRANCH [531]' }),
  Object.freeze({ id: '250', name: 'CITIBANK (HONG KONG) LIMITED [250]' }),
  Object.freeze({ id: '006', name: 'CITIBANK N.A. [006]' }),
  Object.freeze({ id: '097', name: 'COMMERZBANK AG [097]' }),
  Object.freeze({ id: '153', name: 'COMMONWEALTH BANK OF AUSTRALIA [153]' }),
  Object.freeze({ id: '524', name: 'CONCORD BANK LTD., NINGBO [524]' }),
  Object.freeze({ id: '868', name: 'CONTINUOUS LINKED SETTLEMENT BANK INTERNATIONAL [868]' }),
  Object.freeze({
    id: '323',
    name: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. (TRADIN [323]',
  }),
  Object.freeze({ id: '183', name: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. [183]' }),
  Object.freeze({
    id: '319',
    name: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A.(TRADING [319]',
  }),
  Object.freeze({ id: '205', name: 'COUTTS & CO LTD [205]' }),
  Object.freeze({ id: '513', name: 'COUTTS AND CO. LTD., SINGAPORE [513]' }),
  Object.freeze({ id: '339', name: 'CREDIT AGRICOLE (SUISSE) S.A. [339]' }),
  Object.freeze({ id: '005', name: 'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK [005]' }),
  Object.freeze({ id: '233', name: 'CREDIT SUISSE AG [233]' }),
  Object.freeze({ id: '229', name: 'CTBC BANK CO., LTD. [229]' }),
  Object.freeze({ id: '040', name: 'DAH SING BANK LTD [040]' }),
  Object.freeze({ id: '016', name: 'DBS BANK (HONG KONG) LIMITED [016]' }),
  Object.freeze({ id: '054', name: 'DEUTSCHE BANK AG [054]' }),
  Object.freeze({ id: '328', name: 'DNB NOR BANK ASA, SINGAPORE BRANCH [328]' }),
  Object.freeze({ id: '439', name: 'DONGGUAN RURAL CREDIT COOPERATIVES UNION, DONGGUAN [439]' }),
  Object.freeze({ id: '113', name: 'DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK [113]' }),
  Object.freeze({ id: '243', name: 'E.SUN COMMERCIAL BANK, LTD [243]' }),
  Object.freeze({ id: '557', name: 'E.SUN COMMERCIAL BANK, LTD., TAIPEI [557]' }),
  Object.freeze({ id: '258', name: 'EAST WEST BANK [258]' }),
  Object.freeze({ id: '346', name: 'EDMOND DE ROTHSCHILD (SUISSE) S.A. [346]' }),
  Object.freeze({ id: '237', name: 'EFG BANK AG [237]' }),
  Object.freeze({ id: '227', name: 'ERSTE GROUP BANK AG [227]' }),
  Object.freeze({ id: '440', name: 'EXPORT-IMPORT BANK OF THAILAND, BANGKOK [440]' }),
  Object.freeze({ id: '253', name: 'FALCON PRIVATE BANK LTD. [253]' }),
  Object.freeze({ id: '260', name: 'FAR EASTERN INTERNATIONAL BANK [260]' }),
  Object.freeze({ id: '362', name: 'FINABANK N.V. [362]' }),
  Object.freeze({ id: '203', name: 'FIRST COMMERCIAL BANK [203]' }),
  Object.freeze({ id: '128', name: 'FUBON BANK (HONG KONG) LIMITED [128]' }),
  Object.freeze({ id: '391', name: 'FUSION BANK LIMITED [391]' }),
  Object.freeze({ id: '325', name: 'GRANIT BANK ZRT [325]' }),
  Object.freeze({ id: '540', name: 'GUIYANG COMMERCIAL BANK, GUIYANG [540]' }),
  Object.freeze({ id: '322', name: 'HABIB BANK ZURICH (HONG KONG) LIMITED [322]' }),
  Object.freeze({ id: '112', name: 'HANA BANK [112]' }),
  Object.freeze({ id: '024', name: 'HANG SENG BANK LTD [024]' }),
  Object.freeze({ id: '308', name: 'HDFC BANK LTD. [308]' }),
  Object.freeze({ id: '935', name: 'HKT Payment Limited [935]' }),
  Object.freeze({ id: '888', name: 'HONG KONG MONETARY AUTHORITY [888]' }),
  Object.freeze({ id: '248', name: 'HONG LEONG BANK BERHAD [248]' }),
  Object.freeze({
    id: '004',
    name: 'HSBC - THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD [004]',
  }),
  Object.freeze({ id: '450', name: 'HSBC BANGLADESH [450]' }),
  Object.freeze({ id: '464', name: 'HSBC BANK (TAIWAN) LIMITED [464]' }),
  Object.freeze({ id: '457', name: 'HSBC BANK MALAYSIA BERHAD [457]' }),
  Object.freeze({ id: '347', name: 'HSBC BANK PLC [347]' }),
  Object.freeze({ id: '106', name: 'HSBC BANK USA, NATIONAL ASSOCIATION [106]' }),
  Object.freeze({ id: '466', name: 'HSBC BANK VIETNAM LTD [466]' }),
  Object.freeze({ id: '451', name: 'HSBC BRUNEI [451]' }),
  Object.freeze({ id: '452', name: 'HSBC INDIA [452]' }),
  Object.freeze({ id: '453', name: 'HSBC INDONESIA [453]' }),
  Object.freeze({ id: '454', name: 'HSBC JAPAN [454]' }),
  Object.freeze({ id: '458', name: 'HSBC MANILA [458]' }),
  Object.freeze({ id: '235', name: 'HSBC PRIVATE BANK (SUISSE) SA [235]' }),
  Object.freeze({ id: '460', name: 'HSBC SEOUL BRANCH [460]' }),
  Object.freeze({ id: '462', name: 'HSBC SINGAPORE [462]' }),
  Object.freeze({ id: '463', name: 'HSBC SRI LANKA [463]' }),
  Object.freeze({ id: '465', name: 'HSBC THAILAND [465]' }),
  Object.freeze({ id: '198', name: 'HUA NAN COMMERCIAL BANK LTD [198]' }),
  Object.freeze({ id: '550', name: 'HUA NAN COMMERCIAL BANK LTD., TAIPEI [550]' }),
  Object.freeze({ id: '468', name: 'HUA XIA BANK, BEIJING [468]' }),
  Object.freeze({ id: '341', name: 'ICBC STANDARD BANK PLC [341]' }),
  Object.freeze({ id: '251', name: 'ICICI BANK LIMITED [251]' }),
  Object.freeze({ id: '050', name: 'INDIAN OVERSEAS BANK [050]' }),
  Object.freeze({ id: '029', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [029]' }),
  Object.freeze({ id: '072', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [072]' }),
  Object.freeze({
    id: '330',
    name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD [330]',
  }),
  Object.freeze({ id: '214', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED [214]' }),
  Object.freeze({ id: '469', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA, BEIJING [469]' }),
  Object.freeze({ id: '377', name: 'INDUSTRIAL BANK CO., LTD. [377]' }),
  Object.freeze({ id: '445', name: 'INDUSTRIAL BANK COMPANY LTD., FUZHOU [445]' }),
  Object.freeze({ id: '271', name: 'INDUSTRIAL BANK OF KOREA [271]' }),
  Object.freeze({ id: '274', name: 'INDUSTRIAL BANK OF TAIWAN CO., LTD. [274]' }),
  Object.freeze({ id: '470', name: 'INDUSTRIAL BANK OF TAIWAN, TAIPEI [470]' }),
  Object.freeze({ id: '145', name: 'ING BANK N.V. [145]' }),
  Object.freeze({ id: '161', name: 'INTESA SANPAOLO S.P.A. [161]' }),
  Object.freeze({ id: '375', name: 'J.P. MORGAN SECURITIES (ASIA PACIFIC) LIMITED [375]' }),
  Object.freeze({
    id: '541',
    name: 'JIANG YIN RURAL COMMERCIAL BANK, JIANG YIN CITY, JIANG SU [541]',
  }),
  Object.freeze({ id: '317', name: 'JOINT STOCK COMMERCIAL BANK "LANTA-BANK" (CJSC) [317]' }),
  Object.freeze({
    id: '407',
    name: 'JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT O [407]',
  }),
  Object.freeze({ id: '007', name: 'JPMORGAN CHASE BANK [007]' }),
  Object.freeze({ id: '305', name: 'JSC AB (BANK OF CHINA KAZAKHSTAN) [305]' }),
  Object.freeze({ id: 'A06', name: 'JSC CAPITAL BANK [A06]' }),
  Object.freeze({ id: '380', name: 'KASIKORNBANK PUBLIC COMPANY LIMITED [380]' }),
  Object.freeze({ id: '178', name: 'KBC BANK N.V. [178]' }),
  Object.freeze({ id: '318', name: 'KDB ASIA LIMITED [318]' }),
  Object.freeze({ id: '363', name: 'KEB ASIA FINANCE LIMITED [363]' }),
  Object.freeze({ id: '046', name: 'KOREA EXCHANGE BANK [046]' }),
  Object.freeze({ id: 'A02', name: 'KOREA EXCHANGE BANK, KOREA [A02]' }),
  Object.freeze({ id: '264', name: 'LAND BANK OF TAIWAN CO., LTD. [264]' }),
  Object.freeze({ id: '342', name: 'LGT BANK AG [342]' }),
  Object.freeze({ id: '388', name: 'LIVI BANK LIMITED [388]' }),
  Object.freeze({ id: '344', name: 'MACQUARIE BANK LIMITED [344]' }),
  Object.freeze({ id: '379', name: 'MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY [379]' }),
  Object.freeze({ id: '352', name: 'MASHREQBANK PSC [352]' }),
  Object.freeze({ id: '063', name: 'MAYBANK (MALAYAN BANKING BERHAD) [063]' }),
  Object.freeze({ id: '242', name: 'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. [242]' }),
  Object.freeze({ id: '254', name: 'MELLI BANK PLC [254]' }),
  Object.freeze({ id: '295', name: 'METROPOLITAN BANK & TRUST COMPANY [295]' }),
  Object.freeze({ id: '138', name: 'MITSUBISHI UFJ TRUST AND BANKING CORPORATION [138]' }),
  Object.freeze({ id: '109', name: 'MIZUHO BANK, LTD. [109]' }),
  Object.freeze({ id: '389', name: 'Mox Bank Limited [389]' }),
  Object.freeze({ id: '043', name: 'NANYANG COMMERCIAL BANK LTD [043]' }),
  Object.freeze({ id: '150', name: 'NATIONAL AUSTRALIA BANK LTD [150]' }),
  Object.freeze({ id: '277', name: 'NATIONAL BANK OF ABU DHABI [277]' }),
  Object.freeze({ id: '331', name: 'NATIONAL BANK OF CANADA [331]' }),
  Object.freeze({ id: '060', name: 'NATIONAL BANK OF PAKISTAN [060]' }),
  Object.freeze({ id: '210', name: 'NATIXIS [210]' }),
  Object.freeze({ id: '035', name: 'OCBC WING HANG BANK LIMITED [035]' }),
  Object.freeze({ id: '044', name: 'OCBC WING HANG BANK LIMITED [044]' }),
  Object.freeze({ id: '367', name: 'ORIENT EXPRESS BANK [367]' }),
  Object.freeze({ id: '373', name: 'ORIX ASIA LIMITED [373]' }),
  Object.freeze({ id: '022', name: 'OVERSEA-CHINESE BANKING CORPORATION LTD [022]' }),
  Object.freeze({ id: '119', name: 'PHILIPPINE NATIONAL BANK [119]' }),
  Object.freeze({ id: '357', name: 'PICTET & CIE (EUROPE) S.A. [357]' }),
  Object.freeze({ id: '485', name: 'PING AN BANK CO., LTD, SHENZHEN [485]' }),
  Object.freeze({ id: '218', name: 'PORTIGON AG [218]' }),
  Object.freeze({ id: '281', name: 'PT BANK CENTRAL ASIA TBK, INDONESIA [281]' }),
  Object.freeze({ id: '480', name: 'PT. BANK MANDIRI (PERSERO) TBK, HONG KONG BRANCH [480]' }),
  Object.freeze({ id: '066', name: 'PT. BANK NEGARA INDONESIA (PERSERO) TBK. [066]' }),
  Object.freeze({ id: '028', name: 'PUBLIC BANK (HONG KONG) LIMITED [028]' }),
  Object.freeze({ id: '266', name: 'PUNJAB NATIONAL BANK [266]' }),
  Object.freeze({ id: '370', name: 'RAIFFEISEN BANK INTERNATIONAL AG [370]' }),
  Object.freeze({ id: '354', name: 'RAIFFEISEN BANK INTERNATIONAL AG, SINGAPORE BRANCH [354]' }),
  Object.freeze({ id: '080', name: 'ROYAL BANK OF CANADA [080]' }),
  Object.freeze({ id: '296', name: 'SAIGON THUONG TIN COMMERCIAL JOINT STOCK BANK [296]' }),
  Object.freeze({ id: '025', name: 'SHANGHAI COMMERCIAL BANK LTD [025]' }),
  Object.freeze({ id: '345', name: 'SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD [345]' }),
  Object.freeze({ id: '484', name: 'SHANGHAI PUDONG DEVELOPMENT BANK, SHANGHAI [484]' }),
  Object.freeze({ id: '486', name: 'SHENZHEN DEVELOPMENT BANK, SHENZHEN [486]' }),
  Object.freeze({ id: '509', name: 'SHENZHEN RURAL COMMERCIAL BANK [509]' }),
  Object.freeze({ id: '273', name: 'SHINHAN BANK [273]' }),
  Object.freeze({ id: '316', name: 'SKANDINAVISKA ENSKILDA BANKEN AB [316]' }),
  Object.freeze({ id: '255', name: 'SOCIETE GENERALE BANK & TRUST [255]' }),
  Object.freeze({ id: '081', name: 'SOCIETE GENERALE [081]' }),
  Object.freeze({
    id: '818',
    name: 'STANDARD CHARTERED BANK (HONG KONG) LIMITED - EURO CLEARING [818]',
  }),
  Object.freeze({ id: '003', name: 'STANDARD CHARTERED BANK (HONG KONG) LIMITED [003]' }),
  Object.freeze({ id: '249', name: 'STANDARD CHARTERED BANK HONG KONG BRANCH [249]' }),
  Object.freeze({ id: '536', name: 'STANDARD CHARTERED BANK PLC, LONDON [536]' }),
  Object.freeze({ id: '521', name: 'STANDARD CHARTERED BANK, BANGKOK [521]' }),
  Object.freeze({ id: '489', name: 'STANDARD CHARTERED BANK, BEIJING BRANCH [489]' }),
  Object.freeze({ id: '515', name: 'STANDARD CHARTERED BANK, JAKARTA BRANCH [515]' }),
  Object.freeze({ id: '519', name: 'STANDARD CHARTERED BANK, MALAYSIA BERHAD [519]' }),
  Object.freeze({ id: '490', name: 'STANDARD CHARTERED BANK, NANJING BRANCH [490]' }),
  Object.freeze({ id: '517', name: 'STANDARD CHARTERED BANK, PHILIPPINES BRANCH [517]' }),
  Object.freeze({ id: '518', name: 'STANDARD CHARTERED BANK, SEOUL KOREA [518]' }),
  Object.freeze({ id: '491', name: 'STANDARD CHARTERED BANK, SHANGHAI BRANCH [491]' }),
  Object.freeze({ id: '492', name: 'STANDARD CHARTERED BANK, SHENZHEN BRANCH [492]' }),
  Object.freeze({ id: '516', name: 'STANDARD CHARTERED BANK, SINGAPORE BRANCH [516]' }),
  Object.freeze({ id: '522', name: 'STANDARD CHARTERED BANK, SRI LANKA [522]' }),
  Object.freeze({ id: '520', name: 'STANDARD CHARTERED BANK, TAIWAN [520]' }),
  Object.freeze({ id: '493', name: 'STANDARD CHARTERED BANK, TIANJIN BRANCH [493]' }),
  Object.freeze({ id: 'A07', name: 'STANDARD CHARTERED BANK, TOKYO [A07]' }),
  Object.freeze({ id: '494', name: 'STANDARD CHARTERED BANK, XIAMEN BRANCH [494]' }),
  Object.freeze({ id: '495', name: 'STANDARD CHARTERED BANK, ZHUHAI BRANCH [495]' }),
  Object.freeze({ id: '082', name: 'STATE BANK OF INDIA [082]' }),
  Object.freeze({ id: '220', name: 'STATE STREET BANK AND TRUST COMPANY [220]' }),
  Object.freeze({ id: '065', name: 'SUMITOMO MITSUI BANKING CORPORATION [065]' }),
  Object.freeze({ id: '371', name: 'SUMITOMO MITSUI TRUST BANK, LIMITED [371]' }),
  Object.freeze({ id: '496', name: 'SUNNY BANK, TAIPEI [496]' }),
  Object.freeze({ id: '165', name: 'SVENSKA HANDELSBANKEN AB (PUBL) [165]' }),
  Object.freeze({ id: '378', name: 'TA CHONG BANK, LTD. [378]' }),
  Object.freeze({ id: '061', name: 'TAI SANG BANK LTD [061]' }),
  Object.freeze({ id: '038', name: 'TAI YAU BANK LTD [038]' }),
  Object.freeze({ id: '498', name: 'TAICHUNG COMMERCIAL BANK, TAICHUNG [498]' }),
  Object.freeze({ id: '239', name: 'TAIPEI FUBON COMMERCIAL BANK CO., LTD. [239]' }),
  Object.freeze({ id: '245', name: 'TAISHIN INTERNATIONAL BANK CO., LTD [245]' }),
  Object.freeze({ id: '499', name: 'TAISHIN INTERNATIONAL BANK, TAIPEI [499]' }),
  Object.freeze({ id: '230', name: 'TAIWAN BUSINESS BANK HONG KONG BRANCH [230]' }),
  Object.freeze({ id: '265', name: 'TAIWAN COOPERATIVE BANK, LTD. [265]' }),
  Object.freeze({ id: '337', name: 'TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD. [337]' }),
  Object.freeze({
    id: '474',
    name: 'TAIWAN SHIN KONG COMMERCIAL BANK COMPANY LIMITED, TAIPEI [474]',
  }),
  Object.freeze({ id: '502', name: 'THE BANK OF BERMUDA LIMITED, HONG KONG BRANCH [502]' }),
  Object.freeze({ id: '015', name: 'THE BANK OF EAST ASIA, LTD [015]' }),
  Object.freeze({ id: '139', name: 'THE BANK OF NEW YORK MELLON [139]' }),
  Object.freeze({ id: '076', name: 'THE BANK OF NOVA SCOTIA [076]' }),
  Object.freeze({ id: '329', name: 'THE BANK OF NOVA SCOTIA, SINGAPORE [329]' }),
  Object.freeze({ id: '047', name: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD. [047]' }),
  Object.freeze({ id: '170', name: 'THE CHIBA BANK LTD [170]' }),
  Object.freeze({ id: '202', name: 'THE CHUGOKU BANK LTD [202]' }),
  Object.freeze({ id: '188', name: 'THE HACHIJUNI BANK, LTD [188]' }),
  Object.freeze({
    id: '828',
    name: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - USD CLEA [828]',
  }),
  Object.freeze({ id: '554', name: 'THE HOUSING BANK FOR TRADE AND FINANCE, JORDAN [554]' }),
  Object.freeze({ id: '224', name: 'THE IYO BANK LTD [224]' }),
  Object.freeze({ id: '360', name: 'THE ROYAL BANK OF SCOTLAND N.V. [360]' }),
  Object.freeze({ id: '008', name: 'THE ROYAL BANK OF SCOTLAND PLC [008]' }),
  Object.freeze({ id: '528', name: 'THE RURAL CREDIT COOPERATIVES UNION OF SHUNDE [528]' }),
  Object.freeze({ id: '269', name: 'THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD [269]' }),
  Object.freeze({ id: '199', name: 'THE SHIGA BANK, LTD. [199]' }),
  Object.freeze({ id: '186', name: 'THE SHIZUOKA BANK, LTD [186]' }),
  Object.freeze({ id: '487', name: 'THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED [487]' }),
  Object.freeze({ id: '085', name: 'THE TORONTO-DOMINION BANK [085]' }),
  Object.freeze({ id: '500', name: 'TMB BANK PUBLIC COMPANY LIMITED, BANGKOK [500]' }),
  Object.freeze({ id: '501', name: 'TMB BANK PUBLIC COMPANY LIMITED, HONG KONG [501]' }),
  Object.freeze({ id: '312', name: 'TURK EKONOMI BANKASI A.S. [312]' }),
  Object.freeze({ id: '103', name: 'UBS AG, HONG KONG [103]' }),
  Object.freeze({ id: '045', name: 'UCO BANK [045]' }),
  Object.freeze({ id: '164', name: 'UNICREDIT BANK AG [164]' }),
  Object.freeze({ id: '268', name: 'UNION BANK OF INDIA [268]' }),
  Object.freeze({ id: '071', name: 'UNITED OVERSEAS BANK LTD [071]' }),
  Object.freeze({
    id: '504',
    name: 'UNITED SUBURBAN AND RURAL CREDIT COOPERATIVE WUXI, WUXI [504]',
  }),
  Object.freeze({
    id: '293',
    name: 'VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE [293]',
  }),
  Object.freeze({
    id: '505',
    name: 'VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK, HANOI [505]',
  }),
  Object.freeze({ id: '390', name: 'WELAB BANK LIMITED [390]' }),
  Object.freeze({ id: '180', name: 'WELLS FARGO BANK, N.A. [180]' }),
  Object.freeze({ id: '297', name: 'WELLS FARGO BANK, N.A., LONDON BRANCH [297]' }),
  Object.freeze({ id: '151', name: 'WESTPAC BANKING CORPORATION [151]' }),
  Object.freeze({ id: '020', name: 'WING LUNG BANK LTD [020]' }),
  Object.freeze({ id: '118', name: 'WOORI BANK [118]' }),
  Object.freeze({ id: 'A05', name: 'WOORI BANK, KOREA [A05]' }),
  Object.freeze({ id: '547', name: 'XIAMEN BANK CO., LTD., XIAMEN [547]' }),
  Object.freeze({ id: '387', name: 'ZA BANK LIMITED [387]' }),
  Object.freeze({ id: 'A04', name: 'ZAO INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MOSCOW) [A04]' }),
  Object.freeze({ id: '525', name: 'ZIBO CITY COMMERCIAL BANK, SHANDONG [525]' }),
]);

export default HONG_KONG_BANK_NAMES;
