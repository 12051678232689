import React from 'react';
import { withDataProvider, GET_LIST } from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { exportMissionTable, exportXlsx } from './services/exportXlsx.service';

export class ExportMissionButtonPresenter extends React.PureComponent {
  state = {
    isLoading: false,
  };

  onFileExport = async () => {
    const {
      dataProvider,
      currentSort,
      permanentFilter,
      displayedFilters,
      total,
      filterValues,
    } = this.props;
    const payload = {
      filter: {
        ...displayedFilters,
        ...permanentFilter,
        ...filterValues,
      },
      sort: currentSort,
      pagination: {
        page: 1,
        perPage: total,
      },
    };
    this.setState({ isLoading: true });
    const data = await dataProvider(GET_LIST, 'missions', payload);
    this.setState({ isLoading: false });
    const locationFileTable = exportMissionTable(data);
    return exportXlsx(locationFileTable, 'locationFile');
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Button testid="button" color="primary" onClick={this.onFileExport}>
        {isLoading ? <CircularProgress testid="loader" size={20} /> : 'Export'}
      </Button>
    );
  }
}

ExportMissionButtonPresenter.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  currentSort: PropTypes.shape({}).isRequired,
  permanentFilter: PropTypes.shape({}).isRequired,
  displayedFilters: PropTypes.shape({}).isRequired,
  total: PropTypes.shape({}).isRequired,
  resource: PropTypes.shape({}).isRequired,
};

export default withDataProvider(ExportMissionButtonPresenter);
