import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import getMissionFeedback from './mission-feedback.service';

const exporter = async campaignId => {
  const missionFB = await getMissionFeedback(campaignId);
  const d = Object.values(missionFB);
  const csv = convertToCSV(d);
  downloadCSV(csv, `mission-feedback_${campaignId}`);
};

export default exporter;
