import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Title from '../../layout/components/fields/title.presenter';

const styles = {
  tableField: { color: 'grey', fontSize: '12px' },
};

export const PriceField = ({ record, title, gutterBottom, style }) => (
  <React.Fragment>
    {title ? <Title title={title} style={styles.title} /> : null}
    <Typography testid="item" style={style} gutterBottom={gutterBottom}>
      ${record.toLocaleString()}
    </Typography>
  </React.Fragment>
);
PriceField.propTypes = {
  title: PropTypes.string,
  record: PropTypes.arrayOf(PropTypes.any).isRequired,
  gutterBottom: PropTypes.bool,
  style: PropTypes.shape({}),
};

PriceField.defaultProps = {
  title: null,
  gutterBottom: false,
  style: styles.tableField,
};

const BudgetField = ({ record, source, title, style, gutterBottom }) => {
  const budget = record && record.budget ? record.budget : null;
  const item = budget && budget[source] ? budget[source] : null;
  return (
    <React.Fragment>
      {title ? <Title testid="title" title={title} style={styles.title} /> : null}
      {item ? (
        <Typography testid="item" style={style} gutterBottom={gutterBottom}>
          ${item.toLocaleString()}
        </Typography>
      ) : (
        '-'
      )}
    </React.Fragment>
  );
};

BudgetField.propTypes = {
  title: PropTypes.string,
  record: PropTypes.arrayOf(PropTypes.any).isRequired,
  source: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  gutterBottom: PropTypes.bool,
};

BudgetField.defaultProps = {
  title: null,
  style: styles.tableField,
  gutterBottom: false,
};

export default BudgetField;
