import React, { PureComponent } from 'react';
import { addField } from 'react-admin';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import 'froala-editor/js/froala_editor.pkgd.min';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins.pkgd.min';
import FroalaEditor from 'react-froala-wysiwyg';

import config from '../../../../setup/config';

const style = {
  overview: {
    fontFamily: 'Roboto,sans-serif',
    fontSize: '0.9em',
  },
  typography: {
    marginTop: '15px',
  },
};

class RichTextInput extends PureComponent {
  render() {
    const { input, meta, showOverview, label } = this.props;
    const { error } = meta;
    const token = localStorage.getItem('token');
    const backendUrl = `${config.FAN_BACKEND_BASE_URL}/photos/admin?parse-session=${token}`;

    return (
      <div>
        <div>
          {label && (
            <Typography style={style.typography} gutterBottom variant="subtitle2">
              {label}:
            </Typography>
          )}
          {error && (
            <Typography gutterBottom variant="subtitle1" color="error" align="center">
              {error}
            </Typography>
          )}
        </div>
        <FroalaEditor
          tag="textarea"
          model={input.value}
          onModelChange={input.onChange}
          config={{
            key: config.FROALA_KEY,
            placeholderText: 'Edit Your Content Here!',
            charCounterCount: true,
            useClasses: false,
            heightMax: 500,
            heightMin: 200,
            fileUpload: false,
            videoUpload: false,
            attribution: false,
            imageUploadURL: `${backendUrl}`,
          }}
        />
        {showOverview && (
          <div style={style.typography}>
            <Typography gutterBottom variant="subtitle2">
              Overview:
            </Typography>
            <div style={style.overview} dangerouslySetInnerHTML={{ __html: input.value }} />
          </div>
        )}
      </div>
    );
  }
}

RichTextInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  showOverview: PropTypes.bool,
  label: PropTypes.string,
};

RichTextInput.defaultProps = {
  input: {},
  meta: {},
  showOverview: false,
  label: undefined,
};

export default addField(RichTextInput);
