import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  Pagination,
  ReferenceField,
} from 'react-admin';
import { tableStyle } from '../../../layout/globalStyle/tableStyle';

export const SubscriptionsList = props => (
  <List
    key="front"
    {...props}
    title={<Fragment>Subscriptions and Trials</Fragment>}
    style={tableStyle.listHeight}
    resource="subscriptions"
    basePath="/subscriptions"
    hasCreate
    hasList={false}
    hasShow={false}
    perPage={10}
    pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
  >
    <Datagrid
      rowClick="edit"
      postRowStyle={{
        backgroundColor: '#f9fbfc',
        textTransform: 'none',
        tableLayout: 'fixed',
        display: 'table',
        width: '100%',
      }}
    >
      <ChipField source="type" label="Type" testid="typeId" width={20} />
      <ReferenceField
        label="Last Name"
        source="customerId"
        reference="users"
        basePath="/users"
        linkType="show"
        width={100}
        fixed="left"
      >
        <TextField source="lastName" testid="lastNameId" />
      </ReferenceField>
      <ReferenceField
        label="First Name"
        source="customerId"
        reference="users"
        basePath="/users"
        linkType="show"
        width={100}
        fixed="left"
      >
        <TextField source="firstName" testid="firstNameId" />
      </ReferenceField>
      <ReferenceField
        label="Email"
        source="customerId"
        reference="users"
        basePath="/users"
        linkType="show"
        width={300}
        fixed="left"
      >
        <ChipField source="email" testid="emailId" />
      </ReferenceField>
      <DateField source="startDate" label="Start Date" testid="startDateId" />
      <DateField source="endDate" label="End Date" testid="endDateId" />
      <TextField source="id" label="Subscription ID" testid="subscriptionId" width={5} />
    </Datagrid>
  </List>
);

export default SubscriptionsList;
