import React, { PureComponent } from 'react';
import { SimpleForm, TextInput, required, Create, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import { USER_ROLES } from './config/usersConfig';
import isValidPassword from './services/password-validator.service';

class UserCreate extends PureComponent {
  render() {
    const { style } = this.props;
    const emailWarningMessage = 'Don\'t use "@snooper-app.com" for clients';
    return (
      <Create {...this.props}>
        <SimpleForm>
          <p style={{ style, ...customStyle.emailWarning }}>{emailWarningMessage}</p>
          <TextInput source="email" type="email" validate={required()} />
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <TextInput
            source="password"
            type="password"
            validate={password => isValidPassword(password, true)}
          />
          <TextInput source="company" />
          <SelectInput
            source="role"
            validate={required()}
            defaultValue="USER"
            choices={USER_ROLES}
          />
        </SimpleForm>
      </Create>
    );
  }
}

UserCreate.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};
UserCreate.defaultProps = {};

const customStyle = {
  emailWarning: {
    color: 'red',
    fontSize: 15,
  },
};

export default UserCreate;
