import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/MoreVert';

const style = {
  icon: {
    marginRight: '5px',
  },
};

const OpenDrawerButton = ({ toggleDrawerVisibility }) => (
  <Button color="primary" onClick={toggleDrawerVisibility}>
    <Edit style={style.icon} />
    Edit Missions
  </Button>
);
OpenDrawerButton.propTypes = {
  toggleDrawerVisibility: PropTypes.func.isRequired,
};

export default OpenDrawerButton;
