import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Title from './title.presenter';

const BooleanField = ({ title, record }) => (
  <React.Fragment>
    <Title title={title} />
    <Switch checked={record} testid="record" />
  </React.Fragment>
);

BooleanField.propTypes = {
  title: PropTypes.string.isRequired,
  record: PropTypes.bool,
};

BooleanField.defaultProps = {
  record: false,
};

export default BooleanField;
