import React, { PureComponent, Fragment } from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  required,
  LongTextInput,
  Edit,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  FormDataConsumer,
} from 'react-admin';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MessageTitle from './components/MessageTitle';
import MessageAudienceSelector from './components/messageAudienceSelector';

const style = {
  radioButton: {
    flexDirection: 'row',
    display: 'inline-flex',
    marginRight: '1rem',
  },
};

class MessageEdit extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <Edit
        {...this.props}
        title={<MessageTitle />}
        undoable={false}
        validate={required('A title must be provided.')}
      >
        <SimpleForm>
          <FormDataConsumer>
            {({ formData }) => {
              const { userSelection, type, topics } = formData;
              const AUDIENCE_OPTIONS = Object.freeze([
                { id: 'one', name: 'One by One' },
                { id: 'userList', name: 'User IDs' },
                { id: 'topics', name: 'Using topics' },
              ]);

              return (
                <Fragment>
                  <TextField source="type" />
                  <TextField source="status" />
                  <TextInput source="title" />
                  <LongTextInput source="body" required={required('must give a body')} />
                  <RadioButtonGroupInput
                    fullWidth
                    row
                    classes={{ root: classes.radioButton }}
                    source="userSelection"
                    defaultValue="one"
                    choices={AUDIENCE_OPTIONS}
                  />

                  <MessageAudienceSelector
                    userSelection={userSelection}
                    messageType={type}
                    classes={classes}
                    topics={topics}
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>

          <ReferenceInput
            label="Campaign"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="campaignId"
            reference="campaigns"
            fullWidth
          >
            <AutocompleteInput label="Campaign" fullWidth />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  }
}

MessageEdit.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

MessageEdit.defaultProps = {};

export default withStyles(style)(MessageEdit);
