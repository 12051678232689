import React from 'react';
import { Query } from 'react-admin';
import { withStyles } from '@material-ui/core';
import BankInfoCard from './bankInfo.presenter';
import styles from '../../../layout/globalStyle/cardStyle';

const BankInfo = withStyles(styles)(({ classes, record, ...props }) => (
  <Query
    type="GET_LIST"
    resource="bank-details"
    payload={{
      filter: { userId: record.id },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
    }}
  >
    {({ data, loading, error }) => (
      <BankInfoCard
        className={classes.content}
        record={(data || [])[0]}
        id={((data || [])[0] || {}).id}
        user={record}
        loading={loading}
        error={error}
        {...props}
      />
    )}
  </Query>
));
export default BankInfo;
