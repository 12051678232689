import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListInfo from '../listInfo/listInfo';
import styles from '../../../layout/globalStyle/cardStyle';

const PersonalInfo = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Personal Info" />
    <CardContent {...props}>
      <ListInfo title="CreatedAt" record={record.createdAt} />
      <ListInfo title="Id" record={record.id} />
      <ListInfo title="Firstname" record={record.firstName} />
      <ListInfo title="Lastname" record={record.lastName} />
      <ListInfo title="Email" record={record.email} />
      <ListInfo title="Username" record={record.userName} />
      <ListInfo title="City" record={record.city} />
      <ListInfo title="Gender" record={record.gender} />
      <ListInfo title="Date of birth" record={record.dateOfBirth} />
      <ListInfo title="State" record={record.state} />
      <ListInfo title="Payment info" record={record.paymentInfo} />
      <ListInfo title="Account balance" record={record.accountBalance} />
      <ListInfo title="Experience points" record={record.experiencePoints} />
      <ListInfo title="Status" record={record.status} />
      <ListInfo title="Role" record={record.role} />
      <ListInfo
        title="Topics"
        record={((record.phoneInfo || {}).notificationsTopic || []).join(', ')}
      />
      <ListInfo title="Locale" record={(record.preferences || {}).locale || 'en'} />
    </CardContent>
  </Card>
));

export default PersonalInfo;
