import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  Edit,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { USER_ROLES } from './config/usersConfig';
import isValidPassword from './services/password-validator.service';

export const UserEditTitle = ({ record }) => {
  if (!record) {
    return <span />;
  }
  return <span>{record.userName}</span>;
};
UserEditTitle.propTypes = {
  record: PropTypes.shape({}),
};
UserEditTitle.defaultProps = {
  record: null,
};

const UserEditToolBar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

class UserEdit extends PureComponent {
  render() {
    return (
      <Edit {...this.props} title={<UserEditTitle />} undoable={false}>
        <SimpleForm toolbar={<UserEditToolBar />} redirect="show">
          <TextInput source="email" validate={required()} />
          <TextInput source="userName" validate={required()} />
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <SelectInput source="role" validate={required()} choices={USER_ROLES} />
          <TextInput source="password" validate={password => isValidPassword(password, false)} />
        </SimpleForm>
      </Edit>
    );
  }
}

export default UserEdit;
