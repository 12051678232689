import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, withDataProvider, DELETE, Confirm } from 'react-admin';
import { LockOpen } from '@material-ui/icons';
import { showRestrictions } from '../restrictionsInfo/restrictionsInfo';

const styles = theme =>
  createStyles({
    removeRestrictionsButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

export class RemoveRestrictionsButtonPresenter extends Component {
  state = { isOpen: false };

  removeRestrictions = () => {
    const { record, dataProvider } = this.props;

    dataProvider(
      DELETE,
      'userRestrictions',
      { id: record.id },
      {
        refresh: true,
        onSuccess: { notification: { body: 'User restrictions removed', level: 'info' } },
        onError: {
          notification: {
            body: "Error: Something went wrong, couldn't remove the restrictions",
            level: 'warning',
          },
        },
      },
    );
    this.handleDialogClose();
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleDialogOpen = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const { icon, record, label } = this.props;

    const disabled =
      !record || record.status === 'Banned' || !showRestrictions(record.restrictions);

    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <Button
          disabled={disabled}
          onClick={this.handleDialogOpen}
          label={label}
          key="button"
          testId="removeRestrictionsButton"
        >
          {icon}
        </Button>
        <Confirm
          isOpen={isOpen}
          title="Are you sure you want to remove the restrictions?"
          onConfirm={this.removeRestrictions}
          onClose={this.handleDialogClose}
          testId="removeRestrictionsConfirmationDialog"
          content="By confirming this dialog, you will remove all restrictions from the selected user. This action cannot be reversed. If you do not wish to proceed, please click CANCEL to go back."
        />
      </React.Fragment>
    );
  }
}

RemoveRestrictionsButtonPresenter.propTypes = {
  label: PropTypes.string,
  record: PropTypes.shape({}),
  icon: PropTypes.element,
  dataProvider: PropTypes.func.isRequired,
};

RemoveRestrictionsButtonPresenter.defaultProps = {
  label: 'Unrestrict',
  record: null,
  icon: <LockOpen />,
};

export default compose(
  withDataProvider,
  withStyles(styles),
)(RemoveRestrictionsButtonPresenter);
