import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import {
  acceptMissionOnBehalfOfUser,
  searchUserbyString,
} from '../../../../missions/services/accept-mission-on-behalf-of-user';

const styles = {
  listitem: {
    height: '30px',
    lineHeight: '30px',
    padding: '0 10px',
    fontFamily: 'Roboto, sans-serif',
    '&:hover': {
      backgroundColor: 'lightgray',
      cursor: 'pointer',
    },
  },
  nouseritem: {
    color: 'gray',
    fontFamily: 'Roboto, sans-serif',
  },
  listbody: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '40px',
    zIndex: '100',
    width: '100%',
    overflow: 'auto',
    listStyleType: 'none',
    padding: '0',
    borderRadius: '5px',
    boxShadow: '0 0 5px rgb(0, 0, 0)',
  },
};

class AcceptMissionOnBehalfOfUserDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: props.showDialog,
      record: props.record,
      /* The default acceptTime will be 1 */
      acceptTimes: '1',
      hasAcceptTimes: true,
      users: [],
      user: '',
      userName: '',
      hasUserName: true,
      responseMessage: '',
      showMsg: false,
      success: false,
      open: false,
      hasUsers: false,
      userHelperText: '',
      numberHelperText: '',
    };
    this.timer = null;
  }

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  handleOpenDialog = () => {
    this.setState({ showDialog: true });
  };

  changeQuantity = event => {
    const num = event.target.value;
    if (num >= 0 && num <= 50) {
      this.setState({ acceptTimes: num });
    }
    /* when the number not larger than 0 or larger than 50, the number input box will become red */
    if (num > 50) {
      this.setState({ hasAcceptTimes: false, numberHelperText: 'No more than 50' });
    } else if (num > 0) {
      this.setState({ hasAcceptTimes: true, numberHelperText: '' });
    } else {
      this.setState({ hasAcceptTimes: false, numberHelperText: "Can't be 0" });
    }
  };

  /* function to handle searching username, 500ms delay is used to avoid to many requests */
  handleOnchangeSearch = e => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const { value } = e.target;
    if (value) {
      this.setState({ userName: value, open: true, hasUserName: true });
      this.timer = setTimeout(() => {
        const result = searchUserbyString(value);
        result
          .then(res => {
            if (res.data.total > 0) {
              this.setState({ users: [...res.data.data], hasUsers: true });
            } else {
              this.setState({ users: [], hasUsers: false });
            }
          })
          .catch(() => {
            this.setState({ users: [], hasUsers: false });
          });
      }, 500);
    } else {
      this.setState({ userName: value, open: false, hasUsers: false });
    }
  };

  /* trigger the request to accept mission for user/users */
  acceptMission = () => {
    const { user, userName, record, acceptTimes } = this.state;
    if (user && userName && record.id && parseInt(acceptTimes, 10)) {
      const result = acceptMissionOnBehalfOfUser(record.id, user, acceptTimes);
      result
        .then(res => {
          this.setState({
            success: res.data.success,
            showMsg: true,
            responseMessage: `${acceptTimes} Missions Successfully Accepted `,
          });
        })
        .catch(err => {
          this.setState({
            success: false,
            showMsg: true,
            responseMessage: (err && err.body && err.body.message) || '',
          });
        });
    }
    if (!userName) {
      this.setState({ hasUserName: false, userHelperText: 'Enter a user' });
    }
    if (!acceptTimes) {
      this.setState({ hasAcceptTimes: false, numberHelperText: 'Enter a number' });
    }
  };

  hideMsg = () => {
    this.setState({ showMsg: false });
  };

  handleClosePopover = () => {
    this.setState({ open: false });
  };

  handleOnBlur = () => {
    setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };

  handleSelect = (userName, userId) => {
    this.setState({
      userName,
      user: userId,
    });
    this.handleClosePopover();
  };

  /**
   * The purpose of this method is when user click the search box, the letter they type
   * will be cleaned, so user doesn't need to press backspace many times to clean
   * the search box, they can search the next user quickly then.
   */
  handleClick = () => {
    this.setState({
      userName: '',
      user: '',
      users: [],
      open: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      showDialog,
      acceptTimes,
      hasAcceptTimes,
      users,
      userName,
      hasUserName,
      showMsg,
      success,
      responseMessage,
      open,
      hasUsers,
      userHelperText,
      numberHelperText,
    } = this.state;
    return (
      <div>
        <IconButton
          testId="openDialogbtn"
          color="primary"
          aria-label="Accept mission on behalf of a user (creates a new submission)"
          onClick={this.handleOpenDialog}
        >
          <Tooltip
            testID="tooltip"
            title="Accept mission on behalf of a user (creates a new submission)"
            placement="bottom-start"
          >
            <PersonPinCircleIcon />
          </Tooltip>
        </IconButton>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Accept Mission on Behalf Of Users</DialogTitle>
          <DialogContent>
            <DialogContentText>Search Users</DialogContentText>
            <div style={{ position: 'relative' }}>
              <TextField
                testId="userNameinput"
                value={userName}
                onChange={this.handleOnchangeSearch}
                onClick={this.handleClick}
                onBlur={this.handleOnBlur}
                fullWidth
                variant="outlined"
                error={!hasUserName}
                helperText={hasUserName ? '' : userHelperText}
              />
              {open && (
                <ul className={classes.listbody} testId="userList">
                  {!hasUsers && <ListItem className={classes.nouseritem}>No User Found</ListItem>}
                  {users.map(u => (
                    <ListItem
                      className={classes.listitem}
                      key={u.id}
                      onClick={() => this.handleSelect(u.userName, u.id)}
                    >
                      {u.userName}
                    </ListItem>
                  ))}
                </ul>
              )}
            </div>

            <DialogContentText>Select Mission Quantity</DialogContentText>
            <TextField
              testId="quantityNumber"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.changeQuantity}
              value={acceptTimes}
              error={!hasAcceptTimes}
              helperText={hasAcceptTimes ? '' : numberHelperText}
            />
          </DialogContent>
          <DialogActions>
            <Button testId="cancelbtn" onClick={this.handleCloseDialog} variant="contained">
              Cancel
            </Button>
            <Button
              testId="sendbtn"
              onClick={this.acceptMission}
              variant="contained"
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showMsg} onClose={this.hideMsg}>
          <DialogTitle>{success ? 'Success :)' : 'Fail :('}</DialogTitle>
          <DialogContent>
            <DialogContentText>{responseMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideMsg} variant="contained" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AcceptMissionOnBehalfOfUserDialog.propTypes = {
  record: PropTypes.shape({}).isRequired,
  showDialog: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(AcceptMissionOnBehalfOfUserDialog);
