const TH_BANK_CODES = [
  {
    id: '045',
    name: 'BNP Paribas',
  },
  {
    id: '002',
    name: 'Bangkok Bank Public Company',
  },
  {
    id: '034',
    name: 'Bank For Agriculture And Agricultural Cooperatives',
  },
  {
    id: '025',
    name: 'Bank Of Ayudhya (Krungsri bank)',
  },
  {
    id: '022',
    name: 'CIMB',
  },
  {
    id: '017',
    name: 'Citibank',
  },
  {
    id: '031',
    name: 'HSBC',
  },
  {
    id: '070',
    name: 'ICBC',
  },
  {
    id: '066',
    name: 'Islamic Bank Of Thailand',
  },
  {
    id: '004',
    name: 'Kasikorn Bank',
  },
  {
    id: '069',
    name: 'Kiatnakin Bank',
  },
  {
    id: '006',
    name: 'Krung Thai Bank',
  },
  {
    id: '073',
    name: 'Land And Houses Retail Bank',
  },
  {
    id: '039',
    name: 'Mizuho Corporate Bank',
  },
  {
    id: '014',
    name: 'SIAM COMMERCIAL BANK PCL., THE',
  },
  {
    id: '020',
    name: 'Standard Chartered Bank',
  },
  {
    id: '018',
    name: 'Sumitomo Mitsui Banking Corporation',
  },
  {
    id: '067',
    name: 'TISCO Bank',
  },
  {
    id: '011',
    name: 'TMBThanachart Bank',
  },
  {
    id: '033',
    name: 'The Government Housing Bank',
  },
  {
    id: '030',
    name: 'The Government Savings Bank',
  },
  {
    id: '071',
    name: 'The Thai Credit Retail Bank',
  },
  {
    id: '024',
    name: 'United Overseas Bank',
  },
];

export default TH_BANK_CODES;
