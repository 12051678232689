let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL';

if (process.env.NODE_ENV === 'test') {
  env = 'TEST';
}
const LOCAL = {
  PARSE_BACKEND_BASE_URL: '',
  API_ID: '',
  API_KEY: '',
  FAN_BACKEND_BASE_URL: 'http://localhost:8080/v1',
  FROALA_KEY: '',
  EYE_BASE_URL: 'localhost:3000/submissions',
};

const TEST = {
  PARSE_BACKEND_BASE_URL: '',
  API_ID: '',
  API_KEY: '',
  FAN_BACKEND_BASE_URL: 'http://localhost:8080/v1',
  FROALA_KEY: '',
  EYE_BASE_URL: 'https://eye-staging.snooper.app/submissions',
};

const STAGING = {
  PARSE_BACKEND_BASE_URL: '',
  API_ID: '',
  API_KEY: '',
  FAN_BACKEND_BASE_URL: 'https://api-staging.snooper-app.com/v1',
  FROALA_KEY: 'vYA6mA5C5E4E4B2C10A6eMRPYf1h1REb1BGQOQIc2CDBREJImA11C8D6E6B1G4I3F2I3A8==',
  EYE_BASE_URL: 'https://eye-staging.snooper.app/submissions',
};

const PROD = {
  PARSE_BACKEND_BASE_URL: '',
  API_ID: '',
  API_KEY: '',
  FAN_BACKEND_BASE_URL: 'https://api.snooper-app.com/v1',
  FROALA_KEY: 'vYA6mA5C5E4E4B2C10A6eMRPYf1h1REb1BGQOQIc2CDBREJImA11C8D6E6B1G4I3F2I3A8==',
  EYE_BASE_URL: 'https://eye.snooper.app/submissions',
};

const config = {
  LOCAL,
  TEST,
  STAGING,
  PROD,
};

const FAN_BACKEND_BASE_URL = process.env.REACT_APP_FAN_BACKEND_BASE_URL
  ? process.env.REACT_APP_FAN_BACKEND_BASE_URL
  : config[env].FAN_BACKEND_BASE_URL;

const FROALA_KEY = process.env.FROALA_KEY ? process.env.FROALA_KEY : config[env].FROALA_KEY;
export const EYE_BASE_URL = process.env.REACT_APP_EYE_BASE_URL
  ? process.env.REACT_APP_EYE_BASE_URL
  : config[env].EYE_BASE_URL;

export default {
  FAN_BACKEND_BASE_URL,
  FROALA_KEY,
};

export const PGADMIN_URL = 'https://pgadmin.snooper.app/login';
export const WISER_PGADMIN_VIEW_WIKI_URL =
  'https://wisersolutions.atlassian.net/wiki/spaces/SNOOPER/pages/3416687858/PgAdmin+VIEWS+-+Context+Use+cases+How+it+works';
export const WISER_PGADMIN_HOW_TO_FIND_VIEWS_URL =
  'https://wisersolutions.atlassian.net/wiki/spaces/SNOOPER/pages/3416687858/PgAdmin+VIEWS+-+Context+Use+cases+How+it+works#How-to-find-views-in-PgAdmin.';
