import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  Edit,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';

import { renderClient } from '../users/services/render-helper';
import CmpanyTitle from './components/companyTitle';

import { isEmailDomainValid, parseEmailDomains, formatEmailDomains } from './services/emailDomain';

class companyEdit extends PureComponent {
  render() {
    return (
      <Edit {...this.props} title={<CmpanyTitle />} undoable={false}>
        <SimpleForm>
          <TextInput source="name" />
          <TextInput
            source="emailDomains"
            parse={parseEmailDomains}
            format={formatEmailDomains}
            validate={isEmailDomainValid}
          />
          <ReferenceArrayInput
            label="Clients"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="userIds"
            reference="users"
            filter={{ role: 'CLIENT' }}
            linkType="show"
            fullWidth
            required={required()}
          >
            <AutocompleteArrayInput label="User" optionText={renderClient} fullWidth />
          </ReferenceArrayInput>
          <SelectInput
            source="membership"
            choices={[
              { id: 'No plan', name: 'No plan' },
              { id: 'Silver', name: 'Silver' },
              { id: 'Gold', name: 'Gold' },
              { id: 'Platinum', name: 'Platinum' },
            ]}
            required={required()}
          />
        </SimpleForm>
      </Edit>
    );
  }
}

export default companyEdit;
