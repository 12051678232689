import React from 'react';
import {
  List,
  TextField,
  Datagrid,
  ShowButton,
  EditButton,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  DeleteButton,
  Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ChipsField from './components/chipsField';
import { renderClient } from '../users/services/render-helper';
import LengthOfArrayField from './components/lengthOfArrayField';
import { tableStyle, postRowStyle } from '../layout/globalStyle/tableStyle';

export const CompanyFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Name"
      source="name"
      variant="outlined"
      options={{ variant: 'outlined' }}
      alwaysOn
    />
    <TextInput
      label="Email's Domain"
      source="emailDomain"
      variant="outlined"
      options={{ variant: 'outlined' }}
      alwaysOn
    />
    <ReferenceInput
      sort={{ field: 'updatedAt', order: 'DESC' }}
      label="Client"
      source="clientId"
      reference="users"
      filter={{ role: 'CLIENT' }}
      alwaysOn
    >
      <AutocompleteInput optionText={renderClient} />
    </ReferenceInput>
  </Filter>
);

const CompaniesList = ({ classes, ...props }) => (
  <List
    {...props}
    filters={<CompanyFilters />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    perPage={50}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
  >
    <Datagrid
      rowStyle={postRowStyle}
      classes={{
        thead: classes.thead,
        tbody: classes.tbody,
        row: classes.row,
        table: classes.table,
      }}
    >
      <TextField source="name" cellClassName={classes.w25} headerClassName={classes.w25} />
      <ChipsField
        source="emailDomains"
        sortable={false}
        cellClassName={classes.w25}
        headerClassName={classes.w25}
      />
      <LengthOfArrayField label="Users" source="userIds" />
      <TextField source="membership" />
      <ShowButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default withStyles(tableStyle)(CompaniesList);
