const CN_BANK_ACCOUNT_TYPES = [
  {
    id: 'ALIPAY',
    name: 'Ali Pay',
  },
  {
    id: 'WECHAT_PAY',
    name: 'WeChat Pay',
  },
];

export default CN_BANK_ACCOUNT_TYPES;
