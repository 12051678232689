import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  TextField,
  NumberField,
  Pagination,
  ReferenceField,
  Filter,
  TextInput,
  List,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { postRowStyle } from '../../layout/globalStyle/tableStyle';

const styles = {
  thead: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
  tbody: {
    display: 'block !important',
    overflow: 'auto !important',
    tableLayout: 'fixed !important',
    maxHeight: '69vh',
  },
  row: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
  w40: {
    width: '40%',
  },
};

export const UserBankAccountHistoryPanel = ({ record, ...props }) => {
  const comments = record.comments ? <div>{record.comments}</div> : null;
  const campaign = record.campaignId ? (
    <ReferenceField
      {...props}
      label="Campaign"
      source="campaignId"
      reference="campaigns"
      basePath="/campaigns"
      record={record}
      linkType="show"
    >
      <TextField source="name" />
    </ReferenceField>
  ) : null;

  const submission = record.submissionId ? (
    <ReferenceField
      {...props}
      label="Submission"
      source="submissionId"
      reference="submissions"
      record={record}
      basePath="/submissions"
      linkType="show"
    >
      <TextField source="id" />
    </ReferenceField>
  ) : null;

  return (
    <Fragment>
      {campaign}
      {comments}
      {submission}
    </Fragment>
  );
};

UserBankAccountHistoryPanel.propTypes = {
  record: PropTypes.shape({
    comments: PropTypes.string,
    submissionId: PropTypes.string,
    campaignId: PropTypes.string,
  }).isRequired,
};

export const BankHistoryFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput label="Campaign Name" source="campaignName" alwaysOn />
    <TextInput label="InAppName" source="inAppName" alwaysOn />
    <TextInput label="Location" source="location" alwaysOn />
  </Filter>
);

const UserBankAccountHistory = ({ ...props }) => {
  const { record, classes } = props;
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        pagination={<Pagination />}
        perPage={25}
        resource="bank-account/transactions"
        filter={{ userId: record.id }}
        filters={<BankHistoryFilter />}
        bulkActionButtons={false}
        actions={false}
        style={{ width: '100%', overflow: 'scroll' }}
      >
        <Datagrid
          expand={<UserBankAccountHistoryPanel />}
          classes={{
            thead: classes.thead,
            tbody: classes.tbody,
            row: classes.row,
          }}
          rowStyle={postRowStyle}
        >
          <DateField label="Date" source="createdAt" />
          <TextField label="Type" source="type" />
          <TextField
            label="Info"
            source="shortDescription"
            sortable={false}
            cellClassName={classes.w40}
            headerClassName={classes.w40}
          />
          <TextField source="status" />
          <TextField label="Currency" source="currency" />
          <NumberField source="transaction" />
          <NumberField source="balanceAfterTransaction" />
        </Datagrid>
      </List>
    </Fragment>
  );
};

UserBankAccountHistory.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(UserBankAccountHistory);
