import React, { PureComponent } from 'react';
import {
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  ChipField,
  DateField,
  Pagination,
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import exporter from './services/exporter.service';

export const DGrid = ({ campaignId, total, ...props }) => (
  <Grid>
    <Grid
      style={{
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'sticky',
        top: 0,
        background: '#f9fbfc',
      }}
    >
      <Button
        testID="export"
        style={{ color: '#143277' }}
        onClick={() => exporter(campaignId)}
        disabled={total === 0}
      >
        <GetAppIcon />
        Export
      </Button>
    </Grid>
    <Datagrid {...props}>
      <DateField source="createdAt" locales="en-AU" />
      <ChipField source="sourceEvent" />
      <NumberField source="rating" />
      <TextField source="reasons" />
      <TextField source="feedback" />
      <ReferenceField label="User" source="userId" reference="users" linkType="show">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="inAppName" />

      <ReferenceField
        label="Location"
        source="submissionId"
        reference="submissionsNew"
        linkType={false}
      >
        <TextField label="Location" source="missionLocationName" />
      </ReferenceField>
      <NumberField source="likelinessToCompleteSimilarMissions" />
    </Datagrid>
  </Grid>
);

class MissionFeedbackPresenter extends PureComponent {
  render() {
    const { campaignId } = this.props;
    return (
      <ReferenceManyField
        {...this.props}
        label=""
        reference="mission-feedback"
        target="campaignId"
        filter={{ campaignId }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <DGrid {...this.props} />
      </ReferenceManyField>
    );
  }
}

export default MissionFeedbackPresenter;
