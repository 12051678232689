const US_BANK_ACCOUNT_TYPES = [
  {
    id: 'SAVINGS',
    name: 'Savings',
  },
  {
    id: 'CHECKING',
    name: 'Checking',
  },
];

export default US_BANK_ACCOUNT_TYPES;
