import { connect } from 'react-redux';
import { withDataProvider } from 'react-admin';
import BulkMissionPresenter from './bulk-mission-edit.presenter';

const mapStateToProps = state => ({
  totalMissions: state.admin.resources.missions.list.total,
  filtersMissions: state.admin.resources.missions.list.params.filter,
});

const BulkMissionEditContainer = connect(
  mapStateToProps,
  null,
)(withDataProvider(BulkMissionPresenter));

export default BulkMissionEditContainer;
