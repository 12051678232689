import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {
  UPDATE,
  showNotification,
  withDataProvider,
  ArrayField,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
} from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/MoreVert';
import AddNoteDrawer from './components/addNoteDrawer';

const styles = {
  root: {
    width: '95%',
    padding: '1%',
    marginTop: 10,
  },
  deleteButton: {
    color: 'red',
  },
  font: {
    fontSize: '12px',
  },
  noContent: {
    padding: 10,
    marginTop: 30,
  },
  addNoteButton: { float: 'right', marginTop: '17px' },
};

const ExpandContent = ({ record }) => <div> {record.content} </div>;

const ContentTrim = ({ record }) => <Typography>{record.content.substring(0, 80)}...</Typography>;

const DeleteButton = ({ record, onDeleteNote }) => (
  <Button testid="delete" style={styles.deleteButton} onClick={onDeleteNote(record)} size="small">
    delete
  </Button>
);
export class NotesPresenter extends React.PureComponent {
  state = {
    isDrawerOpen: false,
  };

  toggleDrawerVisibility = () => {
    this.setState(prevState => ({ isDrawerOpen: !prevState.isDrawerOpen }));
  };

  deleteNote = noteToDelete => async () => {
    const { dataProvider, dispatch, record } = this.props;
    const campaignWithNotes = {
      ...record,
      notes: [...(record.notes.filter(note => note.createdAt !== noteToDelete.createdAt) || [])],
    };
    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { data: campaignWithNotes, id: record.campaignId },
        {
          onSuccess: {},
          onFailure: {
            notification: {
              body: `Add note failed on the backend side`,
              level: 'warning',
            },
          },
          refresh: true,
        },
      );
    } catch (ex) {
      dispatch(showNotification(`Add note failed: ${ex.message}`, 'warning'));
    }
  };

  renderNotes = () => {
    const { record } = this.props;
    const { notes } = record;

    if (!notes) {
      return <Typography style={styles.noContent}> No notes created yet </Typography>;
    }
    return (
      <div style={styles.root}>
        <ArrayField source="notes" record={record}>
          <Datagrid expand={<ExpandContent />}>
            <ReferenceField
              label="Name"
              source="userId"
              reference="users"
              basePath="#/users"
              linkType={false}
            >
              <TextField testid="name" source="fullName" style={{ textTransform: 'capitalize' }} />
            </ReferenceField>
            <DateField testid="date" source="createdAt" />
            <ContentTrim testid="content" source="content" />
            <DeleteButton testid="delete" onDeleteNote={this.deleteNote} />
          </Datagrid>
        </ArrayField>
      </div>
    );
  };

  render() {
    const { isDrawerOpen } = this.state;
    const { record } = this.props;
    return (
      <React.Fragment>
        <Drawer
          testid="drawer"
          anchor="right"
          open={isDrawerOpen}
          onClose={this.toggleDrawerVisibility}
        >
          <AddNoteDrawer
            campaignId={record.id}
            close={this.toggleDrawerVisibility}
            record={record}
          />
        </Drawer>
        {this.renderNotes()}
        <Button
          testid="addNote"
          color="primary"
          onClick={this.toggleDrawerVisibility}
          style={styles.addNoteButton}
        >
          <Edit style={styles.icon} />
          Add note
        </Button>
      </React.Fragment>
    );
  }
}

NotesPresenter.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

ExpandContent.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

DeleteButton.propTypes = {
  record: PropTypes.shape({}).isRequired,
  onDeleteNote: PropTypes.func.isRequired,
};

ContentTrim.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

export default withDataProvider(NotesPresenter);
