import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SaveButton } from 'react-admin';
import { Dialog, DialogContentText, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { updateCheckpoint, createCheckpoint } from '../actions/checkpointActions';

const styles = {
  button: {
    margin: 5,
  },
  error: {
    color: 'red',
  },
  icon: {
    paddingRight: 3,
  },
};

export const createDialogTitle = checkpointId =>
  `${checkpointId ? 'Saving' : 'Creating'} Checkpoint ${checkpointId || ''}`;

export const SaveCheckpointDialogPresenter = withStyles(styles)(props => {
  const {
    show,
    onClose,
    record = {},
    campaignId,
    classes,
    saveCheckpoint,
    createNewCheckpoint,
    isLoading,
    handleSubmit,
    isValid,
  } = props;
  /* Not sure why campaignId wasn't originally part of the checkpoint props as it is required anyway.
    See 'checkpointEdit.presenter.js' for more info on this.
    Passing it through to the button similar to the existing solution, but seems like it should be part of the record. Instead,
    it seems to be passed as a query parameter. Odd. */
  const data = { ...record, campaignId };
  const { id: checkpointId = null } = data;
  const dialogTitle = createDialogTitle(checkpointId);

  /* TODO Best way to test this? Or re-factor handleSubmit out into parent? */
  const save = recompute =>
    handleSubmit(values => {
      if (checkpointId) {
        saveCheckpoint(checkpointId, { ...values, campaignId }, recompute);
      } else {
        createNewCheckpoint({ ...values, campaignId }, recompute);
      }
    });

  return (
    <Dialog fullWidth open={show} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to save/update the checkpoint.
          <p>
            When you update a checkpoint, you can choose to perform a data integrity check on all
            existing submissions. For example, when you update answerRequired, you typically want to
            re-compute the issues for past submissions. You may want to skip this in some
            circumstances.
          </p>
          <p>
            <b>
              Please note that this will run in the background and can take quite some time to
              process, so only re-compute data integrity issues when needed.
            </b>
          </p>
          {!isValid && (
            <p className={classes.error}>
              <b>
                There are errors in the checkpoint form. Please close the dialog and correct the
                issues.
              </b>
            </p>
          )}
        </DialogContentText>
        <SaveButton
          testid="saveOnlyButtonId"
          disabled={isLoading || !isValid}
          label="Save only"
          handleSubmit={save(false)}
          handleSubmitWithRedirect={save(false)}
          {...record}
          className={classes.button}
          icon={isLoading ? <CircularProgress /> : <SaveIcon />}
        />
        <SaveButton
          testid="saveAndRecomputeButtonId"
          {...record}
          disabled={isLoading || !isValid}
          label="Save and Re-compute Issues"
          handleSubmit={save(true)}
          handleSubmitWithRedirect={save(true)}
          className={classes.button}
          icon={isLoading ? <CircularProgress /> : <SaveIcon />}
        />
        <Button
          size="small"
          variant="contained"
          aria-label="delete"
          onClick={onClose}
          className={classes.button}
        >
          <CloseIcon className={classes.icon} /> Close
        </Button>
      </DialogContent>
    </Dialog>
  );
});

SaveCheckpointDialogPresenter.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  // saveCheckpoint: PropTypes.func.isRequired,
};

const SaveCheckpointDialog = connect(
  state => ({ isLoading: state.admin.loading > 0 }),
  {
    saveCheckpoint: updateCheckpoint,
    createNewCheckpoint: createCheckpoint,
  },
)(SaveCheckpointDialogPresenter);

export default SaveCheckpointDialog;
