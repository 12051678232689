/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ReactTable from 'react-table';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import useFetch from '../../../../../hooks/useFetch';
// eslint-disable-next-line import/no-named-as-default
import TextFieldWithCopyButton from '../../../../components/TextField/TextFieldWithCopyButton';
import { WISER_PGADMIN_HOW_TO_FIND_VIEWS_URL } from '../../../../../setup/config';

const styles = {
  content: {
    margin: 10,
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important',
    borderRadius: '6px',
    fontFamily: 'Roboto, sans-serif',
  },
  tableContainer: {
    fontSize: '12px',
    fontFamily: 'Roboto, sans-serif',
    textTransform: 'none',
    border: 'unset',
    height: '97%',
    marginTop: 20,
  },
  cell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px',
    textAlign: 'left',
  },
  infoBox: {
    borderRadius: 10,
    fontFamily: 'Roboto, sans-serif',
  },
  infoText: {
    margin: 5,
    color: 'black',
  },
};

/**
 * A custom table component that uses ReactTable to display data.
 * @param {Object} props
 * @param {string} props.campaignId - The ID of the campaign.
 * @returns {React.Element}
 */
export const FlatTableExportViews = ({ campaignId }) => {
  const { data, loading } = useFetch(`/campaigns/${campaignId}/exports/summary`);

  const { flatTable } = data || {};
  const { views, tableName, tableSchema } = flatTable || {};

  const columns = [
    {
      Header: 'View Schema',
      accessor: 'viewSchema',
      Cell: ({ original }) => <TextFieldWithCopyButton text={original.viewSchema} />,
    },
    {
      Header: 'View Name',
      accessor: 'viewName',
      Cell: ({ original }) => <TextFieldWithCopyButton text={original.viewName} />,
    },
    {
      Header: 'View Definition',
      accessor: 'viewDefinition',
      Cell: ({ original }) => (
        <TextFieldWithCopyButton
          text={original.viewDefinition}
          copyNotificationText="View definition copied."
          showCopyButton
        />
      ),
    },
  ];

  return (
    <div>
      <div style={styles.infoBox}>
        <Typography style={styles.infoText}>
          Table Name: <span style={{ color: 'grey' }}>{tableName}</span>
        </Typography>
        <Typography style={styles.infoText}>
          Table Schema: <span style={{ color: 'grey' }}>{tableSchema}</span>
        </Typography>
        {!loading && (
          <Typography style={{ ...styles.infoText, textAlign: 'left' }}>
            Click{' '}
            <a href={WISER_PGADMIN_HOW_TO_FIND_VIEWS_URL} target="_blank" rel="noopener noreferrer">
              here
            </a>{' '}
            for more information on how to find views in PgAdmin.
          </Typography>
        )}
      </div>
      <div style={{ overflowY: 'scroll', height: '600px' }}>
        <ReactTable
          columns={columns}
          data={views}
          loading={loading}
          style={styles.tableContainer}
          className={classnames('-highlight', '-striped')}
          showPagination={false}
          minRows={10}
          getTheadThProps={() => ({ style: styles.cell })}
          sortable={false}
        />
      </div>
    </div>
  );
};

FlatTableExportViews.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

/**
 * The main presenter component for campaign analytics.
 * @param {Object} props
 * @param {string} props.campaignId - The ID of the campaign.
 * @returns {React.Element}
 */
const CampaignAnalyticsPresenter = ({ campaignId }) => (
  <Card style={styles.content}>
    <CardHeader title="Flat Table and Views" />
    <CardContent>
      <FlatTableExportViews campaignId={campaignId} />
    </CardContent>
  </Card>
);

CampaignAnalyticsPresenter.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignAnalyticsPresenter;
