import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Field from '../../../../layout/components/fields/textField.presenter';
import BudgetField from '../../../../components/fields/priceField';
import styles from '../../../../layout/globalStyle/cardStyle';

const style = {
  pricePerStoreVisit: {
    color: 'grey',
    fontSize: '12px',
  },
  list: {
    marginTop: '2px',
    color: 'grey',
  },
};

export const PricePerStoreVisit = ({ record }) => (
  <React.Fragment>
    <Typography testid="title">Price per store visit:</Typography>
    {record ? (
      <div>
        <ul style={style.list}>
          {record.map(item => (
            <li>
              <Typography testid="item" style={style.pricePerStoreVisit}>
                {item.name}: ${item.value.toLocaleString()}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    ) : (
      '-'
    )}
  </React.Fragment>
);

PricePerStoreVisit.propTypes = {
  record: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.value,
    }),
  ).isRequired,
};

const BudgetPresenter = withStyles(styles)(({ classes, record, ...props }) => {
  const { budget, campaignTarget } = record;
  const pricePerStoreVisit = budget && budget.pricePerStoreVisit ? budget.pricePerStoreVisit : null;
  return (
    <Card {...props} testid="card" className={classes.content}>
      <CardHeader testid="header" title="Campaign Budget" />
      <CardContent {...props}>
        <BudgetField
          testid="budget"
          title="Total budget"
          record={record}
          source="totalBudget"
          gutterBottom
        />
        <BudgetField
          testid="dataFee"
          title="Design and data fee"
          record={record}
          source="designDataFee"
          gutterBottom
        />
        <PricePerStoreVisit record={pricePerStoreVisit} />
        <Field testid="target" title="# of visits" record={campaignTarget} />
      </CardContent>
    </Card>
  );
});

export default BudgetPresenter;
