import React from 'react';
import {
  Filter,
  TextInput,
  SelectArrayInput,
  NumberInput,
  NullableBooleanInput,
  ReferenceArrayInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import optionRenderer from '../services/optionRender.service';
import { campaignTypes, STATES } from '../../../campaigns/config/campaignConfig';
import MultipleSelect from './multipleSelect/multipleSelect';
import UserFilter from './userFilter/userFilter';
import StyledAutocompleteArrayInput from '../../../components/styledAutocompleteArrayInput/styledAutocompleteArrayInput';
import SelectAndSearchPresenter from '../../../components/select/selectAndSearch';
import sanitizeAndMatchOptionFilter from '../../../components/select/selectAndSearchFilters';

const style = {
  filterWidth: {
    minWidth: 110,
  },
  chipHeight: { height: 23 },
  locationField: {
    maxWidth: '160px',
    overflowX: 'hidden',
  },
  multiSelect: {
    marginTop: '15px',
  },
};

export const LocationFilter = ({ label, source }) => (
  <TextInput label={label} source={source} variant="outlined" options={{ variant: 'outlined' }} />
);

LocationFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

LocationFilter.defaultProps = {
  label: 'Location',
  source: 'location',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const InAppNameFilter = ({ label, source }) => (
  <TextInput label={label} source={source} variant="outlined" options={{ variant: 'outlined' }} />
);

InAppNameFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

InAppNameFilter.defaultProps = {
  label: 'InAppName',
  source: 'inAppName',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const SegmentFilter = ({ label, source }) => <TextInput label={label} source={source} />;

SegmentFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

SegmentFilter.defaultProps = {
  label: 'Segments',
  source: 'segment',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const StatusFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={[
      { id: 'Admin for review', name: 'Admin for review' },
      { id: 'Rejected', name: 'Rejected' },
      { id: 'Uploading', name: 'Uploading' },
      { id: 'Waiting For Validation', name: 'Waiting for validation' },
      { id: 'Aborted - Waiting For Validation', name: 'Aborted - Waiting for validation' },
      { id: 'Cancelled', name: 'Cancelled' },
      { id: 'Active', name: 'Active' },
      { id: 'Completed Successfully', name: 'Completed successfully' },
      { id: 'Aborted - Completed', name: 'Aborted - Completed' },
      { id: 'Pre-Validation', name: 'Pre-Validation' },
      { id: 'Expired', name: 'Expired' },
      { id: 'Upload Failed', name: 'Upload Failed' },
    ]}
    style={style.filterWidth}
    options={{ renderValue: optionRenderer('items'), autoWidth: true, variant: 'outlined' }}
  />
);

StatusFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

StatusFilter.defaultProps = {
  label: 'Status',
  source: 'status',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const StateFilter = ({ source }) => (
  <SelectAndSearchPresenter
    name="state"
    source={source}
    choices={STATES}
    isMulti
    placeholder="State"
    handleFilterOption={sanitizeAndMatchOptionFilter}
    alwaysOn
  />
);

StateFilter.propTypes = {
  source: PropTypes.string,
};

StateFilter.defaultProps = {
  source: 'state',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const BannerFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={[
      { id: 'Coles', name: 'Coles' },
      { id: 'Woolworths', name: 'Woolworths' },
      { id: 'IGA', name: 'IGA' },
      { id: 'Ritchies', name: 'Ritchies' },
      { id: 'BWS', name: 'BWS' },
      { id: 'Liquorland', name: 'Liquorland' },
      { id: 'Dan Murphys', name: 'Dan Murphys' },
      { id: 'Caltex', name: 'Caltex' },
      { id: '7 Eleven', name: '7 Eleven' },
      { id: 'Coles Express', name: 'Coles Express' },
      { id: 'Thirsty Camel', name: 'Thirsty Camel' },
      { id: 'BP', name: 'BP' },
      { id: 'Telstra', name: 'Telstra' },
      { id: 'Chemist Warehouse', name: 'Chemist Warehouse' },
      { id: 'Priceline', name: 'Priceline' },
      { id: 'Terry White', name: 'Terry White' },
      { id: 'Aldi', name: 'Aldi' },
      { id: 'Countdown', name: 'Countdown' },
    ]}
    style={style.filterWidth}
    options={{ renderValue: optionRenderer('banners'), autoWidth: true }}
  />
);

BannerFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

BannerFilter.defaultProps = {
  label: 'Banner',
  source: 'banner',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const AreaFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={[{ id: 'Metro', name: 'Metro' }, { id: 'Regional', name: 'Regional' }]}
    options={{ renderValue: optionRenderer('areas'), autoWidth: true }}
    style={style.filterWidth}
  />
);

AreaFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

export const SovStatusFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={[
      { name: `H&B tagged - not on display`, id: `H&B tagged - not on display` },
      { name: `H&B to be tagged`, id: `H&B to be tagged` },
      { name: `H&B tagged - on display`, id: `H&B tagged - on display` },
      { name: `H&B admin for review`, id: `H&B admin for review` },
      { name: `H&B sov computed`, id: `H&B sov computed` },
      { name: `S&C to be tagged`, id: `S&C to be tagged` },
      { name: `S&C tagged - not on display`, id: `S&C tagged - not on display` },
      { name: `S&C tagged - on display`, id: `S&C tagged - on display` },
      { name: `S&C admin for review`, id: `S&C admin for review` },
      { name: `S&C sov computed`, id: `S&C sov computed` },
      { name: `Liquor to be tagged`, id: `Liquor to be tagged` },
      { name: `Liquor tagged - not on display`, id: `Liquor tagged - not on display` },
      { name: `Liquor tagged - on display`, id: `Liquor tagged - on display` },
      { name: `Liquor admin for review`, id: `Liquor admin for review` },
      { name: `Liquor sov computed`, id: `Liquor sov computed` },
      { name: `Other Categories to be tagged`, id: `Other Categories to be tagged` },
      {
        name: `Other Categories tagged - not on display`,
        id: `Other Categories tagged - not on display`,
      },
      { name: `Other Categories tagged - on display`, id: `Other Categories tagged - on display` },
      { name: `Other Categories admin for review`, id: `Other Categories admin for review` },
      { name: `Other Categories sov computed`, id: `Other Categories sov computed` },
    ]}
    options={{ renderValue: optionRenderer('sovCategoryStatus'), autoWidth: true }}
    style={style.filterWidth}
  />
);

SovStatusFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

SovStatusFilter.defaultProps = {
  label: 'Sov category status',
  source: 'sovCategoryStatus',
};

AreaFilter.defaultProps = {
  label: 'Area',
  source: 'area',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const CampaignTypeFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={campaignTypes}
    options={{ renderValue: optionRenderer('campaign types'), autoWidth: true }}
    style={style.filterWidth}
  />
);

CampaignTypeFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

CampaignTypeFilter.defaultProps = {
  label: 'Campaign types',
  source: 'campaignTypes',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const IsHiddenToClientFilter = ({ label, source }) => (
  <NullableBooleanInput label={label} source={source} />
);

IsHiddenToClientFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

IsHiddenToClientFilter.defaultProps = {
  label: 'Hidden from client',
  source: 'isHiddenToClients',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const UnderReviewFilter = ({ label, source }) => (
  <NullableBooleanInput label={label} source={source} />
);

UnderReviewFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

UnderReviewFilter.defaultProps = {
  label: 'Is under review',
  source: 'underReview',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const TraxStatusFilter = ({ label, source }) => (
  <SelectArrayInput
    label={label}
    source={source}
    choices={[
      { id: 'Failed', name: 'Failed' },
      { id: 'Received', name: 'Received' },
      { id: 'Pending', name: 'Pending' },
      { id: 'Sending', name: 'Sending' },
    ]}
    style={style.filterWidth}
    options={{ renderValue: optionRenderer('items'), autoWidth: true, variant: 'outlined' }}
  />
);

TraxStatusFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

TraxStatusFilter.defaultProps = {
  label: 'Trax Status',
  source: 'traxStatus',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: true,
};

export const DataIntegrity = ({ label, source }) => (
  <NullableBooleanInput label={label} source={source} />
);

DataIntegrity.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

DataIntegrity.defaultProps = {
  label: 'is data incorrect',
  source: 'hasDataIntegrityIssue',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: false,
};

export const HasEverFailedToUploadFilter = ({ label, source }) => (
  <NullableBooleanInput label={label} source={source} />
);

HasEverFailedToUploadFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

HasEverFailedToUploadFilter.defaultProps = {
  label: undefined,
  source: 'hasEverFailedToUpload',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: false,
};

export const CycleFilter = ({ label, source }) => <NumberInput label={label} source={source} />;

CycleFilter.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

CycleFilter.defaultProps = {
  label: undefined,
  source: 'cycleNumber',
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysOn: false,
};

const SubmissionsFilter = ({ classes, ...props }) => (
  <Filter {...props}>
    <Grid container spacing={16} justifyContent="space-between" alignItems="flex-end" alwaysOn>
      <Grid item xs={12} md={5} xl={3}>
        <MultipleSelect source="campaignId" />
      </Grid>

      <Grid item>
        <InAppNameFilter />
      </Grid>

      <Grid item>
        <LocationFilter />
      </Grid>

      <Grid item>
        <SegmentFilter />
      </Grid>

      <Grid item>
        <StatusFilter />
      </Grid>

      <Grid item>
        <StateFilter />
      </Grid>

      <Grid item>
        <BannerFilter />
      </Grid>

      <Grid item>
        <AreaFilter />
      </Grid>

      <Grid item xs={12} md={4} xl={2}>
        <UserFilter source="userId" />
      </Grid>

      <Grid item>
        <CampaignTypeFilter />
      </Grid>

      <Grid item>
        <SovStatusFilter />
      </Grid>
      <Grid item>
        <IsHiddenToClientFilter />
      </Grid>
      <Grid item>
        <UnderReviewFilter />
      </Grid>
      <Grid item>
        <TraxStatusFilter />
      </Grid>
      <Grid item>
        <ReferenceArrayInput
          label="Assignee"
          sort={{ field: 'username', order: 'ASC' }}
          source="assignee"
          reference="users"
          fullWidth
          alwaysOn
          filter={{ role: ['ADMIN'] }}
        >
          <StyledAutocompleteArrayInput />
        </ReferenceArrayInput>
      </Grid>
      <Grid item>
        <ReferenceArrayInput
          label="Reviewers"
          sort={{ field: 'username', order: 'ASC' }}
          source="reviewers"
          reference="users"
          fullWidth
          alwaysOn
          filter={{ role: ['REVIEWER', 'SENIOR_REVIEWER'] }}
        >
          <StyledAutocompleteArrayInput />
        </ReferenceArrayInput>
      </Grid>
    </Grid>

    <HasEverFailedToUploadFilter />
    <DataIntegrity />
    <CycleFilter />
  </Filter>
);

export default withStyles(style)(SubmissionsFilter);
