import React from 'react';
import {
  List,
  TextField,
  Datagrid,
  DateField,
  ShowButton,
  EditButton,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ChipField,
  SelectArrayInput,
  DeleteButton,
  Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ChipsField from './components/chipsField';
import AliasChipsField from './components/aliasChipsField';
import { renderUser } from '../users/services/render-helper';
import SendButton from './components/sendButton';
import { tableStyle, postRowStyle } from '../layout/globalStyle/tableStyle';
import { MESSAGE_TOPICS, MESSAGE_TYPES, MESSAGE_TYPE_SHORT_NAMES } from './config/messageConfig';
import SelectAndSearchPresenter from '../components/select/selectAndSearch';
import sanitizeAndMatchOptionFilter from '../components/select/selectAndSearchFilters';

export const MessageFilters = props => (
  <Filter {...props} style={{ width: '40%', display: 'flex', justifyContent: 'space-between' }}>
    <ReferenceInput
      sort={{ field: 'updatedAt', order: 'DESC' }}
      label="User"
      source="userId"
      reference="users"
      alwaysOn
    >
      <AutocompleteInput optionText={renderUser} />
    </ReferenceInput>

    <SelectAndSearchPresenter
      name="topic"
      source="topic"
      choices={MESSAGE_TOPICS}
      isMulti
      placeholder="Topic"
      handleFilterOption={sanitizeAndMatchOptionFilter}
      alwaysOn
    />

    <SelectArrayInput
      label="Status"
      source="status"
      choices={[{ id: 'Draft', name: 'Draft' }, { id: 'Sent', name: 'Sent' }]}
      alwaysOn
    />
    <SelectArrayInput label="Types" source="type" choices={MESSAGE_TYPES} alwaysOn />
  </Filter>
);

const MessageList = ({ classes, ...props }) => (
  <List
    {...props}
    filters={<MessageFilters />}
    sort={{ field: 'sentAt', order: 'DESC' }}
    perPage={100}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
  >
    <Datagrid
      rowStyle={postRowStyle}
      classes={{
        thead: classes.thead,
        tbody: classes.tbody,
        row: classes.row,
        table: classes.table,
      }}
    >
      <DateField source="sentAt" cellClassName={classes.w10} headerClassName={classes.w10} />
      <TextField source="title" cellClassName={classes.w21} headerClassName={classes.w21} />
      <AliasChipsField
        source="type"
        aliases={MESSAGE_TYPE_SHORT_NAMES}
        cellClassName={classes.w10}
        headerClassName={classes.w10}
      />
      <ChipField source="status" cellClassName={classes.w10} headerClassName={classes.w10} />
      <TextField
        label="Estimated users"
        source="estimatedUserImpacted"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
      />
      <TextField
        label="Actual users"
        source="actualUserImpacted"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
      />
      <ChipsField
        source="topics"
        sortable={false}
        cellClassName={classes.w10}
        headerClassName={classes.w10}
      />
      <SendButton cellClassName={classes.w6} headerClassName={classes.w6} />
      <ShowButton cellClassName={classes.w6} headerClassName={classes.w6} />
      <EditButton cellClassName={classes.w6} headerClassName={classes.w6} />
      <DeleteButton cellClassName={classes.w6} headerClassName={classes.w6} />
    </Datagrid>
  </List>
);

export default withStyles(tableStyle)(MessageList);
