import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../layout/globalStyle/cardStyle';
import { tableStyle } from '../../layout/globalStyle/tableStyle';
import ExperiencePointHistory from './experiencePointHistory';

export const BadgeTable = withStyles(tableStyle)(
  ({ badges }) =>
    (badges && (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {badges.map(badge => (
            <TableRow key={badge.name}>
              <TableCell scope="row" style={tableStyle.cellFlexMiddle}>
                <LoyaltyIcon />
                {badge.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )) || <div />,
);

export const ExperienceSummary = withStyles(styles)(({ user, classes }) => (
  <Card testid="experienceSummaryCard" className={classes.content}>
    <CardHeader testid="experienceSummaryCardHeader" title="Experience Summary" />
    <CardContent>
      <p>
        User{' '}
        <em>
          {user.firstName} {user.lastName}
        </em>{' '}
        currently has <em>{user.experiencePoints}</em> experience points.
      </p>
      <p>
        This corresponds to experience level <em>{user.experienceLevel}</em>.
      </p>
      <p>
        The user has acquired <em>{user.badges ? user.badges.length : 0} badges</em>.
      </p>
    </CardContent>
  </Card>
));

export const BadgesCard = withStyles(styles)(({ badges, classes }) => (
  <Card testid="badgesCard" className={classes.content}>
    <CardHeader testid="badgesCardHeader" title="Badges" />
    <CardContent>
      {<BadgeTable badges={badges} testid="badgesTable" /> || (
        <div>This user does not have any badges yet.</div>
      )}
    </CardContent>
  </Card>
));

export const ExperiencePointsCard = withStyles(styles)(props => (
  <Card testid="experiencePointTransactionsCard" className={props.classes.content}>
    <CardHeader testid="experiencePointCardHeader" title="Experience Point Transactions" />
    <CardContent>
      <ExperiencePointHistory {...props} testid="experiencePointHistory" />
    </CardContent>
  </Card>
));

const ExperienceTab = ({ ...props }) => {
  const { record } = props;
  const user = record;
  const { badges } = user;

  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <ExperienceSummary user={record} />
      </Grid>
      <Grid item xs={12} md={8}>
        <ExperiencePointsCard {...props} />
      </Grid>
      <Grid item xs={12} md={4}>
        <BadgesCard badges={badges} />
      </Grid>
    </Grid>
  );
};

ExperienceTab.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default ExperienceTab;
