import React, { PureComponent } from 'react';
import { UPDATE_MANY, showNotification } from 'react-admin';
import { TextField, Typography, Button, Grid, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ColorInput } from 'react-admin-color-input';
import PropTypes from 'prop-types';
import { rewardCurrencies } from '../../../config/campaignConfig';
import { validateMaxAmountByCurrency } from '../../../../../util/currencyUtil';
import { rewardLimits } from '../../../../config/currencyConfig';

const styles = {
  root: {
    width: '400px',
  },
  inputs: {
    padding: '10px',
    textAlign: 'center',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  textField: {
    width: '100%',
  },
  dateField: {
    width: '100%',
    marginTop: '15px',
  },
  header: {
    backgroundColor: '#00C7FC',
    height: '48px',
  },
  title: {
    padding: '9px 15px',
    color: 'white',
  },
  button: {
    marginTop: '25px',
  },
  batchOperation: {
    border: '1px solid grey',
    borderRadius: '6px',
    padding: '10px',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  colorPicker: { width: '100%' },
};

class BulkMissionEditPresenter extends PureComponent {
  state = {
    missionBatchOperation: 'Increment',
    color: '',
    rewardValidationError: null,
  };

  handleChange = event => {
    if (event.target.value !== 'N/A') {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: null });
    }
  };

  handleMissionBatchOperation = event => {
    this.setState({ missionBatchOperation: event.target.value });
  };

  handleColorChange = color => this.setState({ color });

  updateMissions = async () => {
    if (!this.validateReward()) {
      return;
    }
    const { filtersMissions, dataProvider, dispatch, campaignId, close } = this.props;
    const { state } = this;
    const data = Object.keys(state)
      .filter(key => state[key] !== '')
      .reduce((acc, e) => ({ ...acc, [e]: state[e] }), {});

    close();
    // send data to backend
    try {
      await dataProvider(
        UPDATE_MANY,
        'missions',
        { data, filters: { ...filtersMissions, campaignId } },
        {
          onSuccess: {},
          onFailure: {
            notification: {
              body: `Update missions failed on the backend side`,
              level: 'error',
            },
          },
          refresh: true,
        },
      );
    } catch (ex) {
      dispatch(showNotification(`Missions update failed: ${ex.message}`, 'error'));
    }
  };

  validateReward = () => {
    const { rewardCurrency, reward } = this.state;
    if (rewardCurrency || reward) {
      const validationError = validateMaxAmountByCurrency(rewardCurrency, reward, rewardLimits);
      this.setState({ rewardValidationError: validationError });
      return validationError === '';
    }
    return true;
  };

  render() {
    const { totalMissions, classes } = this.props;
    const { missionBatchOperation, color, rewardValidationError } = this.state;
    return (
      <Grid className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" component="h2" className={classes.title}>
            Edit {totalMissions} missions
          </Typography>
        </div>
        <div className={classes.batchOperation}>
          <RadioGroup
            testid="missionBatchOperation"
            aria-label="gender"
            name="gender1"
            value={missionBatchOperation}
            onChange={this.handleMissionBatchOperation}
            className={classes.radioGroup}
          >
            <FormControlLabel value="Replace" control={<Radio />} label="Replace" />
            <FormControlLabel value="Increment" control={<Radio />} label="Increment" />
          </RadioGroup>
          <TextField
            label="Max submission per location"
            type="number"
            className={classes.textField}
            margin="normal"
            name="maxSubmissionsPerLocation"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
          />
        </div>
        <div className={classes.inputs}>
          <TextField
            label="Max submission per user per location"
            type="number"
            className={classes.textField}
            margin="normal"
            name="maxSubmissionsPerUserPerLocation"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
          />
          <TextField
            id="reward-currency"
            label="Reward Currency"
            name="rewardCurrency"
            className={classes.textField}
            onChange={this.handleChange}
            select
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            <option>N/A</option>
            {rewardCurrencies.map(currency => (
              <option key={currency.id} value={currency.name}>
                {currency.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Reward"
            type="number"
            className={classes.textField}
            margin="normal"
            name="reward"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
            error={!!rewardValidationError}
          />
          {rewardValidationError && <FormHelperText error>{rewardValidationError}</FormHelperText>}
          <TextField
            label="Experience reward"
            type="number"
            className={classes.textField}
            margin="normal"
            name="experienceReward"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
          />
          <TextField
            select
            label="Disabled"
            className={classes.textField}
            margin="normal"
            name="isDisabled"
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            onChange={this.handleChange}
          >
            <option>N/A</option>
            <option key="true" value="true">
              True
            </option>
            <option key="false" value="false">
              False
            </option>
          </TextField>
          <TextField
            select
            label="StoreClosed"
            className={classes.textField}
            margin="normal"
            name="isStoreClosed"
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            onChange={this.handleChange}
          >
            <option>N/A</option>
            <option key="true" value="true">
              Closed
            </option>
            <option key="false" value="false">
              Open
            </option>
          </TextField>
          <TextField
            label="Start Date"
            type="datetime-local"
            className={classes.dateField}
            name="startDate"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            name="endDate"
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleChange}
          />
          <ColorInput
            source="Color"
            fullWidth
            input={{ value: color, onChange: this.handleColorChange }}
            meta={{ touched: false, error: false }}
            className={classes.colorPicker}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.updateMissions}
            id="updateMissionsButton"
          >
            Update missions
          </Button>
        </div>
      </Grid>
    );
  }
}

BulkMissionEditPresenter.propTypes = {
  classes: PropTypes.shape().isRequired,
  totalMissions: PropTypes.number.isRequired,
  filtersMissions: PropTypes.shape().isRequired,
  campaignId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
export default withStyles(styles)(BulkMissionEditPresenter);
