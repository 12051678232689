import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import SaveCheckpointDialog from './saveCheckpointDialog';

const styles = {
  button: {
    margin: 5,
  },
  icon: {
    margin: 2,
  },
};

export class SaveWithOptionalIssueRecomputeButton extends React.Component {
  constructor() {
    super();

    this.state = {
      showDialog: false,
    };
  }

  toggleShowDialog = () => {
    this.setState(state => ({ ...state, showDialog: !state.showDialog }));
  };

  render() {
    const { showDialog } = this.state;
    const { record, campaignId, handleSubmit, invalid, classes } = this.props;

    return (
      <React.Fragment>
        <SaveCheckpointDialog
          handleSubmit={handleSubmit}
          show={showDialog}
          record={record}
          campaignId={campaignId}
          onClose={this.toggleShowDialog}
          testid="saveCheckpointDialogId"
          isValid={!invalid}
        />
        <Button
          size="medium"
          onClick={this.toggleShowDialog}
          testid="saveButtonId"
          className={classes.button}
        >
          <SaveIcon className={classes.icon} />
          Save...
        </Button>
      </React.Fragment>
    );
  }
}

SaveWithOptionalIssueRecomputeButton.propTypes = {
  record: PropTypes.shape({}).isRequired,
  campaignId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}),
};

SaveWithOptionalIssueRecomputeButton.defaultProps = {
  classes: {},
};

export default withStyles(styles)(SaveWithOptionalIssueRecomputeButton);
