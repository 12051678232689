import React, { PureComponent } from 'react';
import { withDataProvider, GET_LIST, addField, GET_MANY } from 'react-admin';
import PropTypes from 'prop-types';
import Select from 'react-select';

export class UserFilterPresenter extends PureComponent {
  state = {
    items: [],
    selectedItems: [],
  };

  componentDidMount = async () => {
    this.getUsers();
    const {
      input: { value },
    } = this.props;
    if (value) {
      const { dataProvider } = this.props;
      const data = await dataProvider(GET_MANY, 'users', { ids: value });
      const selectedItems = data.data
        ? data.data.map(val => ({ value: val.id, label: val.userName }))
        : [];
      this.setState({ selectedItems });
    }
  };

  handleChange = selectedItems => {
    const {
      input: { onChange },
    } = this.props;
    const ids = selectedItems ? selectedItems.map(item => item.value) : [];
    this.setState({ selectedItems });
    return onChange(ids);
  };

  handleSearch = keywords => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.getUsers(keywords);
    }, 200);
  };

  getUsers = async params => {
    const { dataProvider } = this.props;
    const payload = {
      filter: {
        q: params,
      },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: {
        page: 1,
        perPage: 25,
      },
    };
    const data = await dataProvider(GET_LIST, 'users', payload);
    const results = data.data ? data.data.map(d => ({ value: d.id, label: d.userName })) : [];
    this.setState({ items: results });
    return results;
  };

  render() {
    const { items, selectedItems } = this.state;
    return (
      <Select
        testid="multipleSelect"
        autosize
        onChange={this.handleChange}
        onInputChange={this.handleSearch}
        options={items}
        onClick={this.handleMenu}
        isMulti
        value={selectedItems}
        placeholder="User"
        name="User"
        className="userFilter"
        classNamePrefix="user"
        closeMenuOnSelect={false}
        styles={{
          option: base => ({
            ...base,
            '&:hover': { backgroundColor: '#e0e0e1', cursor: 'pointer' },
            backgroundColor: 'white',
          }),
          menu: base => ({
            ...base,
            zIndex: 3,
          }),
          valueContainer: base => ({
            ...base,
            height: '40px',
            width: '100px',
            overflow: 'auto',
            fontFamily: 'Roboto, sans-serif',
            padding: 'unset',
          }),
          menuList: base => ({
            ...base,
            fontSize: '12px',
            fontWeight: 200,
            color: '#32465A',
            fontFamily: 'Roboto, sans-serif',
            zIndex: 2,
          }),
          placeholder: base => ({
            ...base,
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'Roboto, sans-serif',
            color: '#8f9091',
          }),
          control: base => ({
            ...base,
            transition: ' border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: ' 1px solid rgba(0, 0, 0, 0.42); })',
            backgroundColor: 'unset',
            borderColor: 'unset',
            borderRadius: 'unset',
            minHeight: 'unset',
            borderStyle: 'unset',
            borderWidth: 'unset',
            boxShadow: 'unset',
            marginTop: '16px',
            marginBottom: '8px',
            '&:hover': {
              textDecoration: 'unset',
            },
          }),
        }}
      />
    );
  }
}

UserFilterPresenter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  dataProvider: PropTypes.func.isRequired,
  input: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default withDataProvider(addField(UserFilterPresenter));
