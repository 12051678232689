import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = users => {
  const data = users.map(u => {
    const { badges, ...user } = u;

    return {
      badges: badges ? `${badges.map(it => it.name)}` : undefined,
      ...user,
    };
  });

  const csv = convertToCSV({
    data,
    fields: [
      'id',
      'email',
      'firstName',
      'lastName',
      'accountBalance',
      'state',
      'status',
      'role',
      'experienceLevel',
      'badges',
    ],
  });

  downloadCSV(csv, 'users');
};

export default exporter;
