import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import styles from '../../../../layout/globalStyle/cardStyle';

const MissionOverview = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Description" />
    <CardContent {...props}>
      <div dangerouslySetInnerHTML={{ __html: record.description }} />
    </CardContent>
  </Card>
));

export default MissionOverview;
