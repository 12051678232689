import React from 'react';
import {
  ArrayField,
  SingleFieldList,
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  Pagination,
  SelectArrayInput,
  ChipField,
  CardActions,
  CreateButton,
  ExportButton,
  EditButton,
  NullableBooleanInput,
  SelectInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ImportUsersButton from './components/importUsersButton.presenter';
import { badges, experienceLevels } from './config/usersConfig';
import exporter from './services/exporter.service';
import { ROLES_AS_LIST } from './roles';
import { countries } from '../campaigns/config/campaignConfig';

const styles = {
  w5: {
    width: '5%',
  },
  w8: {
    width: '8%',
  },
  w10: {
    width: '10%',
  },
  w15: {
    width: '15%',
    whiteSpace: 'normal',
  },
  w20: {
    width: '20%',
  },
  thead: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
  tbody: {
    display: 'block !important',
    overflow: 'scroll !important',
    tableLayout: 'fixed !important',
    width: '100% !important',
    '@media(max-width: 960px)': {
      maxHeight: '68vh',
    },
    '@media screen and (max-width: 1440px) and (max-height: 815px)': {
      maxHeight: '67vh',
    },
    '@media screen and (max-width: 1440px) and (min-height: 816px)': {
      maxHeight: '69vh',
    },
    '@media(min-width: 1441px)': {
      maxHeight: '74vh',
    },
    '@media(min-width: 1920px)': {
      maxHeight: '75vh',
    },
  },
  row: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
    whiteSpace: 'normal !important',
  },
};

const countryCodes = countries.filter(country => country.name !== 'All');

const postRowStyle = (record, index) => ({
  backgroundColor: index % 2 === 0 ? '#fff' : '#f9fbfc',
  textTransform: 'none',
  width: '100%',
  height: 'auto',
  whiteSpace: 'normal',
});

export const UserAction = ({ filterValues, resource, currentSort, total }) => (
  <CardActions>
    <ImportUsersButton />
    <CreateButton resource="users" basePath="users" />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={5000000}
    />
  </CardActions>
);

UserAction.propTypes = {
  filterValues: PropTypes.shape({}).isRequired,
  resource: PropTypes.string.isRequired,
  currentSort: PropTypes.shape({}).isRequired,
  total: PropTypes.shape({}).isRequired,
};

export const UsersFilter = props => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="FirstName" source="firstName" alwaysOn />
    <TextInput label="LastName" source="lastName" alwaysOn />
    <TextInput label="UserName" source="userName" alwaysOn />
    <SelectArrayInput
      label="Status"
      source="status"
      choices={[
        { id: 'Waiting For Validation', name: 'Waiting For Validation' },
        { id: 'Active', name: 'Active' },
        { id: 'Banned', name: 'Banned' },
      ]}
      alwaysOn
    />
    <SelectArrayInput
      label="Role"
      source="role"
      choices={ROLES_AS_LIST.map(role => ({
        id: role,
        name: role,
      }))}
      alwaysOn
    />
    <SelectArrayInput
      label="Levels"
      source="experienceLevels"
      choices={experienceLevels}
      alwaysOn
    />
    <SelectArrayInput
      label="Badges"
      source="badges"
      choices={badges}
      alwaysOn
      optionValue="name"
      optionText={option => (option ? option.name : 'ERROR')}
      format={v => (v ? v.map(it => (it ? it.name || it : 'ERROR')) : [])}
      parse={v => v.map(it => (it ? it.name || it : 'ERROR'))}
    />
    <NullableBooleanInput
      label="Restrictions"
      source="hasRestrictions"
      testId="hasRestrictions"
      alwaysOn
    />
    <SelectInput
      label="Country"
      source="countryCode"
      testId="country"
      optionText="name"
      optionValue="isoCode2Letter"
      choices={countryCodes}
      alwaysOn
    />
  </Filter>
);

const UserList = withStyles(styles)(({ classes, ...props }) => (
  <div>
    <List
      {...props}
      filters={<UsersFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      perPage={100}
      actions={<UserAction />}
    >
      <Datagrid
        rowClick="show"
        rowStyle={postRowStyle}
        classes={{ thead: classes.thead, tbody: classes.tbody, row: classes.row }}
      >
        <TextField
          source="id"
          sortable={false}
          cellClassName={classes.w5}
          headerClassName={classes.w5}
        />
        <EmailField source="email" cellClassName={classes.w20} headerClassName={classes.w20} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField
          testId="userName"
          source="userName"
          cellClassName={classes.w15}
          headerClassName={classes.w15}
        />
        <TextField source="state" />
        <ChipField source="status" />
        <ChipField source="role" />
        <TextField source="experienceLevel" />
        <ArrayField source="badges" cellClassName={classes.w8} headerClassName={classes.w8}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
      </Datagrid>
    </List>
    <div style={{ minHeight: '56px' }} />
  </div>
));

export default UserList;
