import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import styles from '../../../../layout/globalStyle/cardStyle';
import BooleanField from '../../../../layout/components/fields/booleanField.presenter';

const FeaturedDescription = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Featured Campaign" />
    <CardContent {...props}>
      <BooleanField title="Is Featured" record={record.featured} />
      <div dangerouslySetInnerHTML={{ __html: record.featuredDescription }} />
    </CardContent>
  </Card>
));

export default FeaturedDescription;
