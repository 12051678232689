import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  Filter,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { tableStyle, postRowStyle } from '../layout/globalStyle/tableStyle';
import { renderUser } from '../users/services/render-helper';
import TextFieldWithLink from '../components/fields/textFieldWithLink';

export const BankDetailFilter = props => (
  <Filter {...props}>
    <ReferenceArrayInput
      sort={{ field: 'updatedAt', order: 'DESC' }}
      label="User"
      source="userId"
      reference="users"
      testId="userFilter"
      alwaysOn
    >
      <AutocompleteArrayInput optionText={renderUser} />
    </ReferenceArrayInput>
    <TextInput
      label="Account Name"
      source="accountName"
      testId="accountNameFilter"
      style={{ margin: 'auto' }}
      alwaysOn
    />
    <TextInput
      label="Account Number"
      source="accountNumber"
      testId="accountNumberFilter"
      style={{ margin: 'auto' }}
      alwaysOn
    />
  </Filter>
);

const BankDetailList = ({ classes, ...props }) => (
  <List
    {...props}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    perPage={50}
    bulkActionButtons={false}
    filters={<BankDetailFilter />}
  >
    <Datagrid
      rowStyle={postRowStyle}
      classes={{
        thead: classes.thead,
        tbody: classes.tbody,
        row: classes.row,
        table: classes.table,
      }}
    >
      <TextField
        source="id"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        label="PaymentInfo Id"
        testId="paymentInfoId"
      />
      <TextFieldWithLink
        source="userId"
        link={{ resource: 'users', source: 'userId' }}
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        label="User Id"
        testId="userId"
      />
      <TextField
        source="accountName"
        cellClassName={classes.w15}
        headerClassName={classes.w15}
        testId="accountName"
      />
      <TextField
        source="accountNumber"
        cellClassName={classes.w15}
        headerClassName={classes.w15}
        testId="accountNumber"
      />
      <TextField
        source="currency"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="currency"
      />
      <TextField
        source="bsb"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="bsb"
      />
      <TextField
        source="clabe"
        cellClassName={classes.w15}
        headerClassName={classes.w15}
        testId="clabe"
      />
      <TextField
        source="swiftCode"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="swiftCode"
      />
      <TextField
        source="bankCode"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="bankCode"
      />
      <TextField
        source="branchCode"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="branchCode"
      />
      <TextField
        source="accountType"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="accountType"
      />
      <TextField
        source="payoutService"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="payoutService"
      />
      <TextField
        source="updatedAt"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="updatedAt"
      />
      <TextField
        source="createdAt"
        cellClassName={classes.w10}
        headerClassName={classes.w10}
        testId="createdAt"
      />
    </Datagrid>
  </List>
);

BankDetailList.propTypes = {
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

export default withStyles(tableStyle)(BankDetailList);
