import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { AppBar, Menu, Notification, Sidebar, setSidebarVisibility } from 'react-admin';
import snooperTheme from './themes/snooperTheme';
import StagingWarning from './components/StagingWarning';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: 'white',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    minHeight: 'inherit',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    background: '#f9fbfc',
    position: 'absolute',
    top: '50px',
    left: '64px',
    right: '0px',
    bottom: '0px',
  },
  sidebar: {
    background: '#f9fbfc',
    position: 'fixed',
    top: '40px',
    zIndex: 10,
    bottom: 0,
  },
};

class MyLayout extends Component {
  componentDidUpdate() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setSidebarVisibility(false);
  }

  render() {
    const { children, dashboard, title, logout } = this.props;
    return (
      <MuiThemeProvider theme={snooperTheme}>
        <div style={styles.root}>
          <div style={styles.appFrame}>
            <AppBar title={title} logout={logout} position="fixed" />
            <main style={styles.contentWithSidebar}>
              <Sidebar style={styles.sidebar}>
                <Menu hasDashboard={dashboard} />
              </Sidebar>
              <div style={styles.content}>
                <StagingWarning />
                {children}
              </div>
            </main>
            <Notification />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

MyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  logout: PropTypes.shape(PropTypes.any).isRequired,
  setSidebarVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
export default connect(
  mapStateToProps,
  { setSidebarVisibility },
)(withStyles(styles)(MyLayout));
