import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '../../../../layout/components/fields/textField.presenter';
import ArrayField from '../../../../layout/components/fields/arrayField.presenter';
import ColorField from '../../../../layout/components/fields/colorField.presenter';
import NumberField from '../../../../layout/components/fields/numberField.presenter';
import ToogleButton from '../../../../layout/components/fields/booleanField.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';
import BooleanField from '../../../../components/fields/booleanField';
import { extractTags } from '../../../services/inputs-helper';

const style = {
  title: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
};

export const PurchaseField = ({ record }) => (
  <React.Fragment>
    <Typography style={style.title} testid="title">
      Includes purchase
    </Typography>
    <BooleanField source="includePurchase" record={record} />
  </React.Fragment>
);

PurchaseField.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

const MissionSettings = withStyles(styles)(({ classes, record, ...props }) => {
  let minMaxReward = record.reward;
  if (record.minMaxRewardRangeForAllMissions) {
    const min = record.minMaxRewardRangeForAllMissions.first;
    const max = record.minMaxRewardRangeForAllMissions.second;
    if (min !== max) {
      minMaxReward = `min ${min} - max ${max}`;
    }
  }

  return (
    <Card {...props} testid="card" className={classes.content}>
      <CardHeader testid="header" title="Mission Settings" />
      <CardContent {...props}>
        <ToogleButton
          testId="excludableByUser"
          title="User can hide"
          record={record.excludableByUser}
        />
        <TextField title="Mission Type" record={record.missionType} />
        <PurchaseField record={record} />
        <TextField title="Product Categories" record={extractTags(record.tags || [])} />
        <TextField title="Store Types" record={(record.storeTypes || []).join(', ')} />
        <ArrayField
          title="Area"
          record={
            record.segments
              ? record.segments.filter(seg => seg.name === 'Area').map(it => it.values)
              : null
          }
        />
        <ArrayField title="Channels" record={record.storeTypes} />
        <TextField title="Mission name" record={record.inAppName} />
        <TextField title="Reward Currency" record={record.rewardCurrency} />
        <TextField title="Reward" record={minMaxReward} gutterBottom />
        <NumberField title="Experience reward" record={record.experienceReward} />
        <NumberField title="Estimated time to complete mission" record={record.estimatedTime} />
        <NumberField title="Time allowed to complete mission" record={record.timeAllowed} />
        <ColorField source="color" record={record} title="Color for the mission" />
      </CardContent>
    </Card>
  );
});

export default MissionSettings;
