import React from 'react';
import { AutocompleteArrayInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { renderUser } from '../../users/services/render-helper';

const styles = {
  autocompleteArrayInput: {
    chip: {
      margin: 3,
    },
    container: {
      '& div': {
        flexWrap: 'wrap',
        marginTop: '18px',
      },
      '& label': {
        marginTop: 5,
      },
      marginBottom: '5px',
    },
  },
};

const StyledAutocompleteArrayInput = withStyles(styles.autocompleteArrayInput)(props => (
  <AutocompleteArrayInput optionText={renderUser} fullWidth {...props} />
));

export default StyledAutocompleteArrayInput;
