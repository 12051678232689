import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, withDataProvider, DELETE } from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertError from '@material-ui/icons/ErrorOutline';
import ActionCheck from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';

import { PGADMIN_URL, WISER_PGADMIN_VIEW_WIKI_URL } from '../../../../setup/config';
// eslint-disable-next-line import/no-named-as-default
import Warning from '../../../components/alert/warning';
import { warningStyles } from '../../../layout/globalStyle/alertStyle';

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'red',
        '@media (hover: none)': {
          backgroundColor: 'red',
        },
      },
    },
    actionButtonText: {
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  });

const WarningText = () => (
  <p style={warningStyles.p}>
    You are making changes to the campaign checkpoints. You should update all PgAdmin Views using
    this campaign. Go to{' '}
    <a href={PGADMIN_URL} target="_blank" rel="noopener noreferrer">
      PgAdmin
    </a>{' '}
    to see all existing Views or learn more{' '}
    <a href={WISER_PGADMIN_VIEW_WIKI_URL} target="_blank" rel="noopener noreferrer">
      here
    </a>
    .
  </p>
);

/**
 * DeleteCheckpointButtonPresenter component handles the deletion of a checkpoint.
 *
 * @component
 * @example
 * const record = { id: 1 };
 * const dataProvider = (type, resource, params, options) => { ... };
 * return (
 *   <DeleteCheckpointButtonPresenter
 *     record={record}
 *     dataProvider={dataProvider}
 *   />
 * );
 */
export class DeleteCheckpointButtonPresenter extends Component {
  state = { isDialogOpen: false };

  deleteCheckpoint = () => {
    const { record, dataProvider, redirect } = this.props;
    dataProvider(
      DELETE,
      'checkpoints',
      { id: record.id },
      {
        onSuccess: {
          notification: { body: 'Checkpoint deleted.', level: 'info' },
          redirectTo: redirect !== '/' ? redirect : null,
        },
        onError: {
          notification: {
            body: "Error: Something went wrong, couldn't delete the checkpoint.",
            level: 'warning',
          },
        },

        refresh: true,
      },
    );
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  render() {
    const { icon, record, label, classes } = this.props;
    const { isDialogOpen } = this.state;

    const actionButtonStyle = {
      display: 'flex',
      padding: 3,
      justifyContent: 'center',
      alignItems: 'center',
      justifyItems: 'center',
    };

    return (
      <React.Fragment>
        <Button
          onClick={this.openDialog}
          label={label}
          key="deleteButton"
          style={{ color: 'red', padding: 5 }}
          testId="checkpointDeleteButton"
        >
          {icon}
        </Button>

        <Dialog
          open={isDialogOpen}
          onClose={this.closeDialog}
          testId="checkpointDeleteConfirmationDialog"
        >
          <DialogTitle>{`Are you sure you want to delete checkpoint - ${record.id}?`}</DialogTitle>
          <DialogContent>
            <Warning
              propsStyle={{ warningContainer: { padding: 0, marginBottom: 10 } }}
              testId="pGAdminViewChangeWarningMessage"
              showCloseButton={false}
              content={<WarningText />}
            />

            <DialogContentText>
              {
                'By confirming this dialog, you will delete the checkpoint. This action cannot be reversed.'
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} style={{ borderRadius: 5 }} testId="cancelButton">
              <div style={actionButtonStyle}>
                <AlertError style={{ marginRight: 5 }} />
                <p className={classnames(classes.actionButtonText)}>Cancel</p>
              </div>
            </Button>
            <Button
              onClick={this.deleteCheckpoint}
              style={{ borderRadius: 5 }}
              testId="confirmButton"
            >
              <div style={{ ...actionButtonStyle, color: 'rgb(20, 50, 119)' }}>
                <ActionCheck style={{ marginRight: 5 }} />
                <p className={classnames(classes.actionButtonText)}>Confirm</p>
              </div>
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

DeleteCheckpointButtonPresenter.propTypes = {
  classes: PropTypes.shape({}),
  label: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  icon: PropTypes.element,
  dataProvider: PropTypes.func.isRequired,
  redirect: PropTypes.string,
};

DeleteCheckpointButtonPresenter.defaultProps = {
  classes: {},
  label: 'Delete',
  record: null,
  icon: <ActionDelete style={{ color: 'red' }} />,
  redirect: '/',
};

export default compose(
  withDataProvider,
  withStyles(styles),
)(DeleteCheckpointButtonPresenter);
