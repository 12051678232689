import React from 'react';

export const campaignType = (
  <p>
    Carefully choose the campaign type as it can have important implications on how your campaign
    will be displayed and how the data will be extracted. If you’re not sure, ask your Super Admin.
    Most frequent <br />
    - Client paid: all Ad-hoc custom campaigns ordered by a Client.
    <br /> Own campaigns: all campaigns created by Snooper for internal purpose - Own on-boarding:
    to help new Snoopers when joining the platform
    <br /> - Own training: to train users on specific skills whether they’re new or old users
    <br /> - Own activation: to increase the level of activity of existing users
    <br /> - Own contest: to create some engagement within the community with a prize contest
    <br /> Other:
    <br /> - Pre-registration: to let users show their interest for a coming paid mission <br /> -
    Eligibility: to pre-select users based on their answers for a coming paid mission <br />{' '}
    Products:
    <br /> - Share of Visibility: only to be used for a campaign of share of visibility (including a
    share of visibility checkpoint as only checkpoint)
    <br /> - Industry Report: syndicated campaign aimed at analysis an industry trend or key selling
    period
    <br /> - Module: a short non-customisable campaign tracking predefined KPIs
    <br /> - Excellence program: a large recurring customised campaign usually running several times
    over the year and designed for the specific needs of a client
  </p>
);

export const ProductCategories =
  'If the campaign includes alcohol purchase, you should select the alcohol option, if this field is not selected, it means this campaign does not include alcohol purchase';

export default null;
