import React from 'react';
import {
  List,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  DateInput,
  maxValue,
  ExportButton,
  downloadCSV,
  CardActions,
} from 'react-admin';
import PropTypes from 'prop-types';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

import ReactTableDatagrid from '../components/reactTableDatagrid/reactTableDatagrid';
import TextFieldWithLink from '../components/fields/textFieldWithLink';
import FilterWithApplyButton from './filterWithApplyButton/filterWithApplyButton';

const validateRadius = [maxValue(5, 'Max radius of 5km')];

export const AnalyticsActions = ({ filterValues, resource, currentSort, total, exporter }) => (
  <CardActions>
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={5000000}
    />
  </CardActions>
);

AnalyticsActions.propTypes = {
  displayedFilters: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  filterValues: PropTypes.shape({}).isRequired,
  resource: PropTypes.string.isRequired,
  showFilter: PropTypes.shape({}).isRequired,
  total: PropTypes.shape({}).isRequired,
  currentSort: PropTypes.shape({}).isRequired,
  exporter: PropTypes.shape({}).isRequired,
};

export const CustomFilters = props => (
  <FilterWithApplyButton {...props}>
    <ReferenceInput label="Campaigns" source="campaignId" reference="campaigns" alwaysOn>
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <NumberInput source="radius" alwaysOn validate={validateRadius} />
    <DateInput source="date" alwaysOn />
  </FilterWithApplyButton>
);

const style = {
  tableField: { color: 'rgba(0,0,0,0.87', fontSize: '12px' },
};

const exporter = data => {
  const csv = convertToCSV({
    data,
  });
  downloadCSV(csv, `analytics_${data[0].campaignIdInRadius}`); // download as 'posts.csv` file
};

const AnalyticsList = ({ classes, record, ...props }) => (
  <List
    {...props}
    filters={<CustomFilters />}
    actions={<AnalyticsActions exporter={exporter} {...props} />}
    perPage={20}
  >
    <ReactTableDatagrid record={record}>
      <TextFieldWithLink
        label="Ref CampaignId"
        source="referenceCampaignId"
        sourceValue="referenceCampaignId"
        link={{ resource: 'campaigns', source: 'campaignId' }}
        style={style.tableField}
        fixed="left"
        width={100}
      />
      <TextField
        label="Campaign Name"
        source="referenceCampaignName"
        style={style.tableField}
        width={150}
        fixed="left"
      />
      <TextFieldWithLink
        label="Ref MissionId"
        source="referenceMissionId"
        sourceValue="referenceMissionId"
        link={{ resource: 'missions', source: 'campaignId' }}
        style={style.tableField}
        fixed="left"
        width={100}
      />
      <TextField
        label="Ref Mission Name"
        source="referenceMissionLocationName"
        style={style.tableField}
        width={150}
        fixed="left"
      />
      <TextField
        label="asn"
        source="referenceASN"
        style={style.tableField}
        width={80}
        fixed="left"
      />
      <TextField
        label="disabled"
        source="referenceDisabled"
        style={style.tableField}
        width={80}
        fixed="left"
      />
      <TextField
        label="latitude"
        source="locationLatitude"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextField
        label="longitude"
        source="locationLongitude"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextField
        label="address"
        source="referenceAddress"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextField
        label="postcode"
        source="referencePostcode"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextField
        label="state"
        source="referenceState"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextField
        label="suburb"
        source="referenceSuburb"
        style={style.tableField}
        width={100}
        fixed="left"
      />
      <TextFieldWithLink
        label="userId"
        source="userId"
        sourceValue="userId"
        link={{ resource: 'users', source: 'userId' }}
        style={style.tableField}
        width={100}
      />
      <TextField label="userName" source="userName" style={style.tableField} width={100} />
      <TextField label="firstName" source="firstName" style={style.tableField} width={100} />
      <TextField label="xps" source="xps" style={style.tableField} width={100} />
      <TextFieldWithLink
        label="campaignId"
        source="campaignIdInRadius"
        sourceValue="campaignIdInRadius"
        link={{ resource: 'campaigns', source: 'campaignId' }}
        style={style.tableField}
        width={100}
      />
      <TextField
        label="campaignNameInRadius"
        source="campaignNameInRadius"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="campaignTypeInRadius"
        source="campaignTypeInRadius"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="campaignIncludesPurchase"
        source="campaignIncludesPurchase"
        style={style.tableField}
        width={100}
      />
      <TextFieldWithLink
        label="missionId"
        source="missionIdInRadius"
        sourceValue="missionIdInRadius"
        link={{ resource: 'missions', source: 'missionId' }}
        style={style.tableField}
        width={100}
      />
      <TextField
        label="submissionReward"
        source="submissionReward"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="location"
        source="missionLocationName"
        style={style.tableField}
        width={100}
      />
      <TextField label="distance" source="distance" style={style.tableField} width={100} />
      <TextFieldWithLink
        label="submissionId"
        source="submissionId"
        sourceValue="submissionId"
        link={{ resource: 'submissions', source: 'submissionId' }}
        style={style.tableField}
        width={100}
      />
      <TextField
        label="submissionStatus"
        source="submissionStatus"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="submissionCreatedAt"
        source="submissionCreatedAt"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="missionLatitude"
        source="missionLocationLatitude"
        style={style.tableField}
        width={100}
      />
      <TextField
        label="missionLongitude"
        source="missionLocationLongitude"
        style={style.tableField}
        width={100}
      />
      <TextField label="missionType" source="missionType" style={style.tableField} width={100} />
    </ReactTableDatagrid>
  </List>
);

export default AnalyticsList;
