import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withDataProvider, showNotification } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { importUsers, extractUsersFromExcel } from '../services/import-helper';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    textAlign: 'center',
    color: '#999',
    minHeight: '32px',
  },
  label: {
    margin: 0,
  },

  root: {
    minWidth: '150px',
  },
};

export class ImportUsersButtonPresenter extends PureComponent {
  onDrop = acceptedFiles =>
    new Promise((resolves, reject) => {
      const { dataProvider, dispatch } = this.props;
      const reader = new FileReader();

      reader.onload = async e => {
        e.stopPropagation();
        e.preventDefault();
        try {
          const users = extractUsersFromExcel(reader.result);
          const userInserted = await importUsers(users, dataProvider);
          dispatch(showNotification(`User created`));
          resolves(userInserted);
        } catch (ex) {
          dispatch(showNotification(`User creation failed: ${ex.message}`, 'warning'));
          reject(ex);
        }
      };
      reader.onerror = ex => {
        dispatch(showNotification(`User creation failed: ${ex.message}`, 'warning'));
        reject(ex);
      };
      reader.readAsBinaryString(acceptedFiles[0]);
    });

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Dropzone
          onDrop={this.onDrop}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          disableClick={false}
          multiple={false}
          className={classes.dropZone}
        >
          <p className={classes.label}>Import User</p>
        </Dropzone>
      </div>
    );
  }
}

ImportUsersButtonPresenter.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(withDataProvider(ImportUsersButtonPresenter));
