import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { renderUser } from '../../users/services/render-helper';

export default class AssigneeSelect extends React.Component {
  render() {
    return (
      <ReferenceInput
        label="Assignee"
        sort={{ field: 'username', order: 'ASC' }}
        source="assignee"
        reference="users"
        linkType="show"
        fullWidth
        testId="assignee"
        filter={{ role: ['ADMIN'] }}
        allowEmpty
      >
        <AutocompleteInput testId="assignee-input" optionText={renderUser} fullWidth />
      </ReferenceInput>
    );
  }
}
