import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  Show,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import ChipsField from './components/chipsField';
import MessageTitle from './components/MessageTitle';

const MessageShow = props => (
  <Show {...props} title={<MessageTitle />}>
    <SimpleShowLayout>
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="title" />
      <TextField source="body" />
      <DateField source="sentAt" />
      <TextField label="Estimated impacted users" source="estimatedUserImpacted" />
      <TextField label="Actual impacted users" source="actualUserImpacted" />
      <ChipsField source="topics" testId="topicsChips" />
      <ReferenceArrayField label="Users" reference="users" source="userIds">
        <SingleFieldList linkType="show">
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default MessageShow;
