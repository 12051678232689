import React from 'react';
import { tableStyle } from '../../layout/globalStyle/tableStyle';

// eslint-disable-next-line react/prop-types
export default ({ record }) => {
  const { userEmail, userName } = record;
  return userEmail ? (
    <a style={tableStyle.longTextFieldWithLink} href={`#/users/${record.userId}/show`}>
      {userEmail}
    </a>
  ) : (
    <a style={tableStyle.longTextFieldWithLink} href={`#/users/${record.userId}/show`}>
      {userName}
    </a>
  );
};
