import React from 'react';
import {
  List,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  ImageField,
  Pagination,
  ChipField,
  SelectArrayInput,
  NullableBooleanInput,
  FunctionField,
  ReferenceArrayInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import Typography from '@material-ui/core/Typography';

import { renderClient } from '../users/services/render-helper';
import BooleanField, { OnOffField } from '../components/fields/booleanField';
import BudgetField from '../components/fields/priceField';
import { tableStyle } from '../layout/globalStyle/tableStyle';
import optionRenderer from '../submissions/submissionsList/services/optionRender.service';
import { campaignTypes } from './config/campaignConfig';
import ReactTableDatagrid from '../components/reactTableDatagrid/reactTableDatagrid';
import StyledAutocompleteArrayInput from '../components/styledAutocompleteArrayInput/styledAutocompleteArrayInput';

const styles = {
  selectInput: { width: 125 },
  trueColor: { color: '#30d10b' },
  falseColor: { color: '#f44336' },
  tableField: { color: 'rgba(0,0,0,0.87', fontSize: '12px' },
  pricePerStoreVisit: { fontSize: '12px', color: 'grey' },
  items: { fontSize: '12px' },
};

const isCampaignActive = ({ endDate, startDate }) => {
  const now = new Date().getTime();
  return new Date(startDate).getTime() < now && new Date(endDate).getTime() > now;
};

export const hasAvailableTo = data =>
  ((data || {}).availableTo || []).length > 0 ? <UserIcon /> : <React.Fragment />;

export const PricePerStoreVisit = ({ record }) => {
  const { budget } = record;
  const pricePerStoreVisit = budget && budget.pricePerStoreVisit ? budget.pricePerStoreVisit : null;

  return pricePerStoreVisit ? (
    <ul style={styles.pricePerStoreVisit}>
      {pricePerStoreVisit.map(item => (
        <li>
          <Typography testid="item" style={styles.items}>
            {item.name}: ${item.value.toLocaleString()}
          </Typography>
        </li>
      ))}
    </ul>
  ) : null;
};

PricePerStoreVisit.propTypes = {
  record: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const CampaignFilter = props => (
  <Filter {...props}>
    <TextInput label="Campaign's name" source="name" testId="campaignNameInput" alwaysOn />
    <TextInput label="InAppName " source="inAppName" testId="appNameInput" alwaysOn />
    <ReferenceInput
      label="Client"
      source="clientId"
      reference="users"
      filter={{ role: 'CLIENT' }}
      alwaysOn
    >
      <AutocompleteInput label="Client" optionText={renderClient} alwaysOn />
    </ReferenceInput>
    <SelectArrayInput
      label="campaignTypes"
      source="campaignTypes"
      alwaysOn
      choices={campaignTypes}
      options={{ renderValue: optionRenderer('campaign types'), autoWidth: true }}
      style={styles.selectInput}
    />
    <NullableBooleanInput label="Campaign active" source="isAvailableToUsers" alwaysOn />
    <NullableBooleanInput label="Campaign featured" source="featured" alwaysOn />
    <NullableBooleanInput label="Recuring" source="hasCycle" alwaysOn />
    <ReferenceArrayInput
      label="Assignee"
      sort={{ field: 'username', order: 'ASC' }}
      source="assignee"
      reference="users"
      fullWidth
      alwaysOn
      filter={{ role: ['ADMIN'] }}
    >
      <StyledAutocompleteArrayInput />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Reviewers"
      sort={{ field: 'username', order: 'ASC' }}
      source="reviewers"
      reference="users"
      fullWidth
      alwaysOn
      filter={{ role: ['REVIEWER', 'SENIOR_REVIEWER'] }}
    >
      <StyledAutocompleteArrayInput />
    </ReferenceArrayInput>
  </Filter>
);

const CampaignList = ({ classes, record, ...props }) => (
  <List
    {...props}
    filters={<CampaignFilter />}
    style={tableStyle.listHeight}
    sort={{ field: 'startDate', order: 'DESC' }}
    perPage={50}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    filterDefaultValues={{
      campaignTypes: [
        'Industry Report',
        'Client paid',
        'Own activation',
        'Own on-boarding',
        'Own test',
        'Brief',
        'Own training',
        'Own contest',
        'Eligibility',
        'Pre-registration',
        'Excellence program',
        'Share of Visibility',
        'Module',
        'Trax',
      ],
    }}
  >
    <ReactTableDatagrid rowClick>
      <ImageField
        classes={{ image: classes.image }}
        source="imageUrl"
        sortable={false}
        width={70}
        fixed="left"
      />
      <TextField
        label="Name"
        source="name"
        sortable={false}
        style={styles.tableField}
        width={400}
        fixed="left"
      />
      <TextField
        label="inAppName"
        source="inAppName"
        sortable={false}
        style={styles.tableField}
        width={200}
        fixed="left"
      />
      <ReferenceField
        label="Owner"
        source="ownerId"
        reference="users"
        basePath="/users"
        linkType="show"
        width={300}
        fixed="left"
      >
        <ChipField source="email" />
      </ReferenceField>
      <FunctionField
        label="AvailableToClients"
        sortable={false}
        render={item =>
          item.availableToClients ? (
            <FiberManualRecord style={styles.trueColor} />
          ) : (
            <FiberManualRecord style={styles.falseColor} />
          )
        }
      />
      <TextField
        label="Campaign type"
        source="campaignType"
        sortable={false}
        style={styles.tableField}
      />
      <BooleanField label="Available" source="active" sortable={false} render={isCampaignActive} />
      <OnOffField label="Enabled" source="active" sortable={false} />
      <BooleanField label="Featured" source="featured" sortable={false} />
      <ReferenceField
        label="Access"
        source="audienceId"
        reference="audiences"
        basePath="/audiences"
        allowEmpty
      >
        <FunctionField testid="availableToItem" sortable={false} render={hasAvailableTo} />
      </ReferenceField>
      <DateField label="Start date" source="startDate" locales="en-AU" style={styles.tableField} />
      <DateField label="End date" source="endDate" locales="en-AU" style={styles.tableField} />
      <DateField
        label="Debriefing date"
        source="clientDebriefingDate"
        locales="en-AU"
        style={styles.tableField}
      />
      <TextField title="# of visits" source="campaignTarget" style={styles.tableField} width={80} />
      <BudgetField label="Budget" source="totalBudget" style={styles.tableField} width={80} />
      <BudgetField label="Data fee" source="designDataFee" style={styles.tableField} width={80} />
      <PricePerStoreVisit
        title="Price store visit"
        source="budget.pricePerStoreVisit"
        width={150}
      />
    </ReactTableDatagrid>
  </List>
);

export default withStyles(tableStyle)(CampaignList);
