import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import NotificationsIcon from '@material-ui/icons/Notifications';
import classnames from 'classnames';
import { Button } from 'react-admin';

const styles = theme =>
  createStyles({
    emailButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });
const OpenBankAccountActivityCreationButton = ({
  label = 'Contact',
  classes = {},
  className,
  icon,
  onOpenContactForm,
}) => (
  <Button
    onClick={onOpenContactForm}
    label={label}
    className={classnames(classes.emailButton, className)}
    key="button"
  >
    {icon}
  </Button>
);

OpenBankAccountActivityCreationButton.propTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.shape({}),
  icon: PropTypes.element,
  onOpenContactForm: PropTypes.func.isRequired,
};

OpenBankAccountActivityCreationButton.defaultProps = {
  classes: {},
  className: null,
  label: 'Contact',
  record: null,
  icon: <NotificationsIcon />,
};

export default compose(withStyles(styles))(OpenBankAccountActivityCreationButton);
