import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';

const styles = {
  trueIcon: {
    color: '#30d10b',
    fontSize: 24,
  },
  errorIcon: {
    color: '#f44336',
    fontSize: 24,
  },
  warningIcon: {
    color: '#FFA311',
    fontSize: 24,
  },
};

const BooleanField = ({ source, record, True, False, render, inversed, severity }) => {
  let field = record[source];
  if (render) {
    field = render(record);
  }
  if (inversed) {
    field = !field;
  }
  let styleTrue = null;
  if (severity !== 'info') {
    styleTrue = styles.trueIcon;
  }
  let styleFalse = null;
  if (severity === 'error') {
    styleFalse = styles.errorIcon;
  } else if (severity === 'warning') {
    styleFalse = styles.warningIcon;
  }
  return field ? (
    <True testid="true" style={styleTrue} />
  ) : (
    <False testid="false" style={styleFalse} />
  );
};

BooleanField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.shape({}).isRequired,
  render: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'info']),
  True: PropTypes.element,
  False: PropTypes.element,
  inversed: PropTypes.bool,
};

BooleanField.defaultProps = {
  True: DoneIcon,
  False: ClearIcon,
  source: undefined,
  render: undefined,
  inversed: false,
  severity: 'error',
};

export const BooleanWithCircleIconsField = props => (
  <BooleanField {...props} True={FiberManualRecord} False={FiberManualRecord} />
);

export const OnOffField = props => <TextBooleanField {...props} trueText="On" falseText="Off" />;

export const OpenCloseField = props => (
  <TextBooleanField {...props} trueText="Open" falseText="Closed" />
);

export const TextBooleanField = ({ trueText, falseText, ...props }) => (
  <BooleanField
    {...props}
    True={({ style }) => (
      <span style={{ ...style, fontSize: 16, textTransform: 'capitalize' }}>{trueText}</span>
    )}
    False={({ style }) => (
      <span style={{ ...style, fontSize: 16, textTransform: 'capitalize' }}>{falseText}</span>
    )}
  />
);

export default BooleanField;
