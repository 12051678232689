import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = submissions => {
  const segmentNames = new Set();
  const data = submissions.map(submission => {
    if (!submission.segments) {
      return submission;
    }
    submission.segments.forEach(segment => {
      segmentNames.add(segment.name);
    });
    return {
      ...submission,
      ...submission.segments.reduce(
        (acc, segment) => ({ ...acc, [segment.name]: segment.value }),
        {},
      ),
    };
  });
  const csv = convertToCSV({
    data,
    fields: [
      'id',
      'userId',
      'missionId',
      'campaignId',
      'submittedAt',
      'createdAt',
      'updatedAt',
      'acceptedAt',
      'status',
      'hiddenToClients',
      'campaignName',
      'missionLocationName',
      'userFullName',
      'userName',
      'missionActive',
      'userEmail',
      ...segmentNames,
      'cycleNumber',
    ],
  });
  downloadCSV(csv, 'submissions');
};

export default exporter;
