const styles = {
  content: {
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important',
    borderRadius: '6px',
  },
  cardContent: {
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important',
    borderRadius: '6px',
    width: '100%',
    marginBottom: 20,
  },
  formContent: {
    marginTop: '40px',
    marginBottom: '10px',
  },
  formContentCheckpoints: {
    position: 'relative',
    top: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '100px',
  },
  cardContentCheckpoints: {
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important',
    borderRadius: '6px',
    width: '48%',
    marginBottom: 20,
  },
};

export default styles;
