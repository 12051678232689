/**
 * Sanitize the input string by removing leading whitespace, and replacing
 * occurrences of multiple white space characters with a single space.
 *
 * @param {string} string The string to be sanitized.
 * @returns {string} The sanitized input string.
 */
const sanitizeRedundantWhitespace = string =>
  string ? string.replace(/^\s+/g, '').replace(/\s{2,}/g, ' ') : '';

export default sanitizeRedundantWhitespace;
