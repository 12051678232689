import React, { PureComponent, Fragment } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  NumberInput,
  BooleanInput,
  required,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  RadioButtonGroupInput,
  LongTextInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  ShowButton,
  ArrayInput,
  NullableBooleanInput,
  SimpleFormIterator,
  SelectArrayInput,
  FileInput,
  FileField,
  REDUX_FORM_NAME,
} from 'react-admin';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ColorInput } from 'react-admin-color-input';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { renderClient, renderUser } from '../users/services/render-helper';
import { renderTeam } from '../companies/services/render-helper';
import RichTextInput from '../layout/components/richTextInput/richTextInput.presenter';
import PriceInput from '../layout/components/inputs/priceInput';
import UsersFileInput from './components/usersFile.presenter';
import TooltipWrapper from '../components/tooltip/tooltip';
import { campaignType, ProductCategories } from '../components/tooltip/tooltips.constant';
import { badges, experienceLevels } from '../users/config/usersConfig';
import {
  missionTypes,
  campaignTypes,
  countries,
  storeTypes,
  tags,
  cycles,
  traxTargets,
  rewardCurrencies,
  getCurrencyByCountry,
  deserializeValue,
} from './config/campaignConfig';
import {
  getIndustryReportInput,
  parseAvailableToUsers,
  formatAvailableToUsers,
} from './services/inputs-helper';
import cardStyles from '../layout/globalStyle/cardStyle';
import CustomToolbar from '../components/toolbars/toolbars';
import i18n from '../../i18n/i18n';
import {
  countryValidate,
  availableToBadgesInputFormat,
  availableToBadgesInputParse,
} from './campaignCreate.presenter';
import sanitizeRedundantWhitespace from '../components/utils/sanitizeWhitespace/sanitizeRedundantWhitespace';
import LinkedCampaignsSelect from './components/linkedCampaignsSelect';
import OperationsSettingsPanel from './components/operationsSettingsPanel';
import { validateURL } from './util';
import validateReward from '../../util/rewardUtil';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  richTextInput: {
    '& .ql-editor': {
      minHeight: '100px',
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  arrowColor: {
    color: '#00C7FC',
  },
  userIds: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  content: cardStyles.content,
  width: {
    width: '100%',
    overflow: 'visible',
  },
  purchase: { marginLeft: 20 },
  reward: { marginLeft: 20 },
};

export const CampaignEditTitle = ({ record }) => <span>{record.name}</span>;

export const CampaignNameTextInput = ({ formData }) => (
  <TextInput
    label="Campaign Name"
    source="name"
    fullWidth
    disabled={!!(formData || {}).metabaseDashboardUrl || !!(formData || {}).metabaseId}
    parse={text => sanitizeRedundantWhitespace(text)}
  />
);

class CampaignEdit extends PureComponent {
  renderRichTextInput = (label, source, isRequired = false) => (
    <RichTextInput
      label={label}
      source={source}
      validate={isRequired ? required(`The ${label} is mandatory`) : null}
    />
  );

  renderTypeInput = () => (
    <SelectInput
      source="missionType"
      choices={missionTypes}
      validate={required('The type is mandatory')}
    />
  );

  renderStoreTypeInput = () => (
    <SelectArrayInput
      label="Store types"
      source="storeTypes"
      choices={storeTypes}
      fullWidth
      validate={required('The store type is mandatory')}
    />
  );

  renderTagInput = () => (
    <TooltipWrapper title={ProductCategories}>
      <SelectArrayInput label="Product Categories" source="tags" choices={tags} fullWidth />
    </TooltipWrapper>
  );

  renderAvailableToExperienceLevelInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'availableToExperienceLevels', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'availableToExperienceLevels', 'label')}
        source="availableToExperienceLevels"
        choices={experienceLevels}
        fullWidth
      />
    </TooltipWrapper>
  );

  renderNotAvailableToExperienceLevelInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'notAvailableToExperienceLevels', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'notAvailableToExperienceLevels', 'label')}
        source="notAvailableToExperienceLevels"
        choices={experienceLevels}
        fullWidth
      />
    </TooltipWrapper>
  );

  renderAvailableToBadgesInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'availableToBadges', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'availableToBadges', 'label')}
        source="availableToBadges"
        choices={badges}
        fullWidth
        optionValue="name"
        format={availableToBadgesInputFormat}
        parse={availableToBadgesInputParse}
      />
    </TooltipWrapper>
  );

  renderNotAvailableToBadgesInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'notAvailableToBadges', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'notAvailableToBadges', 'label')}
        source="notAvailableToBadges"
        choices={badges}
        fullWidth
        optionValue="name"
        format={availableToBadgesInputFormat}
        parse={availableToBadgesInputParse}
      />
    </TooltipWrapper>
  );

  renderCountryInput = () => (
    <TooltipWrapper title="Changing country will also change the reward currency is the missions setting below. If you select ALL, the reward currency will be AUD by default.">
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => (
          <SelectInput
            source="country"
            choices={countries}
            validate={countryValidate('The country is mandatory')}
            onChange={value => {
              const country = deserializeValue(value);
              dispatch(change(REDUX_FORM_NAME, 'rewardCurrency', getCurrencyByCountry(country)));
            }}
            {...rest}
          />
        )}
      </FormDataConsumer>
    </TooltipWrapper>
  );

  renderCycleInput = () => (
    <div>
      <SelectInput source="cycleType" choices={cycles} />
      <p>
        The cycle will be generated using the start date and date of the campaign. They are shown
        after saving. The following rules are applied:
      </p>
      <ul>
        <li>The segments are applied by cycle</li>
        <li>It&apos;s not possible to accept a mission if out of the cycle</li>
        <li>It&apos;s impossible to reaccept a mission from a previous cycle</li>
      </ul>
    </div>
  );

  renderCampaignTypeInput = () => (
    <TooltipWrapper title={campaignType}>
      <SelectInput
        source="campaignType"
        choices={campaignTypes}
        validate={required('The type is mandatory')}
      />
    </TooltipWrapper>
  );

  render() {
    const { classes, id } = this.props;
    return (
      <Edit title={<CampaignEditTitle />} {...this.props} undoable={false}>
        <SimpleForm
          className={classes.input}
          toolbar={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CustomToolbar Title={CampaignEditTitle}>
              <ShowButton />
            </CustomToolbar>
          }
          submitOnEnter={false}
          redirect="show"
          validate={validateReward}
        >
          <Grid container direction="row" spacing={24} fullWidth>
            <Grid item md={6}>
              <Grid container direction="column" justify="space-evenly" spacing={24}>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content} style={styles.width}>
                    <CardHeader testid="header" title="Main Settings" />
                    <CardContent>
                      {this.renderCampaignTypeInput()}
                      <p>Stage TODO</p>
                      <BooleanInput source="active" />
                      <BooleanInput source="availableToClients" />
                      <BooleanInput
                        source="excelExportAvailableToClients"
                        testId="excelExportAvailableToClients"
                      />
                      <BooleanInput
                        source="photoDownloadAvailableToClients"
                        testId="photoDownloadAvailableToClients"
                      />
                      <FormDataConsumer>{CampaignNameTextInput}</FormDataConsumer>
                      <ImageInput source="imageUrl" label="Mission's thumbail" accept="image/*">
                        <ImageField source="imageUrl" title="Mission's thumbail" />
                      </ImageInput>
                      <ReferenceInput
                        label="Owner"
                        sort={{ field: 'username', order: 'ASC' }}
                        source="ownerId"
                        reference="users"
                        linkType="show"
                        filter={{ role: 'CLIENT' }}
                        validate={required()}
                        fullWidth
                      >
                        <AutocompleteInput label="Owner" optionText={renderClient} fullWidth />
                      </ReferenceInput>
                      <ReferenceArrayInput
                        label="Guests"
                        sort={{ field: 'username', order: 'ASC' }}
                        source="clientIds"
                        reference="users"
                        linkType="show"
                        filter={{ role: 'CLIENT' }}
                        fullWidth
                      >
                        <AutocompleteArrayInput label="User" optionText={renderClient} fullWidth />
                      </ReferenceArrayInput>
                      <ReferenceArrayInput
                        label="Teams"
                        sort={{ field: 'companyName', order: 'ASC' }}
                        source="teamIds"
                        reference="teams"
                        linkType="show"
                        fullWidth
                      >
                        <AutocompleteArrayInput label="Teams" optionText={renderTeam} fullWidth />
                      </ReferenceArrayInput>
                      {this.renderCountryInput()}
                      <TextInput label="Metabase ID" source="metabaseId" fullWidth disabled />
                      <TextInput
                        label="Metabase Dashboard URL"
                        source="metabaseDashboardUrl"
                        validate={value => validateURL(value)}
                        fullWidth
                      />
                      <LinkedCampaignsSelect
                        label="Linked Campaigns"
                        source="linkedCampaigns"
                        fullWidth
                        id={id}
                      />
                      <FileInput
                        source="excelUrl"
                        label="Excel"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      >
                        <FileField source="excelUrl" title="Excel" />
                      </FileInput>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <OperationsSettingsPanel className={classes.content} />
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Campaign Schedule" />
                    <CardContent>
                      <DateTimeInput
                        label="Start Date (Sydney time)"
                        source="startDate"
                        parse={date => date}
                        fullWidth
                      />
                      <DateTimeInput
                        label="End Date (Sydney time)"
                        source="endDate"
                        parse={date => date}
                        fullWidth
                      />
                      <DateTimeInput source="clientDebriefingDate" parse={date => date} fullWidth />
                      {this.renderCycleInput()}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Mission Settings" />
                    <CardContent>
                      <BooleanInput
                        testId="excludableByUser"
                        source="excludableByUser"
                        label="User can hide"
                      />
                      {this.renderTypeInput()}
                      <NullableBooleanInput
                        fullWidth
                        style={styles.purchase}
                        source="includePurchase"
                        validate={required('The purchase is mandatory')}
                      />
                      {this.renderTagInput()}
                      {this.renderStoreTypeInput()}
                      <TextInput
                        label="Mission Name"
                        source="inAppName"
                        parse={text => sanitizeRedundantWhitespace(text)}
                        fullWidth
                      />
                      <SelectInput
                        source="rewardCurrency"
                        choices={rewardCurrencies}
                        validate={required('The reward currency is mandatory')}
                      />
                      <NumberInput
                        source="reward"
                        style={styles.reward}
                        label="reward"
                        validate={required('The reward is mandatory')}
                      />
                      <NumberInput
                        source="experienceReward"
                        fullWidth
                        validate={required()}
                        label="experience reward"
                      />
                      <NumberInput source="estimatedTime" label="Estimated time (min)" fullWidth />
                      <NumberInput source="timeAllowed" label="Time Allowed(min)" fullWidth />
                      <ColorInput source="color" fullWidth />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Completion Rules" />
                    <CardContent>
                      <NumberInput source="maxSubmissionsPerLocation" fullWidth />
                      <NumberInput source="maxSubmissionsPerUser" fullWidth />
                      <NumberInput source="maxSubmissionsPerUserPerLocation" fullWidth />
                      <NumberInput source="maxActiveSubmissionsPerUser" fullWidth />
                      <FormDataConsumer>
                        {({ formData }) => {
                          if (!(formData || {}).cycleType) {
                            return null;
                          }
                          return (
                            <Fragment>
                              <NumberInput
                                source="maxSubmissionsPerLocationPerCycle"
                                fullWidth
                                validate={required()}
                              />
                            </Fragment>
                          );
                        }}
                      </FormDataConsumer>
                      <BooleanInput source="secondChance" />
                      <BooleanInput source="autoValidationEnabled" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Availability" />
                    <CardContent>
                      <RadioButtonGroupInput
                        fullWidth
                        row
                        classes={{ root: classes.radioButton }}
                        source="userSelection"
                        defaultValue="userList"
                        choices={[
                          { id: 'manually', name: 'One by One' },
                          { id: 'userList', name: 'User IDs' },
                        ]}
                      />
                      <TooltipWrapper title="By selecting one or more users, the user can see this campaign if he is within this list. If the list is empty, all users can see this campaign">
                        <FormDataConsumer>
                          {({ formData }) => {
                            if (!formData) {
                              return null;
                            }
                            if (
                              formData.availableToUsers &&
                              formData.availableToUsers.length > 100
                            ) {
                              return (
                                <LongTextInput
                                  source="availableToUsers"
                                  required={required('must give a list of userIds')}
                                  parse={parseAvailableToUsers}
                                  format={formatAvailableToUsers}
                                  resettable
                                  className={classes.userIds}
                                />
                              );
                            }
                            switch (formData.userSelection) {
                              case 'manually':
                              default:
                                return (
                                  <ReferenceArrayInput
                                    label="Available to Users"
                                    sort={{ field: 'username', order: 'ASC' }}
                                    source="availableToUsers"
                                    reference="users"
                                    linkType="show"
                                    fullWidth
                                  >
                                    <AutocompleteArrayInput optionText={renderUser} fullWidth />
                                  </ReferenceArrayInput>
                                );

                              case 'userList': {
                                return (
                                  <div>
                                    <UsersFileInput
                                      label="Upload users file"
                                      source="availableToUsers"
                                    />
                                    <LongTextInput
                                      source="availableToUsers"
                                      required={required('must give a list of userIds')}
                                      parse={parseAvailableToUsers}
                                      format={formatAvailableToUsers}
                                      resettable
                                      className={classes.userIds}
                                    />
                                  </div>
                                );
                              }
                            }
                          }}
                        </FormDataConsumer>
                      </TooltipWrapper>

                      {this.renderAvailableToExperienceLevelInput()}
                      {this.renderNotAvailableToExperienceLevelInput()}
                      {this.renderAvailableToBadgesInput()}
                      {this.renderNotAvailableToBadgesInput()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container direction="column" justify="space-evenly" spacing={24}>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Locations" />
                    <CardContent>
                      <BooleanInput source="anywhere" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Description" />
                    <CardContent>
                      {this.renderRichTextInput('Description', 'description', true)}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Featured Campaign" />
                    <CardContent>
                      <BooleanInput source="featured" />
                      {this.renderRichTextInput(
                        'Featured Description',
                        'featuredDescription',
                        false,
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Budget" />
                    <CardContent>
                      <PriceInput source="budget.totalBudget" label="Total budget" />
                      <PriceInput source="budget.designDataFee" label="Design and data fee" />
                      <ArrayInput source="budget.pricePerStoreVisit" label="Price per store visit">
                        <SimpleFormIterator>
                          <TextInput source="name" fullWidth label="name" />
                          <PriceInput source="value" fullWidth label="value" />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <NumberInput label="# of visits" source="campaignTarget" fullWidth />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Trax" />
                    <CardContent>
                      <TextInput label="Project Id" source="traxProjectId" fullWidth />
                      <TextInput label="API key" source="traxApiKey" fullWidth />
                      <TextInput label="User ID" source="traxUserId" fullWidth />
                      <SelectInput
                        label="Project stage"
                        source="traxTarget"
                        choices={traxTargets}
                        fullWidth
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <FormDataConsumer>{getIndustryReportInput}</FormDataConsumer>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}

CampaignEditTitle.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CampaignEditTitle.defaultProps = {
  record: {},
};

CampaignNameTextInput.propTypes = {
  formData: PropTypes.shape({
    metabaseDashboardUrl: PropTypes.string.isRequired,
    metabaseId: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(CampaignEdit);
