import React from 'react';
import { Loading } from 'react-admin';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
} from '@material-ui/core';

const styles = {
  card: {
    'margin-bottom': '10px',
  },
  paper: {
    overflow: 'scroll',
  },
};

const moneyInfoRenderer = (data, loading, error) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const { currencyBalances, lastCashOut } = data || {};
  const currencies = Object.keys(currencyBalances || {});
  return (
    <Paper style={styles.paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell>Account Balance</TableCell>
            <TableCell>Waiting For Validation</TableCell>
            <TableCell>Total Cashed Out</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currencies.map(currency => (
            <TableRow>
              <TableCell>{currency}</TableCell>
              <TableCell>{currencyBalances[currency].balanceApproved.toFixed(2)}</TableCell>
              <TableCell>
                {currencyBalances[currency].balanceWaitingForValidation.toFixed(2)}
              </TableCell>
              <TableCell>{currencyBalances[currency].totalCashedOut.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4}>
              Last Cash Out:{' '}
              {lastCashOut
                ? new Date(lastCashOut).toLocaleString('en-AU')
                : 'User has not cashed out yet.'}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

const MoneyInfoCard = ({ data, loading, error, ...props }) => (
  <Card {...props} style={styles.card} testid="card">
    <CardHeader testid="header" title="Money info" />
    <CardContent testid="content">{moneyInfoRenderer(data, loading, error)}</CardContent>
  </Card>
);

export default MoneyInfoCard;
