import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { addField, showNotification } from 'react-admin';
import Dropzone from 'react-dropzone';
import { Typography, withStyles, Button } from '@material-ui/core';
import { extractUsersFromExcel } from '../../missions/services/import-helper';

const styles = {
  drop: {
    display: 'table',
  },
};

export class UsersFileInputPresenter extends PureComponent {
  onDrop = async acceptedFiles => {
    const { dispatch, input } = this.props;
    const reader = new FileReader();

    reader.onabort = () => dispatch(showNotification('file reading was aborted', 'warning'));
    reader.onerror = () => dispatch(showNotification('file reading has failed', 'error'));
    reader.onload = () => {
      const users = extractUsersFromExcel(reader.result);
      input.onChange(users);
    };

    reader.readAsBinaryString(acceptedFiles[0]);
  };

  render() {
    const { meta, label, classes } = this.props;
    const { error } = meta;

    return (
      <div>
        <div>
          {error && (
            <Typography gutterBottom variant="subtitle1" color="error" align="center">
              {error}
            </Typography>
          )}
        </div>
        <Dropzone
          testid="dropzone"
          onDrop={this.onDrop}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          disableClick={false}
          multiple={false}
          className={classes.drop}
        >
          {label && (
            <Button variant="contained" className={classes.button}>
              {label}
            </Button>
          )}
        </Dropzone>
      </div>
    );
  }
}

UsersFileInputPresenter.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  label: PropTypes.string,
  classes: PropTypes.shape({}),
};

UsersFileInputPresenter.defaultProps = {
  input: {},
  meta: {},
  label: undefined,
  classes: {},
};

export default withStyles(styles)(addField(UsersFileInputPresenter));
