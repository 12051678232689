export const USER_ROLES = Object.freeze([
  { id: 'ADMIN', name: 'ADMIN' },
  { id: 'CLIENT', name: 'CLIENT' },
  { id: 'FIELD', name: 'FIELD' },
  { id: 'REVIEWER', name: 'REVIEWER' },
  { id: 'SENIOR_REVIEWER', name: 'SENIOR_REVIEWER' },
  { id: 'TESTER', name: 'TESTER' },
  { id: 'USER', name: 'USER' },
]);

export const experienceLevels = Object.freeze([
  { id: 'Starter', name: 'Starter' },
  { id: 'Snooper', name: 'Snooper' },
  { id: 'Snooper Trooper', name: 'Snooper Trooper' },
  { id: 'Super Snooper', name: 'Super Snooper' },
  { id: 'Master Snooper', name: 'Master Snooper' },
  { id: 'Snooper Star', name: 'Snooper Star' },
]);

export const badges = Object.freeze([
  {
    name: 'PaidAtHomeOrAnywhere',
    description: 'The snooper has completed at least one paid AtHome or Anywhere mission.',
  },
  {
    name: 'PaidInStore',
    description: 'The snooper has completed at least one paid mission in store.',
  },
  {
    name: 'UnpaidAtHomeOrAnywhere',
    description: 'The snooper has completed at least one unpaid AtHome or Anywhere mission.',
  },
  {
    name: 'UnpaidInStore',
    description: 'The snooper has completed at least one unpaid mission in any store.',
  },
  {
    name: 'SnooperAcademy1',
    description: 'The user has completed the Snooper Academy welcome mission.',
  },
]);

export const currencies = Object.freeze([
  { id: 'AUD', name: 'AUD' },
  { id: 'HKD', name: 'HKD' },
  { id: 'NZD', name: 'NZD' },
  { id: 'SGD', name: 'SGD' },
  { id: 'MYR', name: 'MYR' },
  { id: 'MXN', name: 'MXN' },
  { id: 'JPY', name: 'JPY' },
  { id: 'KRW', name: 'KRW' },
  { id: 'THB', name: 'THB' },
  { id: 'USD', name: 'USD' },
  { id: 'CNY', name: 'CNY' },
]);

export const SWIFT_CODE = Object.freeze([
  Object.freeze({ id: 'PHBMMYKL', name: 'Affin Bank' }),
  Object.freeze({ id: 'AIBBMYKL', name: 'Affin Islamic Bank' }),
  Object.freeze({ id: 'AGOBMYKL', name: 'Agro Bank / Bank Pertanian' }),
  Object.freeze({ id: 'RJHIMYKL', name: 'Al Rajhi Banking and Investment Corporation' }),
  Object.freeze({ id: 'MFBBMYKL', name: 'Alliance Bank' }),
  Object.freeze({ id: 'ALSRMYK1', name: 'Alliance Islamic Bank' }),
  Object.freeze({ id: 'ARBKMYKL', name: 'Ambank' }),
  Object.freeze({ id: 'AMMBMYKL', name: 'Aminvestment Bank' }),
  Object.freeze({ id: 'AISLMYKL', name: 'Amislamic Bank' }),
  Object.freeze({ id: 'BNPAMYKL', name: 'BNP Paribas' }),
  Object.freeze({ id: 'BIMBMYKL', name: 'Bank Islam' }),
  Object.freeze({ id: 'BISLMYKA', name: 'Bank Islam (Labuan)' }),
  Object.freeze({ id: 'BKRMMYKL', name: 'Bank Kerjasama Rakyat / Bank Rakyat' }),
  Object.freeze({ id: 'BMMBMYKL', name: 'Bank Muamalat' }),
  Object.freeze({ id: 'BOFAMY2X', name: 'Bank Of America' }),
  Object.freeze({ id: 'BKCHMYKL', name: 'Bank Of China' }),
  Object.freeze({ id: 'BOTKMYKX', name: 'Bank Of Tokyo Mitsubishi UFJ' }),
  Object.freeze({ id: 'BSNAMYK1', name: 'Bank Simpanan Nasional' }),
  Object.freeze({ id: 'CIBBMYKL', name: 'CIMB Bank' }),
  Object.freeze({ id: 'CTBBMYKL', name: 'CIMB Islamic Bank' }),
  Object.freeze({ id: 'CANHMYKL', name: 'CIMB Principal Asset Management' }),
  Object.freeze({ id: 'CITIMYKL', name: 'Citibank' }),
  Object.freeze({ id: 'DEUTMYKL', name: 'Deutsche Bank' }),
  Object.freeze({ id: 'HMABMYKL', name: 'HSBC Amanah' }),
  Object.freeze({ id: 'HBMBMYKL', name: 'HSBC Bank' }),
  Object.freeze({ id: 'HLBBMYKL', name: 'Hong Leong Bank' }),
  Object.freeze({ id: 'HLIBMYKL', name: 'Hong Leong Islamic Bank' }),
  Object.freeze({ id: 'ICBKMYKL', name: 'Industrial and Commercial Bank Of China' }),
  Object.freeze({ id: 'CHASMYKX', name: 'J.P. Morgan Chase Bank' }),
  Object.freeze({ id: 'KFHOMYKL', name: 'Kuwait Finance House' }),
  Object.freeze({ id: 'MBBEMYKL', name: 'Maybank / Malayan Banking' }),
  Object.freeze({ id: 'MBISMYKL', name: 'Maybank Islamic' }),
  Object.freeze({ id: 'MHCBMYKA', name: 'Mizuho Bank' }),
  Object.freeze({ id: 'OABBMYKL', name: 'OCBC Al Amin Bank' }),
  Object.freeze({ id: 'OCBCMYKL', name: 'OCBC Bank (Oversea Chinese Banking Corporation)' }),
  Object.freeze({ id: 'PBLLMYKA', name: 'PUBLIC BANK (L) LTD' }),
  Object.freeze({ id: 'PBBEMYKL', name: 'PUBLIC BANK BERHAD' }),
  Object.freeze({ id: 'PIBEMYK1', name: 'PUBLIC ISLAMIC BANK BERHAD' }),
  Object.freeze({ id: 'RHBBMYKL', name: 'RHB Bank' }),
  Object.freeze({ id: 'OSKIMYKL', name: 'RHB Investment Bank' }),
  Object.freeze({ id: 'RHBAMYKL', name: 'RHB Islamic Bank' }),
  Object.freeze({ id: 'SCBLMYKX', name: 'Standard Chartered Bank' }),
  Object.freeze({ id: 'SCSRMYK1', name: 'Standard Chartered Saadiq' }),
  Object.freeze({ id: 'SMBCMYKL', name: 'Sumitomo Mitsui Banking Corporation' }),
  Object.freeze({ id: 'ABNAMYKL', name: 'The Royal Bank Of Scotland' }),
  Object.freeze({ id: 'UOVBMYKL', name: 'United Overseas Bank' }),
]);

export const JAPANESE_BANK_ACCOUNT_TYPES = Object.freeze([
  Object.freeze({ id: 'CURRENT', name: 'CURRENT' }),
  Object.freeze({ id: 'CHECKING', name: 'CHECKING' }),
  Object.freeze({ id: 'SAVINGS', name: 'SAVINGS' }),
]);

export const bankAccountValidationRules = Object.freeze({
  accountNumber: {
    default: { pattern: /.*/, errorMessage: 'Account number is invalid.' },
    THB: {
      pattern: /^((H?\d{2})|\d{3})-?\d{1}-?\d{5}-?\d{1,7}$/,
      errorMessage: 'THB account number must match the required format (e.g., XXX-X-XXXXX-XXXX).',
    },
    USD: {
      pattern: /^\d{4,17}$/,
      errorMessage: 'USD account number must be between 4 to 17 digits.',
    },
    CNY: {
      WECHAT_PAY: {
        pattern: /^\+?([0-9]{1,3})-([0-9]{4,13})$/,
        errorMessage:
          'WeChat Pay account number must be in the correct format (e.g., +XX-XXXXXXXX).',
      },
    },
    HKD: {
      pattern: /^\d{6,15}$/,
      errorMessage: 'HKD account number must be 6 to 15 digits.',
    },
  },
  routingNumber: {
    default: {
      pattern: /^\d{9}$/,
      errorMessage: 'Routing number must be exactly 9 digits.',
    },
    USD: {
      pattern: /^\d{9}$/,
      errorMessage: 'USD routing number must be exactly 9 digits.',
    },
  },
  phoneNumber: {
    default: {
      pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      errorMessage: 'Phone number must be in a valid format (e.g., +XX XXX-XXX-XXXX).',
    },
    KRW: {
      pattern: /^\d{10,16}$/,
      errorMessage: 'KRW phone number must be between 10 to 16 digits.',
    },
  },
  email: {
    default: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      errorMessage: 'Email address must be in a valid format (e.g., example@mail.com).',
    },
  },
});
