import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';

export default function TooltipWrapper({ children, title }) {
  return (
    <Grid style={styles.container}>
      {children}
      <Tooltip testID="tooltip" title={title} placement="bottom-start">
        <InfoIcon style={styles.icon} />
      </Tooltip>
    </Grid>
  );
}

const styles = {
  container: {
    display: 'flex',
  },
  icon: {
    fontSize: '18px',
    color: '#848383',
    marginLeft: 10,
  },
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
