import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  withDataProvider,
  GET_ONE,
  GET_MANY,
  ArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import PropTypes from 'prop-types';

export class LinkedCampaignsFieldPresenter extends PureComponent {
  state = {
    linkedCampaignsName: [],
  };

  componentDidMount = async () => {
    const {
      record: { linkedCampaigns },
    } = this.props;
    const {
      dataProvider,
      record: { id },
    } = this.props;
    let data;
    if (linkedCampaigns && linkedCampaigns.length > 0) {
      data = await dataProvider(GET_MANY, 'campaigns', { ids: linkedCampaigns });
    } else {
      const currentCampaign = await dataProvider(GET_ONE, 'campaigns', { id });
      data =
        currentCampaign && currentCampaign.data && currentCampaign.data.linkedCampaigns
          ? await dataProvider(GET_MANY, 'campaigns', { ids: currentCampaign.data.linkedCampaigns })
          : null;
    }
    if (data) {
      this.setState({ linkedCampaignsName: data });
    }
  };

  render() {
    const { linkedCampaignsName } = this.state;
    return (
      <ArrayField
        label="Linked Campaigns"
        source="data"
        record={linkedCampaignsName}
        reference="campaigns"
      >
        <SingleFieldList linkType={false}>
          <MyChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    );
  }
}

LinkedCampaignsFieldPresenter.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  dataProvider: PropTypes.func.isRequired,
};

const styles = {
  linkedCampaign: {
    margin: '5px',
    padding: '5px 10px',
    backgroundColor: '#e0e0e0',
    borderRadius: '50px',
  },
};

const MyChipField = withStyles(styles)(({ classes, ...props }) => (
  <TextField className={classes.linkedCampaign} {...props} />
));

export default withDataProvider(LinkedCampaignsFieldPresenter);
