import { bankAccountValidationRules } from '../modules/users/config/usersConfig';

export const getValidationPatternForBankAccount = (currency, fieldName, accountType) => {
  const rules = bankAccountValidationRules[fieldName];

  if (fieldName === 'accountNumber' && currency === 'CNY') {
    let result;
    if (accountType && rules[currency] && rules[currency][accountType]) {
      result = rules[currency][accountType];
    } else if (rules[currency] && rules[currency].default) {
      result = rules[currency].default;
    } else {
      result = rules.default;
    }
    return result;
  }

  return (currency && rules[currency]) || rules.default;
};

export const validateInput = (input, type, pattern) => {
  if (!input) return 'Required';

  if (pattern && !pattern.test(input)) {
    return `${type}`;
  }

  return undefined;
};
