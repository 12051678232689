import React from 'react';
import { Query } from 'react-admin';
import { withStyles } from '@material-ui/core';
import BankInfoCard from './moneyInfo.presenter';
import styles from '../../../layout/globalStyle/cardStyle';

const BankInfo = withStyles(styles)(({ classes, record, ...props }) => (
  <Query type="GET_ONE" resource="userMoneyInfo" payload={{ id: record.id }}>
    {({ data, loading, error }) => (
      <BankInfoCard
        className={classes.content}
        data={data}
        loading={loading}
        error={error}
        {...props}
      />
    )}
  </Query>
));
export default BankInfo;
