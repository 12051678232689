import React, { useState } from 'react';
import { addField } from 'react-admin';
import Select from 'react-select';
import PropTypes from 'prop-types';

export const convertOptionsToLabelAndValue = options => {
  if (!options || options.length === 0) return undefined;

  return options.map(option => ({ value: option.id, label: option.name }));
};

export const SelectAndSearch = ({
  name,
  isMulti,
  choices,
  placeholder,
  input,
  handleFilterOption,
  values,
}) => {
  const [selectedOption, setSelectedOption] = useState(values || []);

  const handleChange = option => {
    const { onChange } = input;
    const ids = option ? option.map(item => item.value) : [];
    setSelectedOption(option);
    return onChange(ids);
  };

  const styles = {
    option: base => ({
      ...base,
      '&:hover': { backgroundColor: '#e0e0e1', cursor: 'pointer' },
      backgroundColor: 'white',
    }),
    menu: base => ({ ...base, zIndex: 3 }),
    valueContainer: base => ({
      ...base,
      height: '40px',
      width: '100px',
      overflow: 'auto',
      fontFamily: 'Roboto, sans-serif',
      padding: 'unset',
    }),
    menuList: base => ({
      ...base,
      fontSize: '12px',
      fontWeight: 200,
      color: '#32465A',
      fontFamily: 'Roboto, sans-serif',
      zIndex: 2,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      color: '#8f9091',
    }),
    control: base => ({
      ...base,
      transition: ' border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: ' 1px solid rgba(0, 0, 0, 0.42); })',
      backgroundColor: 'unset',
      minHeight: 'unset',
      borderColor: 'unset',
      borderRadius: 'unset',
      borderStyle: 'unset',
      borderWidth: 'unset',
      boxShadow: 'unset',
      marginTop: '16px',
      marginBottom: '8px',
      '&:hover': { textDecoration: 'unset' },
    }),
  };

  return (
    <Select
      testid="SelectAndSearch"
      autosize
      value={selectedOption}
      onChange={handleChange}
      name={name}
      isMulti={isMulti}
      options={convertOptionsToLabelAndValue(choices)}
      placeholder={placeholder}
      styles={styles}
      filterOption={handleFilterOption}
    />
  );
};

SelectAndSearch.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  isMulti: PropTypes.bool.isRequired,
  choices: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  handleFilterOption: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const SelectAndSearchPresenter = addField(SelectAndSearch);
export default SelectAndSearchPresenter;
