import React, { Component } from 'react';
import { FormDataConsumer } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AssigneeSelect from './assigneeSelect';
import ReviewersSelect from './reviewersSelect';

class OperationsSettingsPanel extends Component {
  render() {
    return (
      <Card {...this.props}>
        <CardHeader testid="header" title="Operations" />
        <CardContent>
          <FormDataConsumer>{() => <AssigneeSelect />}</FormDataConsumer>
          <FormDataConsumer>{() => <ReviewersSelect />}</FormDataConsumer>
        </CardContent>
      </Card>
    );
  }
}

export default OperationsSettingsPanel;
