import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
import fan from '../../services/fan';

export default async (type, params) => {
  const login = async () => {
    const { username, password } = params;
    const payload = JSON.stringify({
      username,
      password,
    });
    const result = await fan.post(`/users/login`, payload);

    const { objectId, sessionToken } = result.data;
    localStorage.setItem('token', sessionToken);
    localStorage.setItem('clientId', objectId);

    const user = await fan.get(`/users/${objectId}`);
    const { role } = user.data.data;
    localStorage.setItem('role', role);

    return result;
  };
  if (type === AUTH_LOGIN) {
    const logingIn = await login();
    return logingIn;
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem('clientId');
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role'); // Constant to toggle permissions true or false
    return role ? Promise.resolve(role) : Promise.reject();
  }

  return Promise.reject(Error('Unknown method'));
};
