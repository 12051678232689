import { rewardLimits } from '../modules/config/currencyConfig';
import { validateMaxAmountByCurrency } from './currencyUtil';

const validateReward = values => {
  const errors = {};
  const rewardValidationError = validateMaxAmountByCurrency(
    values.rewardCurrency,
    values.reward,
    rewardLimits,
  );
  if (rewardValidationError) {
    errors.reward = rewardValidationError;
  }
  return errors;
};

export default validateReward;
