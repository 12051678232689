import React from 'react';
import {
  List,
  Pagination,
  EditButton,
  ChipField,
  CardActions,
  ExportButton,
  FunctionField,
  TextField,
  DateField,
} from 'react-admin';
import classnames from 'classnames';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import SubmissionFilters from './components/filters';
import { renderSegments } from '../../missions/services/render-helper';
import { EYE_BASE_URL } from '../../../setup/config';
import UserField from '../../components/fields/userField';
import UserEmailField from '../../components/fields/userEmailField';
import BooleanField from '../../components/fields/booleanField';
import VisibilityButton from '../components/visibilityButton';
import TextFieldWithLink from '../../components/fields/textFieldWithLink';
import { tableStyle } from '../../layout/globalStyle/tableStyle';
import exporter from './services/exporter.service';
import ReactTableDatagrid from '../../components/reactTableDatagrid/reactTableDatagrid';

const styles = ({ palette }) => ({
  'waiting-for-validation': {
    backgroundColor: `${palette.secondary.main}  !important`,
    '& span': { color: palette.secondary.contrastText },
  },
  'aborted---waiting-for-validation': {
    backgroundColor: `${palette.secondary.main}  !important`,
    '& span': { color: palette.secondary.contrastText },
  },
  cancelled: {
    backgroundColor: `${palette.error.main} !important`,
    '& span': { color: palette.error.contrastText },
  },
  rejected: {
    backgroundColor: `${palette.error.main} !important`,
    '& span': { color: palette.error.contrastText },
  },
  'admin-for-review': {
    backgroundColor: `#FDBF4E  !important`,
    '& span': { color: palette.secondary.contrastText },
  },
  'completed-successfully': {
    backgroundColor: `#07D10B !important`,
    '& span': { color: '#ffffff' },
  },
  'aborted---completed': {
    backgroundColor: `#07D10B !important`,
    '& span': { color: '#ffffff' },
  },
  'pre-validation': {
    backgroundColor: `#91bb0f !important`,
    '& span': { color: '#ffffff' },
  },
  failed: {
    backgroundColor: `${palette.error.main} !important`,
    '& span': { color: palette.error.contrastText },
  },
  pending: {
    backgroundColor: `${palette.secondary.main}  !important`,
    '& span': { color: palette.secondary.contrastText },
  },
  received: {
    backgroundColor: `#07D10B !important`,
    '& span': { color: '#ffffff' },
  },
  sending: {
    backgroundColor: `#91bb0f !important`,
    '& span': { color: '#ffffff' },
  },
});

const style = {
  chipHeight: { height: 23, width: '100%' },
  tableField: { color: 'rgba(0,0,0,0.87', fontSize: '12px', textDecoration: 'none' },
  tableLocationField: {
    color: 'rgba(0,0,0,0.87',
    fontSize: '12px',
  },
  falseColor: { color: 'rgb(244 134 54)' },
};

export const SubmissionsPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const CustomEditButton = ({ id, open = window.open, ...rest }) => (
  <Tooltip title="View/review submission">
    <span>
      <EditButton
        {...rest}
        to="route"
        label=""
        target="_blank"
        onClick={event => {
          event.preventDefault();
          open(`${EYE_BASE_URL}/${id}`);
        }}
      />
    </span>
  </Tooltip>
);

export const StatusField = withStyles(styles)(
  ({ classes, record, source = 'status', ...props }) => {
    const className = record[source].toLowerCase().replace(/ /g, '-');
    return (
      <ChipField
        className={classnames(classes[className])}
        record={record}
        source={source}
        style={style.chipHeight}
        {...props}
      />
    );
  },
);

export const TraxStatusField = withStyles(styles)(
  ({ classes, record, source = 'status', ...props }) => {
    if (!record[source]) {
      return null;
    }
    const className = record[source].toLowerCase();
    return (
      <ChipField
        className={classnames(classes[className])}
        record={record}
        source={source}
        style={style.chipHeight}
        {...props}
      />
    );
  },
);

export const SovTextfield = ({ record, source, label, category, width }) => {
  if (!record[source]) {
    return null;
  }
  const result = record[source].filter(res => res.includes(category));
  return (
    <p label={label} style={style.tableLocationField} width={width}>
      {result}
    </p>
  );
};

SovTextfield.propTypes = {
  record: PropTypes.shape({}).isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export const SubmissionActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  currentSort,
  total,
}) => {
  const role = localStorage.getItem('role');
  const isAdminOrSeniorReviewer = role === 'ADMIN' || role === 'SENIOR_REVIEWER';

  return (
    <CardActions style={tableStyle.actionsFixed}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isAdminOrSeniorReviewer && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          maxResults={500000}
        />
      )}
    </CardActions>
  );
};

SubmissionActions.propTypes = {
  displayedFilters: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  filterValues: PropTypes.shape({}).isRequired,
  resource: PropTypes.string.isRequired,
  showFilter: PropTypes.shape({}).isRequired,
  total: PropTypes.shape({}).isRequired,
  currentSort: PropTypes.shape({}).isRequired,
  exporter: PropTypes.shape({}).isRequired,
};

const SubmissionsList = withStyles(tableStyle)(
  ({ classes, record, filterDefaultValues, Filters, ...props }) => (
    <List
      {...props}
      filters={<Filters />}
      sort={{ field: 'acceptedAt', order: 'DESC' }}
      perPage={50}
      pagination={<SubmissionsPagination />}
      title="Submissions"
      exporter={exporter}
      filterDefaultValues={filterDefaultValues}
      actions={<SubmissionActions />}
      style={tableStyle.listHeight}
    >
      <ReactTableDatagrid record={record}>
        <CustomEditButton width={70} fixed="left" />
        <VisibilityButton width={70} fixed="left" />
        <DateField
          source="acceptedAt"
          title="Accepted at"
          showTime
          locales="en-au"
          options={{ hour12: false }}
          width={150}
          fixed="left"
          style={style.tableField}
        />
        <TextFieldWithLink
          label="Campaign"
          source="campaignName"
          sourceValue="campaignId"
          link={{ resource: 'campaigns', source: 'campaignId' }}
          style={style.tableField}
          fixed="left"
          width={300}
        />
        <TextField
          label="InAppName"
          source="inAppName"
          style={style.tableField}
          fixed="left"
          width={100}
        />
        <FunctionField
          fixed="left"
          width={80}
          label="Under Review"
          sortable={false}
          render={item => item.underReview && <FiberManualRecord style={style.falseColor} />}
        />
        <StatusField title="status" source="status" fixed="left" width={200} />
        <BooleanField label="Mission active" source="missionActive" fixed="left" />

        <UserField label="User" sourceValue="userFullName" style={style.tableField} />
        <UserEmailField label="email" source="userEmail" />
        <FunctionField label="Segments" source="segments" render={renderSegments(3)} />

        <TextField
          label="Location"
          source="missionLocationName"
          style={style.tableLocationField}
          width={400}
        />
        <TextField label="# cycle" source="cycleNumber" style={style.tableField} width={70} />
        <TraxStatusField title="Trax Status" source="traxStatus" width={100} />
        <SovTextfield
          label="HnB category status"
          category="H&B"
          source="sovCategoriesStatus"
          style={style.tableField}
          width={200}
        />
        <SovTextfield
          label="SnC category status"
          category="S&C"
          source="sovCategoriesStatus"
          style={style.tableField}
          width={200}
        />
        <SovTextfield
          label="Liquor category status"
          category="Liquor"
          source="sovCategoriesStatus"
          style={style.tableField}
          width={200}
        />
        <SovTextfield
          label="Other Categories status"
          category="Other Categories"
          source="sovCategoriesStatus"
          style={style.tableField}
          width={200}
        />
      </ReactTableDatagrid>
    </List>
  ),
);

SubmissionsList.propTypes = {
  Filters: PropTypes.node,
  filterDefaultValues: PropTypes.shape(),
  Actions: PropTypes.node,
  dataProvider: PropTypes.func.isRequired,
};
SubmissionsList.defaultProps = {
  Actions: null,
  Filters: SubmissionFilters,
  filterDefaultValues: {
    status: [
      'Admin for review',
      'Waiting For Validation',
      'Aborted - Waiting For Validation',
      'Completed Successfully',
      'Aborted - Completed',
      'Pre-Validation',
      'Upload Failed',
    ],
    campaignTypes: [
      'Industry Report',
      'Client paid',
      'Own activation',
      'Own on-boarding',
      'Brief',
      'Own training',
      'Own contest',
      'Eligibility',
      'Excellence program',
      'Share of Visibility',
      'Module',
      'Trax',
    ],
    isHiddenToClients: false,
    includes: ['CampaignName', 'MissionLocation', 'UserNames', 'MissionSegments', 'MissionActive'],
  },
};

export default SubmissionsList;
