import React, { PureComponent, Fragment } from 'react';
import {
  Loading,
  Button,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  DateInput,
  SelectInput,
  required,
  FormDataConsumer,
  AutocompleteInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import ReactCardFlip from 'react-card-flip';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ListInfo from '../listInfo/listInfo';
import { currencies, JAPANESE_BANK_ACCOUNT_TYPES, SWIFT_CODE } from '../../config/usersConfig';
import maskWithVisibleTail, {
  formatAccountNumber,
  parseDateToDOBFormat,
} from '../../../../util/stringUtil';
import SOUTH_KOREA_BANK_NAMES from '../../config/kr-bank-codes';
import TH_BANK_CODES from '../../config/th-bank-codes';
import US_BANK_ACCOUNT_TYPES from '../../config/us-bank-account-type';
import CN_BANK_ACCOUNT_TYPES from '../../config/cn-bank-account-type';
import { getValidationPatternForBankAccount, validateInput } from '../../../../util/validatorUtil';
import HONG_KONG_BANK_NAMES from '../../config/hk-bank-codes';

const styles = {
  card: {
    'margin-bottom': '10px',
  },
  historyButton: {
    textDecoration: 'underline',
    margin: '10px 15px 0px 0px',
    padding: '10px',
    borderRadius: 10,
    fontSize: '20px',
    color: 'blue',
    textTransform: 'None',
    fontFamily: 'Roboto,sans-serif',
  },
};

const JpyBankAccountFields = props => (
  <React.Fragment>
    <TextInput testId="bankCodeInput" source="bankCode" {...props} />
    <br />
    <TextInput testId="branchCode" source="branchCode" {...props} />
    <br />
    <SelectInput
      source="accountType"
      choices={JAPANESE_BANK_ACCOUNT_TYPES}
      validate={required()}
      defaultValue="CURRENT"
      testId="japaneseBankTypeSelector"
      {...props}
    />
  </React.Fragment>
);

const hkdBankAccount = props => (
  <React.Fragment>
    <AutocompleteInput
      {...props}
      source="bankCode"
      choices={HONG_KONG_BANK_NAMES}
      validate={required()}
      defaultValue="552"
      optionText="name"
      optionValue="id"
      label="Bank Code/Name"
      testId="hongKongBankCodeInput"
      shouldRenderSuggestions={val => val.trim().length > 2}
    />
  </React.Fragment>
);

const KrwBankAccountFields = props => (
  <React.Fragment>
    <TextInput testId="phoneNumber" source="phoneNumber" {...props} />
    <br />
    <TextInput testId="email" source="email" {...props} />
    <br />
    <DateInput
      testId="dateOfBirth"
      source="dateOfBirth"
      alwaysOn
      validate={required()}
      parse={parseDateToDOBFormat}
      {...props}
    />
    <br />
    <SelectInput
      source="bankCode"
      choices={SOUTH_KOREA_BANK_NAMES}
      validate={required()}
      testId="southKoreanBankNames"
      {...props}
    />
  </React.Fragment>
);

export const BankDetailsEditToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button label="Cancel" onClick={onCancel}>
      <CancelIcon />
    </Button>
  </Toolbar>
);

export const BankIdentifierInput = ({ formData, ...props }) => {
  if ((formData || {}).currency === 'AUD') {
    return <TextInput testid="bsbInput" source="bsb" {...props} />;
  }
  if ((formData || {}).currency === 'SGD') {
    return <TextInput testid="bankCodeInput" source="bankCode" {...props} />;
  }
  if ((formData || {}).currency === 'MYR') {
    return (
      <SelectInput
        source="swiftCode"
        choices={SWIFT_CODE}
        validate={required()}
        defaultValue="PHBMMYKL"
        testid="swiftCodeSelector"
        {...props}
      />
    );
  }

  if ((formData || {}).currency === 'JPY') {
    return JpyBankAccountFields(props);
  }
  if ((formData || {}).currency === 'KRW') {
    return KrwBankAccountFields(props);
  }
  if ((formData || {}).currency === 'THB') {
    return (
      <SelectInput
        source="bankCode"
        label="Bank Name"
        choices={TH_BANK_CODES}
        validate={required()}
        defaultValue="045"
        testid="bankCodeForThailand"
        {...props}
      />
    );
  }

  if ((formData || {}).currency === 'USD') {
    return (
      <div>
        <SelectInput
          source="accountType"
          label="Account Type"
          choices={US_BANK_ACCOUNT_TYPES}
          validate={required()}
          defaultValue="SAVINGS"
          testid="accountTypeForUnitedStates"
          {...props}
        />
        <br />
        <TextInput testid="routingNumber" source="abartn" {...props} />
      </div>
    );
  }

  if ((formData || {}).currency === 'CNY') {
    return (
      <div>
        <SelectInput
          source="accountType"
          label="Account Type"
          choices={CN_BANK_ACCOUNT_TYPES}
          validate={required()}
          testid="accountTypeForChina"
          defaultValue="ALIPAY"
          {...props}
        />
      </div>
    );
  }

  if ((formData || {}).currency === 'HKD') {
    console.warn('Pranav', props);
    return hkdBankAccount(props);
  }

  return null;
};

class BankDetailCard extends PureComponent {
  state = { isFlipped: false, isSourceValueMasked: true, selectedCurrency: 'AUD' };

  componentDidUpdate(prevProps) {
    const { record } = this.props;
    if (prevProps.record !== record) {
      this.setCurrencyChoice(record.currency);
    }
  }

  setCurrencyChoice(value) {
    this.setState({ selectedCurrency: value });
  }

  redirect = () => {
    this.toggleCard();
    return false;
  };

  unMaskSourceValue = () => {
    this.setState({ isSourceValueMasked: true });
  };

  maskSourceValue = () => {
    this.setState({ isSourceValueMasked: false });
  };

  handleSelectChoiceChange = selectedValue => {
    this.setState({ selectedCurrency: selectedValue });
  };

  toggleCard = () => this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));

  validateForm = value => {
    const error = {};
    const { currency, accountType } = value;

    const {
      pattern: accountNumberPattern,
      errorMessage: accountNumberErrorMessage,
    } = getValidationPatternForBankAccount(currency, 'accountNumber', accountType);

    const {
      pattern: routingNumberPattern,
      errorMessage: routingNumberErrorMessage,
    } = getValidationPatternForBankAccount(currency, 'routingNumber', accountType);

    const {
      pattern: phoneNumberPattern,
      errorMessage: phoneNumberErrorMessage,
    } = getValidationPatternForBankAccount(currency, 'phoneNumber', accountType);

    const {
      pattern: emailPattern,
      errorMessage: emailErrorMessage,
    } = getValidationPatternForBankAccount(currency, 'email', accountType);

    error.accountNumber = validateInput(
      value.accountNumber,
      accountNumberErrorMessage,
      accountNumberPattern,
    );

    error.abartn = validateInput(value.abartn, routingNumberErrorMessage, routingNumberPattern);

    error.phoneNumber = validateInput(
      value.phoneNumber,
      phoneNumberErrorMessage,
      phoneNumberPattern,
    );

    error.email = validateInput(value.email, emailErrorMessage, emailPattern);

    return error;
  };

  renderBankDetails() {
    const { record, loading, error, user } = this.props;
    if (error && error.message === `Could not find ${user.id}'s bank account`) {
      return <p>No bank details specified</p>;
    }
    if (!record) {
      return <p>No bank details specified</p>;
    }
    if (error) {
      return <p>{error.message || 'Error'}</p>;
    }
    if (loading) {
      return <Loading />;
    }

    const thailandBankCode = TH_BANK_CODES.find(bank => bank.id === record.bankCode);

    return (
      <Fragment>
        <ListInfo testid="currency" title="Currency" record={record.currency} />
        <ListInfo testid="payoutService" title="PayoutService" record={record.payoutService} />
        <ListInfo testid="accName" title="Account name" record={record.accountName} />
        {record.currency === 'AUD' && <ListInfo testid="bsb" title="BSB" record={record.bsb} />}
        {record.currency === 'SGD' && (
          <ListInfo testid="bankCode" title="BANK CODE" record={record.bankCode} />
        )}
        {record.currency === 'MYR' && (
          <ListInfo testid="swiftCode" title="SWIFT/BIC" record={record.swiftCode} />
        )}
        {record.currency !== 'MXN' ? (
          <ListInfo
            testId="accountNumber"
            title="Account number"
            record={maskWithVisibleTail(record.accountNumber, 3)}
          />
        ) : (
          <ListInfo testId="clabe" title="Clabe" record={maskWithVisibleTail(record.clabe, 3)} />
        )}
        {record.currency === 'KRW' ? (
          <div>
            <ListInfo testId="phoneNumber" title="Phone Number" record={record.phoneNumber} />
            <ListInfo testId="email" title="email" record={record.email} />
            <ListInfo testId="dateOfBirth" title="dateOfBirth" record={record.dateOfBirth} />
            <ListInfo testId="bankCode" title="Bank Code" record={record.bankCode} />
          </div>
        ) : null}
        {record.currency === 'THB' ? (
          <ListInfo
            testId="bankCodeForThailand"
            title="Bank Name"
            record={thailandBankCode ? thailandBankCode.name : ''}
          />
        ) : null}
        <div>
          {(record.currency === 'USD' || record.currency === 'CNY') && (
            <ListInfo testId="accountType" title="Account Type" record={record.accountType} />
          )}

          {record.currency === 'USD' && (
            <ListInfo testId="routingNumber" title="Routing Number" record={record.abartn} />
          )}
          {record.currency === 'HKD' && (
            <div>
              <ListInfo testId="hongKongBankCodeInput" title="Bank Code" record={record.bankCode} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  renderForm() {
    const { loading, record } = this.props;
    if (loading) {
      return <Loading />;
    }
    const Upsert = record ? Edit : Create;
    return (
      <Upsert
        {...this.props}
        title={<Fragment />}
        record={record}
        id={(record || {}).id}
        resource="bank-details"
        basePath="/bank-details"
        key="back"
        hasCreate={false}
        hasList={false}
        hasShow={false}
      >
        {this.renderReactAdminForm()}
      </Upsert>
    );
  }

  renderReactAdminForm() {
    const { user } = this.props;
    const { selectedCurrency, isSourceValueMasked } = this.state;
    return (
      <SimpleForm
        toolbar={<BankDetailsEditToolbar onCancel={this.toggleCard} />}
        defaultValue={{ userId: user.id }}
        redirect={this.redirect}
        undoable={false}
        validate={this.validateForm}
      >
        <SelectInput
          source="currency"
          choices={currencies}
          validate={required()}
          defaultValue="AUD"
          testid="currencySelector"
          onChange={(_, selectedValue) => this.handleSelectChoiceChange(selectedValue)}
        />
        <SelectInput
          source="payoutService"
          choices={[
            { id: 'PinPayment', name: 'Pin Payment' },
            { id: 'TransferWise', name: 'TransferWise' },
          ]}
          validate={required()}
          defaultValue="PinPayment"
          testid="payoutServiceSelector"
        />
        <TextInput source="accountName" validate={required()} testid="accountNameInput" />
        <FormDataConsumer>{BankIdentifierInput}</FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (selectedCurrency === 'MXN') {
              return (
                <TextInput
                  source="clabe"
                  validate={required()}
                  onFocus={this.maskSourceValue}
                  onBlur={this.unMaskSourceValue}
                  format={value =>
                    formatAccountNumber(value, selectedCurrency, isSourceValueMasked)
                  }
                  testId="clabeInput"
                  {...rest}
                />
              );
            }
            if (selectedCurrency !== 'MXN') {
              return (
                <TextInput
                  source="accountNumber"
                  onFocus={this.maskSourceValue}
                  onBlur={this.unMaskSourceValue}
                  format={value =>
                    formatAccountNumber(value, selectedCurrency, isSourceValueMasked)
                  }
                  testId="accountNumberInput"
                  {...rest}
                />
              );
            }
            return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    );
  }

  render() {
    const { record, loading, error, ...props } = this.props;
    const { isFlipped } = this.state;
    return (
      <ReactCardFlip isFlipped={isFlipped}>
        <Card {...props} style={styles.card} testid="card" key="front">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CardHeader testid="header" title="Bank details" />
            {record ? (
              <Link
                to={{
                  pathname: '/bank-details',
                  search: `?filter=${encodeURIComponent(`{"userId":["${record.userId}"]}`)}`,
                }}
                style={styles.historyButton}
                testId="showHistory"
              >
                History
              </Link>
            ) : null}
          </div>
          <CardContent testid="content">{this.renderBankDetails()}</CardContent>
          <CardActions>
            <Button testid="editButton" label="Edit" onClick={this.toggleCard}>
              <CreateIcon />
            </Button>
          </CardActions>
        </Card>
        {this.renderForm()}
      </ReactCardFlip>
    );
  }
}

BankDetailCard.propTypes = {
  record: PropTypes.shape({
    bsb: PropTypes.string,
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    clabe: PropTypes.string,
    bankCode: PropTypes.string,
    swiftCode: PropTypes.string,
    payoutService: PropTypes.string,
    accountType: PropTypes.string,
  }),
};

BankDetailCard.defaultProps = {
  record: null,
};

export default BankDetailCard;
