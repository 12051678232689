import React, { useState } from 'react';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

const styles = () =>
  createStyles({
    container: {
      margin: 0,
      width: '100%',
      backgroundColor: 'rgb(255, 244, 229)',
      height: 'auto',
      borderRadius: 10,
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
    iconButton: {
      display: 'flex',
      alignItems: 'center',
      color: 'rgb(237, 108, 2)',
      marginLeft: 10,
    },
  });

/**
 * Warning component displays a warning message with an optional close button.
 *
 * @param {object} props - The properties object.
 * @param {object} props.classes - The classes object provided by withStyles.
 * @param {node} props.children - The content to display inside the warning.
 * @param {object} props.propsStyle - Custom styles to apply.
 * @param {boolean} props.showCloseButton - Flag to show or hide the close button.
 * @param {function} [props.onHandleClose] - Optional callback when the close button is clicked.
 */
export const Warning = ({ classes, content, propsStyle, showCloseButton, onHandleClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    if (onHandleClose) onHandleClose();
  };

  if (!isVisible) return null;

  return (
    <div
      className={classnames(classes.container)}
      style={propsStyle.warningContainer}
      testId="warning"
    >
      <div className={classnames(classes.iconButton)} style={propsStyle.warningIconButton}>
        <WarningOutlined />
        {content}
      </div>

      {showCloseButton && (
        <IconButton onClick={handleClose} testId="closeButton">
          <Close />
        </IconButton>
      )}
    </div>
  );
};

Warning.propTypes = {
  classes: PropTypes.shape({}),
  propsStyle: PropTypes.shape({
    warningContainer: PropTypes.object,
    warningIconButton: PropTypes.object,
  }),
  showCloseButton: PropTypes.bool,
  onHandleClose: PropTypes.func,
  content: PropTypes.node,
};

Warning.defaultProps = {
  classes: {},
  propsStyle: {
    warningContainer: {},
    warningIconButton: {},
  },
  content: null,
  showCloseButton: true,
  onHandleClose: () => {},
};

export default withStyles(styles)(Warning);
