/**
 * Validates whether a given amount is within the allowed limit for a specified currency.
 * The function checks if the amount is a valid positive number and does not exceed the
 * maximum allowed limit for the currency as defined in `currencyLimits`. It handles
 * validation for missing or invalid amounts, missing or invalid currencies, and amounts
 * exceeding the specified limits.
 *
 * @param {string} currency - The currency code to validate the amount against.
 * @param {number|string} amount - The amount to validate. Can be a number or a string
 *                                 that can be converted to a valid number.
 * @param {Object} currencyLimits - An object mapping currency codes to their maximum
 *                                  allowed limits.
 *
 * @returns {string} An empty string if the amount is valid for the given currency based
 *                   on the provided limits. If invalid, returns an error message indicating
 *                   the nature of the validation failure: invalid or missing amount, invalid
 *                   or missing currency, or amount exceeding the limit for the currency.
 */
export function validateMaxAmountByCurrency(currency, amount, currencyLimits, aboveZero = true) {
  const amountFormatErrorMsg = 'Invalid or missing amount.';
  if (
    amount === undefined ||
    amount === null ||
    amount === '' ||
    typeof amount === 'boolean' ||
    typeof amount === 'object'
  ) {
    return amountFormatErrorMsg;
  }
  const numericAmount = Number(amount);
  if (Number.isNaN(numericAmount)) {
    return amountFormatErrorMsg;
  }
  if (aboveZero && numericAmount < 0) {
    return amountFormatErrorMsg;
  }

  if (hasMoreThanTwoDigits(numericAmount)) {
    return 'Amount must have at most 2 decimal places.';
  }

  if (!currency || !Object.prototype.hasOwnProperty.call(currencyLimits, currency)) {
    return 'Invalid or missing currency.';
  }
  const limit = currencyLimits[currency];
  if (numericAmount > limit) {
    return `For ${currency}, the amount must be equal to or less than ${limit}.`;
  }
  return '';
}

export function hasMoreThanTwoDigits(number) {
  const numberParts = number.toString().split('.');
  return numberParts.length > 1 && numberParts[1].length > 2;
}
