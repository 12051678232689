import React, { Fragment, PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  LongTextInput,
  Create,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MessageAudienceSelector from './components/messageAudienceSelector';
import { MESSAGE_TYPES } from './config/messageConfig';

const style = {
  radioButton: {
    flexDirection: 'row',
    display: 'inline-flex',
    marginRight: '1rem',
  },
};

const renderMission = mission => mission.location.name;
const renderSubmission = submission => `${submission.id} - ${submission.status}`;

const MainForm = ({ classes }) => {
  const AUDIENCE_OPTIONS = Object.freeze([
    { id: 'one', name: 'One by One' },
    { id: 'userList', name: 'User IDs' },
    { id: 'topics', name: 'Using topics' },
  ]);

  return (
    <Fragment>
      <SelectInput
        label="Type"
        source="type"
        defaultValue="PushNotification"
        validate={required('A message type must be provided.')}
        choices={MESSAGE_TYPES}
      />
      <br />
      <TextInput source="title" validate={required('A title must be provided.')} />
      <LongTextInput source="body" validate={required('A body must be provided.')} />
      <SelectInput
        label="Action"
        source="status"
        defaultValue="Sent"
        validate={required('A message status must be provided.')}
        choices={[{ id: 'Draft', name: 'Save as draft' }, { id: 'Sent', name: 'Send now' }]}
      />
      <RadioButtonGroupInput
        fullWidth
        row
        classes={{ root: classes.radioButton }}
        source="userSelection"
        defaultValue="one"
        validate={required('An audience type must be provided.')}
        choices={AUDIENCE_OPTIONS}
      />
    </Fragment>
  );
};

class MessageCreate extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Create {...this.props}>
        <SimpleForm redirect={() => '/messages'}>
          <FormDataConsumer>
            {({ formData }) => {
              const { userSelection, type, topics } = formData;

              return (
                <Fragment>
                  <MainForm classes={classes} type={type} />

                  <MessageAudienceSelector
                    userSelection={userSelection}
                    messageType={type}
                    classes={classes}
                    topics={topics}
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>

          <ReferenceArrayInput
            label="Campaigns"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            source="campaignIds"
            reference="campaigns"
            fullWidth
          >
            <AutocompleteArrayInput label="Campaigns" fullWidth />
          </ReferenceArrayInput>

          <FormDataConsumer>
            {({ formData }) =>
              formData.campaignIds && (
                <ReferenceInput
                  label="Mission"
                  sort={{ field: 'updatedAt', order: 'DESC' }}
                  source="missionId"
                  filter={{ campaignId: formData.campaignIds }}
                  reference="missions"
                  fullWidth
                  allowEmpty
                >
                  <AutocompleteInput label="Mission" fullWidth optionText={renderMission} />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.missionId) {
                return null;
              }
              if (!formData.userIds) {
                return <p>You must select one user to link a submission</p>;
              }
              if (formData.userIds.length !== 1) {
                return <p>You can only select one user to link a submission</p>;
              }
              return (
                <ReferenceInput
                  label="Submission"
                  sort={{ field: 'updatedAt', order: 'DESC' }}
                  source="submissionId"
                  filter={{
                    campaignId: formData.campaignId,
                    missionId: formData.missionId,
                    userId: formData.userIds,
                  }}
                  reference="submissionsNew"
                  fullWidth
                  allowEmpty
                >
                  <SelectInput label="Submission" fullWidth optionText={renderSubmission} />
                </ReferenceInput>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    );
  }
}

MessageCreate.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};
MessageCreate.defaultProps = {};

MainForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(style)(MessageCreate);
