export const warningStyles = {
  p: {
    fontSize: 14,
    color: 'rgb(102, 60, 0)',
    marginLeft: 10,
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
  },
};

export default {};
