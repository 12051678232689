import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionEdit from '@material-ui/icons/Edit';
import classnames from 'classnames';
import { Button } from 'react-admin';

const styles = theme =>
  createStyles({
    editButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

export const EditSegmentButtonPresenter = ({
  classes = {},
  className,
  icon,
  label = 'ra.action.edit',
  onClick,
}) => (
  <Button
    onClick={onClick}
    label={label}
    className={classnames('ra-edit-button', classes.editButton, className)}
    key="button"
  >
    {icon}
  </Button>
);

EditSegmentButtonPresenter.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

EditSegmentButtonPresenter.defaultProps = {
  classes: {},
  className: null,
  label: 'ra.action.edit',
  icon: <ActionEdit />,
};

export default withStyles(styles)(EditSegmentButtonPresenter);
