import React, { Fragment } from 'react';
import { Loading } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import ListInfo from '../listInfo/listInfo';

const styles = {
  card: {
    'margin-bottom': '10px',
  },
};

const successStyle = {
  color: '#07D10B',
};

const warningStyle = {
  color: '#FFA311',
};

const errorStyle = {
  color: '#FC3333',
};

const waitingStyle = {
  color: '#FFD411',
};

const submissionAnalyticsRendeter = (record, loading, error) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>{error.message || 'Error'}</p>;
  }
  const {
    completedSuccessfullySubmissions,
    rejectedSubmission,
    activeSubmissions,
    waitingForValidationSubmission,
    adminForReviewSubmissions,
    expiredSubmissions,
    cancelledSubmissions,
    uploadingSubmissions,
    uploadFailedSubmissions,
  } = record;

  return (
    <Fragment>
      <ListInfo
        styles={{
          label: successStyle,
        }}
        title="Submission Successful"
        record={completedSuccessfullySubmissions}
      />
      <ListInfo
        styles={{
          label: errorStyle,
        }}
        title="Submission Rejected"
        record={rejectedSubmission}
      />
      <ListInfo
        styles={{
          label: warningStyle,
        }}
        title="Submission Expired"
        record={expiredSubmissions}
      />
      <ListInfo
        styles={{
          label: warningStyle,
        }}
        title="Submission Cancelled"
        record={cancelledSubmissions}
      />
      <ListInfo
        styles={{
          label: waitingStyle,
        }}
        title="Submission In review"
        record={waitingForValidationSubmission}
      />
      <ListInfo
        styles={{
          label: waitingStyle,
        }}
        title="Submission Admin for review"
        record={adminForReviewSubmissions}
      />
      <ListInfo title="Submission Active" record={activeSubmissions} />
      <ListInfo title="Submission Uploading" record={uploadingSubmissions} />
      <ListInfo title="Submission Upload failed" record={uploadFailedSubmissions} />
    </Fragment>
  );
};

const SubmissionAnalyticsCard = ({ record, loading, error, ...props }) => (
  <Card {...props} style={styles.card} testid="card">
    <CardHeader testid="header" title="Submission analytics" />
    <CardContent testid="content">
      {submissionAnalyticsRendeter(record, loading, error)}
    </CardContent>
  </Card>
);

export default SubmissionAnalyticsCard;
