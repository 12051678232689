import React from 'react';
import PropTypes from 'prop-types';

const CompanyTitle = ({ record }) => {
  if (!record) {
    return <span />;
  }
  if (!record.name) {
    return <span>Company ${record.id}</span>;
  }
  return <span>{record.name}</span>;
};
CompanyTitle.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

CompanyTitle.defaultProps = {
  record: null,
};

export default CompanyTitle;
