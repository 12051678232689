/**
 * Filters an array of objects based on a specified property inherent to each object and an array of values.
 *
 * @param {Array} array - The array of objects to filter.
 * @param {string} property - The property to filter on.
 * @param {Array} values - The array of values to include in the filtered result.
 * @returns {Array|undefined} The filtered array or undefined if the input array is falsy.
 *
 * @example
 * const MESSAGE_TOPICS = [
 *   { id: 1, name: 'Topic A' },
 *   { id: 2, name: 'Topic B' },
 *   { id: 3, name: 'Topic C' },
 * ];
 *
 * const topicsToInclude = [1, 3];
 * const filteredTopics = filterArrayByPropertyValues(MESSAGE_TOPICS, 'id', topicsToInclude);
 * // Result: [{ id: 1, name: 'Topic A' }, { id: 3, name: 'Topic C' }]
 */
const filterArrayByPropertyValues = (array, property, values) => {
  if (!array || !values || !property) return undefined;

  return array.filter(item => values.includes(item[property]));
};

export default filterArrayByPropertyValues;
