import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import get from 'lodash.get';
import { Typography } from '@material-ui/core';
import Title from '../../../../layout/components/fields/title.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';
import ArrayField from '../../../../layout/components/fields/arrayField.presenter';
import TooltipWrapper from '../../../../components/tooltip/tooltip';
import i18n from '../../../../../i18n/i18n';

const Availability = withStyles(styles)(({ classes, record, ...props }) => {
  const { nbUsersToShow } = props;
  const moreThanExpected = get(record, 'availableToUsers', []).length > nbUsersToShow;
  return (
    <Card {...props} testid="card" className={classes.content}>
      <CardHeader testid="header" title="Availability" />
      <CardContent {...props}>
        <TooltipWrapper title="By selecting one or more users, the user can see this campaign if he is within this list. If the list is empty, all users can see this campaign">
          <Title title="Available to users" />
        </TooltipWrapper>
        <ReferenceArrayField
          label="Available to Users"
          reference="users"
          source="availableToUsers"
          record={
            moreThanExpected
              ? { ...record, availableToUsers: record.availableToUsers.slice(0, nbUsersToShow - 1) }
              : record
          }
          {...props}
        >
          <SingleFieldList linkType="show">
            <ChipField source="email" />
          </SingleFieldList>
        </ReferenceArrayField>
        {moreThanExpected && (
          <Typography variant="overline" display="block">
            and {get(record, 'availableToUsers').length - nbUsersToShow} users more
          </Typography>
        )}
        <Title title="Add eligibility rules based on previous mission" />
        <Title title="Add eligibility rules based on user profile" />

        <TooltipWrapper title={i18n('campaigns', 'availableToExperienceLevels', 'tooltip')}>
          <ArrayField
            title={i18n('campaigns', 'availableToExperienceLevels', 'label')}
            record={record.availableToExperienceLevels}
          />
        </TooltipWrapper>

        <TooltipWrapper title={i18n('campaigns', 'notAvailableToExperienceLevels', 'tooltip')}>
          <ArrayField
            title={i18n('campaigns', 'notAvailableToExperienceLevels', 'label')}
            record={record.notAvailableToExperienceLevels}
          />
        </TooltipWrapper>

        <TooltipWrapper title={i18n('campaigns', 'availableToBadges', 'tooltip')}>
          <ArrayField
            title={i18n('campaigns', 'availableToBadges', 'label')}
            record={
              record.availableToBadges ? record.availableToBadges.map(it => it.name) || [] : []
            }
          />
        </TooltipWrapper>

        <TooltipWrapper title={i18n('campaigns', 'notAvailableToBadges', 'tooltip')}>
          <ArrayField
            title={i18n('campaigns', 'notAvailableToBadges', 'label')}
            record={
              record.notAvailableToBadges
                ? record.notAvailableToBadges.map(it => it.name) || []
                : []
            }
          />
        </TooltipWrapper>
      </CardContent>
    </Card>
  );
});

Availability.propTypes = {
  record: PropTypes.shape({}),
};

Availability.defaultProps = {
  record: {
    availableToExperienceLevels: [],
  },
};

export default Availability;
