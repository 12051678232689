import React from 'react';
import PropTypes from 'prop-types';

const UserTitle = ({ record }) => {
  const { firstName, lastName, userName } = record;
  return <span>{firstName && lastName ? `${firstName} ${lastName}` : `${userName}`}&nbsp;</span>;
};

UserTitle.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UserTitle;
