import React, { Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const ChipsField = ({ source, record = {} }) => (
  <Fragment>
    {(record[source] || []).map(item => (
      <Chip label={item} key={item} />
    ))}
  </Fragment>
);

ChipsField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}),
};

ChipsField.defaultProps = {
  record: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
};
export default ChipsField;
