export const ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  REVIEWER: 'REVIEWER',
  USER: 'USER',
  TESTER: 'TESTER',
  SENIOR_REVIEWER: 'SENIOR_REVIEWER',
  FIELD: 'FIELD',
});

export const ROLES_AS_LIST = Object.values(ROLES);

export default null;
