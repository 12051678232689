import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  Show,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import ChipsField from './components/chipsField';
import CompanyTitle from './components/companyTitle';

const CompanyShow = props => (
  <Show {...props} title={<CompanyTitle />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ChipsField source="emailDomains" sortable={false} />
      <ReferenceArrayField label="Clients" reference="users" source="userIds">
        <SingleFieldList linkType="show">
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="membership" />
    </SimpleShowLayout>
  </Show>
);

export default CompanyShow;
