export const notValidURLMessage =
  'Must be a valid URL. Please make sure you are using https not http.';

export const validateURL = value => {
  if (!value) return undefined;
  // eslint-disable-next-line
  const pattern = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;
  const result = pattern.test(value);
  return result ? undefined : notValidURLMessage;
};
