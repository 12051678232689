import { isWidthUp } from '@material-ui/core/withWidth';

export const tableStyle = {
  baseline: { alignItems: 'baseline' },
  image: { height: '28px', margin: 'unset', borderRadius: '50%', width: '32px' },
  headerCell: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  cellFlexMiddle: { display: 'flex', alignItems: 'center' },
  filter: {
    flexWrap: 'nowrap',
    maxWidth: '72vw',
    overflowX: 'scroll',
    alignItems: 'flex-start',
  },
  thead: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
  tbody: {
    display: 'block !important',
    overflow: 'auto !important',
    tableLayout: 'fixed !important',

    '@media(max-width: 960px)': {
      maxHeight: '68vh',
    },
    '@media screen and (max-width: 1440px) and (max-height: 815px)': {
      maxHeight: '67vh',
    },
    '@media screen and (max-width: 1440px) and (min-height: 816px)': {
      maxHeight: '69vh',
    },
    '@media(min-width: 1441px)': {
      maxHeight: '74vh',
    },
    '@media(min-width: 1920px)': {
      maxHeight: '75vh',
    },
  },

  tbodySubmissions: {
    '@media(max-width: 960px)': {
      maxHeight: '56vh',
    },
    '@media screen and (max-width: 1440px) and (max-height: 815px)': {
      maxHeight: '59vh',
    },
    '@media screen and (max-width: 1440px) and (min-height: 816px)': {
      maxHeight: '61vh',
    },
    '@media(min-width: 1441px)': {
      maxHeight: '62vh',
    },
    '@media(min-width: 1920px)': {
      maxHeight: '69vh',
    },
  },

  tbodyMissions: {
    display: 'block !important',
    overflow: 'auto !important',
    tableLayout: 'fixed !important',

    '@media(max-width: 960px)': {
      maxHeight: '48vh',
    },
    '@media screen and (max-width: 1440px) and (max-height: 815px)': {
      maxHeight: '48vh',
    },
    '@media screen and (max-width: 1440px) and (min-height: 816px)': {
      maxHeight: '50vh',
    },
    '@media(min-width: 1441px)': {
      maxHeight: '52vh',
    },
    '@media(min-width: 1920px)': {
      maxHeight: '59vh',
    },
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
  longField: {
    whiteSpace: 'normal',
    width: '9%',
  },
  listHeight: {
    height: '100%',
  },
  row: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
  rowShow: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
    '&:hover': {
      backgroundColor: '#e8e8e8 !important',
    },
  },
  longTextField: {
    overflowX: 'hidden',
    width: '100%',
  },
  longTextFieldWithLink: {
    overflowX: 'hidden',
    width: '100%',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
  },
  actionsFixed: {
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  w1: {
    width: '1%',
  },
  w2: {
    width: '2%',
  },
  w3: {
    width: '3%',
  },
  w4: {
    width: '4%',
  },
  w5: {
    width: '5%',
  },
  w6: {
    width: '6%',
  },
  w7: {
    width: '7%',
  },
  w8: {
    width: '8%',
  },
  w9: {
    width: '9%',
  },
  w10: {
    width: '10%',
  },
  w11: {
    width: '11%',
  },
  w12: {
    width: '12%',
  },
  w13: {
    width: '13%',
  },
  w14: {
    width: '14%',
  },
  w15: {
    width: '15%',
  },
  w16: {
    width: '16%',
  },
  w17: {
    width: '17%',
  },
  w18: {
    width: '18%',
  },
  w19: {
    width: '19%',
  },
  w20: {
    width: '20%',
  },
  w21: {
    width: '21%',
  },
  w22: {
    width: '22%',
  },
  w23: {
    width: '23%',
  },
  w24: {
    width: '24%',
  },
  w25: {
    width: '25%',
  },
  w26: {
    width: '26%',
  },
  w27: {
    width: '27%',
  },
};

export const editContent = {
  content: {
    height: '83vh',
    overflowY: 'auto',
  },
};

export const postRowStyleSmallScreens = (record, index) => ({
  backgroundColor: index % 2 === 0 ? '#fff' : '#f9fbfc',
  textTransform: 'none',
});

export const postRowStyle = (record, index) => ({
  backgroundColor: index % 2 === 0 ? '#fff' : '#f9fbfc',
  textTransform: 'none',
  tableLayout: 'fixed',
  display: 'table',
  width: '100%',
});

export const getMaxCharAmountPerField = width => {
  if (isWidthUp('xl', width)) {
    return 50;
  }
  if (isWidthUp('lg', width)) {
    return 30;
  }
  return 20;
};
