import React, { PureComponent } from 'react';
import { Button, withDataProvider, UPDATE } from 'react-admin';
import PropTypes from 'prop-types';
import CloudUpload from '@material-ui/icons/CloudUpload';

export class SendButtonPresenter extends PureComponent {
  handleClick = () => {
    const { dataProvider, record } = this.props;
    const updatedRecord = { ...record, status: 'Sent' };
    dataProvider(
      UPDATE,
      'messages',
      { id: record.id, data: updatedRecord },
      {
        onSuccess: {
          notification: { body: 'Message sent', level: 'info' },
        },
        onError: {
          notification: { body: 'Error: message not sent', level: 'warning' },
        },
      },
    );
  };

  render() {
    const { record } = this.props;
    if (record.status === 'Sent') {
      return null;
    }
    return (
      <Button label="Send" onClick={this.handleClick}>
        <CloudUpload />
      </Button>
    );
  }
}

SendButtonPresenter.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withDataProvider(SendButtonPresenter);
