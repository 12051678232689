import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import { tableStyle, getMaxCharAmountPerField } from '../../layout/globalStyle/tableStyle';

export default withWidth()(({ width, record }) => {
  const field = record.location.name;
  const charAmount = getMaxCharAmountPerField(width);
  return field.trim().length > charAmount ? (
    <Tooltip title={field}>
      <p style={tableStyle.longTextField}>{field.trim().substring(0, charAmount - 3)}...</p>
    </Tooltip>
  ) : (
    <p>{field}</p>
  );
});
