const SOUTH_KOREA_BANK_NAMES = Object.freeze([
  {
    id: 'BOOKOOK_290',
    name: 'Bookook Securities (부국증권)',
  },
  {
    id: 'BUSAN_032',
    name: 'Busan Bank (부산)',
  },
  {
    id: 'CITY_027',
    name: 'Citibank Korea (한국씨티)',
  },
  {
    id: 'SAEMAEULGEUMGO_045',
    name: 'Community Credit Cooperative (새마을금고)',
  },
  {
    id: 'SINHYUB_048',
    name: 'Credit Union (신협)',
  },
  {
    id: 'DAEGU_031',
    name: 'Daegu Bank (대구)',
  },
  {
    id: 'DAEWOO_238',
    name: 'Daewoo Securities (대우증권)',
  },
  {
    id: 'DAESHIN_267',
    name: 'Daishin Securities (대신증권)',
  },
  {
    id: 'DONGBU_279',
    name: 'Dongbu Securities (동부증권)',
  },
  {
    id: 'EBEST_265',
    name: 'Ebest Investment & Securities (이베스트투자증권)',
  },
  {
    id: 'EUGENE_280',
    name: 'Eugene Investment & Securities (유진투자증권)',
  },
  {
    id: 'HI_262',
    name: 'HI Investment & Securities (하이투자증권)',
  },
  {
    id: 'HMC_263',
    name: 'HMC Investment & Securities (투자증권)',
  },
  {
    id: 'HSBC_054',
    name: 'HSBC',
  },
  {
    id: 'HANA_270',
    name: 'Hana Financial Investment (하나금융투자)',
  },
  {
    id: 'HANHWA_269',
    name: 'Hanhwa Investment & Securities (한화투자증권)',
  },
  {
    id: 'HYUNDAI_218',
    name: 'Hyundai Securities (현대증권)',
  },
  {
    id: 'IAM_268',
    name: 'Iam Investment & Securities (아이엠투자증권)',
  },
  {
    id: 'KIUP_003',
    name: 'Industrial Bank of Korea (기업)',
  },
  {
    id: 'JEJU_035',
    name: 'Jeju Bank (제주)',
  },
  {
    id: 'JEONBUK_037',
    name: 'Jeonbuk Bank (전북)',
  },
  {
    id: 'KBANK_089 ',
    name: 'KBANK (케이뱅크은행)',
  },
  {
    id: 'HANA_081',
    name: 'KEB Hana Bank (하나은행)',
  },
  {
    id: 'KAKAOBANK_090',
    name: 'Kakao Bank (카카오뱅크)',
  },
  {
    id: 'KIWOOM_264',
    name: 'Kiwoom Securities (키움증권)',
  },
  {
    id: 'KUKMIN_004',
    name: 'Kookmin Bank (국민)',
  },
  {
    id: 'SANUP_002',
    name: 'Korea Development Bank (산업)',
  },
  {
    id: 'KOREA_243',
    name: 'Korea Investment & Securities (한국투자증권)',
  },
  {
    id: 'POSTOFFICE_071',
    name: 'Korea Postbank (우체국)',
  },
  {
    id: 'KWANGJU_034',
    name: 'Kwangju Bank (광주)',
  },
  {
    id: 'KYOBO_261',
    name: 'Kyobo Securities (교보증권)',
  },
  {
    id: 'KYONGNAM_039',
    name: 'Kyongnam Bank (경남)',
  },
  {
    id: 'LIG_292',
    name: 'LIG Investment & Securities (엘아이지투자증권)',
  },
  {
    id: 'MERITZ_287',
    name: 'Meritz Securities (메리츠종합금융증권)',
  },
  {
    id: 'MIRAEASSET_230',
    name: 'Mirae Asset Securities (미래에셋증권)',
  },
  {
    id: 'NH_247',
    name: 'NH Investment & Securities (투자증권)',
  },
  {
    id: 'NONGHYUP_011',
    name: 'NongHyup Bank (농협은행)',
  },
  {
    id: 'SK_266',
    name: 'SK Securities (에스케이증권)',
  },
  {
    id: 'SAMSUNG_240',
    name: 'Samsung Securities (삼성증권)',
  },
  {
    id: 'SB_050',
    name: 'Savings Bank (상호저축은행)',
  },
  {
    id: 'SHINHAN_088',
    name: 'Shinhan Bank (신한)',
  },
  {
    id: 'SHINHAN_278',
    name: 'Shinhan Investment Corp (신한금융투자)',
  },
  {
    id: 'SHINYOUNG_291',
    name: 'Shinyoung Securities (신영증권)',
  },
  {
    id: 'SC_023',
    name: 'Standard Chartered Korea',
  },
  {
    id: 'SUHYUP_007',
    name: 'Suhyup Bank (수협)',
  },
  {
    id: 'WOORI_020',
    name: 'Woori Bank (우리)',
  },
  {
    id: 'YUANTA_209',
    name: 'Yuanta Securities Korea (유안타증권)',
  },
]);

export default SOUTH_KOREA_BANK_NAMES;
