/**
 * Masks a given value, revealing only a specified number of trailing characters.
 *
 * @param {string | number} value - The value to be masked.
 * @param {number} [numbersOfVisibleTailCharacters=3] - The number of visible tail characters. Defaults to 3.
 * @param {string} [maskedCharacter='*'] - The character used as a mask. Defaults to '*'.
 *
 * @returns {string} The masked string value.
 *
 * @example
 * // Returns "******789" with the default settings.
 * maskWithVisibleTail("123456789");
 *
 *  // Returns "******789" with the default settings.
 * maskWithVisibleTail(123456789);
 *
 * // Returns "****56789" with 5 visible tail characters.
 * maskWithVisibleTail("123456789", 5);
 *
 * // Returns "####56789" with '#' as the mask character.
 * maskWithVisibleTail("123456789", 5, '#');
 */
const maskWithVisibleTail = (value, numbersOfVisibleTailCharacters = 3, maskCharacter = '*') => {
  if (!value) return value;

  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new TypeError('value must be a string or a number');
  }

  let stringValue = '';

  if (typeof value === 'number') {
    stringValue = value.toString();
  } else {
    stringValue = value;
  }

  const sanitizedValue = stringValue.trim();

  if (!sanitizedValue) return undefined;

  if (sanitizedValue.length <= numbersOfVisibleTailCharacters) {
    return sanitizedValue;
  }

  const visiblePart = sanitizedValue.slice(-numbersOfVisibleTailCharacters);
  const maskedPart = maskCharacter.repeat(sanitizedValue.length - numbersOfVisibleTailCharacters);

  return maskedPart + visiblePart;
};

export const isValidEmail = value => {
  if (!value) {
    return 'Required';
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(value)) {
    return 'Invalid email address';
  }
  return undefined;
};

export const parseDateToDOBFormat = value => {
  if (!value) return undefined;

  let newValue = value;

  if (!(value instanceof Date)) {
    newValue = new Date(value);
  }

  const year = newValue.getFullYear();
  const month = String(newValue.getMonth() + 1).padStart(2, '0');
  const day = String(newValue.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const isValidAccountNumber = (accountNumber, currency, accountType) => {
  if (!accountNumber) return 'Required';

  const accountValidationRules = {
    THB: /^((H?\d{2})|\d{3})-?\d{1}-?\d{5}-?\d{1,7}$/,
    USD: /^\d{4,17}$/,
    CNY: { WECHAT_PAY: /^\+?([0-9]{1,3})-([0-9]{4,13})$/ },
  };

  let accountNumberRegex;
  if (currency && currency === 'CNY' && accountType) {
    if (!accountValidationRules[currency][accountType]) return undefined;
    accountNumberRegex = accountValidationRules[currency][accountType];
  } else if (currency !== 'CNY') {
    accountNumberRegex = accountValidationRules[currency];
  }

  if (accountNumberRegex && !accountNumberRegex.test(accountNumber)) {
    return 'Invalid account number';
  }

  return undefined;
};

export const formatAccountNumber = (accountNumber, currency, isSourceValueMasked) => {
  if (
    !accountNumber ||
    !currency ||
    isSourceValueMasked === undefined ||
    isSourceValueMasked === null
  )
    return '';

  if (currency === 'THB') {
    let newAccountNumber = accountNumber.replace(/[^H\d]/g, '');

    if (newAccountNumber.startsWith('H')) {
      newAccountNumber = newAccountNumber.replace(
        /^H(\d{0,2})(\d{0,1})(\d{0,5})(\d{0,7})/,
        'H$1-$2-$3-$4',
      );
    } else {
      newAccountNumber = newAccountNumber.replace(
        /^(\d{0,3})(\d{0,1})(\d{0,5})(\d{0,7})/,
        '$1-$2-$3-$4',
      );
    }

    newAccountNumber = newAccountNumber.replace(/-+$/, '');

    return isSourceValueMasked ? maskWithVisibleTail(newAccountNumber) : newAccountNumber;
  }

  return isSourceValueMasked ? maskWithVisibleTail(accountNumber) : accountNumber;
};

export const isValidRoutingNumber = routingNumber => {
  if (!routingNumber) return 'Required';

  const routingNumberRegex = /^\d{9}$/;

  if (!routingNumberRegex.test(routingNumber)) return 'Invalid routing number';

  return undefined;
};

export const isValidPhoneNumber = phoneNumber => {
  if (!phoneNumber) return 'Required';

  const phoneNumberRegex = /^\d{10,16}$/;

  if (!phoneNumberRegex.test(phoneNumber)) return 'Invalid phone number';

  return undefined;
};

export default maskWithVisibleTail;
