import React from 'react';
import {
  BooleanInput,
  NumberInput,
  TextInput,
  required,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

const style = {
  fullWidth: {
    width: '100%',
  },
};

const PHOTO_EXTRA_OPTIONS = {
  panorama: props => <BooleanInput source="panorama" {...props} defaultValue={false} />,
  minNumberOfPhotos: props => (
    <NumberInput
      label="Minimum number of photo"
      source="minNumberOfPhotos"
      {...props}
      defaultValue={1}
      validate={required('The minimum number of photo is mandatory')}
      style={style.fullWidth}
    />
  ),
  maxNumberOfPhotos: props => (
    <NumberInput
      label="Maximum number of photo"
      source="maxNumberOfPhotos"
      {...props}
      defaultValue={1}
      validate={required('The maximum number of photo is mandatory')}
      style={style.fullWidth}
    />
  ),
  photoQuality: props => (
    <SelectInput
      source="photoQuality"
      choices={[
        { id: 'Extreme', name: 'Extreme' },
        { id: 'VeryHigh', name: 'Very High' },
        { id: 'High', name: 'High' },
        { id: 'Medium', name: 'Medium' },
        { id: 'Low', name: 'Low' },
      ]}
      {...props}
      defaultValue="Medium"
      validate={required('The photo Quality is mandatory')}
      style={style.fullWidth}
    />
  ),
  photoTags: props => (
    <ArrayInput source="photoTags" {...props}>
      <SimpleFormIterator>
        <TextInput source="name" />
        <TextInput source="value" />
      </SimpleFormIterator>
    </ArrayInput>
  ),
};

const relatedPhotoCheckpoint = ({ campaignId, ...props }) => (
  <ReferenceArrayInput
    source="relatedPhotoCheckpointId"
    reference="checkpoints"
    filter={{ type: 'Photo', campaignId }}
    label="Related photo checkpoints"
    {...props}
    style={style.fullWidth}
  >
    <SelectArrayInput optionText="shortDescription" />
  </ReferenceArrayInput>
);

const checkpoints = {
  Info: { extraOptions: {} },
  YesNo: {
    extraOptions: {
      relatedPhotoCheckpoint,
    },
  },
  Photo: {
    extraOptions: PHOTO_EXTRA_OPTIONS,
  },
  CheckinWithPhoto: {
    extraOptions: PHOTO_EXTRA_OPTIONS,
  },
  Feedback: {
    extraOptions: PHOTO_EXTRA_OPTIONS,
  },
  Count: {
    extraOptions: {
      relatedPhotoCheckpoint,
    },
  },
  Text: {
    extraOptions: {
      relatedPhotoCheckpoint,
    },
  },
  Checklist: {
    extraOptions: {
      checklistOptions: props => (
        <TextInput
          source="checkListOptions"
          parse={v => (v || '').split(',')}
          format={v => (v || []).join(',')}
          validate={required('The options are mandatory')}
          {...props}
          style={style.fullWidth}
        />
      ),
      isMultiple: props => <BooleanInput source="multipleChoice" {...props} />,
      relatedPhotoCheckpoint,
    },
  },
  Location: {
    extraOptions: {},
  },
  Rating: {
    extraOptions: {
      maxValue: props => <NumberInput source="ratingMaxLimit" {...props} />,
      relatedPhotoCheckpoint,
    },
  },
  ShareOfVisibility: {
    extraOptions: {
      relatedPhotoCheckpoint,
    },
  },
};

export default checkpoints;
