import React from 'react';
// import 'typeface-roboto'; // eslint-disable-line
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { Admin, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import BarIcon from '@material-ui/icons/LocalBar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DollarIcon from '@material-ui/icons/AttachMoney';
import BuildingIcon from '@material-ui/icons/AccountBalance';
import EventIcon from '@material-ui/icons/Event';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Popup from 'react-popup';
import JssProvider from 'react-jss/lib/JssProvider';
import { ThemeProvider } from 'styled-components';

import MyLayout from './modules/layout/myLayout';
import snooperTheme from './modules/layout/themes/snooperTheme';
import oldTheme from './modules/layout/themes/aqua';
import classNameHelper from './modules/layout/classNameHelper';
import authProvider from './modules/authentication/AuthProvider';
import CustomLoginPresenter from './screens/authentication/customLogin.presenter';
import CampaignsList from './modules/campaigns/campaignsList.presenter';
import CampaignShow from './modules/campaigns/campaign-show/campaignShow.presenter';
import CampaignEdit from './modules/campaigns/campaignEdit.presenter';
import CampaignCreate from './modules/campaigns/campaignCreate.presenter';
import CheckpointCreate from './modules/campaigns/checkpoints/checkpointCreate.presenter';
import CheckpointEdit from './modules/campaigns/checkpoints/checkpointEdit.presenter';
import SubmissionsList from './modules/submissions/submissionsList/submissionsList.presenter';
import UserList from './modules/users/usersList.presenter';
import UserCreate from './modules/users/userCreate.presenter';
import UserEdit from './modules/users/userEdit.presenter';
import MissionEdit from './modules/missions/missionEdit.presenter';
import UserShow from './modules/users/userShow.presenter';
import MessageList from './modules/messages/messageList.presenter';
import MessageShow from './modules/messages/messageShow.presenter';
import MessageCreate from './modules/messages/messageCreate.presenter';
import MessageEdit from './modules/messages/messageEdit.presenter';
import UseCaseList from './modules/use-cases/useCaseList.presenter';
import UseCaseCreate from './modules/use-cases/useCaseCreate.presenter';
import UseCaseEdit from './modules/use-cases/useCaseEdit.presenter';
import CompaniesList from './modules/companies/companyList.presenter';
import CompanyShow from './modules/companies/companyShow.presenter';
import CompanyCreate from './modules/companies/companyCreate.presenter';
import CompanyEdit from './modules/companies/companyEdit.presenter';
import EventList from './modules/events/eventList.presenter';
import AnalyticsList from './modules/analytics/analyticsList.presenter';
import BankDetailList from './modules/bank-details/bankDetailList.presenter';

import dataProvider from './modules/data-provider/dataProvider';
import { SubscriptionsList } from './modules/users/components/subscriptions/subscriptionsList';
import SubscriptionCreate from './modules/users/components/subscriptions/subscriptionCreate';
import SubscriptionEdit from './modules/users/components/subscriptions/subscriptionEdit';

const { version } = require('../package.json');

Sentry.init({
  dsn: 'https://093f4865f0e9437f960cd428a6df49ed@sentry.io/1783371',
  release: version,
  environment: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL',
});

Sentry.configureScope(scope => {
  scope.setUser({ id: localStorage.getItem('clientId') });
});

ReactDOM.render(
  <JssProvider generateClassName={classNameHelper}>
    <div>
      <ThemeProvider theme={oldTheme}>
        <Admin
          appLayout={MyLayout}
          loginPage={CustomLoginPresenter}
          theme={snooperTheme}
          authProvider={authProvider}
          dataProvider={dataProvider}
        >
          {permissions => {
            const isAdminOrSeniorReviewer =
              permissions === 'ADMIN' || permissions === 'SENIOR_REVIEWER';
            return [
              isAdminOrSeniorReviewer && (
                <Resource
                  name="campaigns"
                  list={CampaignsList}
                  show={
                    permissions === 'ADMIN' || permissions === 'SENIOR_REVIEWER'
                      ? CampaignShow
                      : null
                  }
                  edit={
                    permissions === 'ADMIN' || permissions === 'SENIOR_REVIEWER'
                      ? CampaignEdit
                      : null
                  }
                  create={CampaignCreate}
                />
              ),
              <Resource
                options={{ label: 'Submissions' }}
                name="submissionsNew"
                list={SubmissionsList}
                icon={BarIcon}
              />,
              <Resource
                name="users"
                list={isAdminOrSeniorReviewer ? UserList : null}
                icon={UserIcon}
                show={isAdminOrSeniorReviewer ? UserShow : null}
                create={isAdminOrSeniorReviewer ? UserCreate : null}
                edit={isAdminOrSeniorReviewer ? UserEdit : null}
              />,
              isAdminOrSeniorReviewer && <Resource name="missions" edit={MissionEdit} />,
              isAdminOrSeniorReviewer && (
                <Resource name="checkpoints" create={CheckpointCreate} edit={CheckpointEdit} />
              ),
              isAdminOrSeniorReviewer && (
                <Resource
                  options={{ label: 'Notifications' }}
                  name="messages"
                  icon={NotificationsIcon}
                  list={MessageList}
                  show={MessageShow}
                  create={MessageCreate}
                  edit={MessageEdit}
                />
              ),
              isAdminOrSeniorReviewer && (
                <Resource name="bank-details" list={BankDetailList} icon={DollarIcon} />
              ),
              isAdminOrSeniorReviewer && (
                <Resource
                  name="companies"
                  icon={BuildingIcon}
                  list={CompaniesList}
                  show={CompanyShow}
                  create={CompanyCreate}
                  edit={CompanyEdit}
                />
              ),
              isAdminOrSeniorReviewer && (
                <Resource
                  name="use-cases"
                  icon={WorkOutlineIcon}
                  list={UseCaseList}
                  create={UseCaseCreate}
                  edit={UseCaseEdit}
                />
              ),
              isAdminOrSeniorReviewer && <Resource name="bank-account/transactions" />,
              isAdminOrSeniorReviewer && <Resource name="experience-points/transactions" />,
              isAdminOrSeniorReviewer && <Resource name="teams" />,
              isAdminOrSeniorReviewer && <Resource name="industry-reports/categories" />,
              isAdminOrSeniorReviewer && <Resource name="mission-feedback" />,
              isAdminOrSeniorReviewer && (
                <Resource name="events" icon={EventIcon} list={EventList} />
              ),
              isAdminOrSeniorReviewer && <Resource name="events/names" />,
              isAdminOrSeniorReviewer && (
                <Resource name="analytics" icon={AssessmentIcon} list={AnalyticsList} />
              ),
              isAdminOrSeniorReviewer && <Resource name="audiences" />,
              permissions === 'ADMIN' && (
                <Resource
                  name="subscriptions"
                  list={SubscriptionsList}
                  create={SubscriptionCreate}
                  edit={SubscriptionEdit}
                  icon={SubscriptionsIcon}
                />
              ),
            ];
          }}
        </Admin>
      </ThemeProvider>
      <Popup />
    </div>
  </JssProvider>,
  document.getElementById('root'),
);
