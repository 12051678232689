import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { tableStyle } from '../../layout/globalStyle/tableStyle';

const TextFieldWithLink = ({ source, record, link, style }) => (
  <a style={style} href={`#/${link.resource}/${record[link.source]}/show`}>
    {record[source]}
  </a>
);

TextFieldWithLink.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
  link: PropTypes.shape({
    resource: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}),
};

TextFieldWithLink.defaultProps = {
  style: undefined,
};

export default withStyles(tableStyle)(TextFieldWithLink);
