import React from 'react';
import { ImageField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Field from '../../../../layout/components/fields/field.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';

const style = {
  image: {
    maxHeight: 80,
    width: 'auto',
    marginRight: 10,
  },
  sneakPeek: { color: '#33475b' },
  additions: {
    color: 'grey',
    fontSize: '12px',
  },
};

const CustomImageField = ({ record }) => {
  const examples = record.industryReport.imageExamples;
  return examples
    ? examples.map(example => <img style={style.image} src={example} alt="" />)
    : null;
};

const IndustryReportSettings = withStyles(styles)(({ classes, record, ...props }) => {
  if (!record || record.campaignType !== 'Industry Report' || !record.industryReport) {
    return null;
  }
  return (
    <Card {...props} testid="card" className={classes.content}>
      <CardHeader
        testid="header"
        title="Industry report"
        subheader={
          record.industryReport.sneakPeekPdfUrl ? (
            <a
              href={record.industryReport.sneakPeekPdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={style.sneakPeek}
            >
              View Sneak Peek
            </a>
          ) : null
        }
      />
      <CardContent {...props}>
        <ImageField source="industryReport.imageUrl" record={record} basePath="campaign" />
        <CustomImageField record={record} />
        <Field title="Short description">{record.industryReport.shortDescription}</Field>
        <Field title="Price">{record.industryReport.price || 0}</Field>
        <Field title="Industries">{(record.industryReport.industries || []).join(', ')}</Field>
        <Field title="Type">{(record.industryReport.types || []).join(', ')}</Field>
        <Field title="Topics">{(record.industryReport.topics || []).join(', ')}</Field>
        <React.Fragment>
          <Typography testid="title">Tags:</Typography>
          {record.industryReport.tags ? (
            <div>
              <ul style={style.list}>
                {record.industryReport.tags.map(item => (
                  <li>
                    <Typography testid="item" style={style.additions}>
                      {item.name}: {item.value}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            '-'
          )}
        </React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: record.industryReport.description }} />
      </CardContent>
    </Card>
  );
});

export default IndustryReportSettings;
