export const campaignTypes = [
  { id: 'Industry Report', name: 'Industry Report' },
  { id: 'Client paid', name: 'Client paid' },
  { id: 'Own activation', name: 'Own activation' },
  { id: 'Own on-boarding', name: 'Own on-boarding' },
  { id: 'Own test', name: 'Own test' },
  { id: 'Brief', name: 'Brief' },
  { id: 'Own training', name: 'Own training' },
  { id: 'Own contest', name: 'Own contest' },
  { id: 'Eligibility', name: 'Eligibility' },
  { id: 'Pre-registration', name: 'Pre-registration' },
  { id: 'Excellence program', name: 'Excellence program' },
  { id: 'Share of Visibility', name: 'Share of Visibility' },
  { id: 'Module', name: 'Module' },
  { id: 'Trax', name: 'Trax' },
];

export const defaultValueForCampaignTypeSubmissionFilter = campaignTypes
  .filter(it => it.id !== 'Brief')
  .map(campaignType => campaignType.name);

export const missionTypes = [
  { id: 'Store-Check', name: 'Store Check' },
  { id: 'Mystery Shopping', name: 'Mystery Shopping' },
  { id: 'Consumer Survey', name: 'Shopper Survey' },
  { id: 'Contest', name: 'Contest' },
  { id: 'Bar Check', name: 'Bar Check' },
  { id: 'Onboarding', name: 'Onboarding' },
  { id: 'Training', name: 'Training' },
  { id: 'Pre-registration', name: 'Pre-registration' },
];

export const traxTargets = [
  { id: 'Test', name: 'Test (Gservices)' },
  { id: 'Live', name: 'Live (services)' },
];

export const storeTypes = [
  { id: 'At home', name: 'At home' },
  { id: 'Anywhere', name: 'Anywhere' },
  { id: 'Grocery National (WW & Coles)', name: 'Grocery National (WW & Coles)' },
  {
    id: 'Grocery independents (Other - IGA, etc)',
    name: 'Grocery independents (Other - IGA, etc)',
  },
  { id: 'Aldi', name: 'Aldi' },
  {
    id: 'Convenience petrol (National - Coles Express, Caltex, BP, 7Eleven))',
    name: 'Convenience petrol (National - Coles Express, Caltex, BP, 7Eleven))',
  },
  { id: 'Convenience petrol (other - Puma)', name: 'Convenience petrol (other - Puma)' },
  {
    id: 'Convenience non-petrol (National - 7Eleven, Ezy Mart, City Convenience)',
    name: 'Convenience non-petrol (National - 7Eleven, Ezy Mart, City Convenience)',
  },
  { id: 'Unbranded convenience', name: 'Unbranded convenience' },
  { id: 'Route', name: 'Route' },
  { id: 'Liquor National', name: 'Liquor National' },
  { id: 'Liquor indies', name: 'Liquor indies' },
  { id: 'Pharmacies National', name: 'Pharmacies National' },
  { id: 'Pharmacies indies', name: 'Pharmacies indies' },
  { id: 'Appliances', name: 'Appliances' },
  { id: 'Office supplies', name: 'Office supplies' },
  { id: 'Apparel', name: 'Apparel' },
  { id: 'Telco', name: 'Telco' },
  { id: 'Hardware', name: 'Hardware' },
  { id: 'On-trade restaurant', name: 'On-trade restaurant' },
  { id: 'On-trade bar', name: 'On-trade bar' },
  { id: 'Other', name: 'Other' },
];

export const tags = [{ id: 'ProductCategory:Alcohol', name: 'Alcohol' }];

export const countries = [
  { id: null, name: 'All', isoCode2Letter: '' },
  { id: 'Australia', name: 'Australia', isoCode2Letter: 'AU' },
  { id: 'HongKong', name: 'Hong Kong', isoCode2Letter: 'HK' },
  { id: 'New Zealand', name: 'New Zealand', isoCode2Letter: 'NZ' },
  { id: 'Singapore', name: 'Singapore', isoCode2Letter: 'SG' },
  { id: 'Malaysia', name: 'Malaysia', isoCode2Letter: 'MY' },
  { id: 'Mexico', name: 'Mexico', isoCode2Letter: 'MX' },
  { id: 'Japan', name: 'Japan', isoCode2Letter: 'JP' },
  { id: 'SouthKorea', name: 'South Korea', isoCode2Letter: 'KR' },
  { id: 'United States', name: 'United States', isoCode2Letter: 'US' },
  { id: 'Canada', name: 'Canada', isoCode2Letter: 'CA' },
  { id: 'Thailand', name: 'Thailand', isoCode2Letter: 'TH' },
  { id: 'Taiwan', name: 'Taiwan', isoCode2Letter: 'TW' },
];

export const STATES = [
  { id: 'ACT', name: 'ACT' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  {
    id: 'WA',
    name: 'WA',
  } /* FIXME State should NOT be abused for the country. The back-end uses "New Zealand"
     as a state. This is not a good choice at all. I have to hard-code Singapore and NZ here
     now because of the timeline for the launch in SG. I have re-factored the hard-coded
     states into this constant because it is used in several places.
     At least, this avoids code duplication.

     Proper fix is to clean up state and country everywhere. See the epic SNOOP-1704
     that I created to cover all the issues with this unfortunate tech debt. */,
  { id: 'New Zealand', name: 'New Zealand' },
  { id: 'HongKong', name: 'Hong Kong' },
  { id: 'Singapore', name: 'Singapore' },
  { id: 'MY_JHR', name: 'Johor (JHR)' },
  { id: 'MY_KDH', name: 'Kedah (KDH)' },
  { id: 'MY_KTN', name: 'Kelantan (KTN)' },
  { id: 'MY_MLK', name: 'Malacca (MLK)' },
  { id: 'MY_NSN', name: 'Negeri Sembilan (NSN)' },
  { id: 'MY_PHG', name: 'Pahang (PHG)' },
  { id: 'MY_PNG', name: 'Penang (PNG)' },
  { id: 'MY_PRK', name: 'Perak (PRK)' },
  { id: 'MY_PLS', name: 'Perlis (PLS)' },
  { id: 'MY_SBH', name: 'Sabah (SBH)' },
  { id: 'MY_SWK', name: 'Sarawak (SWK)' },
  { id: 'MY_SGR', name: 'Selangor (SGR)' },
  { id: 'MY_TRG', name: 'Terengganu (TRG)' },
  { id: 'MY_KUL', name: 'Kuala Lumpur (KUL)' },
  { id: 'MY_LBN', name: 'Labuan (LBN)' },
  { id: 'MY_PJY', name: 'Putrajaya (PJY)' },
  { id: 'MX-CMX', name: 'Ciudad de México' },
  { id: 'MX-AGU', name: 'Aguascalientes' },
  { id: 'MX-BCN', name: 'Baja California' },
  { id: 'MX-BCS', name: 'Baja California Sur' },
  { id: 'MX-CAM', name: 'Campeche' },
  { id: 'MX-COA', name: 'Coahuila de Zaragoza' },
  { id: 'MX-COL', name: 'Colima' },
  { id: 'MX-CHP', name: 'Chiapas' },
  { id: 'MX-CHH', name: 'Chihuahua' },
  { id: 'MX-DUR', name: 'Durango' },
  { id: 'MX-GUA', name: 'Guanajuato' },
  { id: 'MX-GRO', name: 'Guerrero' },
  { id: 'MX-HID', name: 'Hidalgo' },
  { id: 'MX-JAL', name: 'Jalisco' },
  { id: 'MX-MEX', name: 'México' },
  { id: 'MX-MIC', name: 'Michoacán de Ocampo' },
  { id: 'MX-MOR', name: 'Morelos' },
  { id: 'MX-NAY', name: 'Nayarit' },
  { id: 'MX-NLE', name: 'Nuevo León' },
  { id: 'MX-OAX', name: 'Oaxaca' },
  { id: 'MX-PUE', name: 'Puebla' },
  { id: 'MX-QUE', name: 'Querétaro' },
  { id: 'MX-ROO', name: 'Quintana Roo' },
  { id: 'MX-SLP', name: 'San Luis Potosí' },
  { id: 'MX-SIN', name: 'Sinaloa' },
  { id: 'MX-SON', name: 'Sonora' },
  { id: 'MX-TAB', name: 'Tabasco' },
  { id: 'MX-TAM', name: 'Tamaulipas' },
  { id: 'MX-TLA', name: 'Tlaxcala' },
  { id: 'MX-VER', name: 'Veracruz de Ignacio de la Llave' },
  { id: 'MX-YUC', name: 'Yucatán' },
  { id: 'MX-ZAC', name: 'Zacatecas' },
  /* Japan */
  { id: 'JP-01', name: 'Hokkaido' },
  { id: 'JP-02', name: 'Aomori' },
  { id: 'JP-03', name: 'Iwate' },
  { id: 'JP-04', name: 'Miyagi' },
  { id: 'JP-05', name: 'Akita' },
  { id: 'JP-06', name: 'Yamagata' },
  { id: 'JP-07', name: 'Fukushima' },
  { id: 'JP-08', name: 'Ibaraki' },
  { id: 'JP-09', name: 'Tochigi' },
  { id: 'JP-10', name: 'Gunma' },
  { id: 'JP-11', name: 'Saitama' },
  { id: 'JP-12', name: 'Chiba' },
  { id: 'JP-13', name: 'Tokyo' },
  { id: 'JP-14', name: 'Kanagawa' },
  { id: 'JP-15', name: 'Niigata' },
  { id: 'JP-16', name: 'Toyama' },
  { id: 'JP-17', name: 'Ishikawa' },
  { id: 'JP-18', name: 'Fukui' },
  { id: 'JP-19', name: 'Yamanashi' },
  { id: 'JP-20', name: 'Nagano' },
  { id: 'JP-21', name: 'Gifu' },
  { id: 'JP-22', name: 'Shizuoka' },
  { id: 'JP-23', name: 'Aichi' },
  { id: 'JP-24', name: 'Mie' },
  { id: 'JP-25', name: 'Shiga' },
  { id: 'JP-26', name: 'Kyoto' },
  { id: 'JP-27', name: 'Osaka' },
  { id: 'JP-28', name: 'Hyogo' },
  { id: 'JP-29', name: 'Nara' },
  { id: 'JP-30', name: 'Wakayama' },
  { id: 'JP-31', name: 'Tottori' },
  { id: 'JP-32', name: 'Shimane' },
  { id: 'JP-33', name: 'Okayama' },
  { id: 'JP-34', name: 'Hiroshima' },
  { id: 'JP-35', name: 'Yamaguchi' },
  { id: 'JP-36', name: 'Tokushima' },
  { id: 'JP-37', name: 'Kagawa' },
  { id: 'JP-38', name: 'Ehime' },
  { id: 'JP-39', name: 'Kochi' },
  { id: 'JP-40', name: 'Fukuoka' },
  { id: 'JP-41', name: 'Saga' },
  { id: 'JP-42', name: 'Nagasaki' },
  { id: 'JP-43', name: 'Kumamoto' },
  { id: 'JP-44', name: 'Oita' },
  { id: 'JP-45', name: 'Miyazaki' },
  { id: 'JP-46', name: 'Kagoshima' },
  { id: 'JP-47', name: 'Okinawa' },
  /* South Korea */
  { id: 'KR-11', name: 'Seoul' },
  { id: 'KR-26', name: 'Busan' },
  { id: 'KR-27', name: 'Daegu' },
  { id: 'KR-28', name: 'Incheon' },
  { id: 'KR-29', name: 'Gwangju' },
  { id: 'KR-30', name: 'Daejeon' },
  { id: 'KR-31', name: 'Ulsan' },
  { id: 'KR-41', name: 'Gyeonggi' },
  { id: 'KR-42', name: 'Gangwon' },
  { id: 'KR-43', name: 'North Chungcheong' },
  { id: 'KR-44', name: 'South Chungcheong' },
  { id: 'KR-45', name: 'North Jeolla' },
  { id: 'KR-46', name: 'South Jeolla' },
  { id: 'KR-47', name: 'North Gyeongsang' },
  { id: 'KR-48', name: 'South Gyeongsang' },
  { id: 'KR-49', name: 'Jeju' },
  { id: 'KR-50', name: 'Sejong' },
  /* Canada */
  { id: 'CA-AB', name: 'Alberta' },
  { id: 'CA-BC', name: 'British Columbia' },
  { id: 'CA-MB', name: 'Manitoba' },
  { id: 'CA-NB', name: 'New Brunswick' },
  { id: 'CA-NL', name: 'Newfoundland and Labrador' },
  { id: 'CA-NS', name: 'Nova Scotia' },
  { id: 'CA-ON', name: 'Ontario' },
  { id: 'CA-PE', name: 'Prince Edward Island' },
  { id: 'CA-QC', name: 'Quebec' },
  { id: 'CA-SK', name: 'Saskatchewan' },
  { id: 'CA-NT', name: 'Northwest Territories' },
  { id: 'CA-NU', name: 'Nunavut' },
  { id: 'CA-YT', name: 'Yukon' },
  /* United States */
  { id: 'US-AL', name: 'Alabama' },
  { id: 'US-AK', name: 'Alaska' },
  { id: 'US-AZ', name: 'Arizona' },
  { id: 'US-AR', name: 'Arkansas' },
  { id: 'US-CA', name: 'California' },
  { id: 'US-CO', name: 'Colorado' },
  { id: 'US-CT', name: 'Connecticut' },
  { id: 'US-DE', name: 'Delaware' },
  { id: 'US-FL', name: 'Florida' },
  { id: 'US-GA', name: 'Georgia' },
  { id: 'US-HI', name: 'Hawaii' },
  { id: 'US-ID', name: 'Idaho' },
  { id: 'US-IL', name: 'Illinois' },
  { id: 'US-IN', name: 'Indiana' },
  { id: 'US-IA', name: 'Iowa' },
  { id: 'US-KS', name: 'Kansas' },
  { id: 'US-KY', name: 'Kentucky' },
  { id: 'US-LA', name: 'Louisiana' },
  { id: 'US-ME', name: 'Maine' },
  { id: 'US-MD', name: 'Maryland' },
  { id: 'US-MA', name: 'Massachusetts' },
  { id: 'US-MI', name: 'Michigan' },
  { id: 'US-MN', name: 'Minnesota' },
  { id: 'US-MS', name: 'Mississippi' },
  { id: 'US-MO', name: 'Missouri' },
  { id: 'US-MT', name: 'Montana' },
  { id: 'US-NE', name: 'Nebraska' },
  { id: 'US-NV', name: 'Nevada' },
  { id: 'US-NH', name: 'New Hampshire' },
  { id: 'US-NJ', name: 'New Jersey' },
  { id: 'US-NM', name: 'New Mexico' },
  { id: 'US-NY', name: 'New York' },
  { id: 'US-NC', name: 'North Carolina' },
  { id: 'US-ND', name: 'North Dakota' },
  { id: 'US-OH', name: 'Ohio' },
  { id: 'US-OK', name: 'Oklahoma' },
  { id: 'US-OR', name: 'Oregon' },
  { id: 'US-PA', name: 'Pennsylvania' },
  { id: 'US-RI', name: 'Rhode Island' },
  { id: 'US-SC', name: 'South Carolina' },
  { id: 'US-SD', name: 'South Dakota' },
  { id: 'US-TN', name: 'Tennessee' },
  { id: 'US-TX', name: 'Texas' },
  { id: 'US-UT', name: 'Utah' },
  { id: 'US-VT', name: 'Vermont' },
  { id: 'US-VA', name: 'Virginia' },
  { id: 'US-WA', name: 'Washington' },
  { id: 'US-WV', name: 'West Virginia' },
  { id: 'US-WI', name: 'Wisconsin' },
  { id: 'US-WY', name: 'Wyoming' },
  { id: 'US-DC', name: 'District of Columbia' },
  { id: 'US-AS', name: 'American Samoa' },
  { id: 'US-GU', name: 'Guam' },
  { id: 'US-MP', name: 'Northern Mariana Islands' },
  { id: 'US-PR', name: 'Puerto Rico' },
  { id: 'US-UM', name: 'United States Minor Outlying Islands' },
  { id: 'US-VI', name: 'Virgin Islands' },
  /* Thailand */
  { id: 'TH-10', name: 'Krung Thep Maha Nakhon' },
  { id: 'TH-11', name: 'Samut Prakan' },
  { id: 'TH-12', name: 'Nonthaburi' },
  { id: 'TH-13', name: 'Pathum Thani' },
  { id: 'TH-14', name: 'Phra Nakhon Si Ayutthaya' },
  { id: 'TH-15', name: 'Ang Thong' },
  { id: 'TH-16', name: 'Lopburi' },
  { id: 'TH-17', name: 'Sing Buri' },
  { id: 'TH-18', name: 'Chai Nat' },
  { id: 'TH-19', name: 'Saraburi' },
  { id: 'TH-20', name: 'Chonburi' },
  { id: 'TH-21', name: 'Rayong' },
  { id: 'TH-22', name: 'Chanthaburi' },
  { id: 'TH-23', name: 'Trat' },
  { id: 'TH-24', name: 'Chachoengsao' },
  { id: 'TH-25', name: 'Prachin Buri' },
  { id: 'TH-26', name: 'Nakhon Nayok' },
  { id: 'TH-27', name: 'Sa Kaeo' },
  { id: 'TH-30', name: 'Nakhon Ratchasima' },
  { id: 'TH-31', name: 'Buri Ram' },
  { id: 'TH-32', name: 'Surin' },
  { id: 'TH-33', name: 'Si Sa Ket' },
  { id: 'TH-34', name: 'Ubon Ratchathani' },
  { id: 'TH-35', name: 'Yasothon' },
  { id: 'TH-36', name: 'Chaiyaphum' },
  { id: 'TH-37', name: 'Amnat Charoen' },
  { id: 'TH-38', name: 'Bueng Kan' },
  { id: 'TH-39', name: 'Nong Bua Lam Phu' },
  { id: 'TH-40', name: 'Khon Kaen' },
  { id: 'TH-41', name: 'Udon Thani' },
  { id: 'TH-42', name: 'Loei' },
  { id: 'TH-43', name: 'Nong Khai' },
  { id: 'TH-44', name: 'Maha Sarakham' },
  { id: 'TH-45', name: 'Roi Et' },
  { id: 'TH-46', name: 'Kalasin' },
  { id: 'TH-47', name: 'Sakon Nakhon' },
  { id: 'TH-48', name: 'Nakhon Phanom' },
  { id: 'TH-49', name: 'Mukdahan' },
  { id: 'TH-50', name: 'Chiang Mai' },
  { id: 'TH-51', name: 'Lamphun' },
  { id: 'TH-52', name: 'Lampang' },
  { id: 'TH-53', name: 'Uttaradit' },
  { id: 'TH-54', name: 'Phrae' },
  { id: 'TH-55', name: 'Nan' },
  { id: 'TH-56', name: 'Phayao' },
  { id: 'TH-57', name: 'Chiang Rai' },
  { id: 'TH-58', name: 'Mae Hong Son' },
  { id: 'TH-60', name: 'Nakhon Sawan' },
  { id: 'TH-61', name: 'Uthai Thani' },
  { id: 'TH-62', name: 'Kamphaeng Phet' },
  { id: 'TH-63', name: 'Tak' },
  { id: 'TH-64', name: 'Sukhothai' },
  { id: 'TH-65', name: 'Phitsanulok' },
  { id: 'TH-66', name: 'Phichit' },
  { id: 'TH-67', name: 'Phetchabun' },
  { id: 'TH-70', name: 'Ratchaburi' },
  { id: 'TH-71', name: 'Kanchanaburi' },
  { id: 'TH-72', name: 'Suphan Buri' },
  { id: 'TH-73', name: 'Nakhon Pathom' },
  { id: 'TH-74', name: 'Samut Sakhon' },
  { id: 'TH-75', name: 'Samut Songkhram' },
  { id: 'TH-76', name: 'Phetchaburi' },
  { id: 'TH-77', name: 'Prachuap Khiri Khan' },
  { id: 'TH-80', name: 'Nakhon Si Thammarat' },
  { id: 'TH-81', name: 'Krabi' },
  { id: 'TH-82', name: 'Phangnga' },
  { id: 'TH-83', name: 'Phuket' },
  { id: 'TH-84', name: 'Surat Thani' },
  { id: 'TH-85', name: 'Ranong' },
  { id: 'TH-86', name: 'Chumphon' },
  { id: 'TH-90', name: 'Songkhla' },
  { id: 'TH-91', name: 'Satun' },
  { id: 'TH-92', name: 'Trang' },
  { id: 'TH-93', name: 'Phatthalung' },
  { id: 'TH-94', name: 'Pattani' },
  { id: 'TH-95', name: 'Yala' },
  { id: 'TH-96', name: 'Narathiwat' },
  { id: 'TH-S', name: 'Pattaya' },
  /* Taiwan */
  { id: 'TW-KHH', name: 'Kaohsiung' },
  { id: 'TW-TPE', name: 'Taipei' },
  { id: 'TW-TXG', name: 'Taichung' },
  { id: 'TW-TNN', name: 'Tainan' },
  { id: 'TW-KIN', name: 'Kinmen' },
  { id: 'TW-LIE', name: 'Lienchiang' },
  { id: 'TW-CHA', name: 'Changhua' },
  { id: 'TW-HUA', name: 'Hualien' },
  { id: 'TW-ILA', name: 'Yilan' },
  { id: 'TW-MIA', name: 'Miaoli' },
  { id: 'TW-NAN', name: 'Nantou' },
  { id: 'TW-PEN', name: 'Penghu' },
  { id: 'TW-PIF', name: 'Pingtung' },
  { id: 'TW-TTT', name: 'Taitung' },
  { id: 'TW-NWT', name: 'New Taipei' },
  { id: 'TW-YUN', name: 'Yunlin' },
  { id: 'TW-CYI', name: 'Chiayi City' },
  { id: 'TW-HSZ', name: 'Hsinchu City' },
  { id: 'TW-KEE', name: 'Keelung' },
  { id: 'TW-TAO', name: 'Taoyuan' },
  { id: 'TW-CYQ', name: 'Chiayi County' },
  { id: 'TW-HSQ', name: 'Hsinchu County' },
];

export const cycles = [
  { id: null, name: 'None' },
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Fortnightly', name: 'Fortnightly' },
  { id: 'Monthly', name: 'Monthly' },
  { id: 'Quarterly', name: 'Quarterly' },
];

export const topics = [
  `Out of stocks`,
  `Promo Visibility`,
  `Perfect store`,
  `Pricing`,
  `Availability`,
  `Distribution`,
  `Gondola`,
  `Off-location`,
];

export const industries = [
  `Chocolate`,
  `Beverages`,
  `Milk & Dairy`,
  `Confectionery`,
  `Telco`,
  `Alcohol & Spirits`,
  `Grocery`,
  `General`,
];

export const types = [
  `In-store execution`,
  `In-store shopper insights`,
  `At-home shopper insights`,
  `Execution + shopper insights`,
  `Mystery shopper`,
];

export const rewardCurrencies = Object.freeze([
  Object.freeze({ id: 'AUD', name: 'AUD' }),
  Object.freeze({ id: 'HKD', name: 'HKD' }),
  Object.freeze({ id: 'NZD', name: 'NZD' }),
  Object.freeze({ id: 'SGD', name: 'SGD' }),
  Object.freeze({ id: 'MYR', name: 'MYR' }),
  Object.freeze({ id: 'MXN', name: 'MXN' }),
  Object.freeze({ id: 'JPY', name: 'JPY' }),
  Object.freeze({ id: 'KRW', name: 'KRW' }),
  Object.freeze({ id: 'THB', name: 'THB' }),
  Object.freeze({ id: 'TWD', name: 'TWD' }),
]);

export const countryCurrency = Object.freeze({
  Australia: 'AUD',
  HongKong: 'HKD',
  'New Zealand': 'NZD',
  Singapore: 'SGD',
  Malaysia: 'MYR',
  Mexico: 'MXN',
  Japan: 'JPY',
  SouthKorea: 'KRW',
  Thailand: 'THB',
  Taiwan: 'TWD',
});

export const getCurrencyByCountry = country => countryCurrency[country] || 'AUD';

/**
 * The value will be in the shape of {0: 'A', 1:'u', 2: 's', 3:'t', ... f: function }
 * This function will extract all letter and return the complete string value, so the
 * object above will have string 'Aust' as the returned value.
 * @param {Object} value
 * @returns String
 */
export const deserializeValue = value => {
  const objectValuesArray = Object.values(value || {}).filter(v => typeof v === 'string');
  return objectValuesArray.join('');
};
