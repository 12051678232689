import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { renderTeam } from '../services/render-helper';

const TeamChipField = ({ record = {}, isClickable = true }) => {
  const chip = <Chip label={renderTeam(record)} key={record.id} />;
  if (!isClickable) {
    return chip;
  }
  return <Link to={`/companies/${record.companyId}/show`}>{chip}</Link>;
};

TeamChipField.propTypes = {
  record: PropTypes.shape({}),
  isClickable: PropTypes.bool,
};

TeamChipField.defaultProps = {
  record: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
  isClickable: true,
};
export default TeamChipField;
