import React, { Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const AliasChipsField = ({ source, record, aliases }) => {
  const values = [].concat(record ? record[source] || [] : []);

  return (
    <Fragment>
      {values.map(item => {
        const value = aliases[item] || item;

        return <Chip label={value} key={value} />;
      })}
    </Fragment>
  );
};

AliasChipsField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
  aliases: PropTypes.shape({}),
};

AliasChipsField.defaultProps = {
  aliases: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
};

export default AliasChipsField;
