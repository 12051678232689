import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Datagrid, withDataProvider, GET_ONE, UPDATE, showNotification, Button } from 'react-admin';
import { withStyles } from '@material-ui/core';
import SaveButton from '@material-ui/icons/Save';
import SortableDatagridBody from '../../layout/components/sortableDatagridBody.presenter';

const SaveNewOrderCheckpointsButton = ({ classes, onClick }) => (
  <Button
    classes={{
      button: classes.button,
    }}
    label="Save new order"
    onClick={onClick}
  >
    <SaveButton />
  </Button>
);

SaveNewOrderCheckpointsButton.propTypes = {
  classes: PropTypes.shape({}),
  onClick: PropTypes.func,
};

SaveNewOrderCheckpointsButton.defaultProps = {
  onClick: null,
  classes: null,
};

const saveNewOrderCheckpointsButtonStyle = theme => ({
  button: { float: 'left', marginTop: [theme.spacing.unit, '!important'] },
});

const SaveNewOrderCheckpointsButtonStyled = withStyles(saveNewOrderCheckpointsButtonStyle)(
  SaveNewOrderCheckpointsButton,
);

const styles = {
  thead: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
    marginLeft: '0.5em !important',
  },
  tbody: {
    display: 'block !important',
    overflow: 'auto !important',
    tableLayout: 'fixed !important',

    '@media(max-width: 960px)': {
      maxHeight: '68vh',
    },
    '@media screen and (max-width: 1440px) and (max-height: 815px)': {
      maxHeight: '67vh',
    },
    '@media screen and (max-width: 1440px) and (min-height: 816px)': {
      maxHeight: '69vh',
    },
    '@media(min-width: 1441px)': {
      maxHeight: '74vh',
    },
    '@media(min-width: 1920px)': {
      maxHeight: '75vh',
    },
  },
  row: {
    display: 'table !important',
    width: '100% !important',
    tableLayout: 'fixed !important',
  },
};

const postRowStyle = (record, index) => ({
  backgroundColor: index % 2 === 0 ? '#fff' : '#f9fbfc',
  textTransform: 'none',
  tableLayout: 'fixed',
  display: 'table',
  width: '100%',
});

class CheckpointsListSortableForCampaign extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { changed: false, checkpointsId: [], campaignId: props.resourceId };
  }

  saveNewOrder = async () => {
    const { checkpointsId, campaignId } = this.state;
    const { dataProvider, dispatch } = this.props;
    const { data: campaign } = await dataProvider(GET_ONE, 'campaigns', { id: campaignId });
    // render checkpoints
    const updatedCampaign = {
      ...campaign,
      checkpoints: checkpointsId.map(id =>
        campaign.checkpoints.find(checkpoint => checkpoint.id === id),
      ),
    };
    try {
      await dataProvider(
        UPDATE,
        'campaigns',
        { id: campaignId, data: updatedCampaign },
        {
          onSuccess: {},
          onFailure: {
            notification: {
              body: `Checkpoint order update failed`,
              level: 'error',
            },
            refresh: true,
          },
        },
      );
    } catch (ex) {
      dispatch(showNotification(`Checkpoint update failed: ${ex.message}`, 'error'));
    }
    this.setState({ changed: false });
  };

  updateCampaign = (checkpointsId, campaignId) => {
    this.setState({ checkpointsId, campaignId, changed: true });
  };

  renderingSortableButton() {
    const { changed } = this.state;
    if (!changed) {
      return null;
    }
    return <SaveNewOrderCheckpointsButtonStyled onClick={this.saveNewOrder} />;
  }

  render() {
    const { id: campaignId, classes } = this.props;
    return (
      <div>
        {this.renderingSortableButton()}
        <Datagrid
          classes={{
            thead: classes.thead,
            tbody: classes.tbody,
            row: classes.row,
          }}
          rowStyle={postRowStyle}
          body={
            <SortableDatagridBody updateResource={this.updateCampaign} resourceId={campaignId} />
          }
          {...this.props}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withDataProvider(CheckpointsListSortableForCampaign));
