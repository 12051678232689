import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './fieldSharedStyle';

const TextField = ({ title }) => (
  <Typography style={styles.title} testid="title">
    {title}:
  </Typography>
);

TextField.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TextField;
