import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Button, withDataProvider, UPDATE } from 'react-admin';
import VisibleIcon from '@material-ui/icons/Visibility';
import VisibleOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';

export const sanitizeRestProps = ({
  basePath,
  classes,
  dispatchCrudDelete,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  undoable,
  redirect,
  submitOnEnter,
  ...rest
}) => rest;

export class VisibilityButtonPresenter extends Component {
  onToggleVisibility = event => {
    event.stopPropagation();
    const { record, dataProvider } = this.props;
    const newVisibility = this.getNewState();
    const visibilityString = newVisibility ? 'hidden' : 'visible';

    dataProvider(
      UPDATE,
      'submissionClientVisibility',
      { id: record.id, data: newVisibility },
      {
        refresh: true,
        onSuccess: {
          notification: { body: `Submission is now ${visibilityString}`, level: 'info' },
        },
        onError: {
          notification: {
            body: `Error: submission was not setup as ${visibilityString}`,
            level: 'warning',
          },
        },
      },
    );
  };

  getNewState = () => !this.getState();

  getState = () => {
    const { record } = this.props || {};
    if (record.hiddenToClients == null) {
      return null;
    }
    return record.hiddenToClients;
  };

  renderContent = () => {
    const hiddenToClients = this.getState();
    if (hiddenToClients == null) {
      return null;
    }
    const action = hiddenToClients
      ? "Show submission in client's data"
      : "Hide submission in client's data";
    const icon = hiddenToClients ? <VisibleOffIcon color="error" /> : <VisibleIcon />;
    return <Tooltip title={action}>{icon}</Tooltip>;
  };

  render() {
    const { icon, record, ...rest } = this.props;
    if (!record || record.hiddenToClients == null) {
      return null;
    }

    return (
      <Button
        onClick={this.onToggleVisibility}
        label={null}
        key="button"
        {...sanitizeRestProps(rest)}
      >
        {this.renderContent()}
      </Button>
    );
  }
}

VisibilityButtonPresenter.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.shape({}),
  resource: PropTypes.string.isRequired,
  dataProvider: PropTypes.func.isRequired,
};

VisibilityButtonPresenter.defaultProps = {
  basePath: null,
  record: null,
};

export default compose(withDataProvider)(VisibilityButtonPresenter);
