import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import ClientSettings from './clientSettings.presenter';
import OperationsSettings from './operationsSettings.presenter';
import Location from './location.presenter';
import CampaignSchedule from './campaignSchedule.presenter';
import MissionOverview from './missionOverview.presenter';
import FeaturedDescription from './featuredDescription.presenter';
import MissionSettings from './missionSettings.presenter';
import CompletionRules from './completionRules.presenter';
import Availability from './availability.presenter';
import IndustryReportSettings from './industryReportSettings.presenter';
import BudgetPresenter from './budget.presenter';
import Trax from './trax.presenter';

const editStyles = {
  root: { display: 'flex', alignItems: 'flex-start', width: '100%', marginTop: 30 },
  form: { flexGrow: 9 },
  width: { width: '100%' },
};

const CampaignSettings = withStyles(editStyles)(({ children, classes, ...props }) => (
  <div className={classes.root} {...props}>
    <div className={classes.form}>
      <Grid container direction="row" justify="space-between" spacing={24}>
        <Grid item md={6}>
          <Grid container spacing={24} direction="column">
            <Grid item md={12} style={editStyles.width}>
              <ClientSettings {...props} testid="testid" />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <OperationsSettings {...props} testid="operationsSettings" />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <CampaignSchedule {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <MissionSettings {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <CompletionRules {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <Availability nbUsersToShow={25} {...props} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column" spacing={24}>
            <Grid item md={12} style={editStyles.width}>
              <Location {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <MissionOverview {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <FeaturedDescription {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <IndustryReportSettings {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <BudgetPresenter {...props} />
            </Grid>
            <Grid item md={12} style={editStyles.width}>
              <Trax {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  </div>
));

export default CampaignSettings;
