import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
  chipRoot: {
    margin: 4,
    color: 'rgba(0,0,0,0.87',
    fontSize: '12px',
    height: 23,
  },
  content: {
    width: '100%',
    whiteSpace: 'no-wrap',
  },
};

export const renderSegments = max => mission => (
  <div style={styles.content}>
    {(mission.segments || []).slice(0, max).map(segment => (
      <Chip label={segment.value} key={segment.value} style={styles.chipRoot} />
    ))}
  </div>
);

export default undefined;
