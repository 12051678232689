import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Settings';
import classnames from 'classnames';

import { Button, withDataProvider, CREATE } from 'react-admin';

const styles = theme =>
  createStyles({
    button: {
      float: 'right',
    },
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 60,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000,
    },
    floatingLink: {
      color: 'inherit',
    },
  });

export class ApplySegmentsRulesButtonPresenter extends PureComponent {
  onClick = async () => {
    const { campaignId, dataProvider } = this.props;
    await dataProvider(
      CREATE,
      `campaigns/${campaignId}/segmentsRules/apply`,
      {},
      {
        onSuccess: { refresh: true },
        onFailure: {
          notification: {
            body: `Could not apply segment rules`,
            level: 'warning',
          },
        },
      },
    );
  };

  render() {
    const {
      className,
      classes = {},
      label = "Apply segment's rules",
      icon = <ContentAdd />,
    } = this.props;
    return (
      <Button
        onClick={this.onClick}
        label={label}
        className={classnames(classes.button, className)}
        key="button"
      >
        {icon}
      </Button>
    );
  }
}

ApplySegmentsRulesButtonPresenter.propTypes = {
  campaignId: PropTypes.string.isRequired,
  classes: PropTypes.shape(),
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  dataProvider: PropTypes.func.isRequired,
};

ApplySegmentsRulesButtonPresenter.defaultProps = {
  classes: {},
  className: null,
  label: "Apply segment's rules",
  icon: <ContentAdd />,
};

export default withDataProvider(withStyles(styles)(ApplySegmentsRulesButtonPresenter));
