import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { ColorField as ReactAdminColorField } from 'react-admin-color-input';
import Title from './title.presenter';
import styles from './fieldSharedStyle';

const renderingContent = (record, source, defaultValue) => {
  if (get(record, source)) {
    return <ReactAdminColorField source={source} record={record} testid="record" />;
  }
  return (
    <Typography style={styles.record} testid="record">
      {defaultValue}
    </Typography>
  );
};

const ColorField = ({ title, record, source, defaultValue }) => (
  <React.Fragment>
    <Title title={title} />
    {renderingContent(record, source, defaultValue)}
  </React.Fragment>
);

ColorField.propTypes = {
  title: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
  source: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

ColorField.defaultProps = {
  defaultValue: 'No color picked',
};

export default ColorField;
