import React from 'react';
import { ReferenceManyField, Datagrid, TextField, NumberField, DateField } from 'react-admin';
import PropTypes from 'prop-types';
import Pagination from './pagination';

const ExperiencePointHistory = ({ ...props }) => {
  const { record } = props;
  return (
    <ReferenceManyField
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={10}
      reference="experience-points/transactions"
      filter={{ userId: record.id }}
      target="userId"
    >
      <Datagrid>
        <DateField source="createdAt" sortable={false} />
        <TextField source="message" sortable={false} />
        <NumberField source="amount" sortable={false} />
        <NumberField source="balanceAfterTransaction" sortable={false} />
      </Datagrid>
    </ReferenceManyField>
  );
};

ExperiencePointHistory.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default ExperiencePointHistory;
