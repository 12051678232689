import React from 'react';
import { ChipField } from 'react-admin';

/**
 * This is an extention to the ChipField component where it
 * will not render when there is no record.
 */
const NullableChipField = ({ record, source, ...props }) => {
  if (record && record[source]) {
    return <ChipField {...{ record, source, ...props }} />;
  }
  return null;
};

export default NullableChipField;
