import React from 'react';
import PropTypes from 'prop-types';

const MessageTitle = ({ record }) => {
  if (!record) {
    return null;
  }
  return <span>{record.title}</span>;
};
MessageTitle.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

MessageTitle.defaultProps = {
  record: null,
};

export default MessageTitle;
