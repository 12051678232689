import React, { Fragment, PureComponent } from 'react';
// import { change } from 'redux-form';
import {
  TextInput,
  FormDataConsumer,
  SelectInput,
  required,
  BooleanInput,
  // REDUX_FORM_NAME,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import { parse } from 'query-string';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
// import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import checkpoints from '../../services/checkpoints-type';
import RichTextInput from '../../../../layout/components/richTextInput/richTextInput.presenter';
import cardStyle from '../../../../layout/globalStyle/cardStyle';
import sanitizeRedundantWhitespace from '../../../../components/utils/sanitizeWhitespace/sanitizeRedundantWhitespace';
// eslint-disable-next-line import/no-named-as-default
import Warning from '../../../../components/alert/warning';
import { PGADMIN_URL, WISER_PGADMIN_VIEW_WIKI_URL } from '../../../../../setup/config';
import { warningStyles } from '../../../../layout/globalStyle/alertStyle';

const WarningText = () => (
  <p style={warningStyles.p}>
    You are making changes to the campaign checkpoints. You should update all PgAdmin Views using
    this campaign. Go to{' '}
    <a href={PGADMIN_URL} target="_blank" rel="noopener noreferrer">
      PgAdmin
    </a>{' '}
    to see all existing Views or learn more{' '}
    <a href={WISER_PGADMIN_VIEW_WIKI_URL} target="_blank" rel="noopener noreferrer">
      here
    </a>
    .
  </p>
);

class CheckpointForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShortDescriptionChanged: false,
    };
  }

  getCampaignId = () => {
    const { location } = this.props;
    const { campaignId } = parse(location.search) || { campaignId: '' };
    return campaignId;
  };

  renderTypeInput = () => (
    <SelectInput
      source="checkpointType"
      /** We don't need YesNo checkpoint type at this moment when creating/editing checkpoints,
       * so I add a filter to filter out YesNo type, I don't delete the original line of code
       * just in case we want YesNo again in the future
       */

      // choices={Object.keys(checkpoints).map(name => ({ id: name, name }))}
      choices={Object.keys(checkpoints)
        .filter(name => name !== 'YesNo')
        .map(name => ({ id: name, name }))}
      validate={required('The type is mandatory')}
    />
  );

  renderTraxInput = () => <TextInput source="traxTaskId" label="Task Id" resettable />;

  renderQuestionInput = () => <TextInput source="question" fullWidth />;

  returnShortDescriptionInput = () => (
    <TextInput
      source="shortDescription"
      validate={required('The short description is mandatory')}
      testId="shortDescription"
      fullWidth
      parse={sanitizeRedundantWhitespace}
      onChange={event => {
        const { record } = this.props;

        if (record.shortDescription && record.shortDescription !== event.target.value) {
          this.setState({ isShortDescriptionChanged: true });
          return;
        }

        this.setState({ isShortDescriptionChanged: false });
      }}
    />
  );

  renderSpecificInput = ({ formData, ...rest }) => {
    if (!formData || !formData.checkpointType) {
      return null;
    }
    const campaignId = this.getCampaignId();
    const extra = checkpoints[formData.checkpointType].extraOptions;
    return (
      <Fragment>
        {Object.keys(extra).map(key => {
          const Input = extra[key];
          return <Input {...rest} key={key} campaignId={campaignId} />;
        })}
      </Fragment>
    );
  };

  renderRichTextInput = (label, source, isRequired = false, showOverview = false) => {
    const { classes } = this.props;
    return (
      <RichTextInput
        label={label}
        source={source}
        formClassName={classes.richTextInput}
        validate={isRequired ? required(`The ${label} is mandatory`) : null}
        showOverview={showOverview}
      />
    );
  };

  renderIsHiddenTouserInput = () => ({ formData }) => {
    if (!formData || !formData.checkpointType || formData.checkpointType === 'ShareOfVisibility') {
      return null;
    }
    return <BooleanInput source="hiddenToUsers" />;
  };

  render() {
    const { match } = this.props;
    const isNewCheckpoint = match.path.includes('create');

    const { isShortDescriptionChanged } = this.state;
    return (
      <div>
        {isNewCheckpoint || isShortDescriptionChanged ? (
          <Warning
            propsStyle={{
              warningContainer: { marginTop: 50 },
              warningIconButton: { width: '100%' },
            }}
            testId="pGAdminViewChangeWarningMessage"
            text="You are making changes to the campaign Checkpoints."
            content={<WarningText />}
          />
        ) : null}

        <div style={cardStyle.formContentCheckpoints}>
          <Card testid="MainSettings" style={cardStyle.cardContentCheckpoints}>
            <CardHeader testid="header" title="Main settings" />
            <CardContent>
              {this.renderTypeInput()}
              {this.returnShortDescriptionInput()}
              <FormDataConsumer {...this.props}>{this.renderSpecificInput}</FormDataConsumer>
              <FormDataConsumer {...this.props}>
                {this.renderIsHiddenTouserInput()}
              </FormDataConsumer>
              <BooleanInput source="hiddenToClients" />
              <BooleanInput label="Answer Required" source="answerRequired" defaultValue />
              {/* <BooleanInput
              label="Recompute Data Integrity Issues"
              source="recomputeIntegrityIssues"
              defaultValue
              style={{ visibility: 'hidden', width: 0, height: 0 }}
            /> */}
              {this.renderQuestionInput()}
              {/* <FormDataConsumer {...this.props}>
              {({ formData, dispatch }) => (
                <Switch
                  // checked={formData.answerRequired === undefined || formData.answerRequired}
                  // name="answerRequired"
                  defaultValue
                  onChange={e =>
                    dispatch(change(REDUX_FORM_NAME, 'answerRequired', e.target.value))
                  }
                />
              )}
            </FormDataConsumer> */}
            </CardContent>
          </Card>

          <Card testid="UserBriefing" style={cardStyle.cardContentCheckpoints}>
            <CardHeader testid="header" title="User briefing" />
            <CardContent>
              {this.renderRichTextInput('Description', 'description', true)}
              {this.renderRichTextInput('More Info', 'moreInfo')}
            </CardContent>
          </Card>

          <Card testid="Instructions" style={cardStyle.cardContentCheckpoints}>
            <CardHeader testid="header" title="Reviewers instructions" />
            <CardContent>
              {this.renderRichTextInput('Reviewers Instructions', 'reviewerInstructions')}
            </CardContent>
          </Card>

          <Card testid="Trax" style={cardStyle.cardContentCheckpoints}>
            <CardHeader testid="header" title="Trax" />
            <CardContent>{this.renderTraxInput()}</CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

const style = {
  richTextInput: {
    '& .ql-editor': {
      minHeight: '100px',
    },
  },
};

CheckpointForm.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  location: PropTypes.shape({
    search: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }),
};

CheckpointForm.defaultProps = {
  location: {},
};

export default withStyles(style)(CheckpointForm);
