import React from 'react';
import PropTypes from 'prop-types';
import { Create, SimpleForm } from 'react-admin';
import { parse } from 'query-string';
import CheckpointForm from './components/checkpointForm/checkpointForm';
import CheckpointToolbar from './components/checkpointToolbar';

const validateUserCreation = values => {
  const errors = {};
  if (
    values.shortDescription &&
    (values.shortDescription.toLowerCase() === 'age' ||
      values.shortDescription.toLowerCase() === 'gender')
  ) {
    errors.shortDescription = [
      'Age or Gender are not valid. They are by default used within our export flat table.',
    ];
  }
  return errors;
};

const CheckpointTitle = ({ record = {} }) => <span> {record.shortDescription} </span>;

CheckpointTitle.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

function CheckpointCreate(props) {
  const getCampaignId = () => {
    const { location = {} } = props;
    const { campaignId } = parse(location.search) || { campaignId: '' };
    return campaignId;
  };

  const campaignId = getCampaignId();
  const redirectUrl = campaignId ? `/campaigns/${campaignId}/show/checkpoints` : '/campaigns';
  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ campaignId }}
        redirect={redirectUrl}
        toolbar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <CheckpointToolbar Title={CheckpointTitle} campaignId={campaignId} />
        }
        validate={validateUserCreation}
      >
        <CheckpointForm {...props} />
      </SimpleForm>
    </Create>
  );
}

export default CheckpointCreate;
