import React from 'react';
import PropTypes from 'prop-types';

import { LongTextInput, ReferenceArrayInput, AutocompleteArrayInput, required } from 'react-admin';

import { renderUser } from '../../users/services/render-helper';
import { MESSAGE_TOPICS } from '../config/messageConfig';
import SelectAndSearchPresenter, {
  convertOptionsToLabelAndValue,
} from '../../components/select/selectAndSearch';
import sanitizeAndMatchOptionFilter from '../../components/select/selectAndSearchFilters';
import filterArrayByPropertyValues from '../../../util/arrayUtil';

const SendTo = () => (
  <ReferenceArrayInput
    label="Send to"
    sort={{ field: 'updatedAt', order: 'DESC' }}
    source="userIds"
    reference="users"
    linkType="show"
    fullWidth
    validate={required('A list of recipients must be provided.')}
  >
    <AutocompleteArrayInput label="User" optionText={renderUser} fullWidth />
  </ReferenceArrayInput>
);

const UserList = () => (
  <LongTextInput
    source="userIds"
    parse={v => (v || '').split(/\r?\n/)}
    format={v => (v || []).join('\n')}
    validate={required('A list of recipients must be provided.')}
  />
);

const MessageAudienceSelector = ({ userSelection, classes, topics }) => {
  const messageTopics = filterArrayByPropertyValues(MESSAGE_TOPICS, 'id', topics);
  const messageTopicsWithLabelAndValue = convertOptionsToLabelAndValue(messageTopics);

  if (userSelection === 'one') {
    return <SendTo testId="singleUserSelector" classes={classes} />;
  }
  if (userSelection === 'userList') {
    return <UserList testId="userListSelector" classes={classes} />;
  }
  if (userSelection === 'topics') {
    return (
      <SelectAndSearchPresenter
        testId="topicsSelector"
        classes={classes}
        name="topics"
        source="topics"
        choices={MESSAGE_TOPICS}
        isMulti
        placeholder="Topics"
        handleFilterOption={sanitizeAndMatchOptionFilter}
        values={messageTopicsWithLabelAndValue}
        alwaysOn
      />
    );
  }
  return null;
};

MessageAudienceSelector.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  userSelection: PropTypes.bool.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MessageAudienceSelector;
