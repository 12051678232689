import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import { reduxForm, submit } from 'redux-form';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FilterFormInput from './filterFormInput';

const styles = {
  form: {
    marginTop: '-10px',
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  body: { display: 'flex', alignItems: 'flex-end' },
  spacer: { width: '1em' },
  clearFix: { clear: 'right' },
  filterButton: { marginBottom: '10px' },
};

const sanitizeRestProps = ({
  anyTouched,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dispatch,
  displayedFilters,
  filterValues,
  handleSubmit,
  hideFilter,
  initialize,
  initialized,
  initialValues,
  invalid,
  pristine,
  pure,
  reset,
  resetSection,
  save,
  setFilter,
  setFilters,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  triggerSubmit,
  untouch,
  valid,
  validate,
  ...props
}) => props;

export class FilterWithApplyButtonPresenter extends PureComponent {
  applyFilter = () => {
    const { dispatch } = this.props;
    dispatch(submit('analyticsFilter'));
  };

  render() {
    const {
      classes = {},
      dispatch,
      className,
      resource,
      children,
      isLoading,
      ...rest
    } = this.props;
    return (
      <div className={classnames(className, classes.form)} {...sanitizeRestProps(rest)}>
        {children.map(filterElement => (
          <FilterFormInput
            testid="child"
            key={filterElement.props.source}
            filterElement={filterElement}
            handleHide={this.handleHide}
            classes={classes}
            resource={resource}
          />
        ))}
        <Button
          testid="filterButton"
          onClick={this.applyFilter}
          variant="contained"
          color="secondary"
          size="medium"
          className={classes.filterButton}
          disabled={isLoading}
        >
          Filter
        </Button>
      </div>
    );
  }
}

FilterWithApplyButtonPresenter.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.shape({}).isRequired,
  hideFilter: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  reduxForm({
    form: 'analyticsFilter',
    enableReinitialize: true,
    destroyOnUnmount: false, // do not destroy to preserve state across navigation,
    onSubmit: (values, dispatch, props) => props && props.setFilters(values),
  }),
);

export default enhance(FilterWithApplyButtonPresenter);
