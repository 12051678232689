import React, { Fragment, PureComponent } from 'react';
import { Show, TabbedShowLayout, Tab, EditButton } from 'react-admin';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import { Drawer } from '@material-ui/core';

import InformationCards from './components/informationCards';
import Installations from './components/installations/installations';
import UserBankAccountHistory from './components/userBankAccountHistory';
import ExperienceTab from './components/experienceTab';
import BanButton from './components/buttons/banButton';
import ActivateButton from './components/buttons/activateButton';
import ContactButton from './components/buttons/contactButton';
import EditBalanceButton from './components/buttons/editBalanceButton';
import UserTitle from './components/userTitle';
import BankTransactionCreate from './components/bankActivityCreate';
import ContactForm from './components/contactForm';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

export const UserShowAction = ({
  data,
  basePath,
  onOpenAccountBalanceActivity,
  onOpenContactForm,
}) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} label="Edit User Info" record={data} />
    <EditBalanceButton onOpenAccountBalanceActivity={onOpenAccountBalanceActivity} />
    <ContactButton onOpenContactForm={onOpenContactForm} />
    <BanButton record={data} />
    <ActivateButton record={data} />
  </CardActions>
);

UserShowAction.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  basePath: PropTypes.string.isRequired,
  onOpenAccountBalanceActivity: PropTypes.func.isRequired,
  onOpenContactForm: PropTypes.func.isRequired,
};

class UserShow extends PureComponent {
  state = {
    isAccountBalanceActivityOpen: false,
    isContactFormOpen: false,
  };

  toggleAccountBalanceActivityVisibility = () => {
    this.setState(prevState => ({
      isAccountBalanceActivityOpen: !prevState.isAccountBalanceActivityOpen,
    }));
  };

  toggleContactFormVisibility = () => {
    this.setState(prevState => ({
      isContactFormOpen: !prevState.isContactFormOpen,
    }));
  };

  render() {
    const { props } = this;
    const { id: userId } = props;

    const { isAccountBalanceActivityOpen, isContactFormOpen } = this.state;
    return (
      <Fragment>
        <Show
          {...props}
          actions={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <UserShowAction
              onOpenAccountBalanceActivity={this.toggleAccountBalanceActivityVisibility}
              onOpenContactForm={this.toggleContactFormVisibility}
            />
          }
          title={<UserTitle />}
        >
          <TabbedShowLayout>
            <Tab label="User info">
              <InformationCards {...props} testid="informationCards" />
            </Tab>

            <Tab label="Payment history" path="payment-history">
              <UserBankAccountHistory {...props} testid="bankAccountTransaction" />
            </Tab>

            <Tab label="Experience" path="experience">
              <ExperienceTab {...props} testid="experienceTab" />
            </Tab>

            <Tab label="Phone Installations" path="phone-installations">
              <Installations {...props} testid="phone-installations" />
            </Tab>

            <Tab
              label="Submissions"
              component="a"
              href={`/#/submissionsNew?filter={"userId":"${userId}","includes":["CampaignName","MissionLocation","UserNames","MissionSegments","MissionActive"]}&order=DESC&page=1&perPage=50&sort=acceptedAt`}
            />
          </TabbedShowLayout>
        </Show>
        <Drawer
          open={isAccountBalanceActivityOpen}
          onClose={this.toggleAccountBalanceActivityVisibility}
          anchor="right"
          testid="drawer"
        >
          <BankTransactionCreate
            {...props}
            userId={userId}
            onSubmit={this.toggleAccountBalanceActivityVisibility}
          />
        </Drawer>

        <Drawer
          open={isContactFormOpen}
          onClose={this.toggleContactFormVisibility}
          anchor="right"
          testid="drawer2"
        >
          <ContactForm {...props} userId={userId} onSubmit={this.toggleContactFormVisibility} />
        </Drawer>
      </Fragment>
    );
  }
}
export default UserShow;
