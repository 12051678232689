import XLSX from 'xlsx';
import fan from '../../../services/fan';

export const extractMissionsFromExcel = (
  data,
  options = {
    type: 'binary',
  },
) => {
  const workbook = XLSX.read(data, options);

  const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
  const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  const segmentsHeaderSecondRow = rawData[1].splice(LOCATION_FILE_HEADER.length);
  const segmentsHeaderFirstRow = rawData[0].splice(LOCATION_FILE_HEADER.length);
  const isHeaderCorrect = checkIfHeaderIsCorrect(rawData[0]);
  if (!isHeaderCorrect) {
    return null;
  }

  const { isValid, message } = validateSegmentHeaders(
    segmentsHeaderFirstRow,
    segmentsHeaderSecondRow,
  );

  if (!isValid) {
    throw new Error(message);
  }

  const headers = rawData[0];
  headers.forEach((header, index) => {
    if (header[index] !== LOCATION_FILE_HEADER[index] && index < LOCATION_FILE_HEADER.size) {
      throw new Error(
        `Column ${index + 1} - ${header[index]} should be ${LOCATION_FILE_HEADER[index]}`,
      );
    }
  });

  return rawData
    .splice(2) // remove excel headers
    .filter(rawMission => rawMission.some(r => r)) // remove blank lines
    .map((rawMission, index) => {
      if (!rawMission[1]) {
        throw new Error(`name is mandatory, see row: ${index + 3}`);
      }
      if (!rawMission[5]) {
        throw new Error(`state is mandatory, see row: ${index + 3}`);
      }
      if (!rawMission[6]) {
        throw new Error(`latitude is mandatory, see row: ${index + 3}`);
      }
      if (!rawMission[7]) {
        throw new Error(`longitude is mandatory, see row: ${index + 3}`);
      }
      const mission = {
        id: rawMission[0],
        location: {
          name: rawMission[1],
          address: rawMission[2] || '',
          suburb: rawMission[3] || '',
          postCode: rawMission[4] || '',
          state: rawMission[5] || '',
          geoPoint: { latitude: rawMission[6] || '', longitude: rawMission[7] || '' },
        },
        startDate: rawMission[8],
        endDate: rawMission[9],
        rewardCurrency: rawMission[10],
        reward: rawMission[11],
        experienceReward: rawMission[12],
        color: rawMission[13],
        maxSubmissionsPerLocation: rawMission[14],
        disabled: rawMission[16],
        storeClosed: rawMission[17],
        traxStoreId: rawMission[18] ? String(rawMission[18]) : undefined,
        segments: mapSegmentHeaderWithSegment(
          rawMission.splice(LOCATION_FILE_HEADER.length),
          segmentsHeaderSecondRow,
          index + 3,
        ),
      };
      if (mission.location.geoPoint.latitude == null) {
        delete mission.location.geoPoint;
      }
      return mission;
    });
};

const LOCATION_FILE_HEADER = [
  'id',
  'name',
  'street',
  'suburb',
  'postCode',
  'state',
  'lat',
  'long',
  'start',
  'end',
  'rewardCurrency',
  'reward',
  'experienceReward',
  'color',
  'maxSubmissionsPerLocation',
  'availableSubmitNumber',
  'disabled',
  'storeClosed',
  'traxStoreId',
];

const checkIfHeaderIsCorrect = header =>
  LOCATION_FILE_HEADER.every((head, i) => LOCATION_FILE_HEADER.includes(header[i]));

export const findInvalidHeaderIndex = row =>
  row.findIndex(segmentHeader => !segmentHeader || segmentHeader.trim() === '');

export const validateRowLengths = (row1, row2) => {
  if (row1.length !== row2.length) {
    return `Segment headers length mismatch: Row 1 has ${row1.length} headers, but Row 2 has ${
      row2.length
    } headers.`;
  }
  return null;
};

export const validateSegmentHeaders = (segmentsHeaderFirstRow, segmentsHeaderSecondRow) => {
  const lengthValidationMessage = validateRowLengths(
    segmentsHeaderFirstRow,
    segmentsHeaderSecondRow,
  );
  if (lengthValidationMessage) {
    return { isValid: false, message: lengthValidationMessage };
  }

  const invalidIndexRow1 = findInvalidHeaderIndex(segmentsHeaderFirstRow);
  if (invalidIndexRow1 !== -1) {
    return {
      isValid: false,
      message: `Row 1 has an invalid or missing segment header(s) at index ${invalidIndexRow1 +
        LOCATION_FILE_HEADER.length}. Segment header(s): "${segmentsHeaderFirstRow[
        invalidIndexRow1
      ] || 'N/A'}".`,
    };
  }

  const invalidIndexRow2 = findInvalidHeaderIndex(segmentsHeaderSecondRow);
  if (invalidIndexRow2 !== -1) {
    return {
      isValid: false,
      message: `Please check Row 2: the segment header(s) at index ${invalidIndexRow2 +
        LOCATION_FILE_HEADER.length} is missing or invalid. Expected Segment header()s: "${
        segmentsHeaderFirstRow[invalidIndexRow2]
      }"`,
    };
  }

  for (let i = 0; i < segmentsHeaderFirstRow.length; i++) {
    const header1 = segmentsHeaderFirstRow[i];
    const header2 = segmentsHeaderSecondRow[i];
    const adjustedIndex = i + LOCATION_FILE_HEADER.length;

    if (header1.trim() !== header2.trim()) {
      return {
        isValid: false,
        message: `Segment headers do not match: Row 1 has "${header1}" at index ${adjustedIndex}, but Row 2 has "${header2}".`,
      };
    }
  }

  return { isValid: true, message: 'Headers are valid and match across rows.' };
};

const mapSegmentHeaderWithSegment = (segments, segmentsHeader, row) =>
  segments.map((segment, index) => {
    if (segment === null || segment === '') {
      throw new Error(`${segmentsHeader[index]} is mandatory, see row: ${row}`);
    }
    return { name: segmentsHeader[index], value: segment };
  });

export const importMissions = (missions, campaignId) => {
  if (missions.length !== 0) {
    const missionsUpload = missions.map(m => ({ ...m, campaignId }));
    const payload = JSON.stringify({
      campaignId,
      missions: missionsUpload,
    });
    return fan.post(`/missions/batch`, payload);
  }
  return Promise.reject(Error('No Mission is provided'));
};

export const extractUsersFromExcel = (data, options = { type: 'binary' }) => {
  const workbook = XLSX.read(data, options);
  const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
  const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  return rawData
    .splice(1) // remove excel headers
    .map(rawUser => rawUser[0]);
};

export default null;
