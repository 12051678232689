const style = {
  title: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  record: {
    marginBottom: 10,
    color: 'grey',
  },
};

export default style;
