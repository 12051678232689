import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ContentSave from '@material-ui/icons/Save';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
import { GET_LIST, UPDATE, Mutation } from 'react-admin';
import fanDataProvider from '../../../../../data-provider/dataProvider';

const styles = theme =>
  createStyles({
    root: {
      float: 'right',
    },
    button: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    paper: {
      width: 400,
      padding: 20,
    },
    copySegmentRulesTitle: {
      color: theme.palette.secondary.main,
      marginBottom: 20,
    },
    copySegmentRulesBody: {
      marginBottom: 20,
    },
    saveButtonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 50,
    },
  });

class CopySegmentRulesDialog extends PureComponent {
  state = {
    isDialogOpen: false,
    campaigns: [],
    selectedCampaign: {},
  };

  componentDidMount() {
    this.getCampaigns();
  }

  toggleDrawer = () => {
    const { isDialogOpen } = this.state;
    this.setState({ isDialogOpen: !isDialogOpen });
  };

  handleSearch = keyWord => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.getCampaigns(keyWord);
    }, 200);
  };

  getCampaigns = async params => {
    const { dataProvider, campaignId } = this.props;
    const payload = {
      filter: {
        q: params,
      },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: {
        page: 1,
        perPage: 20,
      },
    };
    const data = await dataProvider(GET_LIST, 'campaigns', payload);
    const filteredData = this.filterRecords(data, campaignId);
    this.setState({ campaigns: filteredData });
  };

  /** Filter out campaigns without segment rules and the current campaign itself */
  filterRecords = (data, campaignId) =>
    (data.data || [])
      .filter(campaign => !!campaign.segmentsRules && campaign.id !== campaignId)
      .map(campaign => ({ value: campaign.id, label: campaign.name }));

  handleChange = selectedItem => {
    this.setState({ selectedCampaign: { ...selectedItem } });
  };

  renderSaveButton = (campaignId, fromCampaignId) => {
    const options = {
      onSuccess: {
        notification: { body: 'Segment Rules Copied', level: 'info' },
        refresh: true,
        callback: () => this.setState({ isDialogOpen: false }),
      },
      onFailure: {
        notification: { level: 'warning' },
      },
    };
    const payload = { id: campaignId, fromCampaignId, action: 'copy', importSegmentRules: true };

    return (
      <Mutation type={UPDATE} resource="segmentRules" payload={payload} options={options}>
        {(approve, { loading }) => (
          <Button color="primary" variant="outlined" onClick={approve} id="saveButton">
            {loading ? <CircularProgress size={20} /> : <ContentSave />} COPY
          </Button>
        )}
      </Mutation>
    );
  };

  render() {
    const {
      isDialogOpen,
      campaigns,
      selectedCampaign,
      selectedCampaign: { value },
    } = this.state;
    const { classes, campaignId } = this.props;
    return (
      <div className={classes.root}>
        <Button
          color="primary"
          onClick={this.toggleDrawer}
          testId="openDrawerButton"
          className={classes.button}
        >
          <AddToPhotosIcon /> IMPORT SEGMENT RULES
        </Button>
        <Drawer
          anchor="right"
          open={isDialogOpen}
          onClose={this.toggleDrawer}
          testId="editFromDrawer"
          classes={{ paper: classes.paper }}
        >
          <Typography variant="h5" align="left" className={classes.copySegmentRulesTitle}>
            Import Segment Rules
          </Typography>
          <Typography
            variant="body1"
            align="left"
            color="textPrimary"
            className={classes.copySegmentRulesBody}
            testId="notice"
          >
            Select the Campaign from which you want to import segment rules. You can only select
            campaigns that have segment rules that match your current segments.
          </Typography>
          <Select
            isClearable
            isSearchable
            closeMenuOnSelect
            autosize
            onChange={this.handleChange}
            onInputChange={this.handleSearch}
            options={campaigns}
            value={selectedCampaign}
            placeholder="Select a campaign to copy from"
            name="Campaigns"
            testId="campaignSelect"
          />
          <div className={classes.saveButtonWrapper}>
            {this.renderSaveButton(campaignId, value)}
          </div>
        </Drawer>
      </div>
    );
  }
}

CopySegmentRulesDialog.propTypes = {
  classes: PropTypes.shape(),
  campaignId: PropTypes.string.isRequired,
  dataProvider: PropTypes.func,
};

CopySegmentRulesDialog.defaultProps = {
  classes: {},
  dataProvider: fanDataProvider,
};

export default withStyles(styles)(CopySegmentRulesDialog);
