import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ReferenceArrayField, ReferenceField, ChipField, SingleFieldList } from 'react-admin';
import { styled } from '@mui/system';
import Title from '../../../../layout/components/fields/title.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';
import NullableChipField from '../../../../components/fields/nullableChipField';

const OperationsSettings = withStyles(styles)(({ classes, record, ...props }) => (
  <Card {...props} testid="card" className={classes.content}>
    <CardHeader testid="header" title="Operations" />
    <CardContent {...props}>
      <Title title="Assignee" />
      <ReferenceField
        label="Assignee"
        source="assignee"
        reference="users"
        linkType="show"
        allowEmpty
        record={record}
        {...props}
      >
        <NullableChipField source="email" />
      </ReferenceField>
      <Title title="Reviewers" />
      <ReferenceArrayField
        label="Reviewers"
        source="reviewers"
        reference="users"
        linkType="show"
        allowEmpty
        record={record}
        {...props}
      >
        <StyledSingleFieldList
          sx={{
            a: {
              marginTop: 0,
            },
          }}
          linkType="show"
        >
          <StyledChipField source="email" />
        </StyledSingleFieldList>
      </ReferenceArrayField>
    </CardContent>
  </Card>
));

const StyledSingleFieldList = styled(SingleFieldList)({
  margin: 4,
  fontSize: '0.8125rem',
});

const StyledChipField = styled(ChipField)({
  margin: 0,
  fontSize: '0.8125rem',
});

export default OperationsSettings;
