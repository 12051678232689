import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
  UPDATE_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    if (!(file.rawFile instanceof File)) {
      resolve(file);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    }
  });

export const shouldRecomputeDataIntegrityIssues = params =>
  (params || {}).recomputeDataIntegrityIssues !== false;

/* This monstrous monolith data provider for all endpoints is increasingly inflexible, as we
 * have to cover all cases in one function the way this is implemented. This is one more reason
 * for #ivy, which would get rid of a whole bunch of code that is unnecessarily complex and tedious to maintain. */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = async (type, resource, params) => {
    let body = params.data;
    if (resource === 'campaigns' && [UPDATE, CREATE].includes(type) && body.imageUrl) {
      body = { ...body, imageUrl: await convertFileToBase64(body.imageUrl) };
    }
    if (resource === 'campaigns' && [UPDATE].includes(type) && body.excelUrl) {
      body = { ...body, excelUrl: await convertFileToBase64(body.excelUrl) };
    }
    if (
      resource === 'campaigns' &&
      [UPDATE, CREATE].includes(type) &&
      body.industryReport &&
      body.industryReport.imageUrl
    ) {
      body = {
        ...body,
        industryReport: {
          ...body.industryReport,
          imageUrl: await convertFileToBase64(body.industryReport.imageUrl),
        },
      };
    }
    if (
      resource === 'campaigns' &&
      [UPDATE, CREATE].includes(type) &&
      body.industryReport &&
      body.industryReport.imageExamples
    ) {
      body = {
        ...body,
        industryReport: {
          ...body.industryReport,
          imageExamples: await Promise.all(
            body.industryReport.imageExamples.map(it => convertFileToBase64(it)),
          ),
        },
      };
    }

    if (
      resource === 'campaigns' &&
      [UPDATE, CREATE].includes(type) &&
      body.industryReport &&
      body.industryReport.sneakPeekPdfUrl
    ) {
      body = {
        ...body,
        industryReport: {
          ...body.industryReport,
          sneakPeekPdfUrl: await convertFileToBase64(body.industryReport.sneakPeekPdfUrl),
        },
      };
    }

    if (resource === 'use-cases' && [UPDATE, CREATE].includes(type) && body.imageUrl) {
      body = { ...body, imageUrl: await convertFileToBase64(body.imageUrl) };
    }
    if (resource === 'use-cases' && [UPDATE, CREATE].includes(type) && body.link) {
      body = { ...body, link: await convertFileToBase64(body.link) };
    }
    let url = '';
    const options = {};

    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          sort: field,
          order,
          skip: (page - 1) * perPage,
          limit: perPage,
        };
        if (resource === 'subscriptions') {
          url = `${apiUrl}/customers/subscriptions?${stringify(query)}`;
        } else {
          url = `${apiUrl}/${resource}?${stringify(query)}`;
        }
        break;
      }
      case GET_ONE:
        if (resource === 'subscriptions') {
          url = `${apiUrl}/customers/subscriptions/${params.id}`;
        } else if (resource === 'userSubmissionAnalytics') {
          url = `${apiUrl}/users/${params.id}/submissions/analytics`;
        } else {
          url = `${apiUrl}/${resource}/${params.id}`;
        }
        break;
      case GET_MANY: {
        const query = {
          id: params.ids,
        };
        if (resource === 'subscriptions') {
          throw new Error('GET_MANY is not implemented yet for resource subscriptions.');
          // url = `${apiUrl}/customers/subscriptions?${stringify(query)}`;
        } else {
          url = `${apiUrl}/${resource}?${stringify(query)}`;
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          sort: field,
          order,
          skip: (page - 1) * perPage,
          limit: perPage,
        };
        if (resource === 'subscriptions') {
          const { customerId } = params;
          url = `${apiUrl}/customers/subscriptions/customer/${customerId}`;
        } else {
          url = `${apiUrl}/${resource}?${stringify(query)}`;
        }
        break;
      }
      case UPDATE: {
        if (resource === 'subscriptions') {
          url = `${apiUrl}/customers/subscriptions/${params.id}`;
        } else if (resource === 'userStatus') {
          url = `${apiUrl}/users/${params.id}/status`;
        } else if (resource === 'userSubmissionAnalytics') {
          url = `${apiUrl}/users/${params.id}/submissions/analytics`;
        } else if (resource === 'submissionClientVisibility') {
          url = `${apiUrl}/submissions/${params.id}/hiddenToClients`;
        } else if (resource === 'checkpoints') {
          /* Well... Not liking this too much.
           * In order to allow request parameters when updating a checkpoint, the following special case is made for checkpoint updates.
           * The recomputeDataIntegrityIssues is added according to what has been passed in via the action.
           * However, we cannot keep inflating this data provider: In different cases/for different resources, there
           * could be different params in the future, and this will make the DP less and less readable.
           * -> The data provider being a monolith is not good software design!
           * Only adding/hacking this in here now because there is no time to re-factor. */
          url = `${apiUrl}/checkpoints/${
            params.id
          }?recomputeIntegrityIssues=${shouldRecomputeDataIntegrityIssues(params)}`;
        } else if (resource === 'segmentRules' && params.importSegmentRules !== undefined) {
          url = `${apiUrl}/campaigns/${params.id}/segmentRules?fromCampaignId=${
            params.fromCampaignId
          }&action=${params.action}`;
        } else {
          url = `${apiUrl}/${resource}/${params.id}`;
        }
        options.method = 'PUT';
        options.body = JSON.stringify(body);
        break;
      }
      case CREATE: {
        if (resource === 'subscriptions') {
          url = `${apiUrl}/customers/subscriptions`;
        } else {
          url = `${apiUrl}/${resource}`;
        }

        if (Array.isArray(body)) {
          url = `${url}/batch`;
        }

        if (resource === 'checkpoints') {
          url = `${url}/?recomputeIntegrityIssues=${shouldRecomputeDataIntegrityIssues(params)}`;
        }

        if (resource === 'campaigns' && params.keepCheckpoints !== undefined) {
          url = `${url}/${body.id}/clone/?keepCheckpoints=${params.keepCheckpoints}`;
        }

        options.method = 'POST';
        options.body = JSON.stringify(body);
        break;
      }
      case DELETE: {
        if (resource === 'subscriptions') {
          url = `${apiUrl}/customers/subscriptions/${params.id}`;
        } else if (resource === 'userRestrictions') {
          url = `${apiUrl}/users/${params.id}/restrictions`;
        } else {
          url = `${apiUrl}/${resource}/${params.id}`;
        }
        options.method = 'DELETE';
        break;
      }
      case DELETE_MANY: {
        if (resource === 'subscriptions') {
          /* The below is not ideal. It would be better to follow a /delete-request resource style with POST,
           * or using PATCH. It would even be acceptable to fire multiple requests. Query parameters have some
           * limitations. I adopted the existing style for consistency. */

          url = `${apiUrl}/customers/subscriptions/?id=${params.ids}`;
        } else {
          const ids = params.ids.map(id => `${id}`).join(',');
          url = `${apiUrl}/${resource}?id=${ids}`;
        }
        options.method = 'DELETE';
        break;
      }
      case UPDATE_MANY: {
        const { filters } = params;
        url = `${apiUrl}/${resource}?${Object.entries(filters)
          .map(([key, val]) => `${key}=${val}`)
          .join('&')}`;
        options.method = 'PATCH';
        options.body = JSON.stringify(body);
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource) => {
    let { json } = response;
    json = {
      data: [],
      ...json,
    };
    switch (type) {
      case CREATE:
        if (Array.isArray(response.json.data)) {
          json = { data: response.json.data[0] };
        }
        if (resource === 'users') {
          return {
            data: {
              id: json.userId,
            },
          };
        }
        return json;
      case GET_LIST:
        // case GET_MANY_REFERENCE:
        return json;
      default:
        return json;
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return async (type, resource, params) => {
    const { url, options } = await convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response => convertHTTPResponse(response, type, resource));
  };
};
