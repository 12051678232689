export const rewardLimits = Object.freeze({
  AUD: 100,
  HKD: 500,
  NZD: 100,
  SGD: 100,
  MYR: 300,
  MXN: 1100,
  JPY: 10000,
  KRW: 90000,
  THB: 24000,
  TWD: 21300,
  USD: 70,
});

export const transactionLimits = Object.freeze({
  AUD: 1000,
  HKD: 5000,
  NZD: 1000,
  SGD: 1000,
  MYR: 3000,
  MXN: 11000,
  JPY: 250000,
  KRW: 900000,
  THB: 240000,
  TWD: 213000,
  USD: 670,
});
