import React, { cloneElement } from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import GoBack from '../backButton/backButton';

const styles = {
  toolbar: {
    position: 'fixed',
    top: '30px',
    zIndex: 10,
    left: '66px',
    right: 0,
    justifyContent: 'space-between',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
};

const CustomToolbar = ({ Title, show, basePath, record, children, ...props }) => (
  <Toolbar {...props} style={styles.toolbar}>
    <div style={styles.inline}>
      <GoBack testid="back" />
      {Title ? <Title record={record} testid="test" /> : null}
    </div>
    <div>
      {children
        ? cloneElement(children, {
            basePath,
            record,
            ...props,
          })
        : null}
      <SaveButton {...props} />
    </div>
  </Toolbar>
);

export default CustomToolbar;
