import React from 'react';
import { addField } from 'react-admin';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const styles = {
  margin: { marginBottom: 15 },
};

export class PriceInputPresenter extends React.PureComponent {
  constructor(props) {
    super(props);
    const { value } = props.input;
    this.state = {
      currentValue: value,
    };
  }

  handleChange = e => {
    const { input } = this.props;
    const { onChange } = input;
    let val = e.target.value;
    if (val.includes(',')) {
      val = val.split(',').join('');
    }
    this.setState({ currentValue: Number(val) });
    onChange(Number(val));
  };

  render() {
    const { label } = this.props;
    const { currentValue } = this.state;

    return (
      <FormControl fullWidth style={styles.margin}>
        <InputLabel testid="label" htmlFor="adornment-amount">
          {label}
        </InputLabel>
        <Input
          testid="input"
          id="adornment-amount"
          value={currentValue.toLocaleString()}
          onChange={this.handleChange}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
    );
  }
}

PriceInputPresenter.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
};

export default addField(PriceInputPresenter);
