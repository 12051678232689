import axios from 'axios';
import { HttpError } from 'react-admin';
import config from '../setup/config';

const fan = axios.create({
  baseURL: config.FAN_BACKEND_BASE_URL,
  transformRequest: (body, headers) => {
    headers.put['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign
    headers.post['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign
    headers.patch['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign
    if (localStorage.getItem('token')) {
      headers['x-parse-session'] = localStorage.getItem('token'); // eslint-disable-line no-param-reassign
    }
    return body;
  },
});

fan.interceptors.response.use(
  response => response,
  error => {
    const { status, statusText, data } = error.response;
    const json = data;
    return Promise.reject(new HttpError((json && json.message) || statusText, status, data));
  },
);

export default fan;
