import React, { PureComponent, Fragment } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  required,
  SelectInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  ImageInput,
  ImageField,
  AutocompleteArrayInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  LongTextInput,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NullableBooleanInput,
  SelectArrayInput,
  REDUX_FORM_NAME,
} from 'react-admin';
import { change } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { ColorInput } from 'react-admin-color-input';
import { renderClient, renderUser } from '../users/services/render-helper';
import { renderTeam } from '../companies/services/render-helper';
import { CampaignEditTitle } from './campaignEdit.presenter';
import RichTextInput from '../layout/components/richTextInput/richTextInput.presenter';
import UsersFileInput from './components/usersFile.presenter';
import { badges, experienceLevels } from '../users/config/usersConfig';
import {
  missionTypes,
  campaignTypes,
  countries,
  storeTypes,
  tags,
  cycles,
  traxTargets,
  rewardCurrencies,
  getCurrencyByCountry,
  deserializeValue,
} from './config/campaignConfig';
import PriceInput from '../layout/components/inputs/priceInput';
import TooltipWrapper from '../components/tooltip/tooltip';
import { campaignType, ProductCategories } from '../components/tooltip/tooltips.constant';
import OperationsSettingsPanel from './components/operationsSettingsPanel';

import {
  getIndustryReportInput,
  parseAvailableToUsers,
  formatAvailableToUsers,
} from './services/inputs-helper';
import cardStyles from '../layout/globalStyle/cardStyle';
import CampaignCreateCustomToolbar from './toolbar/campaignCreateToolBar';
import i18n from '../../i18n/i18n';
import sanitizeRedundantWhitespace from '../components/utils/sanitizeWhitespace/sanitizeRedundantWhitespace';
import LinkedCampaignsSelect from './components/linkedCampaignsSelect';
import { validateURL } from './util';
import validateReward from '../../util/rewardUtil';

export const CampaignCampaignTitle = () => <span>New campaign</span>;

export const countryValidate = (message = 'The country is mandatory') => value =>
  value === undefined ? message : undefined;

export const availableToBadgesInputFormat = v => (v ? v.map(it => it.name || it) : []);
export const availableToBadgesInputParse = v => v.map(it => ({ name: it.name || it }));

export const availableToUsers = ({ formData }) => {
  if (!formData) {
    return null;
  }
  switch (formData.userSelection) {
    case 'manually':
    default:
      return (
        <ReferenceArrayInput
          label="Available to Users"
          sort={{ field: 'username', order: 'ASC' }}
          source="availableToUsers"
          reference="users"
          linkType="show"
          fullWidth
          testId="manually-input-testId"
        >
          <AutocompleteArrayInput optionText={renderUser} fullWidth />
        </ReferenceArrayInput>
      );

    case 'userList': {
      return (
        <div testId="userList-testId">
          <UsersFileInput label="Upload users file" source="availableToUsers" />
          <LongTextInput
            source="availableToUsers"
            required={required('must give a list of userIds')}
            parse={parseAvailableToUsers}
            format={formatAvailableToUsers}
            resettable
            styles={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          />
        </div>
      );
    }
  }
};

// eslint-disable-next-line react/prop-types
export const cycleTypeRender = ({ formData }) => {
  if (!(formData || {}).cycleType) {
    return null;
  }
  return (
    <Fragment>
      <NumberInput
        source="maxSubmissionsPerLocationPerCycle"
        fullWidth
        validate={required()}
        testId="cycle-number-input"
      />
    </Fragment>
  );
};

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  grid: {},
  userIds: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  content: cardStyles.content,
  formContent: { ...cardStyles.formContent },
  width: {
    width: '100%',
    overflow: 'visible',
  },
  purchase: { marginLeft: 20 },
  reward: { marginLeft: 20 },
};

class CampaignCreate extends PureComponent {
  renderRichTextInput = (label, source, isRequired = false) => (
    <RichTextInput
      label={label}
      source={source}
      validate={isRequired ? required(`The ${label} is mandatory`) : null}
    />
  );

  renderTypeInput = () => (
    <SelectInput
      source="missionType"
      choices={missionTypes}
      validate={required('The missionType is mandatory')}
    />
  );

  renderStoreTypeInput = () => (
    <SelectArrayInput
      label="Store types"
      source="storeTypes"
      choices={storeTypes}
      fullWidth
      validate={required('The store type is mandatory')}
    />
  );

  renderTagInput = () => (
    <TooltipWrapper title={ProductCategories}>
      <SelectArrayInput label="Product Categories" source="tags" choices={tags} fullWidth />
    </TooltipWrapper>
  );

  renderAvailableToExperienceLevelInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'availableToExperienceLevels', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'availableToExperienceLevels', 'label')}
        source="availableToExperienceLevels"
        choices={experienceLevels}
        fullWidth
      />
    </TooltipWrapper>
  );

  renderNotAvailableToExperienceLevelInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'notAvailableToExperienceLevels', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'notAvailableToExperienceLevels', 'label')}
        source="notAvailableToExperienceLevels"
        choices={experienceLevels}
        fullWidth
      />
    </TooltipWrapper>
  );

  renderAvailableToBadgesInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'availableToBadges', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'availableToBadges', 'label')}
        source="availableToBadges"
        choices={badges}
        fullWidth
        optionValue="name"
        format={availableToBadgesInputFormat}
        parse={availableToBadgesInputParse}
      />
    </TooltipWrapper>
  );

  renderNotAvailableToBadgesInput = () => (
    <TooltipWrapper title={i18n('campaigns', 'notAvailableToBadges', 'tooltip')}>
      <SelectArrayInput
        label={i18n('campaigns', 'notAvailableToBadges', 'label')}
        source="notAvailableToBadges"
        choices={badges}
        fullWidth
        optionValue="name"
        format={availableToBadgesInputFormat}
        parse={availableToBadgesInputParse}
      />
    </TooltipWrapper>
  );

  renderCountryInput = () => (
    <TooltipWrapper title="Changing country will also change the reward currency is the missions setting below. If you select ALL, the reward currency will be AUD by default.">
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => (
          <SelectInput
            source="country"
            choices={countries}
            validate={countryValidate('The country is mandatory')}
            onChange={value => {
              const country = deserializeValue(value);
              dispatch(change(REDUX_FORM_NAME, 'rewardCurrency', getCurrencyByCountry(country)));
            }}
            {...rest}
          />
        )}
      </FormDataConsumer>
    </TooltipWrapper>
  );

  renderCycleInput = () => (
    <div>
      <SelectInput source="cycleType" choices={cycles} />
      <p>
        The cycle will be generated using the start date and date of the campaign. They are shown
        after saving. The following rules are applied:
      </p>
      <ul>
        <li>The segments can be applied by cycle</li>
        <li>It&apos;s not possible to accept a mission if out of the cycle</li>
        <li>It&apos;s impossible to reaccept a mission from a previous cycle</li>
      </ul>
    </div>
  );

  renderCampaignTypeInput = () => (
    <TooltipWrapper title={campaignType}>
      <SelectInput
        source="campaignType"
        choices={campaignTypes}
        validate={required('The type is mandatory')}
      />
    </TooltipWrapper>
  );

  render() {
    const { classes } = this.props;
    const today = new Date();
    today.setHours(0, 1, 0, 0);
    const inAMonth = new Date();
    inAMonth.setMonth(today.getMonth() + 1);
    inAMonth.setHours(0, 1, 0, 0);
    return (
      <Create {...this.props} title={<CampaignEditTitle />}>
        <SimpleForm
          submitOnEnter={false}
          redirect="show"
          toolbar={<CampaignCreateCustomToolbar />}
          validate={validateReward}
        >
          <Grid
            container
            fullWidth
            direction="row"
            justify="space-between"
            spacing={24}
            className={classes.formContent}
          >
            <Grid item md={6}>
              <Grid container direction="column" justify="space-evenly" spacing={24}>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content} style={styles.width}>
                    <CardHeader testid="header" title="Main Settings" />
                    <CardContent>
                      {this.renderCampaignTypeInput()}
                      <p>Stage TODO</p>
                      <BooleanInput source="active" defaultValue />
                      <BooleanInput source="availableToClients" defaultValue={false} />
                      <BooleanInput
                        source="excelExportAvailableToClients"
                        defaultValue
                        testId="excelExportAvailableToClients"
                      />
                      <BooleanInput
                        source="photoDownloadAvailableToClients"
                        defaultValue
                        testId="photoDownloadAvailableToClients"
                      />
                      <TextInput
                        label="Campaign Name"
                        source="name"
                        fullWidth
                        validate={required()}
                        parse={text => sanitizeRedundantWhitespace(text)}
                      />
                      <ImageInput source="imageUrl" label="Mission's thumbail" accept="image/*">
                        <ImageField source="imageUrl" title="Mission's thumbail" />
                      </ImageInput>
                      <ReferenceInput
                        label="Owner"
                        sort={{ field: 'username', order: 'ASC' }}
                        source="ownerId"
                        reference="users"
                        linkType="show"
                        filter={{ role: 'CLIENT' }}
                        validate={required()}
                        fullWidth
                      >
                        <AutocompleteInput label="Owner" optionText={renderClient} fullWidth />
                      </ReferenceInput>
                      <ReferenceArrayInput
                        label="Guests"
                        sort={{ field: 'username', order: 'ASC' }}
                        source="clientIds"
                        reference="users"
                        linkType="show"
                        filter={{ role: 'CLIENT' }}
                        fullWidth
                      >
                        <AutocompleteArrayInput label="User" optionText={renderClient} fullWidth />
                      </ReferenceArrayInput>
                      <ReferenceArrayInput
                        label="Teams"
                        sort={{ field: 'companyName', order: 'ASC' }}
                        source="teamIds"
                        reference="teams"
                        linkType="show"
                        fullWidth
                      >
                        <AutocompleteArrayInput label="Teams" optionText={renderTeam} fullWidth />
                      </ReferenceArrayInput>
                      {this.renderCountryInput()}
                      <TextInput label="Metabase ID" source="metabaseId" fullWidth disabled />
                      <TextInput
                        label="Metabase Dashboard URL"
                        source="metabaseDashboardUrl"
                        testId="metabaseDashboardUrl"
                        validate={value => validateURL(value)}
                        fullWidth
                      />
                      <LinkedCampaignsSelect source="linkedCampaigns" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <OperationsSettingsPanel className={classes.content} />
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Campaign Schedule" />
                    <CardContent>
                      <DateTimeInput
                        label="Start Date (Sydney time)"
                        source="startDate"
                        formClassName={classes.inlineBlock}
                        parse={date => date}
                        defaultValue={today}
                        validate={required()}
                        fullWidth
                      />
                      <DateTimeInput
                        label="End Date (Sydney time)"
                        source="endDate"
                        formClassName={classes.inlineBlock}
                        defaultValue={inAMonth}
                        parse={date => date}
                        validate={required()}
                        fullWidth
                      />
                      <DateTimeInput
                        source="clientDebriefingDate"
                        formClassName={classes.inlineBlock}
                        parse={
                          date => date // defaultValue={inAMonth}
                        }
                        fullWidth
                      />
                      {this.renderCycleInput()}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Mission Settings" />
                    <CardContent>
                      <BooleanInput
                        testId="excludableByUser"
                        source="excludableByUser"
                        label="User can hide"
                        defaultValue
                      />
                      {this.renderTypeInput()}
                      <NullableBooleanInput
                        fullWidth
                        style={styles.purchase}
                        source="includePurchase"
                        validate={required('The purchase is mandatory')}
                      />
                      {this.renderTagInput()}
                      {this.renderStoreTypeInput()}
                      <TextInput
                        label="Mission Name"
                        source="inAppName"
                        fullWidth
                        validate={required()}
                        parse={text => sanitizeRedundantWhitespace(text)}
                      />
                      <SelectInput
                        source="rewardCurrency"
                        choices={rewardCurrencies}
                        validate={required('The reward currency is mandatory')}
                      />
                      <NumberInput source="reward" style={styles.reward} validate={required()} />
                      <NumberInput
                        source="experienceReward"
                        fullWidth
                        validate={required()}
                        label="Experience reward"
                      />
                      <NumberInput
                        label="Estimated time (min)"
                        source="estimatedTime"
                        fullWidth
                        validate={required()}
                      />
                      <NumberInput
                        label="Time allowed (min)"
                        source="timeAllowed"
                        fullWidth
                        validate={required()}
                      />
                      <ColorInput source="color" fullWidth />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Completion Rules" />
                    <CardContent>
                      <NumberInput
                        source="maxSubmissionsPerLocation"
                        fullWidth
                        defaultValue={1}
                        validate={required()}
                      />
                      <NumberInput
                        source="maxSubmissionsPerUser"
                        fullWidth
                        defaultValue={1000}
                        validate={required()}
                      />
                      <NumberInput
                        source="maxSubmissionsPerUserPerLocation"
                        fullWidth
                        defaultValue={1}
                        validate={required()}
                      />
                      <NumberInput
                        source="maxActiveSubmissionsPerUser"
                        fullWidth
                        defaultValue={10}
                      />
                      <FormDataConsumer>{cycleTypeRender}</FormDataConsumer>
                      <BooleanInput source="secondChance" defaultValue validate={required()} />
                      <BooleanInput source="autoValidationEnabled" defaultValue={false} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Availability" />
                    <CardContent>
                      <RadioButtonGroupInput
                        fullWidth
                        row
                        classes={{ root: classes.radioButton }}
                        source="userSelection"
                        defaultValue="manually"
                        choices={[
                          { id: 'manually', name: 'One by One' },
                          { id: 'userList', name: 'User IDs' },
                        ]}
                      />
                      <TooltipWrapper title="By selecting one or more users, the user can see this campaign if he is within this list. If the list is empty, all users can see this campaign">
                        <FormDataConsumer>{availableToUsers}</FormDataConsumer>
                      </TooltipWrapper>
                      {this.renderAvailableToExperienceLevelInput()}
                      {this.renderNotAvailableToExperienceLevelInput()}
                      {this.renderAvailableToBadgesInput()}
                      {this.renderNotAvailableToBadgesInput()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container direction="column" justify="space-evenly" spacing={24}>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Locations" />
                    <CardContent>
                      <BooleanInput source="anywhere" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Description" />
                    <CardContent>
                      {this.renderRichTextInput('Description', 'description', true)}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Budget" />
                    <CardContent>
                      <PriceInput source="budget.totalBudget" label="Total budget" />
                      <PriceInput source="budget.designDataFee" label="Design and data fee" />
                      <ArrayInput source="budget.pricePerStoreVisit" label="Price per store visit">
                        <SimpleFormIterator>
                          <TextInput source="name" fullWidth label="name" />
                          <PriceInput source="value" fullWidth label="value" />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <NumberInput label="# of visits" source="campaignTarget" fullWidth />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} style={styles.width}>
                  <Card className={classes.content}>
                    <CardHeader testid="header" title="Trax" />
                    <CardContent>
                      <TextInput label="Project Id" source="traxProjectId" fullWidth />
                      <TextInput label="API key" source="traxApiKey" fullWidth />
                      <TextInput label="User ID" source="traxUserId" fullWidth />
                      <SelectInput
                        label="Project stage"
                        source="traxTarget"
                        choices={traxTargets}
                        fullWidth
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <FormDataConsumer>{getIndustryReportInput}</FormDataConsumer>
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}

export default withStyles(styles)(CampaignCreate);
