/**
 * Validates the validity of a date value, as well as the validity
 * of both start and end date in combination.
 *
 * @param {*} value the date value to check
 * @param {*} form the form/object containing both start and end dates
 *
 * @returns null if the values provided are considered valid, otherwise a message indicating the issue
 */
export const validateSubscriptionDates = (value, form) => {
  const { startDate, endDate } = form;

  if (!value || value.toString().trim() === '') {
    return `Date and time must be provided.`;
  }

  if (!endDate || !startDate) {
    return `Both start and end dates must be provided.`;
  }

  try {
    const testStart = new Date(startDate);
    const testEnd = new Date(endDate);
    if (!testStart || !testEnd) {
      throw new Error('Conversion error!');
    }
  } catch (error) {
    return error.message;
  }

  if (endDate <= startDate) {
    return `End date must be after start date.`;
  }

  return null;
};

/**
 * Sanitises the provided date for the purpose of using it in a (subscription) component.
 * If generally useful, can be refactored out later.
 *
 * @param {*} date the date to sanitise
 *
 * @returns the sanitised date
 */
export const sanitiseDate = date => {
  if (!date || date.toString().trim() === '') {
    return Date.now();
  }

  try {
    if (typeof date === 'string') {
      return new Date(date).toISOString();
    }

    if (date.toISOString) {
      return date.toISOString();
    }
  } catch (error) {
    throw new Error(`Please check the date. The format appears to be incorrect: ${date}`, {
      cause: error,
    });
  }

  return Date.now();
};

export default {
  sanitiseDate,
  validateSubscriptionDates,
};
