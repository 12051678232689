import React, { Fragment } from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  LongTextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginBottom: theme.spacing.unit * 3,
    zIndex: [1300, '!important'],
  },
  suggestion: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

const renderMission = mission => mission.location.name;

const ContactFormPresenter = props => {
  const { userId, onSubmit, classes } = props;
  const redirect = () => {
    onSubmit();
    return false;
  };

  return (
    <Create {...props} resource="messages" basePath="/messages" title={<Fragment />}>
      <SimpleForm defaultValue={{ userIds: [userId] }} redirect={redirect} undoable={false}>
        <Typography variant="h5" component="h2">
          Sent Notification
        </Typography>

        <TextInput source="title" />
        <LongTextInput source="body" inputProps={{ rows: '3' }} validate={required()} />
        <SelectInput
          label="Action"
          source="status"
          defaultValue="Sent"
          choices={[{ id: 'Sent', name: 'Send now' }, { id: 'Draft', name: 'Save as draft' }]}
        />
        <ReferenceArrayInput
          label="Campaigns"
          sort={{ field: 'updatedAt', order: 'DESC' }}
          source="campaignIds"
          reference="campaigns"
          fullWidth
        >
          <AutocompleteArrayInput
            label="Campaign"
            classes={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            fullWidth
          />
        </ReferenceArrayInput>

        <FormDataConsumer>
          {({ formData }) =>
            formData.campaignIds && (
              <ReferenceInput
                label="Mission"
                sort={{ field: 'updatedAt', order: 'DESC' }}
                source="missionId"
                filter={{ campaignId: formData.campaignIds }}
                reference="missions"
                fullWidth
              >
                <AutocompleteInput
                  label="Mission"
                  fullWidth
                  optionText={renderMission}
                  theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.missionId && (
              <ReferenceInput
                label="Submission"
                sort={{ field: 'updatedAt', order: 'DESC' }}
                source="submissionId"
                filter={{
                  missionId: formData.missionId,
                  userId,
                }}
                reference="submissionsNew"
                fullWidth
              >
                <SelectInput
                  label="Submission"
                  fullWidth
                  optionText={submission => `${submission.id} - ${submission.status}`}
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default withStyles(styles)(ContactFormPresenter);
