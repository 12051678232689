import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import PersonalInfo from './personalInfo/personalInfo';
import MoneyInfo from './moneyInfo/moneyInfo.container';
import BankDetails from './bankDetails/bankInfo.container';
import SubmissionAnalytics from './submissionAnalytics/submissionAnalytics.container';
import RestrictionsInfo from './restrictionsInfo/restrictionsInfo';

const editStyles = {
  root: { display: 'flex', alignItems: 'flex-start', width: '100%', marginTop: 30 },
  form: { flexGrow: 9 },
};

const InformationCards = withStyles(editStyles)(({ children, classes, ...props }) => (
  <div className={classes.root} {...props}>
    <div className={classes.form}>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <RestrictionsInfo testId="restrictionsInfo" record={props.record} />
          <PersonalInfo {...props} testid="personalInfoCard" />
        </Grid>
        <Grid item xs={4}>
          <SubmissionAnalytics {...props} testid="submissionAnalytics" />
        </Grid>
        <Grid item xs={4}>
          <MoneyInfo {...props} testid="moneyInfoCard" />
          <BankDetails {...props} testid="bankDetailsCard" />
        </Grid>
      </Grid>
    </div>
  </div>
));

export default InformationCards;
