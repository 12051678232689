import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (/* theme */) => ({
  warning: {
    width: '95%',
    display: 'inline-block',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '20px',
    color: 'red',
    border: '4px solid red',
    marginTop: 10,
    marginBottom: 20,
    padding: 10,
  },
});

const StagingWarning = props => {
  const { classes } = props;
  const documentUrl = document.URL || window.location.href || '';
  const isStaging = documentUrl.indexOf('admin-staging') >= 0;
  if (!isStaging) return null;

  return (
    <div className={classes.warning}>
      Warning: This is a test environment. Do not make changes to production data on this platform,
      go to the Production website instead. If you change data to production
      campaigns/missions/submissions here, you may break our data.
    </div>
  );
};

StagingWarning.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(StagingWarning);
