import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import TableCell from '@material-ui/core/TableCell/index';
import TableRow from '@material-ui/core/TableRow/index';
import TableBody from '@material-ui/core/TableBody/index';
import classnames from 'classnames';

const DatagridBodySortable = SortableContainer(
  ({
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    isLoading,
    onToggleItem,
    resource,
    row,
    rowClick,
    rowStyle,
    selectedIds,
    styles,
    version,
    ...rest
  }) => (
    <TableBody className={classnames('datagrid-body', className)} {...rest}>
      {ids.map((id, rowIndex) =>
        React.cloneElement(
          row,
          {
            basePath,
            classes,
            className: classnames(classes.row, {
              [classes.rowEven]: rowIndex % 2 === 0,
              [classes.rowOdd]: rowIndex % 2 !== 0,
              [classes.clickableRow]: rowClick,
            }),
            expand,
            hasBulkActions,
            hover,
            id,
            key: id,
            onToggleItem,
            record: data[id],
            resource,
            rowClick,
            selected: selectedIds.includes(id),
            style: rowStyle ? rowStyle(data[id], rowIndex) : null,
            index: rowIndex,
          },
          children,
        ),
      )}
    </TableBody>
  ),
);

const SortableRow = SortableElement(
  ({ record, resource, id, children, basePath, style, index }) => (
    <TableRow key={id} style={{ ...style, ...{ cursor: 'move' } }}>
      {React.Children.map(children, (field, columnIndex) => (
        <TableCell key={`${id}-${field.props.source}`}>
          {columnIndex === 0 ? (
            <p>{index + 1}</p>
          ) : (
            React.cloneElement(field, {
              record,
              basePath,
              resource,
            })
          )}
        </TableCell>
      ))}
    </TableRow>
  ),
);

class SortableDatagridBody extends Component {
  constructor(props) {
    super(props);
    this.state = { ids: props.ids };
  }

  componentWillReceiveProps(nextProps) {
    const { ids } = this.props;
    if (ids !== nextProps.ids) {
      this.setState({ ids: nextProps.ids });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateResource, resourceId } = this.props;
    this.setState(({ ids }) => ({
      ids: arrayMove(ids, oldIndex, newIndex),
    }));

    if (updateResource !== null && resourceId !== null) {
      const { ids } = this.state;
      updateResource(ids, resourceId);
    }
  };

  render() {
    const { ids } = this.state;
    return (
      <DatagridBodySortable
        {...this.props}
        ids={ids}
        onSortEnd={this.onSortEnd}
        lockAxis="y"
        distance={10}
        row={<SortableRow />}
      />
    );
  }
}

SortableDatagridBody.propTypes = {
  updateResource: PropTypes.func,
  resourceId: PropTypes.string,
};

SortableDatagridBody.defaultProps = {
  updateResource: null,
  resourceId: null,
};

export default SortableDatagridBody;
