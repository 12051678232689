import fan from '../../../../../../services/fan';

const getMissionFeedback = async campaignId => {
  const result = await fan.get(`/mission-feedback?campaignId=${campaignId}&limit=5000`);
  const { data } = result.data;

  const userIds = data.map(it => it.userId);
  const submissionIds = data.map(it => it.submissionId);

  const submissions = await getSubmissions(submissionIds);
  const users = await getUsers(userIds);

  const missionFeedbacks = data.map(d => {
    let location = null;
    let email = null;
    const submission = submissions.find(s => s.id === d.submissionId);
    if (submission && submission.missionLocationName) {
      location = submission.missionLocationName;
    }
    const user = users.find(u => u.id === d.userId);
    if (user && user.email) {
      // eslint-disable-next-line prefer-destructuring
      email = user.email;
    }

    return { ...d, location, email };
  });
  return missionFeedbacks;
};

const getSubmissions = async submissionIds => {
  const result = await fan.get(`/submissionsNew?id=${submissionIds}&limit=1000`);
  const { data } = result.data;
  return data;
};

const getUsers = async userIds => {
  const result = await fan.get(`/users?id=${userIds}`);
  const { data } = result.data;
  return data;
};

export default getMissionFeedback;
