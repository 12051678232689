export default {
  colorSnooperBlueLight: '#78baef',
  colorSnooperBlue: '#2196f3',
  colorSnooperBlueDark: '#008eff',

  colorBadLight: '#ffa19a',
  colorBadSolid: '#ea6960',
  colorBadBright: '#f44336',
  colorBadMuted: '#b35049',

  colorGoodLight: '#9bde9e',
  colorGoodSolid: '#6ecc73',
  colorGoodBright: '#4fd855',
  colorGoodMuted: '#4caf50',

  colorAlertLight: '#ffdab9',
  colorAlertSolid: '#fdc04e',
  colorAlertBright: '#fdba0e',

  buttonBgColor: '#dcdcdc',
  buttonBgColorHover: '#cacaca',

  cardColor: 'rgb(245, 248, 251)',

  colorApprovedCard: '#4caf50',
  colorRejectedCard: '#f44336',
  colorPendingCard: '#2196f3',
  colorNonActionableCard: '#b5b5b5',

  textColorLight: '#bdbdbd',
  textColor: '#424349',
  textColorHover: '#2196f3',
};
