import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#143277',
    },
    secondary: {
      main: '#00C7FC', // snooper blue
      contrastText: '#ffffff',
    },
  },
  typography: {
    color: 'blue',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  overrides: {
    MuiInputLabel: {
      root: { fontSize: 12 },
    },
    MuiSelect: {
      selectMenu: {
        fontSize: 12,
      },
    },
    MuiMenuItem: {
      root: { fontSize: 12 },
    },
    MuiList: {
      padding: {
        paddingTop: 'none',
        paddingBottom: 'none',
      },
    },
    MuiChip: {
      clickable: {
        fontSize: 10,
      },
    },
    MuiInput: {
      root: { fontSize: 12 },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f9fbfc',
      },
    },
    MuiTablePagination: {
      toolbar: {
        position: 'fixed',
        backgroundColor: 'rgb(249, 251, 252)',
        left: '60px',
        right: '0',
        bottom: 0,
        zIndex: 30,
      },
    },
  },
});

export default theme;

//   colorBadLight: '#ffa19a',
// colorBadSolid: '#ea6960',
// colorBadBright: '#f44336',
// colorBadMuted: '#b35049',

// colorGoodLight: '#9bde9e',
// colorGoodSolid: '#6ecc73',
// colorGoodBright: '#4fd855',
// colorGoodMuted: '#4caf50',
