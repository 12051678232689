import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const exportXlsx = (csvData, fileName) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToXlsx = () => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return exportToXlsx();
};

export const exportMissionTable = results => {
  const locationFile = results.data.map(result => {
    let mission = {
      id: result.id,
      name: result.location.name,
      street: result.location.address,
      suburb: result.location.suburb,
      postCode: result.location.postCode,
      state: result.location.state,
      lat: result.location.geoPoint.latitude,
      long: result.location.geoPoint.longitude,
      start: result.startDate,
      end: result.endDate,
      rewardCurrency: result.rewardCurrency,
      reward: result.reward,
      experienceReward: result.experienceReward,
      color: result.color,
      maxSubmissionsPerLocation: result.maxSubmissionsPerLocation,
      availableSubmitNumber: result.availableSubmitNumber,
      disabled: result.disabled,
      storeClosed: result.storeClosed,
      traxStoreId: result.traxStoreId,
    };

    result.segments.forEach(segment => {
      mission = { ...mission, [segment.name]: segment.value };
      return null;
    });
    return mission;
  });

  let headers = {};
  locationFile.forEach(header => {
    headers = { ...headers, ...Object.keys(header) };
  });

  let firstRow = {
    id: '-',
    name: '-',
    street: '-',
    suburb: '-',
    postCode: '-',
    state: '-',
    lat: '-',
    long: '-',
    start: '-',
    end: '-',
    rewardCurrency: '-',
    reward: '-',
    experienceReward: '-',
    color: '-',
    maxSubmissionsPerLocation: '-',
    availableSubmitNumber: '-',
    disabled: '-',
    storeClosed: '-',
    traxStoreId: '-',
  };
  Object.values(headers).forEach(header => {
    if (Object.keys(firstRow).includes(header)) {
      return null;
    }
    firstRow = { ...firstRow, [header]: header };
    return null;
  });
  locationFile.unshift(firstRow);
  return locationFile;
};

export default null;
