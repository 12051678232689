import React from 'react';
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ReferenceManyField,
  EditButton,
  ImageField,
  BooleanField,
  CloneButton,
  Button,
  TabbedShowLayoutTabs,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LibraryAddButton from '@material-ui/icons/LibraryAdd';
import CreateButton from '@material-ui/icons/Create';
import QueueIcon from '@material-ui/icons/Queue';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import CheckpointsList from '../checkpoints/checkpointsListSortableForCampaign.presenter';
import CampaignSettings from './components/settings/campaignSettings.presenter';
import MissionList from './components/missionList/missionList.presenter';
import SegmentsPresenter from './components/segments/segments.presenter';
import CashBoostPresenter from './components/cashboosts/cashboosts.presenter';
import MissionFeedback from './components/missionFeedback/missionFeedback.presenter';
import Notes from './components/notes/notes';
import DeleteCheckpointButton from '../checkpoints/components/checkpointDeleteButton.presenter';
import CampaignAnalytics from './components/analytics/analytics.presenter';

const dashboard = require('../../../assets/images/gallery.png');

export const CampaignTitle = ({ record }) => <span> {record ? `${record.name}` : ''}</span>;

CampaignTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
};

CampaignTitle.defaultProps = {
  record: null,
};

const omitId = ({ id, ...rest }) => rest;

export const CloneCheckpointButton = ({ record, campaignId }) => (
  <Button
    component={Link}
    to={{
      pathname: `/checkpoints/create`,
      state: { record: omitId(record) },
      search: `?campaignId=${campaignId}`,
    }}
    label="Clone"
  >
    <QueueIcon />
  </Button>
);

CloneCheckpointButton.propTypes = {
  campaignId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CloneCheckpointButton.defaultProps = {
  record: {},
};

export const AddNewCheckpointButton = ({ record, classes }) => (
  <Button
    component={Link}
    classes={{ button: classes.button }}
    to={{ pathname: '/checkpoints/create', search: `?campaignId=${record.id}` }}
    label="Add a checkpoint"
  >
    <LibraryAddButton />
  </Button>
);

export const EditCheckpointButton = ({ campaignId, record }) => (
  <Button
    component={Link}
    to={{
      pathname: `/checkpoints/${record.id}`,
      search: `?campaignId=${campaignId}`,
    }}
    label="Edit"
  >
    <CreateButton />
  </Button>
);
EditCheckpointButton.propTypes = {
  campaignId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

EditCheckpointButton.defaultProps = {
  record: {},
};

export const CampaignCloneButton = ({ id, basePath = '', record = {}, ...rest }) => (
  <CloneButton
    basePath={basePath}
    id={id}
    record={record}
    {...rest}
    to={{
      pathname: `${basePath}/create`,
      state: {
        record: removeFieldsFromRecord(record, [
          'segments',
          'segmentsRules',
          'metabaseId',
          'metabaseDashboardUrl',
          'cashBoosts',
          'generatedExcelUrl',
          'linkedCampaigns',
          'campaignTarget',
          'budget',
          'notes',
          'checkpoints',
        ]),
      },
    }}
  />
);

/**
 * When cloning a campaign, we do not want to copy every thing, this function
 * will remove linkedCampaigns, Target, Client budget, Notes, and Cash Boost
 * @param {object} record
 * @param {array Of String} fieldsToRemove
 * @returns new Object
 */
export const removeFieldsFromRecord = (record, fieldsToRemove) => {
  const recordAfter = { ...record };
  fieldsToRemove.forEach(field => delete recordAfter[field]);
  return recordAfter;
};

export const CampaignShowActions = connect(
  null,
  { push },
)(({ basePath, data, ...props }) => (
  <div style={styles.showButtons}>
    <div style={styles.inline}>
      <IconButton onClick={() => props.push('/#/campaigns')}>
        <ArrowBackIos testid="back" style={styles.arrowColor} />
      </IconButton>
      <Typography>{data ? data.name : ''}</Typography>
    </div>
    <div style={styles.inline}>
      <Button
        testid="clientDashboard"
        label="dashboard"
        target="_blank"
        href={data ? `https://dashboard.snooper-app.com/#/campaigns/${data.id}` : '#/'}
      >
        <img src={dashboard} alt="test" style={{ height: '20px', marginRight: '5px' }} />
      </Button>
      <CampaignCloneButton basePath={basePath} record={data} />
      <EditButton basePath={basePath} record={data} />
    </div>
  </div>
));

CampaignShowActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  data: PropTypes.shape(PropTypes.any).isRequired,
  history: PropTypes.shape(PropTypes.any).isRequired,
};

const addNewCheckpointButtonStyle = theme => ({
  button: { float: 'right', marginTop: [theme.spacing.unit, '!important'] },
});
const AddNewCheckpointButtonStyled = withStyles(addNewCheckpointButtonStyle)(
  AddNewCheckpointButton,
);

AddNewCheckpointButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  classes: PropTypes.shape({}).isRequired,
};

AddNewCheckpointButton.defaultProps = {
  record: null,
};

const styles = {
  tabLayout: {
    maxHeight: '75vh',
    overflowY: 'auto',
    display: 'inline-block',
    width: '100%',
  },
  showLayout: {
    padding: 'unset',
  },
  showButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowColor: {
    color: '#00C7FC',
  },
  content: {
    marginLeft: '2px',
    marginTop: 20,
    width: '99%',
  },
  checkpointTabLayout: {
    height: 'auto',
    overflow: 'hidden',
    display: 'inline-block',
    width: '100%',
  },
};

const CampaignShow = props => {
  const { id: campaignId, classes, permissions } = props;

  return (
    <Show actions={<CampaignShowActions />} {...props} title={<CampaignTitle />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs scrollable />}>
        <Tab label="Summary" className={classes.tabLayout}>
          <ImageField source="imageUrl" title="Mission's thumbail" />
          <CampaignSettings {...props} className={classes.content} />
        </Tab>
        <Tab label="Checkpoints" path="checkpoints" className={classes.checkpointTabLayout}>
          <AddNewCheckpointButtonStyled />
          <ReferenceManyField
            addLabel={false}
            pagination={null}
            filter={{ campaignId }}
            target="campaignId"
            reference="checkpoints"
          >
            <CheckpointsList {...props}>
              <TextField label="Order" sortable={false} />
              <TextField source="id" sortable={false} />
              <TextField source="checkpointType" sortable={false} />
              <TextField
                source="shortDescription"
                sortable={false}
                style={{ width: '100px', whiteSpace: 'normal' }}
              />
              <BooleanField
                source="hiddenToClients"
                testid="hiddenToClientsTest"
                sortable={false}
              />
              <BooleanField source="hiddenToUsers" sortable={false} />
              <BooleanField source="answerRequired" sortable={false} />
              <TextField source="photoQuality" testid="photoQualityTest" sortable={false} />
              <EditCheckpointButton campaignId={campaignId} />
              <CloneCheckpointButton campaignId={campaignId} />
              <DeleteCheckpointButton />
            </CheckpointsList>
          </ReferenceManyField>
        </Tab>
        <Tab label="Missions" path="missions" className={classes.tabLayout}>
          <MissionList {...props} campaignId={campaignId} />
        </Tab>
        <Tab
          label="Submissions"
          testid="submissions"
          className={classes.tabLayout}
          component="a"
          href={`/#/submissionsNew?filter=%7B"status"%3A%5B"Admin%20for%20review"%2C"Waiting%20For%20Validation"%2C"Completed%20Successfully"%2C"Pre-Validation"%2C"Upload%20Failed"%5D%2C"campaignTypes"%3A%5B"Industry%20Report"%2C"Client%20paid"%2C"Own%20activation"%2C"Own%20on-boarding"%2C"Brief"%2C"Own%20training"%2C"Own%20contest"%2C"Eligibility"%2C"Excellence%20program"%2C"Share%20of%20Visibility"%2C"Module"%2C"Trax"%5D%2C"isHiddenToClients"%3Afalse%2C"includes"%3A%5B"CampaignName"%2C"MissionLocation"%2C"UserNames"%2C"MissionSegments"%2C"MissionActive"%5D%2C"campaignId"%3A%5B"${campaignId}"%5D%7D&order=DESC&page=1&perPage=50&sort=acceptedAt`}
        />
        <Tab label="Segments" path="segments" className={classes.tabLayout}>
          <SegmentsPresenter />
        </Tab>
        <Tab label="Cash boosts" path="cash-boosts" className={classes.tabLayout}>
          <CashBoostPresenter />
        </Tab>
        <Tab label="Feedback" path="feedback" className={classes.tabLayout}>
          <MissionFeedback campaignId={campaignId} />
        </Tab>
        {permissions === 'ADMIN' && (
          <Tab label="Analytics" path="analytics" className={classes.tabLayout}>
            <CampaignAnalytics campaignId={campaignId} />
          </Tab>
        )}
        <Tab label="Notes" path="notes" className={classes.tabLayout}>
          <Notes />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default withStyles(styles)(CampaignShow);
