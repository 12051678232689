import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Field from '../../../../layout/components/fields/textField.presenter';
import BooleanField from '../../../../layout/components/fields/booleanField.presenter';
import styles from '../../../../layout/globalStyle/cardStyle';

const CompletionRules = withStyles(styles)(({ classes, record, ...props }) => {
  let minMaxPerSubmissions = record.maxSubmissionsPerLocation;

  if (
    record.minMaxSubmissionPerLocationRange &&
    record.minMaxSubmissionPerLocationRange.first &&
    record.minMaxSubmissionPerLocationRange.second
  ) {
    const min = record.minMaxSubmissionPerLocationRange.first;
    const max = record.minMaxSubmissionPerLocationRange.second;

    if (min !== max) {
      minMaxPerSubmissions = `min ${min} - max ${max}`;
    }
  }

  return (
    <Card {...props} testid="card" className={classes.content}>
      <CardHeader testid="header" title="Completion Rules" />
      <CardContent {...props}>
        <Field title="Max submission allowed per location" record={minMaxPerSubmissions} />
        <Field
          title="Max submissions allowed per user per campaign"
          record={record.maxSubmissionsPerUser}
        />
        <Field
          title="Max submissions allowed per user per location"
          record={record.maxSubmissionsPerUserPerLocation}
        />
        <Field
          title="Max submissions allowed per location per cycle"
          record={record.maxSubmissionsPerLocationPerCycle}
        />
        <Field title="Max active missions per user" record={record.maxActiveSubmissionsPerUser} />
        <BooleanField title="Second chance" record={record.secondChance} />
        <BooleanField title="Auto validation" record={record.autoValidationEnabled} />
        <Field title="Max per segment" record={record.maxPerSegment} />
      </CardContent>
    </Card>
  );
});

export default CompletionRules;
